import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { ButtonMob, instanceMob, Loadingspinner } from "../../../utils/utils";

const ModalBookingUsers = ({
  onCancel,
  id,
  startTime,
  onConfirm,
  cancelBooking,
  flag = false,
}) => {
  const styleModal = {
    display: "flex",
    position: "fixed",
    zIndex: 999,
    whiteSpace: "pre-line",
  };

  const [loader, setLoader] = useState(false);

  const [availability, setAvailability] = useState({});
  const [loading, setLoading] = useState(false);

  function getAvailability() {
    setLoading(true);
    instanceMob
      .get(
        `admin/availability/bookings?start_time=${startTime}&availability_id=${id}`
      )
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        setAvailability(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }

  useEffect(() => {
    console.log("Llamando a getAvailability...");
    getAvailability();
  }, [flag]);

  return (
    <>
      <div
        className="popUpServices justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "6% 10%",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "5%",
              right: "6%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={onCancel}
          >
            <i className="bi bi-x-circle"></i>
          </div>

          <div
            className="pt-3 my-4 mx-auto"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            <div>Asistentes a clase</div>
            {loading ? (
              <>
                <div className="my-2">
                  <Loadingspinner customStyle="grow" size="2.5rem" />
                </div>
              </>
            ) : (
              <div
                className="d-flex  m-auto justify-content-center align-items-center gap-4"
                style={{ minWidth: "20rem" }}
              >
                {" "}
                {availability?.bookings &&
                Object.keys(availability.bookings).length > 0 ? (
                  <div>
                    <PerfectScrollbar
                      style={{ maxHeight: "10rem" }}
                      className="d-flex my-3 flex-column"
                    >
                      {Object.values(availability.bookings).map(
                        (e) =>
                          e[0]?.users &&
                          e[0].users.map((e, index) => {
                            return (
                              <div
                                key={index + "user-in-booking"}
                                className="d-flex justify-content-center align-items-center gap-3 my-2"
                              >
                                <div>{e.customer_name}</div>
                                <button
                                  style={{
                                    fontSize: "calc(.33rem + .32vw)",
                                    marginLeft: "auto",
                                  }}
                                  className="  d-flex add_location px-4"
                                  onClick={() =>
                                    cancelBooking(e.booking_id, startTime)
                                  }
                                >
                                  Eliminar asistente
                                </button>
                              </div>
                            );
                          })
                      )}
                    </PerfectScrollbar>
                  </div>
                ) : availability?.failed_dates ? (
                  <div>
                    {availability.failed_dates[0]?.error_message ||
                      "No hay data"}
                  </div>
                ) : (
                  <div>No hay data</div>
                )}
              </div>
            )}
          </div>

          <ButtonMob
            handleClick={() => onConfirm(id, startTime)}
            colorButton={"var(--elevvabutonLogin_User)"}
            text={loader ? "Cargando..." : "Adicionar asistente"}
            className={"mb-3 mt-3 letraspopfi2actualizar"}
          />
        </div>
      </div>
    </>
  );
};

export default ModalBookingUsers;
