import MembershipsPlans from "./components/memberships-plans";
import MembershipsUsers from "./components/memberships-users";

const MobMembership = () => {
  return (
    <>
      <MembershipsPlans />
      <MembershipsUsers />
    </>
  );
};
export default MobMembership;
