import React, { useState } from "react";
import { ReactComponent as SearchInvidual } from "../../../assets/elevva-pro-icons/search-individual-table.svg";
import { ReactComponent as DeleteTag } from "../../../assets/elevva-pro-icons/delete-tag.svg";
import { ReactComponent as EditTag } from "../../../assets/elevva-pro-icons/edit-tag.svg";
import { ReactComponent as Person } from "../../../assets/elevva-pro-icons/person-service.svg";
import { ReactComponent as Clock } from "../../../assets/elevva-pro-icons/clock-service.svg";
import { ReactComponent as Location } from "../../../assets/elevva-pro-icons/location-service.svg";
import moment from "moment";

const cardStyle = {
  width: "15rem",
  border: "1px solid #d1d5db",
  borderRadius: "10px",
  padding: "0.5rem 1rem",
  backgroundColor: "#ffffff",
  fontFamily: "Arial, sans-serif",
  color: "#374151",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "1rem 0",
};

const titleStyle = {
  fontSize: "calc(.3rem + .3vw)",

  margin: 0,
};

const iconStyle = {
  cursor: "pointer",
  marginLeft: "0.5rem",
  color: "#9ca3af",
};

const separatorStyle = {
  border: 0,
  borderTop: "1px solid #A8C2D7",
  margin: "0.5rem 0",
};

const detailContainerStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "0.1rem",
  fontSize: "calc(.3rem + .3vw)",
  color: "#1f2937",
};

const iconTextStyle = {
  color: "#3b82f6",
  marginRight: "0.5rem",
  fontSize: "calc(.3rem + .3vw)",
};

const secondaryTextStyle = {
  color: "#3b82f6",
  marginLeft: "1rem",
  marginRight: "0.5rem",
  fontSize: "calc(.3rem + .3vw)",
};

const locationContainerStyle = {
  display: "flex",
  alignItems: "center",
  fontSize: "calc(.3rem + .3vw)",
  color: "#1f2937",
};

const searchIconStyle = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  fontSize: "1rem",
  color: "#3b82f6",

  cursor: "pointer",
};

const CardBookings = (props) => {
  const modalServiceDetail = props.modalServiceDetail;
  const service = props.booking;
  const schedule = props.schedule;

  return (
    <>
      <div style={cardStyle}>
        <div style={headerStyle}>
          <h3 style={titleStyle}>{service.service_name}</h3>
        </div>
        <hr style={separatorStyle} />
        <div>
          <div className="d-flex">
            <span style={iconTextStyle}>
              <Clock />
            </span>
            <span style={{ fontSize: "calc(.3rem + .3vw)" }}>
              {moment(schedule.start_time, "HH:mm:ss").format("h:mm a ")} -{" "}
              {moment(schedule.end_time, "HH:mm:ss").format("h:mm a ")}
              <br></br>
              <span style={{ fontSize: "calc(.3rem + .3vw)" }}>
                {schedule.day}
              </span>
            </span>
          </div>
          <div style={detailContainerStyle}>
            <span style={iconTextStyle}>
              <Clock />
            </span>
            <span style={{ fontSize: "calc(.3rem + .3vw)" }}>
              {service.duration} min
            </span>
            <span style={secondaryTextStyle}>
              <Person />{" "}
            </span>
            <span style={{ fontSize: "calc(.3rem + .3vw)" }}>
              {schedule?.attendees_count || 0}/{schedule.available_slots} cupos
            </span>
          </div>
          <div style={locationContainerStyle}>
            <span style={iconTextStyle}>
              <Location />
            </span>
            <div style={{ lineHeight: "1" }}>
              <span style={{ fontSize: "calc(.3rem + .3vw)" }}>
                {service.location_name}
              </span>
            </div>
          </div>
        </div>
        <div style={searchIconStyle}>
          <SearchInvidual
            width={"1.2rem"}
            onClick={() =>
              modalServiceDetail(
                service.id,
                `${schedule.day} ${moment(schedule.start_time, "HH:mm").format(
                  "h:mm"
                )}`
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default CardBookings;
