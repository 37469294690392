import React from "react";

const cardStyle = {
  width: "12rem",
  border: "1px solid #d1d5db",
  borderRadius: "5px",

  fontFamily: "Arial, sans-serif",
  backgroundColor: "white",
};

const titleStyle = {
  fontSize: "calc(.4rem + .4vw)",
  fontWeight: "bold",

  color: "#374151",
};

const categoryStyle = {
  backgroundColor: "#e5e7eb",
  fontSize: "calc(.4rem + .4vw)",
  padding: "0.3rem 1rem",
  textAlign: "left",
  color: "#374151",
  marginBottom: "10px",
};

const locationStyle = {
  margin: "5px 0",
  fontSize: "calc(.4rem + .4vw)",
  color: "#6b7280",
  padding: "0 1rem",
};

const CardClass = () => {
  return (
    <div style={cardStyle}>
      <div style={{ padding: "0.5rem 1rem" }}>
        <span style={titleStyle}>Crossfit Supremacy</span>
      </div>
      <div style={categoryStyle}>Funcional</div>
      <div style={locationStyle}>
        <p>
          <strong>Colina - Polo Club</strong>
          <br />
          Lun - Mie - Vie
          <br />
          8:00 a.m.
        </p>
        <p>
          <strong>Suba</strong>
          <br />
          Lun - Mie - Vie
          <br />
          8:00 a.m.
        </p>
      </div>
    </div>
  );
};

export default CardClass;
