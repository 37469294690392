import React from "react";
import { useLocation } from "react-router-dom";

import "./NavBar.css";

import { Allitems } from "./utilNav";

import { useState } from "react";
import { checkPathNavFoot } from "../../utils/utils";
const NavBar = (props) => {
  const typeCustomer = props.typeCustomer;
const handlePasswordUpdate = props.handlePasswordUpdate;
const location = useLocation();

return (
  <>
    {checkPathNavFoot(location.pathname, typeCustomer) ? null : (
      <>
        <div
          className=" navbarscroll d-none d-md-block "
          style={{
            background: "var(--backgroundOnboarding)",
            width: "60px",
            flexShrink: "0",
          }}
        >
          <div
            className=" w-100 mx-auto   text-center"
            style={{ paddingLeft: "0px", paddingTop: "15px" }}
          >
            <img
              alt="logo_alta_black"
              style={{ padding: "0% 20%" }}
              src={process.env.PUBLIC_URL + "/assets/img/logo_small_elevva.png"}
            />
          </div>
          <Allitems
            typeCustomer={typeCustomer}
            handlePasswordUpdate={handlePasswordUpdate}
          />
        </div>
      </>
    )}
  </>
);
};
export default NavBar;
