import CardClass from "./card-class";
import { ReactComponent as ArrowLeft } from "../../../assets/elevva-pro-icons/table-arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../assets/elevva-pro-icons/table-arrow-right.svg";
import { laptop1350, laptop1600, Loadingspinner } from "../../../utils/utils";
import debounce from "../functions/debounce";
import { ReactComponent as SearchIconAlone } from "../../../assets/elevva-pro-icons/searchIconAlone.svg";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

const Clases = (props) => {
  const addClass = props.addClass;
  const searchAvailabilities = props.searchAvailabilities;
  const availabilitys = props.availabilitys;
  const isLoading = props.isLoading;
  const page = props.page;
  const changePage = props.changePage;
  const count = props.count;
  const deleteAvailability = props.deleteAvailability;
  const editAvailability = props.editAvailability;
  const modalAvailabilityDetail = props.modalAvailabilityDetail;

  

  return (
    <div className="h-100">
      <div className="my-2" style={{ marginLeft: "40%" }}>
        <span
          style={{
            fontSize: "calc(.4rem + .4vw)",
            color: "var(--elevvaTextTitle)",
          }}
        >
          Clases <strong>disponibles</strong>{" "}
        </span>
      </div>
      <div className="d-flex px-4 justify-content-between ">
        <div
          style={{
            position: "relative",
            width: "auto",
            minWidth: laptop1350.matches ? "25rem" : "10rem",
          }}
          className="me-2"
        >
          <input
            placeholder="Buscar por servicio o locación"
            name="search_clases"
            type="search"
            className=" searchUsers  py-2 mx-auto"
            style={{
              border: "1px solid var(--elevvaTextSubtitle)",
              borderRadius: "20px",
              fontSize: "calc(.33rem + .32vw)",

              // width: "70%",
            }}
            onChange={debounce(searchAvailabilities, 500)}
          ></input>
          <span className="search-icon-admin">
            <SearchIconAlone fontSize={10} />
          </span>
        </div>
        <button
          style={{ marginRight: "2%" }}
          className="  d-flex add_location"
          onClick={addClass}
        >
          Agregar clase +
        </button>
      </div>
      {isLoading ? (
        <div
          style={{ height: "calc(100% - 7rem)" }}
          className="d-flex justify-content-center align-items-center"
        >
          {" "}
          <Loadingspinner customStyle="grow" size="2.5rem" />
        </div>
      ) : availabilitys && availabilitys.length > 0 ? (
        <PerfectScrollbar
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
            gap: `1rem`,
            padding: `1rem`,
            height: "calc(100% - 10rem)",
          }}
        >
          {availabilitys &&
            availabilitys.length > 0 &&
            availabilitys.map((availability, index) => (
              <CardClass
                key={index + "availability"}
                availability={availability}
                editAvailability={editAvailability}
                deleteAvailability={deleteAvailability}
                modalAvailabilityDetail={modalAvailabilityDetail}
              />
            ))}
        </PerfectScrollbar>
      ) : (
        <div
          style={{
            height: "calc(100% - 7rem)",
            fontSize: "calc(.4rem + .4vw)",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          No hay clases disponibles
        </div>
      )}
      <div className="d-flex justify-content-end align-items-center gap-3 mt-2 px-3">
        <span>
          {page * 10 - 10 === 0 || count >= 10
            ? page === 1
              ? 1
              : count
            : page * 10 - 10}
          - {page * 10 >= count ? count : page * 10} de {count}
        </span>
        <div
          onClick={count <= 10 ? null : () => changePage("previous")}
          className="cursor-pointer"
        >
          <ArrowLeft />
        </div>
        <div
          onClick={page * 10 >= count ? null : () => changePage("next")}
          className="cursor-pointer"
        >
          <ArrowRight />
        </div>
      </div>
    </div>
  );
};

export default Clases;
