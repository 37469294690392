import CardLocation from "./card-locations";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/swiper.min.css";
import { laptop1600, Loadingspinner } from "../../../utils/utils";

const Locations = (props) => {
  SwiperCore.use([Pagination, Autoplay, Navigation]);
  const modalAddLocation = props.modalAddLocation;
  const locations = props.locations;
  const cities = props.cities;
  const isLoading = props.isLoading;

  return (
    <div className="d-flex h-100 justify-content-center  align-items-center flex-column">
      <div
        className="d-flex w-100"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0.5rem",
        }}
      >
        <div className="py-0" style={{ marginLeft: "40%" }}>
          <span
            style={{
              fontSize: "calc(.4rem + .4vw)",
              color: "var(--elevvaTextTitle)",
            }}
          >
            Locaciones de <strong>entrenamiento</strong>{" "}
          </span>
        </div>
        <button
          style={{ marginRight: "2%" }}
          className="  d-flex add_location"
          onClick={modalAddLocation}
        >
          Agregar locación +
        </button>
      </div>
      {isLoading ? (
        <Loadingspinner customStyle="grow" size="2.5rem" />
      ) : (
        <>
          {locations.length > 0 ? (
            <div
              className="position-relative w-100 h-100"
              style={{
                width: "95%",
                overflow: "hidden",
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className={
                  "d-flex position-absolute  w-100 h-100 swiper-pagination_p"
                }
              >
                <div
                  style={{ zIndex: 10 }}
                  className={`button-prev-exercise-${`location`} swiper-button-prev-exercise`}
                ></div>
                <div
                  className={`button-next-exercise-${`location`} swiper-button-next-exercise`}
                  style={{
                    zIndex: 10,
                    left: laptop1600.matches ? "90%" : "88%",
                    transform: "translateX(-50%)",
                  }}
                ></div>
              </div>
              <Swiper
                style={{ width: "80%", padding: "1rem 0", height: "100%" }}
                id={`swiper-company-${`location`}`}
                keyboard={{
                  enabled: true,
                }}
                slidesPerView={"auto"}
                navigation={{
                  nextEl: `.button-next-exercise-${`location`}`,
                  prevEl: `.button-prev-exercise-${`location`}`,
                }}
                modules={[Navigation]}
              >
                {locations.length > 0 &&
                  locations.map((location) => (
                    <SwiperSlide
                      className="d-flex position-relative  flex-column w-auto  mx-2 h-100 "
                      key={location.id}
                    >
                      {" "}
                      <CardLocation
                        location={location}
                        cities={cities}
                        deleteLocation={props.deleteLocation}
                        editLocation={props.editLocation}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          ) : (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
              <span
                style={{
                  fontSize: "calc(.4rem + .4vw)",
                  color: "var(--elevvaTextTitle)",
                }}
              >
                No hay locaciones de entrenamiento
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Locations;
