import imageDefaultLogo from "../../../assets/corporative/item-card.png";
import { ReactComponent as DeleteTag } from "../../../assets/elevva-pro-icons/delete-tag.svg";
import { ReactComponent as EditTag } from "../../../assets/elevva-pro-icons/edit-tag.svg";
const CardLocation = (props) => {
  const styles = {
    card: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      padding: "0.5rem",
      width: "18rem",
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      position: "relative",
    },
    cardContent: {
      display: "flex",
      alignItems: "center",
    },
    logo: {
      width: "3rem",
      height: "3rem",
      borderRadius: "50%",
      marginRight: "calc(.4rem + .4vw)",
    },
    textContent: {
      fontSize: "calc(.3rem + .4vw)",
      color: "#1F2937",
    },
    title: {
      fontSize: "calc(.4rem + .4vw)",
      fontWeight: "bold",
      margin: "0 0 4px 0",
    },
    address: {
      margin: 0,
      color: "#374151",
    },
    schedule: {
      margin: "0.1rem 0 0 0",
      color: "#374151",
    },
    dateTitle: {
      margin: "0.1rem 0 0 0",
      fontWeight: "bold",
      color: "#1F2937",
    },
    date: {
      margin: 0,
      color: "#4B5563",
    },
    icons: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "auto",
      gap: "1rem",
      width: "20%",
    },
    icon: {
      fontSize: "5rem",
      color: "#6B7280",
      cursor: "pointer",
    },
  };

  return (
    <div style={styles.card}>
      <div style={styles.cardContent}>
        <div style={{ marginBottom: "auto", paddingTop: "1rem" }}>
          <img src={imageDefaultLogo} alt="Logo" style={styles.logo} />
        </div>
        <div style={styles.textContent}>
          <h2 style={styles.title}>Crossfit Supremacy</h2>
          <p style={styles.address}>
            Carrera 18 # 83 - 18
            <br />
            Bogotá - Colombia
          </p>

          <p style={styles.schedule}>
            <strong>Jue - Vie - Sab - Dom</strong>
            <br />
            6:00 a.m. - 8:00 p.m.
          </p>
          <p style={styles.dateTitle}>Fecha registro</p>
          <p style={styles.date}>8 de octubre de 2024</p>
        </div>
      </div>
      <div style={styles.icons}>
        <EditTag width={"100%"} />
        <DeleteTag width={"100%"} />
      </div>
    </div>
  );
};
export default CardLocation;
