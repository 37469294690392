import  React,{ useCallback, PointerEvent, useState, useRef, useMemo, useEffect } from "react";
import { extent, max } from "d3-array";
import { curveLinear,curveStep,curveNatural } from "@visx/curve";
import { AreaStack, LinePath } from "@visx/shape";
import { scaleLinear, scaleTime } from "@visx/scale";
import { AxisBottom, AxisLeft, AxisRight, TickLabelProps } from "@visx/axis";
import { EditableAnnotation, LineSubject } from "@visx/annotation";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { Brush } from "@visx/brush";
import { Bounds } from "@visx/brush/lib/types";
import BaseBrush from "@visx/brush/lib/BaseBrush";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { BrushHandleRenderProps } from '@visx/brush/lib/BrushHandle';
import moment from "moment";

type PriceData = {
  price: number;
  date: string;
  dateOriginal:any;
  user:any
};

type PriceDataParsed = {
  price: number;
  date: any;
  dateOriginal:any;
  user:any
};

export type PriceDataSeries = {
  title: string;
  color: string;
  data: PriceData[];
};

type PriceDataSeriesParsed = {
  title: string;
  color: string;
  data: PriceDataParsed[];
};

const getPrice = (d: PriceDataParsed) => d?.price;
const getPricerisk = (d: PriceDataParsed) => d?.price;
const getYear = (d: PriceDataParsed) => (d?.date);
const getYearT = (d: PriceDataParsed) => d?.dateOriginal;

const parseYear = (date: any) => date[0];

const LABEL_COLOR = "#3C3C3B";
const LABEL_COLORIGHT = "#FF0000";
const MARGIN_LEFT = 45;

const MARGIN_BOTTOM = 48;
const PADDING_LEFT = 10;
const PADDING_RIGHT = 10;
const BRUSH_HEIGHT = 56;

const axisLabelProps = {
  fill: LABEL_COLOR,
  fontSize: 16,
  textAnchor: "middle"
} as const;

const getLeftAxisTickLabelProps = () =>
  ({
    fill: LABEL_COLOR,
    textAnchor: "end",
    fontSize: 12
  } as const);
  const getRighttAxisTickLabelProps = () =>
  ({
    fill: LABEL_COLORIGHT,
    textAnchor: "end",
    fontSize: 12,fontWeight:"bold"
  } as const);

const getBottomAxisTickLabelProps: TickLabelProps<{ valueOf(): number }> = (
  _,
  __, // i
  ___ // labels
) => ({
  // fill: i === 0 || i === labels.length - 1 ? "none" : LABEL_COLOR,
  fille: LABEL_COLOR,
  textAnchor: "middle",
  fontSize: 12
});

// Interpolate the price given a year between two data points (using y = mx + c)
const interpolatePrice = (
  x: number,
  p1: PriceDataParsed, // [x,y]
  p2: PriceDataParsed // [x,y]
) => {
  // slope/gradient = (y2 - y1) / (x2 - x1)
  const m = (p2.price - p1.price) / (p2.date[0].getTime() - p1.date[0].getTime());
  // y-intercept = y - mx
  const c = p1.price - m * p1.date[0].getTime();
  return m * x + c;
};

export function PPACalculatorChart(props: {
  width: number;
  height: number;
  series: PriceDataSeries[];
  DataUserAge:any;
  option:any;
  focusOnChange: string[]; // The title of the series to focus on (with the brush) change
}) {
  const { width, focusOnChange,option,DataUserAge } = props;
 
  const MARGIN_RIGHT =option.includes("1006")? 45:0;
  let dataDummie =[{color:"blue",title:"rigthTest",data:[{price:2,date:0,user:'Alejandro',dateOriginal:new Date()},{price:3,date:7000,user:'Alejandro',dateOriginal:new Date()},{price:5,date:2287000,user:'Alejandro',dateOriginal:new Date()}]}]
  const series: PriceDataSeriesParsed[] = useMemo(
    () =>
      props.series.map((s) => ({
        ...s,
        data: s.data.map((sd) => ({
          price: sd.price,
          date: sd.date,
          dateOriginal:new Date(sd.date),
          user:sd.user
        }))
      })),
    [props.series]
  );

  const brushRef = useRef<BaseBrush | null>(null);
  const [filteredSeries, setFilteredSeries] = useState<PriceDataSeriesParsed[]>(
    series
  );
  
  
  useEffect(() => {
    setFilteredSeries(series)
   
  }, [series]);
 
  // All data flattened
  const  sortedData = series.flatMap((d) => d.data);
  const allData  = sortedData.sort((a:any, b:any) => a.date - b.date);
  const allFilteredDatasd = filteredSeries.flatMap((d) => d.data);
  const allFilteredData  = allFilteredDatasd.sort((a:any, b:any) => a.date - b.date);

  // Bounds
  const brushHeight = BRUSH_HEIGHT;
  const height = props.height - brushHeight;

  const outerWidth = width - MARGIN_LEFT - MARGIN_RIGHT; // - MARGIN_RIGHT
  const innerWidth = outerWidth - PADDING_LEFT - PADDING_RIGHT;

  const outerHeight = height - MARGIN_BOTTOM; // - MARGIN_TOP
  const innerHeight = outerHeight;

  // where the axis goes
  const outerTop = option.includes("1006")?0:20; // MARGIN_TOP
  const outerBottom = outerTop + outerHeight;
  const outerLeft = MARGIN_LEFT;
  const outerRight = outerWidth + outerLeft;

  const innerTop = outerTop;
  const innerBottom = innerTop + innerHeight;
  const innerLeft = outerLeft + PADDING_LEFT;
  const innerRight = innerWidth + innerLeft;
  const innerRightBrs = innerWidth;
  // const xScale = scaleTime({
  //   domain: extent(allFilteredData, getYear) as [Date, Date],
  //   range: [innerLeft, innerRight]
  // });
  const xScale = useMemo(
    () =>
      scaleTime<number>({
        domain: extent(allFilteredData, getYear) as [Date, Date],
        range: [innerLeft, innerRight]
        
      }),
    [ filteredSeries]
  );
  // const xScale = useMemo(
  //   () =>
  //     scaleTime<number>({
  //       domain: extent(allFilteredData, getYear) as [Date, Date],
  //       range: [innerLeft, innerRight]
        
  //     }),
  //   [ allFilteredData,filteredSeries]
  // );
  // const yScale = scaleLinear<number>({
  //   domain: [0, max(allFilteredData, getPrice) as number],
  //   range: [innerBottom, innerTop]
  // });
  let ageUser = option.includes("1006")?220 - DataUserAge[0]?.customer?.age:0
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: [0, ageUser?ageUser:max(allFilteredData, getPrice) as number],
    range: [innerBottom, innerTop],
        nice: true
      }),
    [innerBottom, innerTop,filteredSeries]
  );
  const yScaleRight = useMemo(
    () =>
      scaleLinear<number>({
        domain: [0, 5],
     range: [innerBottom, innerTop],
   
        nice: true
      }),
    [innerBottom, innerTop,filteredSeries]
  );
  const xScaleGetter = useCallback((d: PriceDataParsed) => xScale(getYear(d)), [
    xScale
  ]);

  const yScaleGetter = useCallback(
    (d: PriceDataParsed) => yScale(getPrice(d)),
    [yScale]
  );
  const yScaleGetterRigth = useCallback(
    (d: PriceDataParsed) => yScaleRight(getPrice(d)),
    [yScaleRight]
  );
  // Brush
  const brushXScaleBrush = useMemo(
    () =>
      scaleTime<number>({
        domain: extent(allData, getYear) as [Date, Date],
    range: [0, innerRightBrs]
      }),
    [innerRightBrs,filteredSeries,series]
  );
  // const brushXScale = scaleTime({
  //   domain: extent(allData, getYear) as [Date, Date],
  //   range: [0, innerRight]
  // });
  const brushXScale = useMemo(
    () =>
      scaleTime<number>({
        domain: extent(allData, getYear) as [Date, Date],
    range: [0, innerWidth ]
      }),
    [innerRight,filteredSeries,series]
  );
  // const brushYScale = scaleLinear<number>({
  //   domain: [0, max(allData, getPrice) as number],
  //   range: [brushHeight, 0]
  // });
  const brushYScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, max(allData, getPrice) as number],
     range: [brushHeight, 0],
        nice: true
      }),
    [brushHeight,filteredSeries,series]
  );
  const onBrushChange = (domain: Bounds | null) => {
    if (!domain) return;
    const { x0, x1,y0, y1 } = domain;
    
    const seriesCopy = series.map((s) => {
      const sortedData = s.data.sort((a:any, b:any) => a.date - b.date);
      const [xMin, xMax] = extent(sortedData, getYearT) as [Date, Date];
      const filteredData = sortedData.filter((datum,index) => {
        
        const x = getYearT(datum).getTime();
      
        // if((brushXScale(xMax)) - (((brushXScale(getYear(datum))) - - brushXScale(new Date(x1)))/2)  < 30){
        //   return x >= x0 && x <=  xMax.getTime();
        // }else{

          return x >= x0 && x <= x1;
        // };
      });
     
    // if (!domain) return;
    // const { x0, x1, y0, y1 } = domain;
    // const stockCopy = series.flatMap((d) => d.data).filter((s:any) => {
    //   const x =  getYear(s.date).getTime();
    //   const y = getPrice(s.price);
     
    //   // return domain?.xValues?.includes(s.date)
    //   return x > x0 && x < x1 && y > y0 && y < y1;
    // });
    
    //  setFilteredSeries(stockCopy);

      // Interpolate price at x0 and prepend a data point for it
      // This ensures there's always a value on the chart at either end
      // when necessary.
      
      // For example if the brush domain, x0 and x1, falls between p1 and p2:
      
      // |---p1--x0--x1--p2---|
      
      // We'll add interpolated data points for x0 and x1 and show a
      // segment of the line. We use the simple straight line function
      // for the interpolation: y = mx + c
      // If you can think of a cleaner/more performant way to handle this
      // please holla!
      // if (xMin.getTime() < x0) {
      //   const date = new Date(x0);
      //   const year = date;
      //   const p1Index = s.data.findIndex(
      //     (sd) => sd.date === year
      //   );
      //   if (p1Index >= 0 && p1Index < s.data.length - 1) {
      //     const p1 = s.data[p1Index];
      //     const p2 = s.data[p1Index + 1];
      //     const price = interpolatePrice(x0, p1, p2);
      //     filteredData.unshift({ date, price });
      //   }
      // }

      // // Same for other end
      // if (xMax.getTime() > x1) {
      //   const date = new Date(x1);
      //   const year = date;
      //   const p1Index = s.data.findIndex(
      //     (sd) => sd.date === year
      //   );
      //   if (p1Index >= 0 && p1Index <= s.data.length - 1) {
      //     const p1 = s.data[p1Index];
      //     const p2 = s.data[p1Index + 1];
      //     const price = interpolatePrice(x1, p1, p2);
      //     filteredData.push({ date, price });
      //   }
      // }

      return { ...s, data: filteredData };
    });
   
    setFilteredSeries(seriesCopy);
    
  };

  /**
   * Tooltips
   */
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip
  } = useTooltip<{
    date: Date;price:any,title:string,user:any
    // seriesData: { title: string; color: string; price: number }[];
  }>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true
  });

  // const handlePointerMove = useCallback(
  //   (event: PointerEvent<SVGSVGElement>) => {
  //     const coords = localPoint(event.currentTarget, event);
  //     if (coords) {
  //       const closestDate = xScale.invert(coords.x);
  //       const closestDateX = xScale(closestDate);
  //       if (closestDateX >= innerLeft && closestDateX <= innerRight) {
  //         showTooltip({
  //           tooltipLeft: closestDateX,
  //           tooltipTop: coords.y,
  //           tooltipData: {
  //             : closestDate,
  //             seriesData: filteredSeries.reduce((r, s) => {
  //               console.log(s.data,closestDate)
  //               const datumAtYear = s.data.find(
  //                 (d) => d.date.getTime() === closestDate.getTime()
  //               );
  //               if (datumAtYear) {
  //                 r.push({
  //                   title: s.title,
  //                   color: s.color,
  //                   price: datumAtYear.price
  //                 });
  //               }
  //               return r;
  //             }, [] as { title: string; color: string; price: number }[])
  //           }
  //         });
  //       }
  //     }
  //   },
  //   [filteredSeries, showTooltip, xScale, innerLeft, innerRight]
  // );
  const handlePointerMoves =(event:any)=>{
    const coords = localPoint(event.target.ownerSVGElement, event);
    let title= event.target.getAttribute('data-title')
    let user= event.target.getAttribute('data-user')
    let price = event.target.getAttribute('data-price')
    let date = event.target.getAttribute('data-date')
    
    if(price){

      showTooltip({
        tooltipLeft: coords?.x,
        tooltipTop: coords?.y,
        tooltipData: {date:date,price:price,user:user,title:title}
      });
    }
  }
  const handlePointerMove = (event:any, datum:any,title:string) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    let nuevodA=datum;
    nuevodA.title=title
    showTooltip({
      tooltipLeft: coords?.x,
      tooltipTop: coords?.y,
      tooltipData: nuevodA
    });
  ;
     
  };

  

  const handlePointerOut = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);
  function BrushHandle({ x, height, isBrushActive }: BrushHandleRenderProps) {
    const pathWidth = 8;
    const pathHeight = 15;
    if (!isBrushActive) {
      return null;
    }
    return (
      <Group left={x + pathWidth / 2} top={(height - pathHeight) / 2}>
        <path
          fill="#f2f2f2"
          d="M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5 M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12"
          stroke="#999999"
          strokeWidth="1"
          style={{ cursor: 'ew-resize' }}
        />
      </Group>
    );
  }
  // Focus the brush on specific series
  // const focusOnSeries = useCallback(() => {
  //   if (focusOnChange && brushRef.current) {
  //     const seriesToFocus = series.filter((s) =>
  //       focusOnChange.includes(s.title)
  //     );
  //     if (seriesToFocus.length > 0) {
  //       const flat = seriesToFocus.flatMap((d) => d.data);
  //       const [xMin, xMax] = extent(flat, getYear);
  //       if (xMin && xMax) {
  //         const brushExtent = brushRef.current.getExtent(
  //           { x: brushXScale(xMin) },
  //           { x: brushXScale(xMax) }
  //         );
  //         brushRef.current.updateBrush((prev) => ({
  //           ...prev,
  //           start: { ...prev.start, x: brushExtent.x0 },
  //           end: { ...prev.end, x: brushExtent.x1 },
  //           extent: brushExtent
  //         }));
  //       }
  //     }
  //   }
  // }, [series, focusOnChange, brushXScale]);

  // Separate at current year
  const separatorDate = parseYear(new Date());
  const separatorDateX = xScale(separatorDate);
  const separatorAreaWidth = separatorDateX - outerLeft;
  const showSeparator = separatorAreaWidth > 1;
  
  const initialBrushPositionC = useMemo(
    () => {
       // Agregar console.log aquí
       if(filteredSeries.length>0){
      
        let data=filteredSeries.flatMap((d) => d.data);
        const sortedData = data.sort((a:any, b:any) => a.date - b.date);  
    
        return {
          start: { x: brushXScaleBrush(getYearT(sortedData[0])) },
          end: { x: brushXScaleBrush(getYearT(sortedData[sortedData.length - 1])) }
        };
      } 
    },
    [filteredSeries,brushXScaleBrush]
  );
  function formatTime(date:any) {
    // Add leading zeros to ensure two digits for hours, minutes, and seconds
    return date.getUTCHours().toString().padStart(2, '0') + ':' +
           date.getUTCMinutes().toString().padStart(2, '0') + ':' +
           date.getUTCSeconds().toString().padStart(2, '0');
};
const [minValue, maxValue] = extent(allFilteredData.map(getPrice)) as [number, number];

const minValueAdjusted = minValue - (maxValue - minValue) * 0.05;
const getTickYPosition = (tickValue, maxTickValue,ageUser) => {

  if(tickValue ==1){
   
    return yScale((ageUser*(60 - 23))/100);
  } if(tickValue ==2){
   
    return yScale((ageUser*(70 - 3))/100);
  }if(tickValue ==3){
   
    return yScale((ageUser*(80 - 3))/100);
  }if(tickValue ==4){
    
    return yScale((ageUser*(90 - 3))/100);
  }else{
  
    return yScale((ageUser*(100 - 3))/100);
  }
  
};

const totality=[ {color:"rgba(255,0,0,0.5)",data:[ { price: (ageUser*(90))/100 || 160 },
  {  price:ageUser}]},{color:"rgba(252,134,0,0.5)",data:[{ price: (ageUser*(80))/100 },
  {  price:(ageUser*(90))/100 || 160}]},{color:"rgba(0,252,109,0.5)",data:[{ price:  (ageUser*(70))/100 || 90 },
    {  price:  (ageUser*(80))/100}]},{color:"rgba(0,76,255,0.5)",data:[{ price:  (ageUser*(60))/100 ||55 },
      {  price:  (ageUser*(70))/100}]},{color:"rgba(210,210,210,0.5)",data:[{ price: 0 },
        {  price: (ageUser*(60))/100}]}]

const keys = ['price'];
  return (
    <>
      <svg
        ref={containerRef}
        width={width}
        height={height}
         onPointerMove={handlePointerMoves}
        onPointerOut={handlePointerOut}
      >
          <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill={`#fff`}
          rx={14}
        />
        {/* {showSeparator && (
          <rect
            x={outerLeft}
            y={outerTop}
            width={separatorAreaWidth}
            height={outerHeight}
            fill="#FAFAFA"
          />
        )} */}
        <GridRows
          scale={yScale}
          left={outerLeft}
          width={outerWidth}
          stroke="#EEEEEE"
        />
{option.includes("1006")?  totality.map((cases,index)=>(<AreaStack
 
 data={ cases.data}
 // Aquí solo tenemos una serie
  keys={keys}
  
  x0={(d) =>MARGIN_LEFT}
  x1={(d) =>width  - MARGIN_LEFT}
  y1={(d) => yScale(d[1])}
  y0={(d) =>yScale(d[1])}
>
  {({ stacks, path }) =>
    stacks.map((stack) => (
      <path
      key={`stack-${stack.key}`}
      d={path(stack) || ''}
      fill={cases.color}
      stroke="#fff"
      strokeWidth={3}
      // onAnimationEnd={handleAnimationEnd}
      // style={{ animation: 'fadeIn 2s ease forwards' }}
    />
    ))
  }
</AreaStack>)) :null}
        {/* Data lines */}
        {filteredSeries.map(({ color, data,title }, i) => (
           <g  key={`line-${i}`}>
          <LinePath 
            key={`line-${i}`}
            curve={curveStep}
            data={data}
            x={xScaleGetter}
            y={yScaleGetter}
            stroke={color}
            strokeWidth={1}
            shapeRendering="geometricPrecision"
          />
          {data.map((point, j) => (
            <circle
             data-title={title}
             data-price={point.price || null}
             data-date={point.date || null}
             data-user={point.user || null}
              key={j}
              r={2}
              cx={xScaleGetter(point)}
              cy={yScaleGetter(point)}
              // onPointerMove={(e) =>{
              //  console.log("e",point);
              //   return handlePointerMove(e,point,title)}}
               
              fill={color}
            />
          ))}</g>
        ))}
      

        {/* Axes */}
        <AxisLeft
          left={outerLeft}
          // label="price"
          scale={yScale}
          hideTicks
          hideAxisLine
          stroke={LABEL_COLOR}
          labelProps={axisLabelProps}
          labelOffset={16}
          tickLength={4}
          tickLabelProps={getLeftAxisTickLabelProps}
        />
       {option.includes("1006")? <AxisRight
           left={brushXScaleBrush(getYear(allData[allData.length - 1])) + MARGIN_RIGHT + (MARGIN_LEFT/1.4)}
           orientation="right"
           numTicks={5}
          // label="price"
        
          scale={yScaleRight}
          top={10}
          hideZero
          hideTicks
          hideAxisLine
          stroke={LABEL_COLOR}
          labelProps={axisLabelProps}
          labelOffset={16}
          tickLength={4}
          tickLabelProps={getRighttAxisTickLabelProps}
          tickFormat={(value:any, index:number, array:any) => { return value}}
            // Calcula la posición y usando getTickYPosition
    tickComponent={({x, y, formattedValue }) => (
      <text
          key={`tick-${formattedValue}`}
          x={0}
          fill="red"
          y={getTickYPosition(formattedValue, 5,ageUser)} // 5 es el máximo del dominio de la escala y 180 es el rango máximo
          style={{ fontSize: 10, textAnchor: 'end',fontWeight:"bold" }}
      >
          {formattedValue}
      </text>
  )}
        />:null}
     
        <AxisBottom
          top={outerBottom}
          // label="date"
          scale={xScale}
          hideTicks
          hideAxisLine
          stroke={LABEL_COLOR}
          
          // labelProps={axisLabelProps}
          // labelOffset={0}
          // tickLength={0}
           tickFormat={(value:any, index:number, array:any) => {
         
      
            // const firstDate = moment(array[0].value);
            // const lastDate = moment(array[array.length - 1].value);
            // const diff = lastDate.diff(firstDate, 'hours');
            // console.log(array[0],firstDate.format('L'),lastDate,diff)
             
                return formatTime(value)  
              
            
          }}
          // labelProps={axisLabelProps}
          // labelOffset={0}
          // tickLength={0}
           numTicks={width > 520 ? 5 : 5}
          tickLabelProps={getBottomAxisTickLabelProps}
        />

        {/* {showSeparator && (
          <EditableAnnotation
            canEditLabel={false}
            width={200}
            height={height}
            x={separatorDateX}
          >
            <LineSubject
              min={innerTop}
              max={innerBottom}
              stroke="#EEEEEE"
              strokeWidth={2}
              strokeDasharray="4 3"
            />
          </EditableAnnotation>
        )} */}

        {/* Fancy axis line with a curved corner, the radius is 8px */}
     { option.includes("1006")?null:  <path
          d={`M${outerLeft} ${outerTop}
              V${outerBottom - 8}
              Q${outerLeft} ${outerBottom} ${outerLeft + 8} ${outerBottom}
              H${outerRight}`}
          fill="none"
          stroke={LABEL_COLOR}
          strokeWidth={1}
        />}

        {/* Tooltip line */}
        {tooltipOpen && (
          <line
            x1={tooltipLeft}
            x2={tooltipLeft}
            y1={outerTop}
            y2={outerBottom}
            stroke={"#F77F00"}
            strokeWidth={1}
            strokeDasharray="4 3"
          />
        )}
      </svg>

      {/* Tooltip */}
      { tooltipOpen && tooltipData && (
        <TooltipInPortal
          // set this to random so it correctly updates with parent bounds
          
          top={tooltipTop}
          left={tooltipLeft}
        >
          <div style={{textAlign:"center"}}>
            <b> {tooltipData?.user?tooltipData?.user:tooltipData?.title}</b>
            <br />
            <br />
            {tooltipData?.price?tooltipData?.price + " ppm":0}
            <br />
            {tooltipData?.date?formatTime(new Date(Number(tooltipData.date))):""}
          {/* date:  {tooltipData?.date?tooltipData?.date:"0"}
          position:  {tooltipData?.user?tooltipData?.user:"us"} */}
            {/* {tooltipData.seriesData[0].map((d) => 
          
              (
              <div key={d.title}>
                <b style={{ color: d.color }}>{d.title}: </b>£{d.price}
              </div>
            )
            )} */}
          </div>
        </TooltipInPortal>
      )}

      {/* eed a separate svg for the brush as we use the svg border for axis lines */}
   
   <svg
        width={innerWidth}
        height={brushHeight}
        style={{ marginLeft: innerLeft }}
      >
        <rect
          x={0}
          y={0}
          width={innerWidth}
          height={brushHeight}
          fill={`#fff`}
          rx={14}
        />
        {/* Data lines */}
        <Group left={0} top={5}>
        {series.map(({ color, data }, i) => (
          <LinePath
            key={`line-${i}`}
            curve={curveLinear}
            data={data}
            x={(d) => brushXScale(getYear(d))}
            y={(d) => brushYScale(getPrice(d))}
            stroke={color}
            strokeWidth={1} //brush lines are skinny malinks
            shapeRendering="geometricPrecision"
          />
        ))}

        <Brush
          innerRef={brushRef}
          xScale={brushXScale}
          yScale={brushYScale}
          width={innerWidth}
          height={brushHeight}
          handleSize={8}
          resizeTriggerAreas={["left", "right"]}
          brushDirection="horizontal"
          selectedBoxStyle={{
            fill: "purple",
            fillOpacity: 0.2,
            stroke: "purple",
            strokeWidth: 1,
            strokeOpacity: 0.8
          }}
            onClick={() => setFilteredSeries(filteredSeries)}
          initialBrushPosition={initialBrushPositionC}
           useWindowMoveEvents
          onChange={onBrushChange}
          renderBrushHandle={(props) => <BrushHandle {...props} />}
        />
         </Group>
      </svg>
    </>
  );
}
