import "bootstrap/dist/css/bootstrap.min.css";
import HeaderSesion from "../components/header-sesion";
import { useParams } from "react-router-dom";
import GridSesions from "../components/grid-sesions";
import NavBarSessions from "../containers/navBarSessions";

const users = [
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "active" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "resting" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "alert" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "waiting" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "warning" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "warning" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "active" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "alert" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "active" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "warning" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "alert" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "warning" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "active" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "warning" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "active" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "warning" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "alert" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "resting" },
];


export default function LiveSesionsId() {
      const { id } = useParams();
      console.log(id, "id");
  return (
      <div style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
      <NavBarSessions/>
      <div className="w-100 p-5 " style={{ height: "calc(100vh - 60px)" }} >
      <div className="container-fluid border rounded h-100" style={{ border: "1 px solid #9DACBD" }}>
      <HeaderSesion />
      <GridSesions users={users} />
      </div>
    </div>
    </div>
  );
}
