import { ReactComponent as PlanUsersIcon } from "../../assets/elevva-pro-icons/Plan_users_icon.svg";
import { ReactComponent as ArrowRigth } from "../../assets/elevva-pro-icons/arrow-right-icon.svg";
const PlanUsers = (props) => {
  return (
    <div className="d-flex justify-content-center flex-column align-items-center h-100">
      <div className="d-flex justify-content-center align-items-center gap-4">
        {" "}
        <div>
          <PlanUsersIcon />
        </div>{" "}
        <div className="d-flex justify-content-center flex-column align-items-center">
          <div className="my-3" style={{ color: "#394F65" }}>
            Plan de Usuarios
          </div>
          <div className="d-flex align-items-center gap-4">
            <div style={{ color: "#394F65" }}>
              Plan de seguimiento y <br></br> data 50 usuarios
            </div>
            <div>
              <ArrowRigth />
            </div>
            <div style={{ color: "#394F65" }}>
              Desde 10 / 10 /2024 <br></br> Hasta 10 / 10 /2025
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PlanUsers;
