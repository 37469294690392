import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { laptopMax1350 } from "./utils";
import { ReactComponent as SearchInvidual } from "../assets/elevva-pro-icons/search-individual-table.svg";
import { ReactComponent as ArrowLeft } from "../assets/elevva-pro-icons/table-arrow-left.svg";
import { ReactComponent as ArrowRight } from "../assets/elevva-pro-icons/table-arrow-right.svg";
import { ReactComponent as EditIcon } from "../assets/elevva-pro-icons/edit-icon.svg";
import { ReactComponent as DeleteTag } from "../assets/elevva-pro-icons/delete-tag.svg";
import { ReactComponent as ReloadIcon } from "../assets/elevva-pro-icons/reloadIcon.svg";

import moment from "moment";

const TableCustomExpired = (props) => {
  const users = props.users;
  const page = props.page;
  const count = props.count;
  const changePage = props.changePage;

  const deleteUser = props.deleteUser;

  const renewUser = props.renewUser;
  console.log(users);

  return (
    <div className={laptopMax1350.matches ? "p-1" : "p-3"}>
      {users.length > 0 ? (
        <div style={{ width: "100%", minWidth: "35rem" }}>
          <PerfectScrollbar
            className="py-3"
            style={{
              maxHeight: "calc(75vh - 15rem)",
              overflowY: "scroll",
            }}
          >
            <table style={{ width: "100%" }}>
              <thead
                className="size-table-users"
                style={{
                  width: "100%",
                  overflow: "scroll",
                  position: "sticky",
                  top: "0",
                  backgroundColor: "white",
                }}
              >
                <tr>
                  <th
                    style={{
                      fontWeight: "normal",
                      width: "25%",
                    }}
                  >
                    Nombre
                  </th>
                  <th
                    style={{
                      fontWeight: "normal",
                      width: "25%",
                    }}
                  >
                    Correo Electrónico
                  </th>
                  <th
                    style={{
                      fontWeight: "normal",
                      width: "20%",
                    }}
                  >
                    plan
                  </th>
                  <th
                    style={{
                      fontWeight: "normal",
                      width: "20%",
                      color: "red",
                    }}
                  >
                    Accesos<br></br> restantes
                  </th>
                  <th
                    style={{
                      fontWeight: "normal",
                      width: "10%",
                      color: "red",
                    }}
                  >
                    Vencimiento
                  </th>
                </tr>
              </thead>
              <tbody className="w-100">
                {users.length > 0 &&
                  users.map((user, index) => (
                    <tr
                      key={user.index}
                      className="size-table-users my-2"
                      style={{
                        borderBottom: "1px solid #A8C2D7",
                        color: "#394F65",
                      }}
                    >
                      <td style={{ width: "25%" }}>{user.customer}</td>
                      <td style={{ width: "25%" }}>
                        {user?.customer_email || "----"}
                      </td>
                      <td style={{ width: "20%" }}>
                        {user.service_name.length > 0
                          ? user.service_name
                          : "----"}
                      </td>
                      <td style={{ width: "20%", color: "red" }}>
                        {user.quota ? user.quota : "----"}
                      </td>
                      <td style={{ width: "5%", color: "red" }}>
                        {user.end_date
                          ? moment(user.end_date)?.format("DD-MM-YY")
                          : "----"}
                      </td>
                      <td style={{ width: "5%", textAlign: "right" }}>
                        <div className="d-flex gap-2">
                          <div className="cursor-pointer">
                            <button
                              style={{ padding: "0.4rem" }}
                              className="  d-flex add_location w-100"
                              onClick={() => renewUser(user)}
                            >
                              {" "}
                              <ReloadIcon />
                            </button>
                          </div>

                          {/* <div>
                          {" "}
                          <DeleteTag
                            onClick={() => deleteUser(user)}
                            className="cursor-pointer"
                            width={"20px"}
                            height={"20px"}
                          />
                        </div> */}
                        </div>
                        {/* <button
                        onClick={() => setUserDetail(user)}
                        className="pe-3"
                        style={{
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <SearchInvidual />
                      </button> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </PerfectScrollbar>
          <div className="d-flex justify-content-end align-items-center gap-3 mt-2">
            <span>
              {page * 10 - 10 === 0 || count >= 10
                ? page === 1
                  ? 1
                  : count
                : page * 10 - 10}
              - {page * 10 >= count ? count : page * 10} de {count}
            </span>
            <div
              onClick={count <= 10 ? null : () => changePage("previous")}
              className="cursor-pointer"
            >
              <ArrowLeft />
            </div>
            <div
              onClick={page * 10 >= count ? null : () => changePage("next")}
              className="cursor-pointer"
            >
              <ArrowRight />
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center h-100">
          <span>No se encontraron usuarios</span>
        </div>
      )}
    </div>
  );
};

export default TableCustomExpired;
