import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { instanceMob, Loadingspinner } from "../../../utils/utils";
import { DropdownList } from "react-widgets";
import { ReactComponent as ArrowDown } from "../../../assets/elevva-pro-icons/arrowDown.svg";
import { Spinner } from "react-bootstrap";
import DateInputStart from "../../admin/date-input-start";

const ModalAssignPlan = (props) => {
  const [usersNoPlan, setUsersNoPlan] = useState(null);
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  function getUsersNoPlan() {
    let url = `admin/customer/noplan?page_size=1000`;

    instanceMob
      .get(url)
      .then((res) => res.data)
      .then((data) => {
        setIsLoadingUsers(false);
        setUsersNoPlan(data.results);
      })
      .catch((err) => console.error(err));
  }

  function getPlans() {
    setIsLoading(true);
    instanceMob
      .get(`admin/plan?no_paginate=True`)
      .then((res) => res.data)
      .then((data) => {
        setPlans(data);

        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    getUsersNoPlan();
    getPlans();
  }, []);
  useEffect(() => {
    if (props.userData) {
      props.setUserNoPlan(props.userData.id);
    }
  }, [props.userData]);

  return (
    <div style={{ minWidth: "25rem" }}>
      <div>
        Asignar <strong>plan</strong>
      </div>
      {isLoading ? (
        <Spinner customStyle="grow" size="2.5rem" className="my-2"></Spinner>
      ) : (
        <>
          {usersNoPlan && usersNoPlan.length > 0 ? (
            <div className="d-flex  w-100 my-2" id="location-select">
              <DropdownList
                style={{ width: "100%" }}
                busy
                defaultValue={
                  props.userData
                    ? usersNoPlan
                        .filter((e) => e.id == props.userData.id)
                        .map((memb) => ({
                          id: memb.id,
                          name: memb.name,
                          value: memb.id,
                        }))[0]
                    : undefined
                }
                busySpinner={<ArrowDown></ArrowDown>}
                className={"input-style-elevva-graphics assign-plan"}
                id="optionSummary"
                placeholder={"Usuarios sin plan"}
                data={usersNoPlan.map((memb) => ({
                  id: memb.id,
                  name: memb.name,
                  value: memb.id,
                }))}
                dataKey="id"
                textField="name"
                onChange={(value) => {
                  props.setUserNoPlan(value.value);
                  // setFormDataAvailability((prevData) => ({
                  //   ...prevData,
                  //   ["location_id"]: value.value,
                  // }));
                }}
                renderListItem={({ item }) => (
                  <div
                    className="w-100 mb-1"
                    style={{ borderBottom: "1px solid #9DACBD" }}
                  >
                    <div>{item.name}</div>
                  </div>
                )}
              />
            </div>
          ) : (
            <div>No hay usuarios sin plan</div>
          )}

          {plans && plans.length > 0 ? (
            <div className="d-flex  w-100 my-2 " id="location-select">
              <DropdownList
                style={{ width: "100%" }}
                busy
                // defaultValue={
                //   listLocations
                //     .filter((e) => e.id == availability?.location_id)
                //     .map((memb) => ({
                //       id: memb.id,
                //       name: memb.name,
                //       value: memb.id,
                //     }))[0]
                // }
                busySpinner={<ArrowDown></ArrowDown>}
                className={"input-style-elevva-graphics"}
                id="optionSummary"
                placeholder={"Selecciona un plan"}
                data={plans.map((memb) => ({
                  id: memb.id,
                  name: memb.name,
                  value: memb.id,
                }))}
                dataKey="id"
                textField="name"
                onChange={(value) => {
                  props.setPlanUserNoPlan(value.value);
                  // setFormDataAvailability((prevData) => ({
                  //   ...prevData,
                  //   ["location_id"]: value.value,
                  // }));
                }}
                renderListItem={({ item }) => (
                  <div
                    className="w-100 mb-1"
                    style={{ borderBottom: "1px solid #9DACBD" }}
                  >
                    <div>{item.name}</div>
                  </div>
                )}
              />
            </div>
          ) : (
            <div>No hay planes</div>
          )}
          <DateInputStart setPlanDate={props.setPlanDate} />
          <hr></hr>

          {/* {(props.userNoPLan || props.planUserNoPlan) && (
            <div>
              <div>Resumen de asignación</div>
              <div className="d-flex w-100 my-2 flex-column gap-2 ">
                {props.userNoPLan && (
                  <span>
                    {" "}
                    {
                      usersNoPlan.filter((e) => e.id == props.userNoPLan)[0]
                        .name
                    }
                  </span>
                )}
                {props.userNoPLan && (
                  <span
                    style={{
                      color: "#9DACBD",
                      fontSize: "calc(0.4rem + 0.4vw)",
                    }}
                  >
                    {
                      usersNoPlan.filter((e) => e.id == props.userNoPLan)[0]
                        .email
                    }
                  </span>
                )}
                {props.planUserNoPlan && (
                  <span
                    style={{
                      color: "#9DACBD",
                      fontSize: "calc(0.4rem + 0.4vw)",
                    }}
                  >
                    {plans.filter((e) => e.id == props.planUserNoPlan)[0].name}
                  </span>
                )}
              </div>
            </div>
          )} */}
        </>
      )}
    </div>
  );
};

export default ModalAssignPlan;
