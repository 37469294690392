import { ReactComponent as BackArrow } from "../../../assets/newIcons/arrow-back.svg";
import { useNavigate } from "react-router";

const HeaderMetricSession = () => {
  const navigate = useNavigate();
  const text_size_metrics="calc(0.8rem + 0.5vw)"
  return (
    <div className="d-flex justify-content-between align-items-center p-3 border-bottom ">
 
    {/* Lado izquierdo */}
    <div className="d-flex gap-2 align-items-center flex-grow-1">
      <button
        className="button_w_h_back"
        style={{ background: "transparent", border: "none" }}
        onClick={() => navigate(-1)}
      >
        <BackArrow width={40} height={40}  />
      </button>
  
      <div className="d-flex align-items-center justify-content-center fs-5 text-color-live">
        <img className="ms-2" src="/assets/icons-multiple-live/fast-clock.svg" alt="watch" />
        <span className="mx-2" style={{fontSize:text_size_metrics}}>00:00:00</span>
        <img className="ms-2" src="/assets/icons-multiple-live/fire-icon.svg" alt="fire" />
        <span className="ms-2" style={{fontSize:text_size_metrics}}>000000</span>
      </div>
    </div>
  
    {/* Centro - Asegurar que quede centrado */}
    <div className="d-flex justify-content-center align-items-center flex-grow-1">
    Resumen<span style={{ fontSize: "calc(0.45rem + 0.5vw)", fontWeight: "bold" }} className="text-color-live-details ms-1"> de sesión - Crosffit - 28 - feb - 2025 - 08:00 a.m.</span>
    </div>
  
    {/* Lado derecho */}
    <div className="d-flex align-items-center justify-content-end flex-grow-1 text-color-live">
    
      
      <div className="ms-3">
        <button
          style={{ padding: "0.5rem 0.7rem", backgroundColor:"white",color:"black" }}
          className="d-flex add_location text-color-live-details"  
        >
          Regresar a panel principal 

        </button>
      </div>
    </div>
  
  </div>
  
  );
};
export default HeaderMetricSession;
