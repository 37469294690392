import { useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate } from "react-router-dom";
import LogoSupremacyBig from "../../assets/assets/elevva-mora_big.png";
import { ButtonMob, instance } from "../../utils/utils";
import { SeleccionaMembresia } from "../dashboard/utils";
import TrainingPlanCalendar from "./training-plan-calendar";

const TrainingPlan = () => {
  const navigate = useNavigate();

  const [modal, setModal] = useState(null);
  const [totalMemberships, setTotalMemberships] = useState(null);
  const [MembershipOptionId, setMembershipOptionId] = useState(null);
  const [trainingDay, setTrainingDay] = useState("");
  const [description, setDescription] = useState("");
  const [inputDay, setInputDay] = useState(1);
  const [hrZone, setHrZone] = useState("No aplica");
  const [cardioZone, setCardioZone] = useState("No aplica");
  const [method, setMethod] = useState("No aplica");
  const [target, setTarget] = useState("No aplica");
  const [activityType, setActivityType] = useState("No aplica");
  const [intensity, setIntensity] = useState("No aplica");
  const [classType, setClassType] = useState("No aplica");
  const [duration, setDuration] = useState("No aplica");
  const [label, setLabel] = useState("No aplica");

  const [modifyItemId, setModifyItemId] = useState(null);
  const [trainingPlans, setTrainingPlans] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [canPersist, setCanPersist] = useState(false);
  const [messagePopup, setMessagePopup] = useState(
    "El plan de entrenamiento se guardo exitosamente"
  );

  useEffect(() => {
    instance
      .get("memberships/corp/memberships")
      .then((res) => res.data)
      .then((data) => {
        if (data.length === 1) {
          setMembershipOptionId(data[0].id);
        }
        setTotalMemberships(data);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (MembershipOptionId) {
      instance
        .get(`memberships/${MembershipOptionId}/plan`)
        .then((res) => res.data)
        .then((data) => {
          setTrainingPlans(data);
          setInputDay(data.length !== 0 ? data.length : 1);
        })
        .catch((err) => console.error(err));
    }
  }, [MembershipOptionId]);

  useEffect(() => {
    if (canPersist) {
      persist();
      setCanPersist(false);
    }
  }, [canPersist]);

  const defaultValues = () => {
    setModifyItemId(null);
    setHrZone("No aplica");
    setCardioZone("No aplica");
    setMethod("No aplica");
    setTarget("No aplica");
    setActivityType("No aplica");
    setIntensity("No aplica");
    setClassType("No aplica");
    setDuration("No aplica");
    setLabel("No aplica");
    setShowForm(false);
    setDescription("");
    setInputDay(trainingPlans.length + 1);
    setTrainingDay("");
  };

  const editTrainingPlan = (index) => {
    let modifyDayTrainingPlan = trainingPlans[index];
    setModifyItemId(index);
    setActivityType(modifyDayTrainingPlan?.detail?.activity_type);
    setCardioZone(modifyDayTrainingPlan?.detail?.cardio_zone);
    setClassType(modifyDayTrainingPlan?.detail?.class_type);
    setDuration(modifyDayTrainingPlan?.detail?.duration);
    setHrZone(modifyDayTrainingPlan?.detail?.hr_zone);
    setIntensity(modifyDayTrainingPlan?.detail?.intensity);
    setLabel(modifyDayTrainingPlan?.label);
    setMethod(modifyDayTrainingPlan?.detail?.method);
    setTarget(modifyDayTrainingPlan?.detail?.target);
    setInputDay(modifyDayTrainingPlan?.day);
    setDescription(modifyDayTrainingPlan?.detail?.notes);
    setTrainingDay(modifyDayTrainingPlan?.date);
    setShowForm(true);
  };

  const deleteTrainingPlanDay = (index) => {
    let deleteItem = (index) => {
      setModal(null);
      setMessagePopup(
        "El entrenamiento ha sido <strong><br/> eliminado <br/> correctamente</strong>"
      );
      setTrainingPlans((prev) => {
        let temp = prev;
        temp.splice(index, 1);
        return temp;
      }, setCanPersist(true));
    };

    setModal(
      showModal(
        "¿Estas seguro de <br /><strong>eliminar este <br /> entrenamiento?</strong>",
        "info",
        "Confirmar",
        () => deleteItem(index)
      )
    );
  };

  const save = () => {
    let dayPlan = {
      date: trainingDay,
      day: parseInt(inputDay),
      type: "",
      label: label,
      detail: {
        type: "",
        notes: description,
        method: method,
        target: target,
        hr_zone: hrZone,
        duration: duration,
        intensity: intensity,
        cardio_zone: cardioZone,
        activity_type: activityType,
        sets: "6 sets",
        description: "",
        class_type: classType,
      },
      content: "",
      id_content: "",
      placeholder: "",
    };

    if (modifyItemId !== null) {
      setMessagePopup(
        "El entrenamiento ha sido <br/> modificado <br/> correctamente"
      );
      setTrainingPlans((prev) => {
        let temp = prev;
        temp[modifyItemId] = dayPlan;
        return temp;
      }, setCanPersist(true));
    } else {
      setMessagePopup(
        "El entrenamiento ha sido <br/> agregado <br/> correctamente"
      );
      setTrainingPlans((prev) => {
        let temp = prev;
        temp = [dayPlan, ...temp];
        return temp;
      }, setCanPersist(true));
    }

    defaultValues();
  };

  const persist = () => {
    setInputDay(trainingPlans.length);
    instance
      .post(`memberships/${MembershipOptionId}/plan/create`, {
        plan: trainingPlans,
      })
      .then(() => setModal(showModal(messagePopup, "info")))
      .catch((err) =>
        setModal(
          showModal("Error al guardar, intente nuevamente mas tarde", "error")
        )
      );
  };

  const dayForm = () => {
    return (
      <div className="d-flex justify-content-center align-items-center p-3 h-100 flex-column w-100">
        <div
          className="mb-3 pb-3"
          style={{
            lineHeight: "100%",
            textAlign: "center",
            color: "var(--elevvabutonLogin_User)",
          }}
        >
          Plan de entrenamiento individual
        </div>
        <div
          className="d-flex mr-auto my-2"
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            className="mx-1"
            style={{
              fontSize: "calc(0.4rem + 0.4vw)",
              textAlign: "left",
              lineHeight: "100%",
              width: "100%",
            }}
          >
            Día
          </div>
          <select
            disabled={true}
            defaultValue={inputDay}
            className="form-control selector_edit_day"
            name="day"
            style={{
              color: "var(--elevvaTextTitle)",
              width: "7.5rem",
            }}
          >
            <option value={inputDay}>Día {inputDay}</option>
          </select>
        </div>
        <br />
        <div
          className="mx-1"
          style={{
            fontSize: "calc(0.4rem + 0.4vw)",
            textAlign: "left",
            lineHeight: "100%",
            width: "100%",
            marginBottom: "2%",
          }}
        >
          Fecha del entrenamiento
        </div>
        <div className="w-100">
          <input
            className="form-control"
            style={{
              minWidth: "calc(12rem + 1vmin)",
              fontSize: "calc(0.4rem + 0.4vw)",
              borderRadius: "var(--bs-border-radius)",
              border: "1px solid var(--elevvaTextSubtitle)",
            }}
            name="date"
            min={new Date().toLocaleDateString("en-CA", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
            placeholder="Ingresa fecha"
            required
            type="date"
            value={trainingDay}
            onChange={(e) => setTrainingDay(e.target.value)}
          />
        </div>
        <br />
        <PerfectScrollbar className="w-100" style={{ maxHeight: "500px" }}>
          <Accordion className="w-100">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Opciones Avanzadas</Accordion.Header>
              <Accordion.Body>
                <div
                  className="d-flex mr-auto my-2"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className="mx-1"
                    style={{
                      fontSize: "calc(0.4rem + 0.4vw)",
                      textAlign: "left",
                      lineHeight: "100%",
                      width: "35%",
                    }}
                  >
                    Entrenamiento
                  </div>
                  <div style={{ width: "65%" }}>
                    <select
                      value={label}
                      className="form-control selector_edit_day"
                      name="label"
                      style={{ color: "var(--elevvaTextTitle)", width: "80%" }}
                      onChange={(e) => setLabel(e.target.value)}
                    >
                      <option value="Valoración física / Control">
                        Valoración física / Control
                      </option>
                      <option value="Valoración nutricional / Control">
                        Valoración nutricional / Control
                      </option>
                      <option value="Programa cardio libre">
                        Programa cardio libre
                      </option>
                      <option value="Entrenamiento 1 a 1">
                        Entrenamiento 1 a 1
                      </option>
                      <option value="Clase grupal virtual">
                        Clase grupal virtual
                      </option>
                      <option value="Video pregrabado">Video pregrabado</option>
                      <option value="Día de descanso">Día de descanso</option>
                      <option value="Sesión de Estiramiento">
                        Sesión de Estiramiento
                      </option>
                      <option value="Entrenamiento de Fuerza">
                        Entrenamiento de Fuerza
                      </option>
                      <option value="Fisioterapia y recuperación">
                        Fisioterapia y recuperación
                      </option>
                      <option value="Entrenamiento libre">
                        Entrenamiento libre
                      </option>
                      <option value="No aplica">No aplica</option>
                    </select>
                    <i></i>
                  </div>
                </div>
                {/* Tiempo */}
                <div
                  className="d-flex mr-auto my-2"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className="mx-1"
                    style={{
                      fontSize: "calc(0.4rem + 0.4vw)",
                      textAlign: "left",
                      lineHeight: "100%",
                      width: "35%",
                    }}
                  >
                    Tiempo
                  </div>
                  <div style={{ width: "65%" }}>
                    <select
                      value={duration}
                      className="form-control selector_edit_day"
                      name="time"
                      style={{ color: "var(--elevvaTextTitle)", width: "60%" }}
                      onChange={(e) => setDuration(e.target.value)}
                    >
                      <option value="10">10 min</option>
                      <option value="20">20 min</option>
                      <option value="30">30 min</option>
                      <option value="40">40 min</option>
                      <option value="50">50 min</option>
                      <option value="60">60 min</option>
                      <option value="90">90 min</option>
                      <option value="120">120 min</option>
                      <option value="No aplica">No aplica</option>
                    </select>
                    <i></i>
                  </div>
                </div>
                {/* Tipo de clase */}
                <div
                  className="d-flex mr-auto my-2"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className="mx-1"
                    style={{
                      fontSize: "calc(0.4rem + 0.4vw)",
                      textAlign: "left",
                      lineHeight: "100%",
                      width: "35%",
                    }}
                  >
                    Tipo de clase
                  </div>
                  <div style={{ width: "65%" }}>
                    <select
                      value={classType}
                      className="form-control selector_edit_day"
                      name="time"
                      style={{ color: "var(--elevvaTextTitle)", width: "60%" }}
                      onChange={(e) => setClassType(e.target.value)}
                    >
                      <option value="Acompañamiento presencial">
                        Acompañamiento presencial
                      </option>
                      <option value="Acompañamiento virtual">
                        Acompañamiento virtual
                      </option>
                      <option value="Solo">Solo</option>
                      <option value="No aplica">No aplica</option>
                    </select>
                  </div>
                </div>
                {/* Intesidad */}
                <div
                  className="d-flex mr-auto my-2"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className="mx-1"
                    style={{
                      fontSize: "calc(0.4rem + 0.4vw)",
                      textAlign: "left",
                      lineHeight: "100%",
                      width: "35%",
                    }}
                  >
                    Intesidad
                  </div>
                  <div style={{ width: "65%" }}>
                    <select
                      value={intensity}
                      className="form-control selector_edit_day"
                      name="intensity"
                      style={{ color: "var(--elevvaTextTitle)", width: "60%" }}
                      onChange={(e) => setIntensity(e.target.value)}
                    >
                      <option value="Alta">Alta</option>
                      <option value="Media">Media</option>
                      <option value="Baja">Baja</option>
                      <option value="No aplica">No aplica</option>
                    </select>
                  </div>
                </div>
                {/* Tipo de actividad */}
                <div
                  className="d-flex mr-auto my-2"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className="mx-1"
                    style={{
                      fontSize: "calc(0.4rem + 0.4vw)",
                      textAlign: "left",
                      lineHeight: "100%",
                      width: "35%",
                    }}
                  >
                    Tipo de actividad
                  </div>
                  <div style={{ width: "65%" }}>
                    <select
                      value={activityType}
                      className="form-control selector_edit_day"
                      name="time"
                      style={{ color: "var(--elevvaTextTitle)", width: "60%" }}
                      onChange={(e) => setActivityType(e.target.value)}
                    >
                      <option value="Anaeróbica">Anaeróbica</option>
                      <option value="Aeróbica">Aeróbica</option>
                      <option value="Recuperación">Recuperación</option>
                      <option value="Prueba">Prueba</option>
                      <option value="No aplica">No aplica</option>
                    </select>
                  </div>
                </div>
                {/* Objetivo  */}
                <div
                  className="d-flex mr-auto my-2"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className="mx-1"
                    style={{
                      fontSize: "calc(0.4rem + 0.4vw)",
                      textAlign: "left",
                      lineHeight: "100%",
                      width: "35%",
                    }}
                  >
                    Objetivo
                  </div>
                  <div style={{ width: "65%" }}>
                    <select
                      value={target}
                      className="form-control selector_edit_day"
                      name="time"
                      style={{ color: "var(--elevvaTextTitle)", width: "60%" }}
                      onChange={(e) => setTarget(e.target.value)}
                    >
                      <option value="Quema calórica">Quema calórica</option>
                      <option value="Desarrollo de masa muscular">
                        Desarrollo de masa muscular
                      </option>
                      <option value="Acondicionamiento físico">
                        Acondicionamiento físico
                      </option>
                      <option value="valoración y prueba">
                        valoración y prueba
                      </option>
                      <option value="Recuperación">Recuperación</option>
                      <option value="Desarrollo de movilidad">
                        Desarrollo de movilidad
                      </option>
                      <option value="Desarrollo de agilidad">
                        Desarrollo de agilidad
                      </option>
                      <option value="Fortalecimiento">Fortalecimiento</option>
                      <option value="Desarrollo de potencia">
                        Desarrollo de potencia
                      </option>
                      <option value="Desarrollo de velocidad">
                        Desarrollo de velocidad
                      </option>
                      <option value="Desarrollo cardiovascular">
                        Desarrollo cardiovascular
                      </option>
                      <option value="No aplica">No aplica</option>
                    </select>
                  </div>
                </div>
                {/* Metodología  */}
                <div
                  className="d-flex mr-auto my-2"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className="mx-1"
                    style={{
                      fontSize: "calc(0.4rem + 0.4vw)",
                      textAlign: "left",
                      lineHeight: "100%",
                      width: "35%",
                    }}
                  >
                    Metodología
                  </div>
                  <div style={{ width: "65%" }}>
                    <select
                      value={method}
                      className="form-control selector_edit_day"
                      name="time"
                      style={{ color: "var(--elevvaTextTitle)", width: "60%" }}
                      onChange={(e) => setMethod(e.target.value)}
                    >
                      <option value="Intervalos">Intervalos</option>
                      <option value="Resistencia">Resistencia</option>
                      <option value="Por estaciones">Por estaciones</option>
                      <option value="Falla muscular">Falla muscular</option>
                      <option value="Máximos / PRs">Máximos / PRs</option>
                      <option value="EMOM">EMOM</option>
                      <option value="AMRAP">AMRAP</option>
                      <option value="TABATA">TABATA</option>
                      <option value="Carrera continua">Carrera continua</option>
                      <option value="No aplica">No aplica</option>
                    </select>
                  </div>
                </div>
                {/* Zona de frecuencia cardíaca */}
                <div
                  className="d-flex mr-auto my-2"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className="mx-1"
                    style={{
                      fontSize: "calc(0.4rem + 0.4vw)",
                      textAlign: "left",
                      lineHeight: "100%",
                      width: "35%",
                    }}
                  >
                    Zona de frecuencia cardíaca
                  </div>
                  <div style={{ width: "65%" }}>
                    <select
                      value={cardioZone}
                      className="form-control selector_edit_day"
                      name="time"
                      style={{ color: "var(--elevvaTextTitle)", width: "60%" }}
                      onChange={(e) => setCardioZone(e.target.value)}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="1 y 2">1 y 2</option>
                      <option value="2 y 3">2 y 3</option>
                      <option value="3 y 4">3 y 4</option>
                      <option value="4 y 5">4 y 5</option>
                      <option value="1, 2, 3">1, 2, 3</option>
                      <option value="4, 5, 6">4, 5, 6</option>
                      <option value="No aplica">No aplica</option>
                    </select>
                  </div>
                </div>
                {/* Zona corporal de trabajo  */}
                <div
                  className="d-flex mr-auto my-2"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className="mx-1"
                    style={{
                      fontSize: "calc(0.4rem + 0.4vw)",
                      textAlign: "left",
                      lineHeight: "100%",
                      width: "35%",
                    }}
                  >
                    Zona corporal de trabajo
                  </div>
                  <div style={{ width: "65%" }}>
                    <select
                      value={hrZone}
                      className="form-control selector_edit_day"
                      name="time"
                      style={{ color: "var(--elevvaTextTitle)", width: "60%" }}
                      onChange={(e) => setHrZone(e.target.value)}
                    >
                      <option value="Tren inferior">Tren inferior</option>
                      <option value="Tren superior">Tren superior</option>
                      <option value="Core">Core</option>
                      <option value="Zona especifica">Zona especifica</option>
                      <option value="Todo el cuerpo">Todo el cuerpo</option>
                      <option value="No aplica">No aplica</option>
                    </select>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <br />
          <div
            className="d-flex mr-auto my-2"
            style={{
              justifyContent: "center",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <div
              className="mx-1"
              style={{
                fontSize: "calc(0.4rem + 0.4vw)",
                textAlign: "left",
                lineHeight: "100%",
                width: "100%",
              }}
            >
              Describe la actividad de este día
            </div>
            <div className="d-flex w-100">
              <textarea
                defaultValue={description}
                className="mt-2"
                style={{
                  backgroundColor: "#fff",
                  width: "100%",
                  padding: "2%",
                  fontSize: "calc(0.4rem + 0.4vw)",
                  borderRadius: "var(--bs-border-radius)",
                }}
                placeholder="Escribe aquí"
                rows={15}
                onInput={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
        </PerfectScrollbar>
        <div className="d-flex gap-3 w-75">
          <ButtonMob
            disabled={!trainingDay}
            colorButton={
              !trainingDay
                ? "rgb(173, 173, 173)"
                : "var(--elevvabutonLogin_User)"
            }
            text="Guardar"
            className="my-3 letraspopfi2actualizar form-save-btn"
            handleClick={() => save()}
          />
          <ButtonMob
            colorTextButton="valr(--elevvabutonLogin_User)"
            borderbutton={`1px solid var(--elevvabutonLogin_User)`}
            colorButton="#fff"
            text="Cancelar"
            className="my-3 letraspopfi2actualizar"
            handleClick={() => defaultValues()}
          />
        </div>
      </div>
    );
  };

  const form = () => {
    return (
      <div className="d-flex justify-content-center align-items-center h-100 flex-column">
        <span
          className="text-center"
          style={{
            fontSize: "16px",
            color: "var(--elevvaTextTitle)",
          }}
        >
          Plan de entrenamiento membresia
        </span>
        <ButtonMob
          colorTextButton="#fff"
          colorButton="var(--elevvabutonLogin_User)"
          text="Crear plan"
          className="my-3"
          handleClick={() => setShowForm(true)}
        />
      </div>
    );
  };

  const showModal = (message, textType, textButton, buttonFunction) => {
    const colorText = {
      info: "var(--elevvabutonLogin_User)",
      success: "green",
      error: "red",
    };

    return (
      <div
        className="popUpServices justify-content-center align-items-center"
        style={{
          display: "flex",
          position: "fixed",
          zIndex: 999,
          whiteSpace: "pre-line",
        }}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "10%",
            minWidth: "300px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModal(null)}
          >
            <i className="bi bi-x-circle" />
          </div>
          <div
            className="p-3"
            style={{ color: colorText[textType], textAlign: "center" }}
          >
            <div dangerouslySetInnerHTML={{ __html: message }} />
            {textButton ? (
              <ButtonMob
                colorTextButton="#fff"
                colorButton="var(--elevvabutonLogin_User)"
                text={textButton}
                sizeFont="10px"
                className="mb-3 px-4"
                handleClick={buttonFunction}
                style={{
                  width: "auto",
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
      {modal}
      <div
        className="d-flex position-relative"
        style={{
          height: "60px",
          background: "var(--backgroundOnboarding)",
          columnGap: "2rem",
        }}
      >
        <SeleccionaMembresia
          MembershipOptionId={MembershipOptionId}
          totalMemberships={totalMemberships && totalMemberships}
          membershipIdOption={(e) => setMembershipOptionId(e)}
        />{" "}
        <div className="logo-supremacy">
          <img
            style={{ width: "6rem" }}
            src={LogoSupremacyBig}
            alt="Eleva logo"
          />
        </div>
      </div>

      <div
        className="d-flex justify-content-center align-items-center h-100 flex-rows gap-3 py-2 px-3"
        style={{
          border: "1px solid #B6C1CE",
          borderRadius: "10px",
          padding: 10,
          margin: 10,
          maxHeight: "calc(100vh - 80px)",
        }}
      >
        <div
          className="z-3 position-absolute"
          style={{
            top: "90px",
            left: "100px",
          }}
        >
          <Button
            variant="outline-secondary"
            style={{ padding: 5 }}
            onClick={() => navigate(-1)}
          >
            <i className="bi bi-chevron-left"></i>
          </Button>
        </div>
        {MembershipOptionId ? (
          <div className="d-flex justify-content-center align-items-center h-100 flex-rows gap-3 w-100">
            <div
              className="d-flex justify-content-center align-items-center h-100 flex-column"
              style={{
                border: "1px solid #B6C1CE",
                borderRadius: "10px",
                width: "40%",
              }}
            >
              {!showForm
                ? trainingPlans.length === 0
                  ? form()
                  : ""
                : dayForm()}
            </div>
            <div
              className="d-flex justify-content-center align-items-center h-100 flex-column"
              style={{
                border: "1px solid #B6C1CE",
                borderRadius: "10px",
                width: "60%",
              }}
            >
              {trainingPlans.length !== 0 ? (
                <TrainingPlanCalendar
                  data={trainingPlans}
                  anchoGrid={"100%"}
                  widthBox={"auto"}
                  minWidthBox={"auto"}
                  deleteFunction={deleteTrainingPlanDay}
                  updateFunction={editTrainingPlan}
                  showForm={setShowForm}
                  defaultValues={defaultValues}
                />
              ) : (
                <div className="d-flex justify-content-center align-items-center h-100 flex-column">
                  <img
                    alt="logo_alta_black_2"
                    style={{ padding: "0% 20%" }}
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/img/logo_small_elevva_metrics.png"
                    }
                  />
                  <br />
                  <div
                    style={{ maxWidth: "80%" }}
                    className="text-center mx-auto"
                  >
                    <span
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        color: "var(--elevvabutonLogin_User)",
                      }}
                    >
                      Crea el plan de entrenamiento para los miembros de tu
                      membresia
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center flex-column">
            <img
              alt="logo_alta_black_2"
              style={{ padding: "0% 20%" }}
              src={
                process.env.PUBLIC_URL +
                "/assets/img/logo_small_elevva_metrics.png"
              }
            />
            <br />
            <div style={{ maxWidth: "80%" }} className="text-center mx-auto">
              <span
                style={{
                  textAlign: "center",
                  fontSize: "calc( 1.5rem + 0.5vw )",
                  color: "var(--elevvabutonLogin_User)",
                }}
              >
                Para continuar, selecciona tu membresia
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default TrainingPlan;
