import { ReactComponent as BackArrow } from "../../../assets/newIcons/arrow-back.svg";
const HeaderSesion = () => {
  return (
    <div className="d-flex justify-content-between align-items-center p-3 bg-light rounded shadow-sm">
  
    {/* Lado izquierdo */}
    <div className="d-flex gap-2 align-items-center flex-grow-1">
      <button
        className="button_w_h_back"
        style={{ background: "transparent", border: "none" }}
      >
        <BackArrow width={40} height={40} />
      </button>
  
      <div className="d-flex align-items-center justify-content-center fs-5 text-color-live">
        <img className="ms-2" src="/assets/icons-multiple-live/fast-clock.svg" alt="watch" />
        <span className="mx-2">00:00:00</span>
        <img className="ms-2" src="/assets/icons-multiple-live/fire-icon.svg" alt="fire" />
        <span className="ms-2">000000</span>
      </div>
    </div>
  
    {/* Centro - Asegurar que quede centrado */}
    <div className="d-flex justify-content-center align-items-center flex-grow-1">
      <span style={{ fontSize: "calc(0.6rem + 0.5vw)", fontWeight: "semi-bold" }} >Crosfit</span>
    </div>
  
    {/* Lado derecho */}
    <div className="d-flex align-items-center justify-content-end flex-grow-1 text-color-live">
      <div className="d-flex align-items-center text-color-live-details" >
        <span className="mx-2" >08:00 am</span>
        <img className="ms-2" src="/assets/icons-multiple-live/clock-blue.svg" alt="clock-blue" />
        <span className="ms-2">60 min</span>
        <img className="ms-2" src="/assets/icons-multiple-live/person-service.svg" alt="person-service" />
        <span className="ms-2">24/24 cupos</span>
      </div>
      
      <div className="ms-3">
        <button
          style={{ padding: "0.6rem 1rem", lineHeight: "1",fontWeight:"bold" }}
          className="d-flex add_location"  
        >
          Detener Sesión 
          <img className="ms-2" src="/assets/icons-multiple-live/red-square.svg" alt="stop" />
        </button>
      </div>
    </div>
  
  </div>
  
  );
};
export default HeaderSesion;
