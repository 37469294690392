// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backgrounImgLoginStart{
  
    right: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/recoverPassword/loginStart.css"],"names":[],"mappings":"AAAA;;IAEI,QAAQ;IACR,kBAAkB;IAClB,MAAM;IACN,SAAS;IACT,OAAO;IACP,sBAAsB;IACtB,4BAA4B;IAC5B,2BAA2B;AAC/B","sourcesContent":[".backgrounImgLoginStart{\n  \n    right: 0;\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
