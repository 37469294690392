const MetricRanges = ({ name, img, zone, status }) => {

  const text_size="calc(0.45rem + 0.5vw)"
 
  const text_size_metrics="calc(0.35rem + 0.1vw)"

  const metrics=[
    {
      img:"/assets/icons-multiple-live/ubication.svg",
      title:"Distancia",
      value:"0.00",
      unit:"Km"
    },
    {
      img:"/assets/icons-multiple-live/fire-icon.svg",
      title:"Calorías",
      value:"0.00",
      unit:"Kcal"
    },
    {
      img:"/assets/icons-multiple-live/heart-gray.svg",
      title:"FC Promedio",
      value:"0.00",
      unit:"Fc Prom"
    },
    {
      img:"/assets/icons-multiple-live/heart-gray.svg",
      title:"FC Max",
      value:"0.00",
      unit:"Fc Max"
    },
  ]
    return (
     
        <div className="d-flex justify-content-between align-items-center gap-1 my-1">
        {/* Metric */}
    {  metrics.map((metric,index)=>  <div className="d-flex">
        <div className="text-center">
        <img className={`${index==0?"":"ms-1"}`} width={15} height={15} src={metric.img} alt={metric.title} />  
        </div>
        <div className="ms-1 d-flex flex-column" style={{lineHeight:"0.8rem"}}>
          <div><span style={{fontSize:text_size,color  :"#ADBAC4"}}>{metric.value}</span></div>
          <small className="text-center" style={{fontSize:text_size_metrics}}>{metric.unit}</small>
       </div>
       </div>)}
     
  
   
      </div>
    
     
    );
  };
  export default MetricRanges;
  