import NavBarSessions from "../live-sesions/containers/navBarSessions";
import GridMetricSesions from "./components/grid-metric-sessions";
import HeaderMetricSession from "./components/header-metric-sessions";


const users = [
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "active" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "resting" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "alert" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "waiting" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "warning" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "warning" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "active" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "alert" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "active" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "warning" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "alert" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "warning" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "active" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "warning" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "active" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "warning" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "alert" },
  { name: "Alejandro Gómez", img: 'https://api.staging.elevva.com.co/uploads/customers/photoUser.jpg', zone: "ZONA 2", status: "resting" },
];


export default function LiveMetricsSesions() {

  return (
      <div style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
      <NavBarSessions/>
      <div className="w-100 p-3 " style={{ height: "calc(100vh - 60px)" }} >
      <div className="container-fluid border rounded h-100" style={{ border: "1 px solid #9DACBD" }}>
      <HeaderMetricSession />
      <GridMetricSesions users={users} />
      </div>
    </div>
    </div>
  );
}
