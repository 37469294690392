import { use, useEffect } from "react";
import { useState } from "react";

const DateInputEnd = (props) => {
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);
  const [trainingDay, setTrainingDay] = useState("Fecha de finalizacion");

  useEffect(() => {
    if (props.setPlanDate) {
      props.setPlanDate(trainingDay);
    }
  }, [trainingDay]);
  useEffect(() => {
    if (props.defaultDate) {
      setIsPlaceholderVisible(false);
      setTrainingDay(new Date(props.defaultDate).toISOString().split("T")[0]);
    }
  }, [props?.defaultDate]);
  return (
    <input
      id="date-start-user"
      required
      className="form-control input-style-elevva py-2"
      style={{
        color: "#9DACBD",
        fontSize: "calc(0.4rem + 0.4vw)",
      }}
      name="freeze_end_date"
      type={isPlaceholderVisible ? "text" : "date"} // ✅ Cambiamos a tipo texto
      placeholder="Fecha de finalizacion"
      value={isPlaceholderVisible ? "Fecha de finalizacion" : trainingDay} // ✅ Mostramos el texto como valor
      onFocus={() => {
        if (isPlaceholderVisible) {
          setIsPlaceholderVisible(false);
        }
      }}
      onBlur={() => {
        if (!trainingDay) {
          setTrainingDay("Fecha de finalizacion"); // Restauramos el texto si está vacío
          setIsPlaceholderVisible(true);
        }
      }}
      onChange={(e) => setTrainingDay(e.target.value)}
    />
  );
};

export default DateInputEnd;
