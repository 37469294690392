import { useEffect, useState } from "react";
import MembershipsPlans from "./components/memberships-plans";
import MembershipsUsers from "./components/memberships-users";
import { instanceMob } from "../../utils/utils";

const MobMembership = () => {
  const [plans, setPlans] = useState([]);

  const [loading, setLoading] = useState(false);

  function getPlans() {
    setLoading(true);
    instanceMob
      .get(`admin/plan?no_paginate=True`)
      .then((res) => res.data)
      .then((data) => {
        setPlans(data);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <>
      <MembershipsPlans getPlansCreateOrUpdate={getPlans} />
      <MembershipsUsers plans={plans} loading={loading} getPlans={getPlans} />
    </>
  );
};
export default MobMembership;
