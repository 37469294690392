import  React,{ useCallback, PointerEvent, useState, useRef, useMemo, useEffect } from "react";
import { extent, max } from "d3-array";
import { curveLinear } from "@visx/curve";
import { AreaStack, LinePath } from "@visx/shape";
import { scaleLinear, scaleTime,scaleLog  } from "@visx/scale";
import { AxisBottom, AxisLeft, AxisRight, TickLabelProps } from "@visx/axis";
import { EditableAnnotation, LineSubject } from "@visx/annotation";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { Brush } from "@visx/brush";
import { Bounds } from "@visx/brush/lib/types";
import BaseBrush from "@visx/brush/lib/BaseBrush";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { BrushHandleRenderProps } from '@visx/brush/lib/BrushHandle';
import moment from "moment";

import { knokinjurie, metricFormat } from "../../utils/utils";

type PriceData = {
  price: number;
  date: string;
  dateOriginal:any;injurie?: any;
};

type PriceDataParsed = {
  price: number;
  date: any;
  dateOriginal:any;injurie?: any;
};

export type PriceDataSeries = {
  title: string;
  color: string;
  data: PriceData[];
  user:string;
  
};

type PriceDataSeriesParsed = {
  title: string;
  color: string;
  data: PriceDataParsed[];
  user:string;
  
};

const getPrice = (d: PriceDataParsed) => d?.price;
const getYear = (d: PriceDataParsed) => new Date(d?.date);


const parseYear = (date: any) => date;

const LABEL_COLOR = "rgb(157, 172, 189)";
const MARGIN_LEFT = 45;

const MARGIN_BOTTOM = 48;
const PADDING_LEFT = 10;
const PADDING_RIGHT = 15;
const BRUSH_HEIGHT = 56;

const axisLabelProps = {
  fill: LABEL_COLOR,
  fontSize: 10,
  textAnchor: "middle"
} as const;

const getLeftAxisTickLabelProps = () =>
  ({
    fill: LABEL_COLOR,
    textAnchor: "end",
    fontSize: 10
  } as const);

const getBottomAxisTickLabelProps: TickLabelProps<{ valueOf(): number }> = (
  _,
  __, // i
  ___ // labels
) => ({
  // fill: i === 0 || i === labels.length - 1 ? "none" : LABEL_COLOR,
  fill: LABEL_COLOR,
  textAnchor: "middle",
  fontSize: 10
});

// Interpolate the price given a year between two data points (using y = mx + c

export function MetricsGraphics(props: {
  width: number;
  height: number;option:string;optionLogs:any;totalCustomers:any;ArrowUp:any,ArrowDown:any,PerfectScrollbar:any
  series: PriceDataSeries[];
  focusOnChange: string[]; // The title of the series to focus on (with the brush) change
}) {
  const { width, focusOnChange,option,optionLogs,totalCustomers,ArrowDown,ArrowUp,PerfectScrollbar } = props;

  const series: PriceDataSeriesParsed[] = useMemo(
    () =>
      props.series.map((s) => ({
        ...s,
        data: s.data.map((sd) => ({
          price: sd.price,
          date: new Date(sd.date),
          dateOriginal:new Date(sd.date),
          injurie:sd.injurie
        }))
      })),
    [props.series]
  );

  const brushRef = useRef<BaseBrush | null>(null);
  const [filteredSeries, setFilteredSeries] = useState<PriceDataSeriesParsed[]>(
    series
  );
  useEffect(() => {
    setFilteredSeries(series)
   
  }, [series])
  // const [isHovered, setIsHovered] = useState(false);
  // All data flattened
  const allData = series.flatMap((d) => d.data);
  const allFilteredData = filteredSeries.flatMap((d) => d.data).sort((a:any, b:any) => a.date - b.date);

  // Bounds
  const brushHeight = BRUSH_HEIGHT;
  const height = props.height - brushHeight;

  const outerWidth = width - MARGIN_LEFT - PADDING_LEFT; // - MARGIN_RIGHT
  const innerWidth = outerWidth - PADDING_LEFT - PADDING_RIGHT;
  const innerWidthBrush = outerWidth  - PADDING_RIGHT;
//   const outerWidthBrush = Math.max(width - MARGIN_LEFT - MARGIN_RIGHT, 0); 
// console.log(innerWidth,innerWidthBrush,outerWidthBrush)
  const outerHeight = height - MARGIN_BOTTOM; // - MARGIN_TOP
  const innerHeight = outerHeight;

  // where the axis goes
  const outerTop = 10; // MARGIN_TOP
  const outerBottom = outerTop + outerHeight;
  const outerLeft = MARGIN_LEFT;
  const outerRight = outerWidth + outerLeft;

  const innerTop = outerTop;
  const innerBottom = innerTop + innerHeight;
  const innerLeft = outerLeft + PADDING_LEFT;
  const innerRight = innerWidth + innerLeft;
  const innerRightBrs = innerWidth;
  // const xScale = scaleTime({
  //   domain: extent(allFilteredData, getYear) as [Date, Date],
  //   range: [innerLeft, innerRight]
  // });
  const xScale = useMemo(
    () =>
      scaleTime<number>({
        domain: extent(allFilteredData, getYear) as [Date, Date],
        range: [innerLeft, innerRight]
        
      }),
    [ filteredSeries]
  );
  // const yScale = scaleLinear<number>({
  //   domain: [0, max(allFilteredData, getPrice) as number],
  //   range: [innerBottom, innerTop]
  // });
  const yScale =useMemo(
    () =>
      option.includes("17")? scaleLog <number>({
        domain: [1, 100], // Rango de tus datos en el eje Y
        range: [innerBottom, innerTop], // Altura del gráfico
        base: 10,
      }):  scaleLinear<number>({
        domain:option.includes("7") && option.length==1? [40,70]:option.includes("16")?[0,100]:option.includes("6") && option.length==1? [0,60]: [0, max(allFilteredData, getPrice) as number],
    range: [innerBottom, innerTop],
        nice: true
      }),
    [innerBottom, innerTop,filteredSeries]
  );

  const yScaleRight = useMemo(
    () =>
      scaleLinear<number>({
        domain:option.includes("6")? [0, 60]:option.includes("7")?[40,70]:[0, 50],
     range: [innerBottom, innerTop],
    nice: true
      }),
    [innerBottom, innerTop,filteredSeries]
  );

  const xScaleGetter = useCallback((d: PriceDataParsed) => xScale(getYear(d)), [
    xScale,filteredSeries,series
  ]);

  const yScaleGetter = useCallback(
    (d: PriceDataParsed) => yScale(getPrice(d)),
    [yScale,filteredSeries,series]
  );
  const yScaleGetterRight = useCallback(
    (d: PriceDataParsed) => yScaleRight(getPrice(d)),
    [yScaleRight]
  );
  // Brush

  // const brushXScale = scaleTime({
  //   domain: extent(allData, getYear) as [Date, Date],
  //   range: [0, innerRight]
  // });
  const brushXScale = useMemo(
    () =>
      scaleTime<number>({
        domain: extent(allData, getYear) as [Date, Date],
    range: [0, innerWidth]
      }),
    [innerRight,filteredSeries,series]
  );
  const brushXScaleBrush = useMemo(
    () =>
      scaleTime<number>({
        domain: extent(allData, getYear) as [Date, Date],
    range: [0, innerRightBrs]
      }),
    [innerRightBrs,filteredSeries,series]
  );
  // const brushYScale = scaleLinear<number>({
  //   domain: [0, max(allData, getPrice) as number],
  //   range: [brushHeight, 0]
  // });
  const interpolatePrice = (
    x: number,
    p1: PriceDataParsed, // [x,y]
    p2: PriceDataParsed // [x,y]
  ) => {
    // slope/gradient = (y2 - y1) / (x2 - x1)
    const m = (p2.price - p1.price) / (p2.date.getTime() - p1.date.getTime());
    // y-intercept = y - mx
    const c = p1.price - m * p1.date.getTime();
    return m * x + c;
  };
  const brushYScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, max(allData, getPrice) as number],
     range: [brushHeight, 0],
        nice: true
      }),
    [brushHeight,filteredSeries,series]
  );
  const onBrushChange = (domain: Bounds | null) => {
    if (!domain) return;
    const { x0, x1 } = domain;
    const seriesCopy = series.map((s) => {
      const sortedData = s.data.sort((a:any, b:any) => a.date - b.date);
      const filteredData = sortedData.filter((datum,index) => {
        const [xMin, xMax] = extent(s.data, getYear) as [Date, Date];
     
        const x = getYear(datum).getTime();
        
        
        // if((brushXScale(xMax)) - (((brushXScale(getYear(datum))) - - brushXScale(new Date(x1)))/2)  < 30 ){
        //   return x >= x0 && x <=  xMax.getTime();
        // }else{

          return x >= x0 && x <= x1;
        // };
      });

      // Interpolate price at x0 and prepend a data point for it
      // This ensures there's always a value on the chart at either end
      // when necessary.
      //
      // For example if the brush domain, x0 and x1, falls between p1 and p2:
      //
      // |---p1--x0--x1--p2---|
      //
      // We'll add interpolated data points for x0 and x1 and show a
      // segment of the line. We use the simple straight line function
      // for the interpolation: y = mx + c
      // If you can think of a cleaner/more performant way to handle this
      // please holla!
      // console.log(xMin.getTime(),new Date(x0).getTime())
      // if (xMin.getTime() < x0) {
      //   const date = new Date(x0);
      //   const year = date.getTime();
      //   const p1Index = s.data.findIndex(
      //     (sd) =>{console.log(sd.date.getTime()); return sd.date.getTime() === year}
      //   );
      //   if (p1Index >= 0 && p1Index < s.data.length - 1) {
      //     const p1 = s.data[p1Index];
      //     const p2 = s.data[p1Index + 1];
      //     const price = interpolatePrice(x0, p1, p2);
      //     console.log(price)
      //   filteredData.push({ price:price,date: date,dateOriginal:date });
      //   }
      // }

      // // // Same for other end
      // if (xMax.getTime() > x1) {
      //   const date = new Date(x1);
      //   const year = date.getFullYear();
      //   const p1Index = s.data.findIndex(
      //     (sd) => sd.date.getFullYear() === year
      //   );
      //   if (p1Index >= 0 && p1Index <= s.data.length - 1) {
      //     const p1 = s.data[p1Index];
      //     const p2 = s.data[p1Index + 1];
      //     console.log(p2,date)
      //     const price = interpolatePrice(x1, p1, p2);
      //     console.log(price,filteredData)
      //      filteredData.push({ price:price,date: date,dateOriginal:date });
      //   }
      // }

      return { ...s, data: filteredData };
    });
    setFilteredSeries(seriesCopy);
  };
  // const onBrushChange = (domain: Bounds | null) => {
  //   if (!domain) return;
  //   const { x0, x1,y0, y1 } = domain;
  //   console.log(series)
  //   const seriesCopy = series.map((s) => {
     

  //     // Filter out values not within domain specified by brush
  //     const filteredData = s.data.filter((datum) => {
  //       const [xMin, xMax] = extent(s.data, getYear) as [Date, Date];
  //       console.log(datum)
  //       const x = getYear(datum).getTime();
  //       const y = getPrice(datum);
  //       console.log(x,x1,datum)
  //       return x > x0 && x < x1 + 99000000 && y > y0 && y < y1;;
  //     });
  //     console.log("filteredData",filteredData)
   

  //     // Interpolate price at x0 and prepend a data point for it
  //     // This ensures there's always a value on the chart at either end
  //     // when necessary.
      
  //     // For example if the brush domain, x0 and x1, falls between p1 and p2:
      
  //     // |---p1--x0--x1--p2---|
      
  //     // We'll add interpolated data points for x0 and x1 and show a
  //     // segment of the line. We use the simple straight line function
  //     // for the interpolation: y = mx + c
  //     // If you can think of a cleaner/more performant way to handle this
  //     // please holla!
  //     // if (xMin.getTime() < x0) {
  //     //   const date = new Date(x0);
  //     //   const year = date;
  //     //   const p1Index = s.data.findIndex(
  //     //     (sd) => sd.date === year
  //     //   );
  //     //   if (p1Index >= 0 && p1Index < s.data.length - 1) {
  //     //     const p1 = s.data[p1Index];
  //     //     const p2 = s.data[p1Index + 1];
  //     //     const price = interpolatePrice(x0, p1, p2);
  //     //     filteredData.unshift({ date, price });
  //     //   }
  //     // }

  //     // // Same for other end
  //     // if (xMax.getTime() > x1) {
  //     //   const date = new Date(x1);
  //     //   const year = date;
  //     //   const p1Index = s.data.findIndex(
  //     //     (sd) => sd.date === year
  //     //   );
  //     //   if (p1Index >= 0 && p1Index <= s.data.length - 1) {
  //     //     const p1 = s.data[p1Index];
  //     //     const p2 = s.data[p1Index + 1];
  //     //     const price = interpolatePrice(x1, p1, p2);
  //     //     filteredData.push({ date, price });
  //     //   }
  //     // }

  //     return { ...s, data: filteredData };
  //   });
  //   console.log(seriesCopy)
  //   setFilteredSeries(seriesCopy);
    
  // };

  /**
   * Tooltips
   */
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip
  } = useTooltip<{
    date: Date;price:any,title:string,user:string,indice:number,injurie:any
    // seriesData: { title: string; color: string; price: number }[];
  }>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true
  });

  // const handlePointerMove = useCallback(
  //   (event: PointerEvent<SVGSVGElement>) => {
  //     const coords = localPoint(event.currentTarget, event);
  //     if (coords) {
  //       const closestDate = xScale.invert(coords.x);
  //       const closestDateX = xScale(closestDate);
  //       if (closestDateX >= innerLeft && closestDateX <= innerRight) {
  //         showTooltip({
  //           tooltipLeft: closestDateX,
  //           tooltipTop: coords.y,
  //           tooltipData: {
  //             date: closestDate,
  //             seriesData: filteredSeries.reduce((r, s) => {
  //               console.log(s.data,closestDate)
  //               const datumAtYear = s.data.find(
  //                 (d) => d.date.getTime() === closestDate.getTime()
  //               );
  //               if (datumAtYear) {
  //                 r.push({
  //                   title: s.title,
  //                   color: s.color,
  //                   price: datumAtYear.price
  //                 });
  //               }
  //               return r;
  //             }, [] as { title: string; color: string; price: number }[])
  //           }
  //         });
  //       }
  //     }
  //   },
  //   [filteredSeries, showTooltip, xScale, innerLeft, innerRight]
  // );
  
  const handlePointerMove = (event:any, datum:any,title:string,index:number,user:string) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
   
    let nuevodA=datum;
    nuevodA.title=title;
    nuevodA.indice=index;
    nuevodA.user=user;
  
    showTooltip({
      tooltipLeft: coords?.x,
      tooltipTop: coords?.y,
      tooltipData: nuevodA,
      
    });
  };

  const handlePointerOut = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);
  function BrushHandle({ x, height, isBrushActive }: BrushHandleRenderProps) {
    const pathWidth = 8;
    const pathHeight = 15;
    if (!isBrushActive) {
      return null;
    }
    return (
      <Group left={x + pathWidth / 2} top={(height - pathHeight) / 2}>
        <path
          fill="#f2f2f2"
          d="M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5 M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12"
          stroke="#999999"
          strokeWidth="1"
          style={{ cursor: 'ew-resize' }}
        />
      </Group>
    );
  }
  // Focus the brush on specific series
  const focusOnSeries = useCallback(() => {
    if (focusOnChange && brushRef.current) {
      const seriesToFocus = series.filter((s) =>
        focusOnChange.includes(s.title)
      );
      if (seriesToFocus.length > 0) {
        const flat = seriesToFocus.flatMap((d) => d.data);
        const [xMin, xMax] = extent(flat, getYear);
        if (xMin && xMax) {
          const brushExtent = brushRef.current.getExtent(
            { x: brushXScale(xMin) },
            { x: brushXScale(xMax) }
          );
          brushRef.current.updateBrush((prev) => ({
            ...prev,
            start: { ...prev.start, x: brushExtent.x0 },
            end: { ...prev.end, x: brushExtent.x1 },
            extent: brushExtent
          }));
        }
      }
    }
  }, [series, focusOnChange, brushXScale]);

  // Separate at current year
  const separatorDate = parseYear(new Date());
  const separatorDateX = xScale(separatorDate);
  const separatorAreaWidth = separatorDateX - outerLeft;
  const showSeparator = separatorAreaWidth > 1;
  
  const initialBrushPosition = useMemo(
    () => {
       // Agregar console.log aquí
      if(filteredSeries.length>0){
        let data=filteredSeries.flatMap((d) => d.data);
        const sortedData = data.sort((a:any, b:any) => a.date - b.date);  
    
        return {
          start: { x: brushXScaleBrush(getYear(sortedData[0])) },
          end: { x: brushXScaleBrush(getYear(sortedData[sortedData.length - 1])) }
        };
      }
    },
    [filteredSeries,brushXScaleBrush]
  );
  const totality=[ 
    {color:"rgba(0,255,0,0.1)",data:[ { price:option.includes("16")?50: 5 },{  price:option.includes("16")?100:100}]},
    {color:"rgba(0,0,0,0.1)",data:[{ price:option.includes("16")?20: 2 },{price:option.includes("16")?50:5}]},
    {color:"rgba(255,0,0,0.1)",data:[ { price: option.includes("16")?0:1 },{price:option.includes("16")?20:2}]}]

const keys = ['price'];

// formulas metrics
// const formulaPeso =
// pesoActual && pesoActual[0]
//   ? pesoActual[0] / Math.pow(data_user?.height / 100, 2)
//   : null;
function arrowDirection(tooltipData,filteredSeries){
  if(tooltipData?.indice){
    let valueHover =parseFloat(tooltipData?.price.toFixed(2));
    let filtroTitle=filteredSeries.find(ara=>ara.title ==tooltipData.title && ara.user ==tooltipData.user ).data
    let toolIndex = tooltipData.indice && tooltipData?.price?tooltipData.indice:0;
 
    let valueBefore = parseFloat(filtroTitle[String(toolIndex==0?0:toolIndex - 1)]?.price.toFixed(2))
    if(valueHover > valueBefore){
      return <ArrowUp></ArrowUp>
    }else{
      return <ArrowDown></ArrowDown>
    }

  }
 
}
function differencePosition(tooltipData,filteredSeries){

  let valueHover =parseFloat(tooltipData?.price.toFixed(2));
  let filtroTitle=filteredSeries.find(ara=>ara.title ==tooltipData.title  && ara.user ==tooltipData.user).data
  let toolIndex = tooltipData?.indice == 0?1:tooltipData.indice
  let valueBefore = parseFloat(filtroTitle[String(toolIndex - 1)]?.price.toFixed(2))
  if(tooltipData?.title == "13"  ||
  tooltipData?.title == "18"){return null}
  else{
   
return tooltipData.title=="20" ||  tooltipData.title=="19"?"": "/" + ( valueHover - valueBefore).toFixed(2)
  }
 
}

  return (
    <div>
      <svg
        ref={containerRef}
        width={option.includes("6") &&option.length>1 || option.includes("3") &&option.length>1 || option.includes("5") &&option.length>1 || option.includes("9") &&option.length>1 || option.includes("8") &&option.length>1?width + 15:width}
        height={height}
       
        // onPointerMove={handlePointerMove}
         onPointerOut={option.includes("20") || option.includes("19")?()=>null:handlePointerOut}
      >
          <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill={`#fff`}
          rx={14}
        />
        {/* {showSeparator && (
          <rect
            x={outerLeft}
            y={outerTop}
            width={separatorAreaWidth}
            height={outerHeight}
            fill="#FAFAFA"
          />
        )} */}
        <GridRows
          scale={yScale}
          left={outerLeft}
          width={outerWidth}
          stroke="#EEEEEE"
        />
{option.includes("16") || option.includes("17")?  totality.map((cases,index)=>(<AreaStack
 
 data={ cases.data}
 // Aquí solo tenemos una serie
  keys={keys}
  
  x0={(d) =>MARGIN_LEFT}
  x1={(d) =>width - PADDING_RIGHT}
  y1={(d) => yScale(d[1])}
  y0={(d) =>yScale(d[1])}
>
  {({ stacks, path }) =>
    stacks.map((stack) => (
      <path
      key={`stack-${stack.key}`}
      d={path(stack) || ''}
      fill={cases.color}
      stroke="#fff"
      strokeWidth={3}
      // onAnimationEnd={handleAnimationEnd}
      // style={{ animation: 'fadeIn 2s ease forwards' }}
    />
    ))
  }
</AreaStack>)) :null}
        {/* Data lines */}
        {filteredSeries.map(({ color, data,title,user }, i) => ( <g key={`line-${i}`}>
          <LinePath 
            key={`line-${i}`}
            curve={curveLinear}
            data={data}
            x={xScaleGetter}
            y={title =="6" &&option.length>1 || title =="3" &&option.length>1|| title =="5" &&option.length>1||title =="9" &&option.length>1||title =="8" &&option.length>1? yScaleGetterRight: yScaleGetter}
            
            stroke={color}
            strokeWidth={2}
            shapeRendering="geometricPrecision"
          />
          {data.map((point, j) => (
            <circle 
              key={j}
              r={5}
              cx={xScaleGetter(point)}
              cy={title =="6" &&option.length>1 || title =="3" &&option.length>1|| title =="5" &&option.length>1||title =="9" &&option.length>1||title =="8" &&option.length>1? yScaleGetterRight(point): yScaleGetter(point)}
              onMouseOver={(e) =>{
                console.log(e,point);
                return handlePointerMove(e,point,title,j,user)}}
              fill={'#ffff'}
              stroke={color}       // Borde rojo
              strokeWidth={3} 
            />
          ))}</g>
        ))}

        {/* Axes */}
    { option.includes("7")?   <AxisLeft
          left={outerLeft}
           label="%"
          scale={yScale}
          hideTicks
          hideAxisLine
          stroke={LABEL_COLOR}
          labelProps={axisLabelProps}
          labelOffset={16}
          tickLength={4}
           numTicks={5}
          tickLabelProps={getLeftAxisTickLabelProps}
        />:<AxisLeft
        left={outerLeft}
         label={option.includes("6")&&option.includes("3")&&option.includes("5") ?"%":option.includes("17")?"%":option.includes("16")?"ms":option.includes("12")?"Calorías":option.includes("2")?"#":option.includes("11") || option.includes("8") &&option.length ==1|| option.includes("3") &&option.length ==1 || option.includes("5") &&option.length ==1?"%":option.includes("6")  &&option.length ==1?"#":"Kg"}
        scale={yScale}
        // labelClassName="labelAxisText"
        hideTicks
        hideAxisLine
        stroke={LABEL_COLOR}
        labelProps={axisLabelProps}
        labelOffset={26}
        tickLength={4}
       
        tickLabelProps={getLeftAxisTickLabelProps}
      />}
        {option.includes("6") &&option.length>1 || option.includes("3") &&option.length>1 || option.includes("5") &&option.length>1 || option.includes("9") &&option.length>1 || option.includes("8") &&option.length>1? <AxisRight
           left={width + 10}
          
           scale={yScaleRight}
          top={10}
          hideZero
          hideTicks
          hideAxisLine
          stroke={LABEL_COLOR}
          labelProps={axisLabelProps}
          labelOffset={16}
          tickLength={4}
          tickComponent={({x, y, formattedValue }) => (
            <text
                key={`tick-${formattedValue}`}
                x={x}
                fill={"#A8C2D7"}
                y={y} // 5 es el máximo del dominio de la escala y 180 es el rango máximo
                style={{ fontSize: 10, textAnchor: 'end',fontWeight:"bold" }}
            >
                {formattedValue} {option.includes("6")?"":"%"}
            </text>
        )}
        />:null}
        <AxisBottom
          labelProps={axisLabelProps}

          top={outerBottom}
          // label="date"
          scale={xScale}
          
          stroke={LABEL_COLOR}
            tickFormat={(value:any, index:number, array:any) => {
          //   console.log((array[index]))
      
          //   // const firstDate = moment(array[0].value);
          //   // const lastDate = moment(array[array.length - 1].value);
          //   // const diff = lastDate.diff(firstDate, 'hours');
          //   // console.log(array[0],firstDate.format('L'),lastDate,diff)
          //   return new Date(value)
         return moment(value).format('DD-MMM-YY')  
              
            
           }}
           tickStroke={"rgb(157, 172, 189"}
          // labelProps={axisLabelProps}
          // labelOffset={0}
          // tickLength={0}
          numTicks={6}
          tickLabelProps={getBottomAxisTickLabelProps}
        />

        {/* {showSeparator && (
          <EditableAnnotation
            canEditLabel={false}
            width={200}
            height={height}
            x={separatorDateX}
          >
            <LineSubject
              min={innerTop}
              max={innerBottom}
              stroke="#EEEEEE"
              strokeWidth={2}
              strokeDasharray="4 3"
            />
          </EditableAnnotation>
        )} */}

        {/* Fancy axis line with a curved corner, the radius is 8px */}
        <path
          d={`M${outerLeft} ${outerTop}
              V${outerBottom - 8}
              Q${outerLeft} ${outerBottom} ${outerLeft + 8} ${outerBottom}
              H${outerRight}`}
          fill="none"
          stroke={LABEL_COLOR}
          strokeWidth={1}
        />

        {/* Tooltip line */}
        {tooltipOpen && (
          <line
            x1={tooltipLeft}
            x2={tooltipLeft}
            y1={outerTop}
            y2={outerBottom}
            stroke={"#F77F00"}
            strokeWidth={1}
            strokeDasharray="4 3"
          />
        )}
      </svg>

      {/* Tooltip */}
      { tooltipOpen && tooltipData && (
        <TooltipInPortal
          // set this to random so it correctly updates with parent bounds
         className="portalEvents"
          top={tooltipTop}
          left={tooltipLeft}
        >
          <div className="d-flex" style={{lineHeight:"1"}} onMouseLeave={()=> hideTooltip()} >
            {/* <b> {tooltipData?.title}</b> */}
          
            <g className="d-flex mx-auto">
             
              <svg x={-12} y={-10} width={30} height={30}>
        <circle cx={10} cy={10} r={10} fill="white" ></circle>
           
                <foreignObject x={0} y={5} width={25} height={25}>
          <div
           style={{
           height: "100%",
            width: "100%",
            borderRadius: "50%",
            overflow:"hidden"
          }}
          >
            <img   style={{width:"100%",height:"100%",   objectFit: "cover",}}
             src={ totalCustomers.filter((user) => user.id == tooltipData?.user )[0]?.profile_pic}
              alt="img"
            
            />
          </div>
        </foreignObject>
              </svg>
            </g>
            {/* <img src={tooltipData?.user? totalCustomers.filter((user) => user.id == tooltipData?.user )[0].profile_pic:null} />  */}
            <br />
            <div>
             
         <b> {tooltipData?.title == "14"?"Sistólica":tooltipData?.title == "15"?"Diastólica":optionLogs.filter((e:any)=>e.id==tooltipData?.title)[0]?.name || ''}</b>  {tooltipData?.title == "20" || tooltipData?.title == "19"? null:<br />}
         
            {tooltipData?.price?parseFloat(tooltipData?.price.toFixed(2)):0}{differencePosition(tooltipData,filteredSeries)} 
            
            { tooltipData?.title == "6" || tooltipData?.title == "13" ||
             tooltipData?.title == "18"||
             tooltipData?.title == "20" || tooltipData?.title == "19"||
             tooltipData?.title == "2"? "":tooltipData?.title == "1" || tooltipData?.title == "10"? " Kg":tooltipData?.title == "14" || tooltipData?.title == "15"?" mm de Hg":tooltipData?.title == "16" ?" ms": tooltipData?.title == "17"?" %": tooltipData?.title == "12"?" Calorías": " %"}
        {  tooltipData?.title == "13" ||
             tooltipData?.title == "18"?null: <span className="mx-1"> {arrowDirection(tooltipData,filteredSeries)}</span> }
    <br />
    
    <b>{metricFormat(tooltipData?.user,tooltipData?.title,tooltipData?.price,totalCustomers)}</b><br></br>
   <i>Registro: {moment(tooltipData?.date).format('DD-MMM-YY')}</i> <br></br><br></br>
   { option.includes("20") || option.includes("19") &&tooltipData.injurie?<PerfectScrollbar style={{ height: "calc(10vmin + 0.5vw)",width:"calc(16vmin + 0.5vw)" }}>    {tooltipData.injurie.map((d,i) => 
          
              (
              <div key={d.id}>
                <b >{i + 1 + ". "}{knokinjurie(d.part)} </b>
        <br></br> <div className="my-2 p-2">   <span>{d.summary}</span> </div>
              </div>
            )
            )} </PerfectScrollbar>:null }
            </div>
          </div>
        </TooltipInPortal>
      )}

      {/* Need a separate svg for the brush as we use the svg border for axis lines */}
   
   <svg
        width={innerWidth}
        height={brushHeight}
        style={{ marginLeft: innerLeft }}
      >
        <rect
          x={0}
          y={0}
          width={innerWidth}
          height={brushHeight}
          fill={`#fff`}
          rx={14}
        />
        {/* Data lines */}
        <Group left={0} top={0}>
        {series.map(({ color, data }, i) => (
          <LinePath
            key={`line-${i}`}
            curve={curveLinear}
            data={data}
            x={(d) =>{ return brushXScale(getYear(d))}}
            y={(d) => brushYScale(getPrice(d))}
            stroke={color}
            strokeWidth={1} //brush lines are skinny malinks
            shapeRendering="geometricPrecision"
          />
        ))}

        <Brush
        useWindowMoveEvents
          innerRef={brushRef}
          xScale={brushXScale}
          yScale={brushYScale}
          width={innerWidth}
          height={brushHeight}
          handleSize={8}
          resizeTriggerAreas={["left", "right"]}
          brushDirection="horizontal"
          selectedBoxStyle={{
            fill: "purple",
            fillOpacity: 0.2,
            stroke: "purple",
            strokeWidth: 1,
            strokeOpacity: 0.8
          }}
          onClick={() => setFilteredSeries(filteredSeries)}
          initialBrushPosition={initialBrushPosition}
          //  useWindowMoveEvents
          onChange={onBrushChange}
          renderBrushHandle={(props) => <BrushHandle {...props} />}
        />
        </Group>
      </svg>
    </div>
  );
}
