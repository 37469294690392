import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { DropdownList } from "react-widgets";
import imageDefault from "../../assets/corporative/imageDefault.png";
import { ReactComponent as ArrowDown } from "../../assets/elevva-pro-icons/arrowDown.svg";
import { ReactComponent as FuerzaIcon } from "../../assets/newIcons/Entrenamiento_fuerza.svg";
import { ReactComponent as LibreIcon } from "../../assets/newIcons/entrenamiento_libre.svg";
import { ReactComponent as FisioterapiaIcon } from "../../assets/newIcons/fisioterapia_recuperacion.svg";
import { ReactComponent as GrupalIcon } from "../../assets/newIcons/grupalIcon.svg";
import { ReactComponent as CardioIcon } from "../../assets/newIcons/ICO-PLAN-CARDIO.svg";
import { ReactComponent as TrainIcon } from "../../assets/newIcons/ICO-PLAN-ENTRENAMIENTO.svg";
import { ReactComponent as ReposoIcon } from "../../assets/newIcons/ICO-PLAN-REPOSO.svg";
import { ReactComponent as ValoraIco } from "../../assets/newIcons/ICO-PLAN-VALORACION.svg";
import { ReactComponent as VideoIcon } from "../../assets/newIcons/ICO-PLAN-VIDEO.svg";
import { ReactComponent as EstiramientoIcon } from "../../assets/newIcons/sesion_estiramiento.svg";
import { ReactComponent as ValoraNutricional } from "../../assets/newIcons/valora_nutricional.svg";
export function SeleccionaMembresia(props) {
  const totalMemberships = props.totalMemberships;
  const membershipIdOption = props.membershipIdOption;
  const textStart = props.textStart || "MEMBRESÍA";
  const styleComponent = props.styleComponent || "input-style-elevva-personal";
  const MembershipOptionId = props.MembershipOptionId || null;

  const recharge = (MembershipOptionId) => (
    <DropdownList
      busy
      busySpinner={<ArrowDown></ArrowDown>}
      className={styleComponent}
      id="newScrollmemb"
      placeholder={textStart}
      defaultValue={
        MembershipOptionId
          ? {
              id: totalMemberships.filter((e) => e.id == MembershipOptionId)[0]
                .id,
              name: totalMemberships.filter(
                (e) => e.id == MembershipOptionId
              )[0].name,
            }
          : null
      }
      data={
        totalMemberships &&
        totalMemberships.map((memb) => ({
          id: memb.id,
          name: memb.name,
        }))
      }
      dataKey="id"
      textField="name"
      onChange={(value) => membershipIdOption(value.id)}
      renderListItem={({ item }) => (
        <div
          className="w-100 mb-1"
          style={{ borderBottom: "1px solid #9DACBD" }}
        >
          <div className="fw-bold">{item.name}</div>
        </div>
      )}
    />
  );

  return totalMemberships ? recharge(MembershipOptionId) : null;
}
export function HabitosCorporativo(props) {
  const handleClick = props.handleClick;
  const habitos = props.habitos;

  return (
    <div
      className="d-flex flex-column justify-content-center "
      style={{
        height: "100%",
        margin: "1% 0px 0px",
        gap: "1vmin",

        borderRadius: "20px",
      }}
    >
      {" "}
      <div className="col-11 mx-auto" style={{ lineHeight: "1" }}>
        <div
          className="   d-flex justify-content-center"
          style={{
            height: "20%",
            alignItems: "center",
          }}
        >
          {" "}
          <span
            className=" letraPequeña "
            style={{
              color: "var(--elevvaTextTitle)",
            }}
          >
            Hábitos e información <strong>del atleta </strong>
          </span>
        </div>
      </div>
      <PerfectScrollbar
        className="col-10 mx-auto  scrollPlan_proximas"
        style={{ height: "auto" }}
      >
        {habitos
          .filter((e) => e)
          .map((hab, index) => (
            <div
              key={index}
              className=" d-flex habitos_info my-1"
              style={{ alignItems: "center" }}
            >
              {/* <ChevronBlack style={{ color: "#CB3D82" }} />{" "} */}
              <span className="letraPequeña">
                <i class="bi bi-cursor-fill me-2"></i>
                {hab}
              </span>
            </div>
          ))}
      </PerfectScrollbar>
      <button className="mx-auto d-flex ver_mas" onClick={handleClick}>
        Ver más
      </button>
    </div>
  );
}
export const ComponentNameCorporativo = (props) => {
  const padreclass = props.padreclass;
  const userData = props.userData;
  const onClickButton = props.onClickButton;
  const disabled = props.disabled;

  return (
    <div className={padreclass} style={{ justifyContent: "center" }}>
      {" "}
      <span
        className="fw-bold  d-flex col-3 mx-auto"
        style={{
          fontSize: "1.6vmin",
          alignItems: "center",
          justifyContent: "center",
          color: "var(--elevvaTextTitle)",
        }}
      >
        <img
          src={userData?.profile_pic || imageDefault}
          alt="Avatar"
          className="rounded-circle imageCustomer-corporative"
        />
      </span>
      <span className="fw-bold  d-flex col-4 letraName mx-auto">
        <span style={{ fontSize: "calc(0.4rem + 0.4vw)" }}>{`${
          userData?.full_name || "-----"
        }`}</span>
        <span
          style={{ fontSize: "calc(0.4rem + 0.4vw)" }}
          className="fw-light"
        >{`${userData?.birthday || ""}`}</span>
      </span>
      <span className="fw-bold d-flex col-5 letraName mx-auto px-2">
        <button
          className="mx-auto d-flex ver_mas reportButton"
          onClick={onClickButton}
          disabled={!disabled}
          style={{
            background: !disabled
              ? "rgb(173, 173, 173)"
              : "var(--elevvabutonLogin_User)",
            border: "0",
          }}
        >
          Generar informe del atleta
        </button>
      </span>
    </div>
  );
};

export function ObjectivesCustomer(props) {
  const idIdentificar = props.idIdentificar;
  const userData = props.userData;

  const objetivesCustomer = props.objetivesCustomer;

  return (
    <div
      id={idIdentificar}
      className={`d-flex flex-column justify-content-center text-center w-100`}
      style={{
        background: "var(--white)",
        height: "auto",

        borderRadius: "20px",
      }}
    >
      <div
        className="   d-flex justify-content-center"
        style={{
          height: "20%",
          alignItems: "center",
        }}
      >
        <span
          className="letraPequeña"
          style={{
            color: "var(--elevvaTextTitle)",
          }}
        >
          Objetivos <strong> del atleta</strong>
        </span>
      </div>
      {objetivesCustomer &&
      objetivesCustomer.length > 0 &&
      objetivesCustomer.filter((elemento) =>
        userData?.objectives?.includes(elemento.code)
      ).length > 0 ? (
        <PerfectScrollbar
          className="col-11 mx-auto text-center d-grid my-2 scrollPlan_proximas grid-container-perfil"
          style={{ height: "auto", maxHeight: "100%", columnGap: "0.6rem" }}
        >
          {objetivesCustomer.map((elemento) =>
            userData?.objectives?.includes(elemento.code) ? (
              <div className="d-flex m-1" style={{ alignItems: "center" }}>
                <div className="icon-objective">{elemento.icon}</div>
                <div style={{ textAlign: "left" }}>
                  <span
                    className=" text-objective-jus"
                    style={{ fontSize: "calc(.4rem + .3vw)" }}
                  >
                    {elemento.name}
                  </span>
                </div>
              </div>
            ) : null
          )}
          {/* {objetivesOldCustomer.map((elemento) =>
          userData?.wellness_objective == elemento.code ? (
            <div className="d-flex " style={{ alignItems: "center" }}>
              <div>{elemento.icon}</div>
              <div style={{ textAlign: "left" }}>
                <span className="letraPequeña text-objective-jus ">
                  {elemento.name}
                </span>
              </div>
            </div>
          ) : null
        )} */}
        </PerfectScrollbar>
      ) : (
        <div className="d-flex mx-1 my-2" style={{ alignItems: "center" }}>
          <span
            className=" text-objective-jus"
            style={{ fontSize: "calc(.4rem + .3vw)" }}
          >
            No tiene objetivos asignados
          </span>
        </div>
      )}
    </div>
  );
}
export function whatmonthSwiper(day) {
  if (day >= 0 && day < 32) {
    return 0;
  } else if (day > 31 && day < 63) {
    return 1;
  } else if (day > 62 && day < 94) {
    return 2;
  } else if (day > 93 && day < 125) {
    return 3;
  } else if (day > 124 && day < 156) {
    return 4;
  } else if (day > 155 && day < 187) {
    return 5;
  } else if (day > 186 && day < 218) {
    return 6;
  } else if (day > 217 && day < 249) {
    return 7;
  } else if (day > 248 && day < 280) {
    return 8;
  } else if (day > 279 && day < 311) {
    return 9;
  } else if (day > 310 && day < 342) {
    return 10;
  } else return 11;
}
export function iconReviewColor(e) {
  return "var(--elevvabutonLogin_User)";
  // if (e == "valoracion_fisica") {
  //   return "#70AAD8";
  // } else if (e == "valoracion_nutricional") {
  //   return "#99FF00";
  // } else if (e == "cardio") {
  //   return "#A43E80";
  // } else if (e == "reserva") {
  //   return "#8089CC";
  // } else if (e == "grupal") {
  //   return "#69D2D1";
  // } else if (e == "video") {
  //   return "#4B6380";
  // } else if (e == "sesion_estiramiento") {
  //   return "#C22C2C";
  // } else if (e == "entrenamiento_fuerza") {
  //   return "#F3539B";
  // } else if (e == "fisioterapia_recuperacion") {
  //   return "#FFDC97";
  // } else if (e == "entrenamiento_libre") {
  //   return "#FF6100";
  // } else {
  //   return "#CBA791";
  // }
}
export const mestraining = [
  {
    name: "Valoración física / Control",
    icon: <ValoraIco width={"100%"} height={"100%"} />,
    color: "#70AAD8",
  },
  {
    name: "Valoración Nutricional / Control",
    icon: <ValoraNutricional width={"100%"} height={"100%"} />,
    color: "#99FF00",
  },
  {
    name: "Programa Cardio",
    icon: <CardioIcon width={"100%"} height={"100%"} />,
    color: "#A43E80",
  },
  {
    name: "Entrenamiento 1 a 1",
    icon: <TrainIcon width={"100%"} height={"100%"} />,
    color: "#8089CC",
  },

  {
    name: "Clase grupal",
    icon: <GrupalIcon width={"100%"} height={"100%"} />,
    color: "#69D2D1",
  },
  {
    name: "Video pregrabado",
    icon: <VideoIcon width={"100%"} height={"100%"} />,
    color: "#4B6380",
  },
  {
    name: "Día de descanso",
    icon: <ReposoIcon width={"100%"} height={"100%"} />,
    color: "#CBA791",
  },
  {
    name: "Sesión de Estiramiento",
    icon: <EstiramientoIcon width={"100%"} height={"100%"} />,
    color: "#C22C2C",
  },
  {
    name: "Entrenamiento de Fuerza",
    icon: <FuerzaIcon width={"100%"} height={"100%"} />,
    color: "#F3539B",
  },
  {
    name: "Fisioterapia y recuperación",
    icon: <FisioterapiaIcon width={"100%"} height={"100%"} />,
    color: "#FFDC97",
  },
  {
    name: "Entrenamiento libre",
    icon: <LibreIcon width={"100%"} height={"100%"} />,
    color: "#FF6100",
  },
];
export function iconReview(e) {
  if (e == "valoracion_fisica") {
    return mestraining[0].icon;
  } else if (e == "valoracion_nutricional") {
    return mestraining[1].icon;
  } else if (e == "cardio") {
    return mestraining[2].icon;
  } else if (e == "reserva") {
    return mestraining[3].icon;
  } else if (e == "grupal") {
    return mestraining[4].icon;
  } else if (e == "video") {
    return mestraining[5].icon;
  } else if (e == "descanso") {
    return mestraining[6].icon;
  } else if (e == "sesion_estiramiento") {
    return mestraining[7].icon;
  } else if (e == "entrenamiento_fuerza") {
    return mestraining[8].icon;
  } else if (e == "fisioterapia_recuperacion") {
    return mestraining[9].icon;
  } else if (e == "entrenamiento_libre") {
    return mestraining[10].icon;
  } else {
    return mestraining[1].icon;
  }
}

export class FechaEnEspañol extends Date {
  constructor(fecha) {
    super(fecha);
    this.options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
  }

  toDate() {
    return new Date(this);
  }

  toString() {
    return this.toLocaleDateString("es-ES", this.options);
  }
}
