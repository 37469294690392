import { SeleccionaMembresia } from "../../dashboard/utils";

import { useEffect, useState } from "react";
import LogoSupremacyBig from "../../../assets/assets/elevva-mora_big.png";
import { instance } from "../../../utils/utils";

export default function NavBarSessions() {
     const [MembershipOptionId, setMembershipOptionId] = useState(null);
      const [totalMemberships, setTotalMemberships] = useState(null);
      useEffect(() => {
        instance
          .get("memberships/corp/memberships")
          .then((res) => res.data)
          .then((data) => {
            if (data.length === 1) {
              setMembershipOptionId(data[0].id);
            }
            setTotalMemberships(data);
          })
          .catch((err) => console.error(err));
      }, []);
  
       useEffect(() => {
          let MembershipOptionId = localStorage.getItem("MembershipOptionId");
          if (MembershipOptionId && Number(MembershipOptionId) > 0) {
            setMembershipOptionId(Number(localStorage.getItem("MembershipOptionId")));
          }
          
        }, []);
        return(
            <div
            className="d-flex position-relative"
            style={{
              height: "60px",
              background: "var(--backgroundOnboarding)",
              columnGap: "2rem",
            }}
          >
            <SeleccionaMembresia
              MembershipOptionId={MembershipOptionId}
              totalMemberships={totalMemberships && totalMemberships}
              membershipIdOption={(e) => setMembershipOptionId(e)}
            />{" "}
            <div className="logo-supremacy">
              <img style={{ width: "6rem" }} src={LogoSupremacyBig}></img>
            </div>
          </div>
           

        )
}