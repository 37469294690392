import React from "react";
import moment from "moment";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { ReactComponent as DeleteTag } from "../../../assets/elevva-pro-icons/delete-tag.svg";
import { ReactComponent as EditTag } from "../../../assets/elevva-pro-icons/edit-tag.svg";
import { ReactComponent as SearchInvidual } from "../../../assets/elevva-pro-icons/search-individual-table.svg";
import { laptop1600 } from "../../../utils/utils";

const cardStyle = {
  width: laptop1600.matches ? "12rem" : "10rem",
  border: "1px solid #d1d5db",
  borderRadius: "5px",
  height: "auto",
  maxHeight: "13rem",
  minHeight: "12rem",
  fontFamily: "Arial, sans-serif",
  backgroundColor: "white",
  overflow: "hidden",
};
const iconStyle = {
  cursor: "pointer",
  marginLeft: "0.5rem",
  color: "#9ca3af",
};
const searchIconStyle = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  fontSize: "1rem",
  color: "#3b82f6",
  marginBottom: "auto",
  cursor: "pointer",
  padding: "0.5rem 0.5rem",
};

const titleStyle = {
  fontSize: "calc(.4rem + .4vw)",
  fontWeight: "bold",
  minHeight: "2.5rem",
  display: "flex",
  alignItems: "center",
  color: "#374151",
};

const categoryStyle = {
  backgroundColor: "#e5e7eb",
  fontSize: "calc(.4rem + .4vw)",
  padding: "0.3rem 1rem",
  textAlign: "left",
  color: "#374151",
  marginBottom: "10px",
};

const locationStyle = {
  margin: "5px 0",
  fontSize: "calc(.4rem + .4vw)",
  color: "#6b7280",
  padding: "0 1rem",
};

const CardClass = (props) => {
  const availability = props.availability;
  const daysName = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];

  return (
    <div style={cardStyle}>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ padding: "0rem 0.5rem" }}
      >
        <span style={titleStyle}>{availability.location_name}</span>
        <div className="d-flex align-items-center">
          <span style={iconStyle}>
            <EditTag onClick={() => props.editAvailability(availability)} />
          </span>
          <span style={iconStyle}>
            <DeleteTag onClick={() => props.deleteAvailability(availability)} />
          </span>
        </div>
      </div>
      <div style={categoryStyle}>{availability.service_name}</div>

      <div style={locationStyle}>
        <PerfectScrollbar style={{ maxHeight: "4rem", overflow: "auto" }}>
          {availability.weekly_schedule.map((schedule, index) => (
            <p style={{ fontSize: "calc(.4rem + .4vw)" }}>
              <strong> {daysName[schedule.day]}</strong>
              <br />
              {schedule.times.map((time) => (
                <span style={{ fontSize: "calc(.4rem + .4vw)" }}>
                  <li>
                    {moment(time.start, "HH:mm:ss").format("h:mm a ")}-{" "}
                    {moment(time.end, "HH:mm:ss").format("h:mm a")}
                  </li>
                </span>
              ))}
            </p>
          ))}
        </PerfectScrollbar>
      </div>
      <div style={searchIconStyle}>
        <SearchInvidual
          width={"1.2rem"}
          onClick={() => props.modalAvailabilityDetail(availability)}
        />
      </div>
    </div>
  );
};

export default CardClass;
