// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbarscroll {
  height: 100vh;
}

@media (max-width: 1275px) {
  .navbarscroll {
    overflow-y: auto !important;
  }
}
.option-page{
  color:var(--elevvaTexttitleLow);
  cursor:pointer;
  align-items:center;
  width: 100%;
 
 
}
.option-page.active{
  color:var(--elevva);
  /* background: var(--backgroundOnboardingContain) */
}
.option-page:hover{
  color:var(--elevva);

}


`, "",{"version":3,"sources":["webpack://./src/components/Navbar/NavBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE;IACE,2BAA2B;EAC7B;AACF;AACA;EACE,+BAA+B;EAC/B,cAAc;EACd,kBAAkB;EAClB,WAAW;;;AAGb;AACA;EACE,mBAAmB;EACnB,mDAAmD;AACrD;AACA;EACE,mBAAmB;;AAErB","sourcesContent":[".navbarscroll {\n  height: 100vh;\n}\n\n@media (max-width: 1275px) {\n  .navbarscroll {\n    overflow-y: auto !important;\n  }\n}\n.option-page{\n  color:var(--elevvaTexttitleLow);\n  cursor:pointer;\n  align-items:center;\n  width: 100%;\n \n \n}\n.option-page.active{\n  color:var(--elevva);\n  /* background: var(--backgroundOnboardingContain) */\n}\n.option-page:hover{\n  color:var(--elevva);\n\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
