import  React,{ useCallback, PointerEvent, useState, useRef, useMemo, useEffect } from "react";
import { extent, max } from "d3-array";
import { curveLinear } from "@visx/curve";
import { AreaStack, LinePath } from "@visx/shape";
import { scaleLinear, scaleTime,scaleOrdinal } from "@visx/scale";
import { AxisBottom, AxisLeft, TickLabelProps } from "@visx/axis";
import { EditableAnnotation, LineSubject } from "@visx/annotation";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { Brush } from "@visx/brush";
import { Bounds } from "@visx/brush/lib/types";
import BaseBrush from "@visx/brush/lib/BaseBrush";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { BrushHandleRenderProps } from '@visx/brush/lib/BrushHandle';
import moment from "moment";

type PriceData = {
  price: number;
  year: number;
};

type PriceDataParsed = {
  price: number;
  date: Date;
};

export type PriceDataSeries = {
  title: string;
  color: string;
  data: PriceData[];
};

type PriceDataSeriesParsed = {
  title: string;
  color: string;
  data: PriceDataParsed[];
};

const getPrice = (d: PriceDataParsed) => d.price;
const getYear = (d: PriceDataParsed) => d.date;

const parseYear = (year: number) => new Date(year, 0, 1);

const LABEL_COLOR = "#3C3C3B";
const MARGIN_LEFT = 33;
const MARGIN_BOTTOM = 48;
const PADDING_LEFT = 14;
const PADDING_RIGHT = 14;
const BRUSH_HEIGHT = 56;

const axisLabelProps = {
  fill: LABEL_COLOR,
  fontSize: 16,
  textAnchor: "middle"
} as const;

const getLeftAxisTickLabelProps = () =>
  ({
    fill: LABEL_COLOR,
    textAnchor: "end",
    fontSize: 12
  } as const);

const getBottomAxisTickLabelProps: TickLabelProps<{ valueOf(): number }> = (
  _,
  __, // i
  ___ // labels
) => ({
  // fill: i === 0 || i === labels.length - 1 ? "none" : LABEL_COLOR,
  fille: LABEL_COLOR,
  textAnchor: "middle",
  fontSize: 12
});

// Interpolate the price given a year between two data points (using y = mx + c)
const interpolatePrice = (
  x: number,
  p1: PriceDataParsed, // [x,y]
  p2: PriceDataParsed // [x,y]
) => {
  // slope/gradient = (y2 - y1) / (x2 - x1);
 
  const m = (p2.price - p1.price) / (p2.date.getTime() - p1.date.getTime());
  // y-intercept = y - mx
  const c = p1.price - m * p1.date.getTime();
  return m * x + c;
};

export function PPACalculatorTest(props: {
  width: number;
  height: number;
  series: PriceDataSeries[];
  focusOnChange: string[]; // The title of the series to focus on (with the brush) change
}) {
  const { width, focusOnChange } = props;

  const series: PriceDataSeriesParsed[] = useMemo(
    () =>
      props.series.map((s) => ({
        ...s,
        data: s.data.map((sd) => ({
          price: sd.price,
          date: new Date(sd.year)
        })).sort((a:any, b:any) => a.date - b.date)
      })),
    [props.series]
  );

  const brushRef = useRef<BaseBrush | null>(null);
  const [filteredSeries, setFilteredSeries] = useState<PriceDataSeriesParsed[]>(
    series
  );
  
 
 useEffect(() => {
     setFilteredSeries(series)
   
   }, [series])
  // All data flattened
  const  sortedData = series.flatMap((d) => d.data);
  const allData  = sortedData.sort((a:any, b:any) => a.date - b.date);
  const allFilteredDatasd = filteredSeries.flatMap((d) => d.data);
  const allFilteredData  = allFilteredDatasd.sort((a:any, b:any) => a.date - b.date);

  // Bounds
  const brushHeight = BRUSH_HEIGHT;
  const height = props.height - brushHeight;

  const outerWidth = width - MARGIN_LEFT; // - MARGIN_RIGHT
   const innerWidth = outerWidth - PADDING_LEFT - PADDING_RIGHT;
  // const innerWidth = outerWidth ;
  const outerHeight = height - MARGIN_BOTTOM; // - MARGIN_TOP
  const innerHeight = outerHeight;

  // where the axis goes
  const outerTop = 0; // MARGIN_TOP
  const outerBottom = outerTop + outerHeight;
  const outerLeft = MARGIN_LEFT;
  const outerRight = outerWidth + outerLeft;

  const innerTop = outerTop;
  const innerBottom = innerTop + innerHeight;
  const innerLeft = outerLeft + PADDING_LEFT;
  const innerRight = innerWidth + innerLeft;
 

  const xScale = scaleTime({
    domain: extent(allFilteredData, getYear) as [Date, Date],
    range: [innerLeft, innerRight]
  });
  const xMax = Math.max(width - innerLeft - innerRight, 0);
  const [minValue, maxValue] = extent(allFilteredData.map(getPrice)) as [number, number];

  const minValueAdjusted = minValue - (maxValue - minValue) * 0.05;
  const yScale = scaleLinear<number>({
    domain: [minValueAdjusted as number, max(allFilteredData, getPrice) as number],
    range: [innerBottom, innerTop]
  });

  const xScaleGetter = useCallback((d: PriceDataParsed) => xScale(getYear(d)), [
    xScale
  ]);

  const yScaleGetter = useCallback(
    (d: PriceDataParsed) => yScale(getPrice(d)),
    [yScale]
  );

  // Brush
  const brushXScale = scaleTime({
    domain: extent(allData, getYear) as [Date, Date],
    range: [0, innerRight]
  });
  const brushYScale = scaleLinear<number>({
    domain: [0, max(allData, getPrice) as number],
    range: [brushHeight, 0]
  });

  const onBrushChange = (domain: Bounds | null) => {
    if (!domain) return;
    const { x0, x1 } = domain;;
   
    const seriesCopy = series.map((s) => {
      // Filter out values not within domain specified by brush
      const sortedData = s.data.sort((a:any, b:any) => a.date - b.date);
      const filteredData = sortedData.filter((datum,index) => {
        const [xMin, xMax] = extent(s.data, getYear) as [Date, Date];
        const x = getYear(datum).getTime();
        if((brushXScale(xMax)) - (((brushXScale(getYear(datum))) - - brushXScale(new Date(x1)))/2)  < 27 ){
          return x >= x0 && x <=  xMax.getTime();
        }else{

          return x >= x0 && x <= x1;
        }
        // return x > x0 && x < xMax.getTime() - - 1;
      });
  
      return { ...s, data: filteredData };
    });
    
    setFilteredSeries(seriesCopy);
  };

  /**
   * Tooltips
   */
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip
  } = useTooltip<{
    date: Date;price:number;
    seriesData: { title: string; color: string; price: number }[];
  }>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true
  });

  // const handlePointerMove = useCallback(
  //   (event: PointerEvent<SVGSVGElement>) => {
  //     const coords = localPoint(event.currentTarget, event);
  //     if (coords) {
  //       const closestDate = parseYear(xScale.invert(coords.x).getFullYear());
  //       const closestDateX = xScale(closestDate);
  //       if (closestDateX >= innerLeft && closestDateX <= innerRight) {
  //         showTooltip({
  //           tooltipLeft: xScale(closestDate),
  //           tooltipTop: coords.y,
  //           tooltipData: {
  //             date: closestDate,
  //             seriesData: filteredSeries.reduce((r, s) => {
  //               const datumAtYear = s.data.find(
  //                 (d) => d.date.getTime() === closestDate.getTime()
  //               );
  //               if (datumAtYear) {
  //                 r.push({
  //                   title: s.title,
  //                   color: s.color,
  //                   price: datumAtYear.price
  //                 });
  //               }
  //               return r;
  //             }, [] as { title: string; color: string; price: number }[])
  //           }
  //         });
  //       }
  //     }
  //   },
  //   [filteredSeries, showTooltip, xScale, innerLeft, innerRight]
  // );

  const handlePointerOut = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);

  // Focus the brush on specific series
  const focusOnSeries = useCallback(() => {
    if (focusOnChange && brushRef.current) {
      const seriesToFocus = series.filter((s) =>
        focusOnChange.includes(s.title)
      );
      if (seriesToFocus.length > 0) {
        const flat = seriesToFocus.flatMap((d) => d.data);
        const [xMin, xMax] = extent(flat, getYear);
        if (xMin && xMax) {
          const brushExtent = brushRef.current.getExtent(
            { x: brushXScale(xMin) },
            { x: brushXScale(xMax) }
          );
          brushRef.current.updateBrush((prev) => ({
            ...prev,
            start: { ...prev.start, x: brushExtent.x0 },
            end: { ...prev.end, x: brushExtent.x1 },
            extent: brushExtent
          }));
        }
      }
    }
  }, [series, focusOnChange, brushXScale]);

  // Separate at current year
  const separatorDate = parseYear(new Date().getFullYear());
  const separatorDateX = xScale(separatorDate);
  const separatorAreaWidth = separatorDateX - outerLeft;
  const showSeparator = separatorAreaWidth > 1;
  const handlePointerMove = (event:any, datum:any,title:string) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    let nuevodA=datum;
    nuevodA.title=title
    showTooltip({
      tooltipLeft: coords?.x,
      tooltipTop: coords?.y,
      tooltipData: nuevodA
    });
  };
  const initialBrushPosition = useMemo(
    () => {
       // Agregar console.log aquí
      if(series.length>0){
        let data=series.flatMap((d) => d.data);
        const sortedData = data.sort((a:any, b:any) => a.date - b.date);


       
       
        return {
          start: { x: brushXScale(getYear(sortedData[0])) },
          end: { x: brushXScale(getYear(sortedData[series.flatMap((d) => d.data).length - 1])) }
        };
      }
    },
    [series,brushXScale]
  );
  function BrushHandle({ x, height, isBrushActive }: BrushHandleRenderProps) {
    const pathWidth = 8;
    const pathHeight = 15;
    if (!isBrushActive) {
      return null;
    }
    return (
      <Group left={x + pathWidth / 2} top={(height - pathHeight) / 2}>
        <path
          fill="#f2f2f2"
          d="M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5 M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12"
          stroke="#999999"
          strokeWidth="1"
          style={{ cursor: 'ew-resize' }}
        />
      </Group>
    );
  };

  const totality=[ {color:"rgba(255,0,0,0.5)",data:[ { year: 80 },
    {  year: 82}]},{color:"rgba(252,134,0,0.5)",data:[{ year: 79 },
    {  year: 80}]},{color:"rgba(0,252,109,0.5)",data:[{ year: 77 },
      {  year: 79}]},{color:"rgba(0,76,255,0.5)",data:[{ year: 75 },
        {  year: 77}]},{color:"rgba(210,210,210,0.5)",data:[{ year: minValueAdjusted },
          {  year: 75}]}]
  
  const keys = ['year'];

  return (
    <>
      <svg
        ref={containerRef}
        width={width}
        height={height}
       
        // onPointerMove={handlePointerMove}
         onPointerOut={handlePointerOut}
      >
    
        <GridRows
          scale={yScale}
          left={outerLeft}
          width={outerWidth}
          stroke="#EEEEEE"
        />
{totality.map((cases,index)=>(<AreaStack
 
  data={ cases.data}
  // Aquí solo tenemos una serie
   keys={['year']}
   
   x0={(d) =>MARGIN_LEFT}
   x1={(d) =>brushXScale(getYear(allData[allData.length - 1]))}
   y1={(d) => yScale(d[1])}
   y0={(d) =>yScale(d[1])}
   
 >
   {({ stacks, path }) =>
     stacks.map((stack) => (
       <path
       key={`stack-${stack.key}`}
       d={path(stack) || ''}
       fill={cases.color}
       stroke="#fff"
       strokeWidth={5}
       // onAnimationEnd={handleAnimationEnd}
       // style={{ animation: 'fadeIn 2s ease forwards' }}
     />
     ))
   }
 </AreaStack>)) }
        {/* Data lines */}
        {filteredSeries.map(({ color, data,title }, i) => ( <g key={`line-${i}`}>
          <LinePath
            key={`line-${i}`}
            curve={curveLinear}
            data={data.sort((a:any, b:any) => a.date - b.date)}
            x={(d) => xScale(getYear(d)) || 0}
            y={(d) => yScale(getPrice(d)) || 0}
            stroke={color}
            strokeWidth={2}
            shapeRendering="geometricPrecision"
          />
          {data.map((point, j) => (
            <circle
              key={j}
              r={4}
              cx={xScaleGetter(point)}
              cy={yScaleGetter(point)}
              onMouseOver={(e) =>{
                let nuevoPoint = point;
                return handlePointerMove(e,point,title)}}
              fill={color}
            />
          ))}</g>
        ))}

        {/* Axes */}
        <AxisLeft
          left={outerLeft}
          // label="Price"
          scale={yScale}
          hideTicks
          hideAxisLine
          stroke={LABEL_COLOR}
          labelProps={axisLabelProps}
          labelOffset={16}
          tickLength={4}
          tickLabelProps={getLeftAxisTickLabelProps}
        />
        <AxisBottom
          top={outerBottom}
          // label="Year"
          scale={xScale}
          hideTicks
          hideAxisLine
          stroke={LABEL_COLOR}
          labelProps={axisLabelProps}
          labelOffset={0}
          tickLength={0}
          // numTicks={10}
          tickFormat={(value:any, index:number, array:any) => {
            //   console.log((array[index]))
        
            //   // const firstDate = moment(array[0].value);
            //   // const lastDate = moment(array[array.length - 1].value);
            //   // const diff = lastDate.diff(firstDate, 'hours');
            //   // console.log(array[0],firstDate.format('L'),lastDate,diff)
            //   return new Date(value)
           return moment(value).format('DD-MMM-YY')  
                
              
             }}
          tickLabelProps={getBottomAxisTickLabelProps}
        />

        {/* {showSeparator && (
          <EditableAnnotation
            canEditLabel={false}
            width={200}
            height={height}
            x={separatorDateX}
          >
            <LineSubject
              min={innerTop}
              max={innerBottom}
              stroke="#EEEEEE"
              strokeWidth={2}
              strokeDasharray="4 3"
            />
          </EditableAnnotation>
        )} */}

        {/* Fancy axis line with a curved corner, the radius is 8px */}
        <path
          d={`M${outerLeft} ${outerTop}
              V${outerBottom - 8}
              Q${outerLeft} ${outerBottom} ${outerLeft + 8} ${outerBottom}
              H${outerRight}`}
          fill="none"
          stroke={LABEL_COLOR}
          strokeWidth={1}
        />

        {/* Tooltip line */}
        {/* {tooltipOpen && (
          <line
            x1={tooltipLeft}
            x2={tooltipLeft}
            y1={outerTop}
            y2={outerBottom}
            stroke={LABEL_COLOR}
            strokeWidth={2}
            strokeDasharray="4 3"
          />
        )} */}
      </svg>

      {/* Tooltip */}
      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          // set this to random so it correctly updates with parent bounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
        >
          <div>
            <b>Price in {brushXScale(tooltipData.date)}</b>
            <br />
            <br />
            {tooltipData?.price?tooltipData?.price:0}
            {/* {tooltipData.seriesData.map((d) => (
              <div key={d.title}>
                <b style={{ color: d.color }}>{d.title}: </b>£{d.price}
              </div>
            ))} */}
          </div>
        </TooltipInPortal>
      )}

      {/* Need a separate svg for the brush as we use the svg border for axis lines */}
      <svg
        width={innerWidth}
        height={brushHeight}
        style={{ marginLeft: innerLeft }}
      >
        {/* Data lines */}
        {series.map(({ color, data }, i) => (
          <LinePath
            key={`line-${i}`}
             curve={curveLinear}
            data={data}
            x={(d) => brushXScale(getYear(d))}
            y={(d) => brushYScale(getPrice(d))}
            stroke={color}
            strokeWidth={1} //brush lines are skinny malinks
            shapeRendering="geometricPrecision"
          />
        ))}

        <Brush
       
          innerRef={brushRef}
          xScale={brushXScale}
          yScale={brushYScale}
          width={innerWidth}
          height={brushHeight}
          handleSize={8}
          resizeTriggerAreas={["left", "right"]}
          brushDirection="horizontal"
          selectedBoxStyle={{
            fill: "purple",
            fillOpacity: 0.2,
            stroke: "purple",
            strokeWidth: 1,
            strokeOpacity: 0.8
          }}
          useWindowMoveEvents
          onChange={onBrushChange}
          // onClick={() => setFilteredSeries(series)}
           initialBrushPosition={initialBrushPosition}
          renderBrushHandle={(props) => <BrushHandle {...props} />}
        />
      </svg>
    </>
  );
}
