import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { ReactComponent as DeleteTag } from "../../../assets/elevva-pro-icons/delete-tag.svg";
import { ReactComponent as EditTag } from "../../../assets/elevva-pro-icons/edit-tag.svg";
import moment from "moment";
const CardLocation = (props) => {
  const location = props.location;
  const cities = props.cities;
  const daysOfWeek = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vier", "Sáb"];
  const styles = {
    card: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      padding: "0.5rem",
      minWidth: "22rem",
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      position: "relative",
      height: "100%",
    },
    cardContent: {
      display: "flex",
      alignItems: "center",
    },
    logo: {
      width: "3rem",
      height: "3rem",
      borderRadius: "50%",
      marginRight: "calc(.4rem + .4vw)",
    },
    textContent: {
      fontSize: "calc(.3rem + .4vw)",
      color: "#1F2937",
    },
    title: {
      fontSize: "calc(.4rem + .4vw)",
      fontWeight: "bold",
      margin: "0 0 4px 0",
    },
    address: {
      margin: 0,
      color: "#374151",
    },
    schedule: {
      margin: "0.1rem 0 0 0",
      color: "#374151",
    },
    dateTitle: {
      margin: "0.1rem 0 0 0",
      fontWeight: "bold",
      color: "#1F2937",
    },
    date: {
      margin: 0,
      color: "#4B5563",
    },
    icons: {
      display: "flex",

      marginBottom: "auto",
      gap: "0.5rem",
      width: "20%",
    },
    icon: {
      fontSize: "5rem",
      color: "#6B7280",
      cursor: "pointer",
    },
  };

  return (
    <PerfectScrollbar style={styles.card}>
      <div style={styles.cardContent}>
        <div style={{ marginBottom: "auto", paddingTop: "1rem" }}>
          <img src={location.photo} alt="Logo" style={styles.logo} />
        </div>
        <div style={styles.textContent}>
          <h2 style={styles.title}>{location.name}</h2>
          <p style={styles.address}>
            {location.address}
            <br />
            {cities.length > 0
              ? cities.find((city) => city.value === location.city)?.label
              : "xxx"}{" "}
            - Colombia
          </p>

          <p style={styles.schedule}>
            <strong>
              {location.days.map((day) => daysOfWeek[Number(day)]).join("- ")}
            </strong>
            <br />
            {moment(location.start_operation, "HH:mm:ss").format(
              "h:mm a "
            )}- {moment(location.close_operation, "HH:mm:ss").format("h:mm a")}
          </p>
          <p style={styles.dateTitle}>Fecha registro</p>
          <p style={styles.date}>
            {moment(location.created_at).format("D [de] MMM [de] YYYY")}
          </p>
        </div>
      </div>
      <div style={styles.icons} className="p-2">
        <EditTag
          className="cursor-pointer"
          width={"100%"}
          onClick={() => props.editLocation(location)}
        />
        <DeleteTag
          width={"100%"}
          className="cursor-pointer"
          onClick={() => props.deleteLocation(location)}
        />
      </div>
    </PerfectScrollbar>
  );
};
export default CardLocation;
