import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  Pagination,
  Navigation,
  EffectCoverflow,
} from "swiper";
import ReactHtmlParser from "html-react-parser";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import cardioProgram from "../../assets/assets/run.png";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import testClass from "../../assets/assets/testClass.jpg";
import actividad from "../../assets/assets/mi_actividad.png";
import tucuerpo from "../../assets/assets/conoce_cuerpo.png";
import recientes from "../../assets/assets/sesiones_rec.png";
import Moment from "moment";
import { extendMoment } from "moment-range";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/swiper.min.css";
import campana_notifit from "../../assets/assets/campananoti.png";
import { iconReview, iconReviewColor, whatmonthSwiper } from "./utils";

export const SwiperPlan = (props) => {
  const mesPrograma = props.mesPrograma;
  const userData = props.userData;
  const CardioTraining = props.CardioTraining;
  const anchoGrid = props.anchoGrid;
  const widthBox = props.widthBox || "100%";
  const minWidthBox = props.minWidthBox || "auto";

  //  const monthSwiper = whatmonthSwiper(
  //    userData.membership.current_plan_day || 0
  //  );
  SwiperCore.use([Navigation]);
  const monthSwiper = whatmonthSwiper(0);
  const navigate = useNavigate();
  return (
    <Swiper
      initialSlide={monthSwiper}
      style={{
        width: "100%",
        margin: "auto",
        justifyContent: "center",
      }}
      navigation={{
        nextEl: `.swiper-button-next-months`,
        prevEl: `.swiper-button-prev-months`,
      }}
      modules={[Navigation]}
      slidesPerView="auto"
      spaceBetween={0}
      slidesOffsetBefore={0}
      slidesOffsetAfter={0}
      slidesPerGroup={1}
      slidesPerGroupSkip={1}
    >
      {/* mes1 */}
      <SwiperSlide>
        <div
          className={"d-flex justify-content-center mb-4"}
          style={{ alignItems: "center" }}
        >
          <div className={`swiper-button-prev-months tonin-prev`}></div>
          <div className="mx-5">Mes 1</div>
          <div className={`swiper-button-next-months tonin-next`}></div>
        </div>

        <PerfectScrollbar
          className="w-100"
          style={{ height: "calc(75vh - 60px )" }}
        >
          <div
            className="grid-container-perfil  justify-content-center m-auto"
            style={{ width: anchoGrid }}
          >
            {mesPrograma &&
              mesPrograma.slice(0, 31).map((elemento, index) => (
                <div className="grid-item-perfil">
                  <div
                    className="box"
                    style={{ border: "0.5px solid #B6C1CE" }}
                  >
                    <div
                      className={`box-content ${
                        // userData.membership.current_plan_day == index + 1
                        0 == index + 1 ? "tooltip_bottom_select" : ""
                      }`}
                      style={{
                        position: "relative",
                        zIndex: "1",
                        fontSize: "calc(.4rem + .4vw)",
                        borderBottom: "1px solid #B6C1CE",
                        background:
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 1 ? "var(--elevva)" : "#fff",
                        color:
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 1
                            ? "#ffff"
                            : "var(--elevvabutonLogin_User)",
                        minWidth: minWidthBox,
                        width: widthBox,
                      }}
                    >
                      Día {index + 1}
                    </div>
                    <div
                      onClick={() => CardioTraining([elemento, index + 1])}
                      className={`box-text ${
                        // userData.membership.current_plan_day == index + 1
                        0 == index + 1 ? " resplandor" : ""
                      }`}
                      style={{
                        margin: "1rem 0",
                        pointerEvents: "none",
                        fontSize: "calc(0.3rem + 0.3vw)",
                        borderTop: "1px solid #B6C1CE",
                        borderBottom: "1px solid #B6C1CE",
                        // background: iconReviewColor(elemento.type),
                        background: "#DFDFDF",
                        minWidth: minWidthBox,
                        width: widthBox,
                      }}
                    >
                      {elemento.label}
                      {/* {iconReview(elemento.type)} */}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </PerfectScrollbar>
      </SwiperSlide>
      {/* mes2 */}
      {mesPrograma && mesPrograma.length > 31 ? (
        <SwiperSlide>
          <div
            className={"d-flex justify-content-center mb-4"}
            style={{ alignItems: "center" }}
          >
            <div className={`swiper-button-prev-months tonin-prev`}></div>
            <div className="mx-5">Mes 2</div>
            <div className={`swiper-button-next-months tonin-next`}></div>
          </div>
          <PerfectScrollbar
            className="w-100"
            style={{ height: "calc(75vh - 60px )" }}
          >
            <div
              className="grid-container-perfil  justify-content-center m-auto"
              style={{ width: anchoGrid }}
            >
              {mesPrograma &&
                mesPrograma.slice(31, 62).map((elemento, index) => (
                  <div className="grid-item-perfil">
                    <div
                      className="box"
                      style={{ border: "0.5px solid #B6C1CE" }}
                    >
                      <div
                        className={`box-content ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 32 ? "tooltip_bottom_select" : ""
                        }`}
                        style={{
                          position: "relative",
                          zIndex: "1",
                          fontSize: "calc(.4rem + .4vw)",
                          borderBottom: "1px solid #B6C1CE",
                          background:
                            // userData.membership.current_plan_day == index + 1
                            0 == index + 32 ? "var(--elevva)" : "#fff",
                          color:
                            // userData.membership.current_plan_day == index + 29
                            0 == index + 32
                              ? "#ffff"
                              : "var(--elevvabutonLogin_User)",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        Día {index + 1}{" "}
                      </div>
                      <div
                        onClick={() => CardioTraining([elemento, index + 32])}
                        className={`box-text ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 32 ? " resplandor" : ""
                        }`}
                        style={{
                          margin: "1rem 0",
                          pointerEvents: "none",
                          fontSize: "calc(0.3rem + 0.3vw)",
                          borderTop: "1px solid #B6C1CE",
                          borderBottom: "1px solid #B6C1CE",
                          // background: iconReviewColor(elemento.type),
                          background: "#DFDFDF",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        {elemento.label}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </PerfectScrollbar>
        </SwiperSlide>
      ) : null}
      {/* mes3 */}
      {mesPrograma && mesPrograma.length > 62 ? (
        <SwiperSlide>
          <div
            className={"d-flex justify-content-center mb-4"}
            style={{ alignItems: "center" }}
          >
            <div className={`swiper-button-prev-months tonin-prev`}></div>
            <div className="mx-5">Mes 3</div>
            <div className={`swiper-button-next-months tonin-next`}></div>
          </div>
          <PerfectScrollbar
            className="w-100"
            style={{ height: "calc(75vh - 60px )" }}
          >
            {" "}
            <div
              className="grid-container-perfil  justify-content-center m-auto"
              style={{ width: anchoGrid }}
            >
              {mesPrograma &&
                mesPrograma.slice(62, 93).map((elemento, index) => (
                  <div className="grid-item-perfil">
                    <div
                      className="box"
                      style={{ border: "0.5px solid #B6C1CE" }}
                    >
                      <div
                        className={`box-content ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 63 ? "tooltip_bottom_select" : ""
                        }`}
                        style={{
                          position: "relative",
                          zIndex: "1",
                          fontSize: "calc(.4rem + .4vw)",
                          borderBottom: "1px solid #B6C1CE",
                          background:
                            // userData.membership.current_plan_day == index + 1
                            0 == index + 63 ? "var(--elevva)" : "#fff",
                          color:
                            // userData.membership.current_plan_day == index + 63
                            0 == index + 63
                              ? "#ffff"
                              : "var(--elevvabutonLogin_User)",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        Día {index + 1}{" "}
                      </div>
                      <div
                        onClick={() => CardioTraining([elemento, index + 63])}
                        className={`box-text ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 63 ? " resplandor" : ""
                        }`}
                        style={{
                          margin: "1rem 0",
                          pointerEvents: "none",
                          fontSize: "calc(0.3rem + 0.3vw)",
                          borderTop: "1px solid #B6C1CE",
                          borderBottom: "1px solid #B6C1CE",
                          // background: iconReviewColor(elemento.type),
                          background: "#DFDFDF",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        {elemento.label}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </PerfectScrollbar>
        </SwiperSlide>
      ) : null}

      {/* mes4 */}
      {mesPrograma && mesPrograma.length > 93 ? (
        <SwiperSlide>
          <div
            className={"d-flex justify-content-center mb-4"}
            style={{ alignItems: "center" }}
          >
            <div className={`swiper-button-prev-months tonin-prev`}></div>
            <div className="mx-5">Mes 4</div>
            <div className={`swiper-button-next-months tonin-next`}></div>
          </div>
          <PerfectScrollbar
            className="w-100"
            style={{ height: "calc(75vh - 60px )" }}
          >
            <div
              className="grid-container-perfil  justify-content-center m-auto"
              style={{ width: anchoGrid }}
            >
              {mesPrograma &&
                mesPrograma.slice(93, 124).map((elemento, index) => (
                  <div className="grid-item-perfil">
                    <div
                      className="box"
                      style={{ border: "0.5px solid #B6C1CE" }}
                    >
                      <div
                        className={`box-content ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 94 ? "tooltip_bottom_select" : ""
                        }`}
                        style={{
                          position: "relative",
                          zIndex: "1",
                          fontSize: "calc(.4rem + .4vw)",
                          borderBottom: "1px solid #B6C1CE",
                          background:
                            // userData.membership.current_plan_day == index + 1
                            0 == index + 94 ? "var(--elevva)" : "#fff",
                          color:
                            // userData.membership.current_plan_day == index + 94
                            0 == index + 94
                              ? "#ffff"
                              : "var(--elevvabutonLogin_User)",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        Día {index + 1}{" "}
                      </div>
                      <div
                        onClick={() => CardioTraining([elemento, index + 94])}
                        className={`box-text ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 94 ? " resplandor" : ""
                        }`}
                        style={{
                          margin: "1rem 0",
                          pointerEvents: "none",
                          fontSize: "calc(0.3rem + 0.3vw)",
                          borderTop: "1px solid #B6C1CE",
                          borderBottom: "1px solid #B6C1CE",
                          // background: iconReviewColor(elemento.type),
                          background: "#DFDFDF",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        {elemento.label}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </PerfectScrollbar>
        </SwiperSlide>
      ) : null}
      {/* mes5 */}
      {mesPrograma && mesPrograma.length > 124 ? (
        <SwiperSlide>
          <div
            className={"d-flex justify-content-center mb-4"}
            style={{ alignItems: "center" }}
          >
            <div className={`swiper-button-prev-months tonin-prev`}></div>
            <div className="mx-5">Mes 5</div>
            <div className={`swiper-button-next-months tonin-next`}></div>
          </div>
          <PerfectScrollbar
            className="w-100"
            style={{ height: "calc(75vh - 60px )" }}
          >
            <div
              className="grid-container-perfil  justify-content-center m-auto"
              style={{ width: anchoGrid }}
            >
              {mesPrograma &&
                mesPrograma.slice(124, 155).map((elemento, index) => (
                  <div className="grid-item-perfil">
                    <div
                      className="box"
                      style={{ border: "0.5px solid #B6C1CE" }}
                    >
                      <div
                        className={`box-content ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 125 ? "tooltip_bottom_select" : ""
                        }`}
                        style={{
                          position: "relative",
                          zIndex: "1",
                          fontSize: "calc(.4rem + .4vw)",
                          borderBottom: "1px solid #B6C1CE",
                          background:
                            // userData.membership.current_plan_day == index + 1
                            0 == index + 125 ? "var(--elevva)" : "#fff",
                          color:
                            // userData.membership.current_plan_day == index + 125
                            0 == index + 125
                              ? "#ffff"
                              : "var(--elevvabutonLogin_User)",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        Día {index + 1}
                      </div>
                      <div
                        onClick={() => CardioTraining([elemento, index + 125])}
                        className={`box-text ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 125 ? " resplandor" : ""
                        }`}
                        style={{
                          margin: "1rem 0",
                          pointerEvents: "none",
                          fontSize: "calc(0.3rem + 0.3vw)",
                          borderTop: "1px solid #B6C1CE",
                          borderBottom: "1px solid #B6C1CE",
                          // background: iconReviewColor(elemento.type),
                          background: "#DFDFDF",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        {elemento.label}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </PerfectScrollbar>
        </SwiperSlide>
      ) : null}

      {/* mes6 */}
      {mesPrograma && mesPrograma.length > 155 ? (
        <SwiperSlide>
          <div
            className={"d-flex justify-content-center mb-4"}
            style={{ alignItems: "center" }}
          >
            <div className={`swiper-button-prev-months tonin-prev`}></div>
            <div className="mx-5">Mes 6</div>
            <div className={`swiper-button-next-months tonin-next`}></div>
          </div>
          <PerfectScrollbar
            className="w-100"
            style={{ height: "calc(75vh - 60px )" }}
          >
            <div
              className="grid-container-perfil  justify-content-center m-auto"
              style={{ width: anchoGrid }}
            >
              {mesPrograma &&
                mesPrograma.slice(155, 186).map((elemento, index) => (
                  <div className="grid-item-perfil">
                    <div
                      className="box"
                      style={{ border: "0.5px solid #B6C1CE" }}
                    >
                      <div
                        className={`box-content ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 156 ? "tooltip_bottom_select" : ""
                        }`}
                        style={{
                          position: "relative",
                          zIndex: "1",
                          fontSize: "calc(.4rem + .4vw)",
                          borderBottom: "1px solid #B6C1CE",
                          background:
                            // userData.membership.current_plan_day == index + 1
                            0 == index + 156 ? "var(--elevva)" : "#fff",
                          color:
                            // userData.membership.current_plan_day == index + 156
                            0 == index + 156
                              ? "#ffff"
                              : "var(--elevvabutonLogin_User)",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        Día {index + 1}
                      </div>
                      <div
                        onClick={() => CardioTraining([elemento, index + 156])}
                        className={`box-text ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 156 ? " resplandor" : ""
                        }`}
                        style={{
                          margin: "1rem 0",
                          pointerEvents: "none",
                          fontSize: "calc(0.3rem + 0.3vw)",
                          borderTop: "1px solid #B6C1CE",
                          borderBottom: "1px solid #B6C1CE",
                          // background: iconReviewColor(elemento.type),
                          background: "#DFDFDF",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        {elemento.label}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </PerfectScrollbar>
        </SwiperSlide>
      ) : null}

      {/* mes7 */}
      {mesPrograma && mesPrograma.length > 186 ? (
        <SwiperSlide>
          <div
            className={"d-flex justify-content-center mb-4"}
            style={{ alignItems: "center" }}
          >
            <div className={`swiper-button-prev-months tonin-prev`}></div>
            <div className="mx-5">Mes 7</div>
            <div className={`swiper-button-next-months tonin-next`}></div>
          </div>
          <PerfectScrollbar
            className="w-100"
            style={{ height: "calc(75vh - 60px )" }}
          >
            <div
              className="grid-container-perfil  justify-content-center m-auto"
              style={{ width: anchoGrid }}
            >
              {mesPrograma &&
                mesPrograma.slice(186, 217).map((elemento, index) => (
                  <div className="grid-item-perfil">
                    <div
                      className="box"
                      style={{ border: "0.5px solid #B6C1CE" }}
                    >
                      <div
                        className={`box-content ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 187 ? "tooltip_bottom_select" : ""
                        }`}
                        style={{
                          position: "relative",
                          zIndex: "1",
                          fontSize: "calc(.4rem + .4vw)",
                          borderBottom: "1px solid #B6C1CE",
                          background:
                            // userData.membership.current_plan_day == index + 1
                            0 == index + 187 ? "var(--elevva)" : "#fff",
                          color:
                            // userData.membership.current_plan_day == index + 187
                            0 == index + 187
                              ? "#ffff"
                              : "var(--elevvabutonLogin_User)",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        Día {index + 1}
                      </div>
                      <div
                        onClick={() => CardioTraining([elemento, index + 187])}
                        className={`box-text ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 187 ? " resplandor" : ""
                        }`}
                        style={{
                          margin: "1rem 0",
                          pointerEvents: "none",
                          fontSize: "calc(0.3rem + 0.3vw)",
                          borderTop: "1px solid #B6C1CE",
                          borderBottom: "1px solid #B6C1CE",
                          // background: iconReviewColor(elemento.type),
                          background: "#DFDFDF",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        {elemento.label}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </PerfectScrollbar>
        </SwiperSlide>
      ) : null}

      {/* mes8 */}
      {mesPrograma && mesPrograma.length > 217 ? (
        <SwiperSlide>
          <div
            className={"d-flex justify-content-center mb-4"}
            style={{ alignItems: "center" }}
          >
            <div className={`swiper-button-prev-months tonin-prev`}></div>
            <div className="mx-5">Mes 8</div>
            <div className={`swiper-button-next-months tonin-next`}></div>
          </div>
          <PerfectScrollbar
            className="w-100"
            style={{ height: "calc(75vh - 60px )" }}
          >
            <div
              className="grid-container-perfil  justify-content-center m-auto"
              style={{ width: anchoGrid }}
            >
              {mesPrograma &&
                mesPrograma.slice(217, 248).map((elemento, index) => (
                  <div className="grid-item-perfil">
                    <div
                      className="box"
                      style={{ border: "0.5px solid #B6C1CE" }}
                    >
                      <div
                        className={`box-content ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 218 ? "tooltip_bottom_select" : ""
                        }`}
                        style={{
                          position: "relative",
                          zIndex: "1",
                          fontSize: "calc(.4rem + .4vw)",
                          borderBottom: "1px solid #B6C1CE",
                          background:
                            // userData.membership.current_plan_day == index + 1
                            0 == index + 218 ? "var(--elevva)" : "#fff",
                          color:
                            // userData.membership.current_plan_day == index + 218
                            0 == index + 218
                              ? "#ffff"
                              : "var(--elevvabutonLogin_User)",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        Día {index + 1}
                      </div>
                      <div
                        onClick={() => CardioTraining([elemento, index + 218])}
                        className={`box-text ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 218 ? " resplandor" : ""
                        }`}
                        style={{
                          margin: "1rem 0",
                          pointerEvents: "none",
                          fontSize: "calc(0.3rem + 0.3vw)",
                          borderTop: "1px solid #B6C1CE",
                          borderBottom: "1px solid #B6C1CE",
                          // background: iconReviewColor(elemento.type),
                          background: "#DFDFDF",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        {elemento.label}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </PerfectScrollbar>
        </SwiperSlide>
      ) : null}
      {/* mes9 */}
      {mesPrograma && mesPrograma.length > 248 ? (
        <SwiperSlide>
          <div
            className={"d-flex justify-content-center mb-4"}
            style={{ alignItems: "center" }}
          >
            <div className={`swiper-button-prev-months tonin-prev`}></div>
            <div className="mx-5">Mes 9</div>
            <div className={`swiper-button-next-months tonin-next`}></div>
          </div>
          <PerfectScrollbar
            className="w-100"
            style={{ height: "calc(75vh - 60px )" }}
          >
            <div
              className="grid-container-perfil  justify-content-center m-auto"
              style={{ width: anchoGrid }}
            >
              {mesPrograma &&
                mesPrograma.slice(248, 279).map((elemento, index) => (
                  <div className="grid-item-perfil">
                    <div
                      className="box"
                      style={{ border: "0.5px solid #B6C1CE" }}
                    >
                      <div
                        className={`box-content ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 249 ? "tooltip_bottom_select" : ""
                        }`}
                        style={{
                          position: "relative",
                          zIndex: "1",
                          fontSize: "calc(.4rem + .4vw)",
                          borderBottom: "1px solid #B6C1CE",
                          background:
                            // userData.membership.current_plan_day == index + 1
                            0 == index + 249 ? "var(--elevva)" : "#fff",
                          color:
                            // userData.membership.current_plan_day == index + 249
                            0 == index + 249
                              ? "#ffff"
                              : "var(--elevvabutonLogin_User)",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        Día {index + 1}
                      </div>
                      <div
                        onClick={() => CardioTraining([elemento, index + 249])}
                        className={`box-text ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 249 ? " resplandor" : ""
                        }`}
                        style={{
                          margin: "1rem 0",
                          pointerEvents: "none",
                          fontSize: "calc(0.3rem + 0.3vw)",
                          borderTop: "1px solid #B6C1CE",
                          borderBottom: "1px solid #B6C1CE",
                          // background: iconReviewColor(elemento.type),
                          background: "#DFDFDF",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        {elemento.label}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </PerfectScrollbar>
        </SwiperSlide>
      ) : null}
      {/* mes10 */}
      {mesPrograma && mesPrograma.length > 279 ? (
        <SwiperSlide>
          <div
            className={"d-flex justify-content-center mb-4"}
            style={{ alignItems: "center" }}
          >
            <div className={`swiper-button-prev-months tonin-prev`}></div>
            <div className="mx-5">Mes 10</div>
            <div className={`swiper-button-next-months tonin-next`}></div>
          </div>
          <PerfectScrollbar
            className="w-100"
            style={{ height: "calc(75vh - 60px )" }}
          >
            <div
              className="grid-container-perfil  justify-content-center m-auto"
              style={{ width: anchoGrid }}
            >
              {mesPrograma &&
                mesPrograma.slice(279, 310).map((elemento, index) => (
                  <div className="grid-item-perfil">
                    <div
                      className="box"
                      style={{ border: "0.5px solid #B6C1CE" }}
                    >
                      <div
                        className={`box-content ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 280 ? "tooltip_bottom_select" : ""
                        }`}
                        style={{
                          position: "relative",
                          zIndex: "1",
                          fontSize: "calc(.4rem + .4vw)",
                          borderBottom: "1px solid #B6C1CE",
                          background:
                            // userData.membership.current_plan_day == index + 1
                            0 == index + 280 ? "var(--elevva)" : "#fff",
                          color:
                            // userData.membership.current_plan_day == index + 280
                            0 == index + 280
                              ? "#ffff"
                              : "var(--elevvabutonLogin_User)",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        Día {index + 1}
                      </div>
                      <div
                        onClick={() => CardioTraining([elemento, index + 280])}
                        className={`box-text ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 280 ? " resplandor" : ""
                        }`}
                        style={{
                          margin: "1rem 0",
                          pointerEvents: "none",
                          fontSize: "calc(0.3rem + 0.3vw)",
                          borderTop: "1px solid #B6C1CE",
                          borderBottom: "1px solid #B6C1CE",
                          // background: iconReviewColor(elemento.type),
                          background: "#DFDFDF",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        {elemento.label}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </PerfectScrollbar>
        </SwiperSlide>
      ) : null}
      {/* mes11 */}
      {mesPrograma && mesPrograma.length > 310 ? (
        <SwiperSlide>
          <div
            className={"d-flex justify-content-center mb-4"}
            style={{ alignItems: "center" }}
          >
            <div className={`swiper-button-prev-months tonin-prev`}></div>
            <div className="mx-5">Mes 11</div>
            <div className={`swiper-button-next-months tonin-next`}></div>
          </div>
          <PerfectScrollbar
            className="w-100"
            style={{ height: "calc(75vh - 60px )" }}
          >
            <div
              className="grid-container-perfil  justify-content-center m-auto"
              style={{ width: anchoGrid }}
            >
              {mesPrograma &&
                mesPrograma.slice(310, 341).map((elemento, index) => (
                  <div className="grid-item-perfil">
                    <div
                      className="box"
                      style={{ border: "0.5px solid #B6C1CE" }}
                    >
                      <div
                        className={`box-content ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 311 ? "tooltip_bottom_select" : ""
                        }`}
                        style={{
                          position: "relative",
                          zIndex: "1",
                          fontSize: "calc(.4rem + .4vw)",
                          borderBottom: "1px solid #B6C1CE",
                          background:
                            // userData.membership.current_plan_day == index + 1
                            0 == index + 311 ? "var(--elevva)" : "#fff",
                          color:
                            // userData.membership.current_plan_day == index + 311
                            0 == index + 311
                              ? "#ffff"
                              : "var(--elevvabutonLogin_User)",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        Día {index + 1}
                      </div>
                      <div
                        onClick={() => CardioTraining([elemento, index + 311])}
                        className={`box-text ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 311 ? " resplandor" : ""
                        }`}
                        style={{
                          margin: "1rem 0",
                          pointerEvents: "none",
                          fontSize: "calc(0.3rem + 0.3vw)",
                          borderTop: "1px solid #B6C1CE",
                          borderBottom: "1px solid #B6C1CE",
                          // background: iconReviewColor(elemento.type),
                          background: "#DFDFDF",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        {elemento.label}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </PerfectScrollbar>
        </SwiperSlide>
      ) : null}
      {/* mes12 */}
      {mesPrograma && mesPrograma.length > 341 ? (
        <SwiperSlide>
          <div
            className={"d-flex justify-content-center mb-4"}
            style={{ alignItems: "center" }}
          >
            <div className={`swiper-button-prev-months tonin-prev`}></div>
            <div className="mx-5">Mes 12</div>
            <div className={`swiper-button-next-months tonin-next`}></div>
          </div>
          <PerfectScrollbar
            className="w-100"
            style={{ height: "calc(75vh - 60px )" }}
          >
            <div
              className="grid-container-perfil  justify-content-center m-auto"
              style={{ width: anchoGrid }}
            >
              {mesPrograma &&
                mesPrograma.slice(341, 372).map((elemento, index) => (
                  <div className="grid-item-perfil">
                    <div
                      className="box"
                      style={{ border: "0.5px solid #B6C1CE" }}
                    >
                      <div
                        className={`box-content ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 342 ? "tooltip_bottom_select" : ""
                        }`}
                        style={{
                          position: "relative",
                          zIndex: "1",
                          fontSize: "calc(.4rem + .4vw)",
                          borderBottom: "1px solid #B6C1CE",
                          background:
                            // userData.membership.current_plan_day == index + 1
                            0 == index + 342 ? "var(--elevva)" : "#fff",
                          color:
                            // userData.membership.current_plan_day == index + 342
                            0 == index + 342
                              ? "#ffff"
                              : "var(--elevvabutonLogin_User)",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        Día {index + 1}
                      </div>
                      <div
                        onClick={() => CardioTraining([elemento, index + 342])}
                        className={`box-text ${
                          // userData.membership.current_plan_day == index + 1
                          0 == index + 342 ? " resplandor" : ""
                        }`}
                        style={{
                          margin: "1rem 0",
                          pointerEvents: "none",
                          fontSize: "calc(0.3rem + 0.3vw)",
                          borderTop: "1px solid #B6C1CE",
                          borderBottom: "1px solid #B6C1CE",
                          // background: iconReviewColor(elemento.type),
                          background: "#DFDFDF",
                          minWidth: minWidthBox,
                          width: widthBox,
                        }}
                      >
                        {elemento.label}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </PerfectScrollbar>
        </SwiperSlide>
      ) : null}
    </Swiper>
  );
};
