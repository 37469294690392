import React from "react";
import { useNavigate } from "react-router-dom";

export default function TrainingPlanForm() {
  const navigate = useNavigate();

  return (
    <div className="d-flex justify-content-center align-items-center h-100 flex-column w-100">
      <div className="text-center mx-auto my-2">
        <span
          style={{ textAlign: "center", fontSize: "calc( 0.4rem + 0.5vw )" }}
        >
          Plan de entrenamiento
        </span>
      </div>
      <div className="d-flex justify-content-center align-items-center gap-xl-3 gap-md-2">
        <button
          style={{
            fontSize: "calc(.2rem + .32vw)",
          }}
          className="d-flex add_location px-5 py-1"
          onClick={() => navigate("/admin/training-plan")}
        >
          Gestionar <br />
          entrenamiento
        </button>
      </div>
    </div>
  );
}
