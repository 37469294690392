import React, { useState } from "react";
import { ReactComponent as SearchInvidual } from "../../../assets/elevva-pro-icons/search-individual-table.svg";
import { ReactComponent as DeleteTag } from "../../../assets/elevva-pro-icons/delete-tag.svg";
import { ReactComponent as EditTag } from "../../../assets/elevva-pro-icons/edit-tag.svg";
import { ReactComponent as Person } from "../../../assets/elevva-pro-icons/person-service.svg";
import { ReactComponent as Clock } from "../../../assets/elevva-pro-icons/clock-service.svg";
import { ReactComponent as Location } from "../../../assets/elevva-pro-icons/location-service.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
const cardStyle = {
  maxWidth: "12rem",
  border: "1px solid #d1d5db",
  borderRadius: "10px",
  padding: "0.5rem 1rem",
  backgroundColor: "#ffffff",
  fontFamily: "Arial, sans-serif",
  color: "#374151",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const titleStyle = {
  fontSize: "calc(.3rem + .3vw)",

  margin: 0,
};

const iconStyle = {
  cursor: "pointer",
  marginLeft: "0.5rem",
  color: "#9ca3af",
  display: "flex",
};

const separatorStyle = {
  border: 0,
  borderTop: "1px solid #A8C2D7",
  margin: "0.5rem 0",
};

const detailContainerStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "0.1rem",
  fontSize: "calc(.3rem + .3vw)",
  color: "#1f2937",
};

const iconTextStyle = {
  color: "#3b82f6",
  marginRight: "0.5rem",
  fontSize: "calc(.3rem + .3vw)",
};

const secondaryTextStyle = {
  color: "#3b82f6",
  marginLeft: "1rem",
  marginRight: "0.5rem",
  fontSize: "calc(.3rem + .3vw)",
};

const locationContainerStyle = {
  display: "flex",
  alignItems: "center",
  fontSize: "calc(.3rem + .3vw)",
  color: "#1f2937",
};

const searchIconStyle = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  fontSize: "1rem",
  color: "#3b82f6",
  marginBottom: "auto",
  cursor: "pointer",
};

const CardService = (props) => {
  const service = props.service;

  return (
    <>
      <div style={cardStyle}>
        <div style={headerStyle}>
          <div
            style={{
              alignItems: "center",
            }}
          >
            <img
              src={service.photo}
              alt="Logo"
              style={{
                width: "1.5rem",
                height: "1.5rem",
                borderRadius: "50%",
                marginRight: "calc(.4rem + .4vw)",
              }}
            />
          </div>
          <h3 style={titleStyle}>{service.name}</h3>
          <div className="d-flex gap-1" style={iconStyle}>
            <span>
              <EditTag onClick={() => props.editService(service)} />
            </span>
            <span>
              <DeleteTag onClick={() => props.deleteService(service)} />
            </span>
          </div>
        </div>
        <hr style={separatorStyle} />
        <div>
          <div style={detailContainerStyle}>
            <span style={iconTextStyle}>
              <Clock />
            </span>
            <span style={{ fontSize: "calc(.3rem + .3vw)" }}>
              {service.duration} min
            </span>
            <span style={secondaryTextStyle}>
              <Person />{" "}
            </span>
            <span style={{ fontSize: "calc(.3rem + .3vw)" }}>
              {service?.slots || 0} cupos
            </span>
          </div>
          {service.locations.length > 0 && (
            <div style={locationContainerStyle}>
              <PerfectScrollbar style={{ lineHeight: "1" }}>
                {service.locations.map((location) => (
                  <>
                    <span style={iconTextStyle}>
                      <Location />
                    </span>
                    <span style={{ fontSize: "calc(.3rem + .3vw)" }}>
                      {location.name}
                    </span>
                    <br></br>
                  </>
                ))}
              </PerfectScrollbar>
            </div>
          )}
        </div>
        <div style={searchIconStyle}>
          <SearchInvidual
            width={"1.2rem"}
            onClick={() => props.modalServiceDetail(service.id)}
          />
        </div>
      </div>
    </>
  );
};

export default CardService;
