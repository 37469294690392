import { useEffect, useState } from "react";
import { ButtonMob, instanceMob, Loadingspinner } from "../../../utils/utils";
import TableCustom from "../../../utils/table-custom";
import { ReactComponent as SearchIconAlone } from "../../../assets/elevva-pro-icons/searchIconAlone.svg";
import { ReactComponent as FiltersTable } from "../../../assets/elevva-pro-icons/filters-table.svg";
import { ReactComponent as ArrowDown } from "../../../assets/elevva-pro-icons/arrowDown.svg";

import changePage from "../functions/changePage";
import ConfirmDeletionModal from "./modal-delete";
import { DropdownList } from "react-widgets";
import { Modal, Spinner } from "react-bootstrap";
import TableCustomExpired from "../../../utils/table-custom-expired";
import debounce from "../functions/debounce";

const ModalExpiredPlans = (props) => {
  const filter = props.filter || "";
  const [users, setUsers] = useState([]);

  const [usersExpired, setUsersExpired] = useState({});
  const [userNearExpired, setUserNearExpired] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [userDetail, setUserDetail] = useState(null);
  const [pageNext, setPageNext] = useState(null);
  const [pageBack, setPageBack] = useState(null);
  const [dataBackup, setDataBackup] = useState({});
  const [modal, setModal] = useState(null);
  const [modalDelete, setModalDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loaderDelete, setLoaderDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ErroraGregado, seterroraGregado] = useState("");
  const [loader, setLoader] = useState(false);

  console.log(users, "user");

  function getUsersPage(value) {
    let url = value ? value : `admin/membership`;

    instanceMob
      .get(url)
      .then((res) => res.data)
      .then((data) => {
        setUsers(data.results);
        setPageNext(data.next);
        setPageBack(data.previous);
      })
      .catch((err) => console.error(err));
  }

  function getUsers() {
    instanceMob
      .get(`admin/membership/plan/expiration?filter=${filter}`)
      .then((res) => res.data)
      .then((data) => {
        setUsers(data.results);
        console.log(data.results);
        setCount(data.count);
        setPageNext(data.next);
        setPageBack(data.previous);
        setDataBackup(data);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }
  useEffect(() => {
    if (modal && loaderDelete) renewUser();
  }, [loaderDelete]);

  useEffect(() => {
    setIsLoading(true);
    getUsers();
  }, []);

  function planUserChange(event, user) {
    setLoader(true);
    let formData = new FormData();
    const { value, name } = event.target[0];
    formData.append(name, value);
    formData.append("customer", user.customer_id);
    instanceMob
      .post(`admin/membership/plan/change`, formData)
      .then((res) => {
        if (res.status == "204" || res.status == "201") {
          console.log(res);
          setLoader(false);
          setModal(null);

          setModalDelete(
            <ConfirmDeletionModal
              onCancel={() => setModalDelete(null)}
              onConfirm={() => setModalDelete(null)}
              loader={false}
            >
              {/* Inserta cualquier contenido aquí como children */}
              {user.customer_name}
              <br></br>
              <strong>asignado correctamente </strong>
            </ConfirmDeletionModal>
          );
          getUsers();
        }
      })
      .catch((err) => {
        let msg_error = err.response.data;
        if (msg_error) {
          seterroraGregado(msg_error);
        }
      });
  }

  function renewUser(user) {
    const handleDelete = () => {
      setLoaderDelete(true);
      let formData = new FormData();
      formData.append("customer", user.customer_id);
      instanceMob
        .post(`admin/membership/plan/renew`, formData)
        .then((res) => {
          if (res.status == "204" || res.status == "201") {
            setLoaderDelete(false);
            getUsers();
          }
        })
        .catch((err) => {
          let msg_error = err.response.data;
          if (msg_error) {
            seterroraGregado(msg_error);
          }
        });
    };

    setModal(
      user ? (
        <ConfirmDeletionModal
          onCancel={() => setModal(null)}
          onConfirm={handleDelete}
          loader={loaderDelete}
        >
          {/* Inserta cualquier contenido aquí como children */}
          ¿Estas seguro de <br></br>
          <strong>renovar el plan de {user?.customer_name}? </strong>
        </ConfirmDeletionModal>
      ) : (
        <ConfirmDeletionModal
          onCancel={() => setModal(null)}
          onConfirm={() => setModal(null)}
          loader={false}
        >
          <br />
          Plan de usuario
          <br />
          <strong> renovado</strong>
          <br />
          <strong>correctamente.</strong>
          <br />
        </ConfirmDeletionModal>
      )
    );
  }
  function deleteUser(user) {
    const handleDelete = () => {
      setLoaderDelete(true);
      let formData = new FormData();
      formData.append("customer", user.customer_id);
      formData.append("plan", user.id);

      instanceMob
        .post(`admin/membership/plan/deactivate`)
        .then((res) => {
          if (res.status == "204" || res.status == "200") {
            setLoaderDelete(false);
            // getPlans();
          }
        })
        .catch((err) => {
          let msg_error = err.response.data;
          if (msg_error) {
            seterroraGregado(msg_error);
          }
        });
    };

    setModal(
      user ? (
        <ConfirmDeletionModal
          onCancel={() => setModal(null)}
          onConfirm={handleDelete}
          loader={loaderDelete}
        >
          {/* Inserta cualquier contenido aquí como children */}
          ¿Estas seguro de <br></br>
          <strong>eliminar {user?.name || "el plan"}? </strong>
        </ConfirmDeletionModal>
      ) : (
        <ConfirmDeletionModal
          onCancel={() => setModal(null)}
          onConfirm={() => setModal(null)}
          loader={false}
        >
          <br />
          Plan
          <br />
          <strong> eliminada</strong>
          <br />
          <strong>correctamente.</strong>
          <br />
        </ConfirmDeletionModal>
      )
    );
  }
  function getListFilter(value) {
    instanceMob
      .get(`admin/membership/plan/expiration?filter=${filter}`)
      .then((res) => res.data)
      .then((data) => {
        setUsers([]);
        setPageNext(data.next);
        setPageBack(data.previous);
        setCount(data.count);
      })
      .catch((err) => console.error(err));
  }

  function searchUser(e) {
    let valueLower = e.target.value.toLowerCase();
    if (valueLower == "") {
      setUsers(dataBackup.results);
      setCount(dataBackup.count);
      setPageNext(dataBackup.next);
      setPageBack(dataBackup.previous);
    } else {
      getListFilter(valueLower);
    }
  }

  return (
    <>
      {modal}
      {modalDelete}
      <div
        style={{
          width: "100%",
          borderRadius: "10px",
          height: "100%",
        }}
      >
        <div className="h-100">
          <div className="my-2 px-4">
            <div
              style={{
                width: "100%",

                height: "100%",
              }}
            >
              <div
                className="d-flex  p-2"
                style={{
                  justifyContent: "center",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "calc(.4rem + .4vw)",
                    color: "var(--elevvaTextTitle)",
                  }}
                >
                  {filter == "expired"
                    ? "Planes vencidos"
                    : "Planes próximos a vencer"}
                </span>
              </div>

              {filter == "near_to_expire" && (
                <div
                  className="d-flex  p-2"
                  style={{
                    justifyContent: "center",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "calc(.4rem + .4vw)",
                      color: "var(--elevvaTextTitle)",
                    }}
                  >
                    Acá veras los usuarios con planes próximos <br></br>a vencer
                    en 7 días o les quedan 3 accesos
                  </span>
                </div>
              )}

              <div
                style={{ justifyContent: "flex-end" }}
                className="d-flex mx-3 my-2"
              >
                <div
                  className="mx-3"
                  style={{ position: "relative", marginLeft: "auto" }}
                >
                  <input
                    placeholder="Buscar usuario"
                    type="search"
                    className="searchUsers "
                    style={{
                      border: "1px solid var(--elevvaTextSubtitle)",
                      borderRadius: "20px",
                      fontSize: "calc(.33rem + .32vw)",
                      padding: "0.4rem 1rem",
                    }}
                    onChange={debounce(searchUser, 500)}
                  ></input>
                  <span className="search-icon-admin">
                    <SearchIconAlone fontSize={10} />
                  </span>
                </div>{" "}
                {/* <div className="d-flex align-items-center me-1">
                  <FiltersTable
                    style={{ width: "100%", padding: "2%" }}
                    className="cursor-pointer"
                    onClick={() => {}}
                  />
                </div> */}
              </div>
              <div
                className="py-2"
                style={{ minHeight: "50vh", minWidth: "30rem" }}
              >
                {isLoading ? (
                  <Loadingspinner customStyle="grow" size="2.5rem" />
                ) : (
                  <TableCustomExpired
                    deleteUser={deleteUser}
                    renewUser={renewUser}
                    changePage={(value) =>
                      changePage(
                        value,
                        getUsersPage,
                        pageNext,
                        pageBack,
                        page,
                        count,
                        setPage
                      )
                    }
                    count={count}
                    page={page}
                    users={users}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ModalExpiredPlans;
