
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useNavigate } from "react-router";
import CardMetrics from "./card-metrics";
const GridMetricSesions = ({ users }) => {
  const navigate = useNavigate();
  return (
    <div className="container-fluid mt-3">
    <div style={{margin:"0 auto"}}>
      <PerfectScrollbar className="px-1 w-100" style={{ height: "calc(100vh - 13rem)" }}>
       <div className="row row-cols-2 row-cols-md-5 row-cols-lg-5 row-cols-xl-4 row-cols-xxl-5 g-1">
        {users.map((user, index) => (
          <div key={index} className="col cursor-pointer" onClick={ () => navigate(`/live-metrics-sessions`)}>
            <CardMetrics {...user} />
          </div>
        ))}
      </div>
      </PerfectScrollbar>
      </div>
    </div>
  );
};
export default GridMetricSesions;
