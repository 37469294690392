// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, h1, h2, h3, h4, h5, h6,label,div,u,span,input,button,textarea  {
  margin: 0;
  font-family: 'Work Sans', sans-serif !important ;
  font-size:16px;
  
 
}
body {
  font-family: 'Work Sans' , sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.0;
  height: 100%;
  /* mobile viewport bug fix */

 
 
}



code {
  font-family: 'Work Sans', sans-serif
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,SAAS;EACT,gDAAgD;EAChD,cAAc;;;AAGhB;AACA;EACE,gDAAgD;EAChD,mCAAmC;EACnC,kCAAkC;EAClC,gBAAgB;EAChB,YAAY;EACZ,4BAA4B;;;;AAI9B;;;;AAIA;EACE;AACF","sourcesContent":["@import url(\"https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css\");\nbody, h1, h2, h3, h4, h5, h6,label,div,u,span,input,button,textarea  {\n  margin: 0;\n  font-family: 'Work Sans', sans-serif !important ;\n  font-size:16px;\n  \n \n}\nbody {\n  font-family: 'Work Sans' , sans-serif !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  line-height: 1.0;\n  height: 100%;\n  /* mobile viewport bug fix */\n\n \n \n}\n\n\n\ncode {\n  font-family: 'Work Sans', sans-serif\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
