import { use, useEffect, useRef, useState } from "react";
import { ReactComponent as DeleteTag } from "../../../assets/elevva-pro-icons/delete-tag.svg";
import moment from "moment";
const separatorStyle = {
  border: 0,
  borderTop: "1px solid #A8C2D7",
  margin: "0.5rem 0",
};
function esHoraValida(hora1, hora2) {
  const formato = "HH:mm"; // Formato de 24 horas

  let t1 = moment(hora1, formato);
  let t2 = moment(hora2, formato);

  return t2.isAfter(t1); // Retorna true si la segunda hora es mayor
}
const ScheduleComponent = (props) => {
  const setStartTime = props.setStartTime;
  const setEndTime = props.setEndTime;
  const days = props.days;
  const handleDaySelection = props.handleDaySelection;
  const selectedDays = props.selectedDays;
  const schedule = props.schedule;
  const index = props.index;
  const deleteSchedule = props.deleteSchedule;
  const setScheduleSelected = props.setScheduleSelected;
  const setErrorModal = props.setErrorModal;
  const endTime = props.endTime;
  const startTime = props.startTime;
  const errorModal = props.errorModal;
  const hours = Object.values(schedule.weekly_hours) || [];
  const hoursIndex = hours.findIndex((array) => array.length > 0) || 0;
  const [errorHours, setErrorHours] = useState("");

  const startInputRef = useRef(null);
  const endInputRef = useRef(null);
  useEffect(() => {
    if (startTime && endTime) {
      let result = esHoraValida(startTime, endTime);
      if (!result) {
        setErrorHours("Error: No es un dia continuo en 24h");
        setStartTime(null);
        setEndTime(null);
        startInputRef.current.value = "";
        endInputRef.current.value = "";
        setTimeout(() => {
          setErrorHours("");
        }, 3000);
      } else {
        setErrorHours("");
      }
    } // ❌ Error: No es un día continuo en 24h
  }, [startTime, endTime]);

  return (
    <div className="my-2">
      <div className="d-flex justify-content-between mx-auto gap-3">
        <span style={{ fontSize: "calc(.4rem + .4vw)" }}>Horario {index}</span>
        {schedule.id !== 1 && (
          <div>
            <DeleteTag
              className="cursor-pointer"
              onClick={() => deleteSchedule(schedule.id)}
            />
          </div>
        )}
      </div>
      <hr style={separatorStyle} />
      <div className="d-flex">
        <div className="d-flex justify-content-between mx-auto gap-3">
          <div className="d-flex flex-column">
            <span style={{ fontSize: "calc(.4rem + .4vw)" }}>
              Horario de inicio
            </span>
            <div>
              <input
                ref={startInputRef}
                type="time"
                placeholder=""
                defaultValue={
                  hours.length > 0 && hours && hours[hoursIndex]
                    ? hours[hoursIndex][0][0]
                    : ""
                }
                onChange={(e) => {
                  setScheduleSelected(schedule.id);
                  setStartTime(e.target.value);
                }}
                style={{
                  color: "#9DACBD",
                  fontSize: "calc(.4rem + .4vw)",
                  border: "1px solid #9DACBD",
                  borderRadius: "5px",
                }}
                className="me-2 w-100"
              ></input>
            </div>
          </div>
          <div className="d-flex flex-column">
            <span style={{ fontSize: "calc(.4rem + .4vw)" }}>
              Horario de finalización
            </span>
            <div style={{ position: "relative", display: "inline-block" }}>
              <input
                ref={endInputRef}
                defaultValue={
                  hours.length > 0 && hours && hours[hoursIndex]
                    ? hours[hoursIndex][0][1]
                    : ""
                }
                type="time"
                placeholder=""
                onChange={(e) => {
                  setScheduleSelected(schedule.id);
                  setEndTime(e.target.value);
                }}
                style={{
                  color: "#9DACBD",
                  fontSize: "calc(.4rem + .4vw)",
                  border: "1px solid #9DACBD",
                  borderRadius: "5px",
                }}
                className="me-2 w-100 "
              ></input>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      {errorHours && errorHours.length > 0 && (
        <div className="my-2 text-center">
          <span className="text-danger ">{errorHours}</span>
        </div>
      )}
      {errorModal && errorModal.length > 0 && (
        <div className="my-2 text-center">
          <span className="text-danger ">{errorModal}</span>
        </div>
      )}

      <div
        className="my-2"
        style={{
          display: "flex",
          justifyContent: "space-around",
          padding: "0.3rem",
        }}
      >
        {days.map(({ name, value }) => (
          <label
            key={value}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              backgroundColor: selectedDays.includes(value)
                ? "var(--elevvabutonLogin_User)"
                : "#E3E3E3",
              color: "#9DACBD",
              fontSize: "calc(.4rem + .4vw)",
              fontWeight: "500",
              cursor: "pointer",
            }}
            className={`flex items-center justify-center w-10 h-10 rounded-full cursor-pointer ${
              selectedDays.includes(value) ? " text-white" : " text-gray-700"
            }`}
            onClick={() => {
              if (startTime && endTime) {
                setScheduleSelected(schedule.id);
                handleDaySelection(value, schedule.id);
              } else {
                setErrorModal("Por favor ingresa ambos horarios.");
                setTimeout(() => {
                  setErrorModal("");
                }, 3000);
              }
            }}
          >
            {name}
          </label>
        ))}
      </div>
    </div>
  );
};
export default ScheduleComponent;
