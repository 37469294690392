import { Group } from "@visx/group";
import { curveMonotoneX } from "@visx/curve";
import React, { useRef, useState, useMemo } from "react";
import { scaleTime, scaleLinear, scaleBand, scaleOrdinal } from "@visx/scale";
import appleStock, { AppleStock } from "@visx/mock-data/lib/mocks/appleStock";
import cityTemperature, {
  CityTemperature
} from "@visx/mock-data/lib/mocks/cityTemperature";
import { AxisLeft, AxisBottom, AxisScale, AxisRight } from "@visx/axis";
import Moment from "moment";
import { BarGroup } from "@visx/shape";

import { AreaClosed } from '@visx/shape';

import { max, extent } from "d3-array";
import { localPoint } from '@visx/event';
import { Dummie } from "./barGraphic";
// Initialize some variables
const axisColor = "#fff";
const axisBottomTickLabelProps = {
  textAnchor: "middle" as const,
  fontFamily: "Arial",
  fontSize: 10,
  fill: "var(--elevva)",
  
};



export default function AreaChart({
  data,
  gradientColor,
  dataUsers,
  width,
  yMax,
  margin,
  hideBottomAxis = false,
  hideLeftAxis = false,
  top,
  left,
  children,
  keys,
  dateScale,
  cityScale,
  tempScale,tempScaleRight,
  colorScale,
  getDate,hideTooltip,showTooltip
}: {
  data: Dummie[];
  dataUsers:any,
  keys:any,
  dateScale:any,
  cityScale:any,
  tempScale:any,
  tempScaleRight:any,
  colorScale:any,
  getDate:any,
  xScale:any,
  gradientColor: string;
  width: number;
  yMax: number;
  margin: { top: number; right: number; bottom: number; left: number };
  hideBottomAxis?: boolean;
  hideLeftAxis?: boolean;
  top?: number;
  left?: number;
  children?: React.ReactNode;
  hideTooltip:any;
  showTooltip:any
}) {
  if (width < 10) return null;

let tooltipTimeout:number;
  tempScale.range([yMax, 0]);
 
 const getRighttAxisTickLabelProps = () =>
 ({
   fill: Object.keys(data[0]).includes("Calorías")?"red":Object.keys(data[0]).includes("Distancia")? "rgb(138, 231, 224)":"#222",
   textAnchor: "end",
   fontSize: 10
 } as const);

  return (
    <Group left={left || margin.left} top={top || margin.top}>
      <BarGroup
        data={data}
        keys={keys}
        height={yMax}
        x0={getDate}
        x0Scale={dateScale}
        x1Scale={cityScale}
        yScale={tempScale}
        
        color={colorScale}
      >
        {(barGroups) =>
          barGroups.map((barGroup) => (
            <Group
              key={`bar-group-${barGroup.index}-${barGroup.x0}`}
              left={barGroup.x0}
            >
              {barGroup.bars.map((bar) => {
                let yScalex = Object.keys(data[0]).includes("Calorías") &&  Object.keys(data[0]).includes("Distancia")  && Object.keys(data[0]).length ==3 &&bar.key === 'Calorías'?tempScaleRight: bar.key === 'Calorías' && Object.keys(data[0]).length >3 ? tempScaleRight : tempScale;
                return(
                <rect
                  key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                  x={bar.x}
                  y={yScalex(bar.value)}
                  width={bar.width}
                  height={yMax - yScalex(bar.value)}
                  fill={bar.color}
                  rx={4}
                  onClick={(events) => {
                    if (!events) return;
                    const { key, value } = bar;
                    // alert(JSON.stringify({ key, value }));
                  }}
                  onMouseLeave={() => {
                    tooltipTimeout = window.setTimeout(() => {
                      hideTooltip();
                    }, 300);
                  }}
                  onMouseMove={(event) => {
                    if (tooltipTimeout) clearTimeout(tooltipTimeout);
                   
                    const coords = localPoint(event?.target?.ownerSVGElement, event);
                    showTooltip({
                      tooltipLeft: coords?.x,
                      tooltipTop: coords?.y,
                      tooltipData: bar
                    });
                   
                  }}
                />
              )})}
            </Group>
          ))
        }
       
      </BarGroup>
     
      {children}

    { !hideLeftAxis&& <AxisLeft
          scale={tempScale}
          // numTicks={5}
          stroke={axisColor}
          tickStroke={axisColor}
          //  tickLabelProps={() => axisLeftTickLabelProps}
        />}
         {!hideLeftAxis && Object.keys(data[0]).length>2 && Object.keys(data[0]).includes("Calorías") || !hideLeftAxis && Object.keys(data[0]).length>2 &&Object.keys(data[0]).includes("Distancia")? <AxisRight
           left={width - margin.left - 10 }
         
          //  numTicks={5}
          // label="price"
        
          scale={tempScaleRight}
          // top={0}
         
          hideTicks
          hideAxisLine
          stroke={axisColor}
          //  labelProps={axisLabelProps}
          // labelOffset={16}
          // tickLength={4}
            tickLabelProps={getRighttAxisTickLabelProps}
            tickComponent={({x, y, formattedValue }) => (
              <text
                  key={`tick-${formattedValue}`}
                  x={x}
                  fill={ Object.keys(data[0]).includes("Calorías")?"red":Object.keys(data[0]).includes("Distancia")? "rgb(138, 231, 224)":"#222"}
                  y={y} // 5 es el máximo del dominio de la escala y 180 es el rango máximo
                  style={{ fontSize: 10, textAnchor: 'end',fontWeight:"bold" }}
              >
                  {formattedValue}
              </text>
          )}
          // tickFormat={(value:any, index:number, array:any) => { return value}}
            // Calcula la posición y usando getTickYPosition
  
        />:null}
      
      {!hideBottomAxis && (
        <AxisBottom
          top={yMax}
          scale={dateScale}
          numTicks={width > 520 ? 10 : 5}
          stroke={"gray"}
          
          tickStroke={"gray"}
          tickLabelProps={() => axisBottomTickLabelProps}
       
          tickComponent={({ x, y, formattedValue }) => {
           
            return(
            <g transform={`translate(${x},${y})`}>
              <text x={0} y={30} fontSize={10} textAnchor="middle">
                {Moment(formattedValue)?.format("DD-MM-YYYY")} 
              </text>
              <svg x={-12} y={-10} width={30} height={30}>
        <circle cx={10} cy={10} r={10} fill="white" ></circle>
           
                <foreignObject x={0} y={5} width={25} height={25}>
          <div
           style={{
           height: "100%",
            width: "100%",
            borderRadius: "50%",
            overflow:"hidden"
          }}
          >
            <img   style={{width:"100%",height:"100%",   objectFit: "cover",}}
              src={dataUsers.find((img:any) => Moment(img.date).format() === Moment(formattedValue).format())?.customer?.profile_pic}
              alt="img"
            
            />
          </div>
        </foreignObject>
              </svg>
            </g>
          )}}
       
        />
      )}
       
    </Group>
  );
}
