import CardService from "./card-services";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import { laptop1600 } from "../../../utils/utils";
import "swiper/swiper.min.css";
const Services = (props) => {
  const modalServiceDetail = props.modalServiceDetail;
  const addService = props.addService;
  return (
    <div className="d-flex justify-content-center h-100 align-items-center flex-column">
      <div
        className="d-flex w-100"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem 1rem 0rem 1rem",
        }}
      >
        {" "}
        <div className="py-0" style={{ marginLeft: "40%" }}>
          {" "}
          <span
            style={{
              fontSize: "calc(.4rem + .4vw)",
              color: "var(--elevvaTextTitle)",
            }}
          >
            Panel de <strong>servicios</strong>{" "}
          </span>
        </div>
        <div className="d-flex ">
          <input
            placeholder="Ver servicios"
            type="search"
            className="searchAtletas  py-1 w-100"
            style={{
              border: "1px solid var(--elevvaTextSubtitle)",
              borderRadius: "20px",
              fontSize: "calc(.4rem + .4vw)",
            }}
            onChange={() => {}}
          ></input>
        </div>
        <button
          style={{ marginRight: "2%" }}
          className="  d-flex add_location"
          onClick={addService}
        >
          Agregar servicio +
        </button>
      </div>
      <div
        className="position-relative w-100 h-100"
        style={{
          width: "95%",
          overflow: "hidden",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className={
            "d-flex position-absolute  w-100 h-100 swiper-pagination_p"
          }
        >
          <div
            style={{ zIndex: 10 }}
            className={`button-prev-exercise-${`service`} swiper-button-prev-exercise`}
          ></div>
          <div
            className={`button-next-exercise-${`service`} swiper-button-next-exercise`}
            style={{
              zIndex: 10,
              left: laptop1600.matches ? "90%" : "88%",
              transform: "translateX(-50%)",
            }}
          ></div>
        </div>
        <Swiper
          style={{ width: "80%", padding: "1rem 0" }}
          id={`swiper-company-${`service`}`}
          keyboard={{
            enabled: true,
          }}
          slidesPerView={"auto"}
          navigation={{
            nextEl: `.button-next-exercise-${`service`}`,
            prevEl: `.button-prev-exercise-${`service`}`,
          }}
          modules={[Navigation]}
        >
          <SwiperSlide
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(4,1fr)",
              gridTemplateRows: "repeat(2,1fr)",
              gap: " 0.5rem",
            }}
            className=" position-relative w-auto mx-2"
            key={1}
          >
            {" "}
            <CardService step={1} modalServiceDetail={modalServiceDetail} />
            <CardService step={2} modalServiceDetail={modalServiceDetail} />
            <CardService step={3} modalServiceDetail={modalServiceDetail} />
            <CardService step={4} modalServiceDetail={modalServiceDetail} />
            <CardService step={5} modalServiceDetail={modalServiceDetail} />
            <CardService step={6} modalServiceDetail={modalServiceDetail} />
            <CardService step={7} modalServiceDetail={modalServiceDetail} />
            <CardService step={8} modalServiceDetail={modalServiceDetail} />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Services;
