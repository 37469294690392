import { useState } from "react";
import LogoSupremacyBig from "../../assets/assets/elevva-mora_big.png";
import MobConfig from "./mob-config";
import MobMembership from "./mob-membership";
import MobBooking from "./mob-booking";

const Mob = () => {
  const [selectedTab, setSelectedTab] = useState(1);

  return (
    <div
      style={{
        flexGrow: "1",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div
        className="d-flex position-relative"
        style={{
          height: "60px",
          background: "var(--backgroundOnboarding)",
          columnGap: "2rem",
        }}
      >
        <div className="logo-supremacy">
          <img style={{ width: "6rem" }} src={LogoSupremacyBig}></img>
        </div>
      </div>
      <div
        style={{
          flexGrow: "1",
          height: "calc(100% - 60px)",
        }}
        className="d-flex "
      >
        <div
          style={{
            width: "100%",

            borderRadius: "10px",
            height: "calc(100% - 1.5rem)",
            padding: "3rem 3rem 0rem 3rem",
            flexGrow: "1",
            overflow: "auto",
            columnGap: "calc(.5rem + .5vw)",
          }}
          className="d-flex m-3"
        >
          <div
            style={{
              width: "100%",
              border: "1px solid #B6C1CE",
              borderRadius: "10px",
              height: "calc(100% - 1.5rem)",
              flexGrow: "1",
              columnGap: "calc(.5rem + .5vw)",
              position: "relative",
            }}
            className="d-flex p-3 "
          >
            <div
              style={{
                top: "-42px",
                position: "absolute",
                zIndex: selectedTab == 1 ? "2" : "0",
                borderRadius: "10px",
              }}
            >
              <div className="tab" onClick={() => setSelectedTab(1)}>
                <div
                  className="tab-label"
                  style={
                    selectedTab === 1
                      ? { borderBottom: "1px solid #ffff" }
                      : { borderBottom: "1px solid #b0bac5" }
                  }
                >
                  Configuración
                </div>
              </div>
            </div>
            <div
              style={{
                top: "-42px",
                left: "15rem",
                position: "absolute",
                zIndex: selectedTab == 2 ? "2" : "1",
                borderRadius: "10px",
              }}
            >
              <div className="tab" onClick={() => setSelectedTab(2)}>
                <div
                  className="tab-label"
                  style={
                    selectedTab === 2
                      ? { borderBottom: "1px solid #ffff" }
                      : { borderBottom: "1px solid #b0bac5" }
                  }
                >
                  Planes
                </div>
              </div>
            </div>
            <div
              style={{
                top: "-42px",
                left: "25rem",
                position: "absolute",
                zIndex: selectedTab == 3 ? "2" : "0",
                borderRadius: "10px",
              }}
            >
              <div className="tab" onClick={() => setSelectedTab(3)}>
                <div
                  className="tab-label"
                  style={
                    selectedTab === 3
                      ? { borderBottom: "1px solid #fff" }
                      : { borderBottom: "1px solid #b0bac5" }
                  }
                >
                  Reservas
                </div>
              </div>
            </div>
            {selectedTab === 1 && <MobConfig />}
            {selectedTab === 2 && <MobMembership />}
            {selectedTab === 3 && <MobBooking />}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Mob;
