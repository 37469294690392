import React from "react";
import { ReactComponent as SearchInvidual } from "../../../assets/elevva-pro-icons/search-individual-table.svg";
import { ReactComponent as Person } from "../../../assets/elevva-pro-icons/person-service-modificate.svg";
import { ReactComponent as Clock } from "../../../assets/elevva-pro-icons/clock-service-modificate.svg";
import { ReactComponent as Location } from "../../../assets/elevva-pro-icons/location-service-modificate.svg";
import moment from "moment";
// 3177be
const cardStyle = {
  width: "15rem",
  border: "1px solid #d1d5db",
  borderRadius: "10px",
  padding: "0rem 0rem",
  backgroundColor: "#ffffff",
  fontFamily: "Arial, sans-serif",
  color: "#374151",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  height: "auto",
  maxHeight: "10rem",
};

const titleStyle = {
  fontSize: "calc(.3rem + .3vw)",

  margin: 0,
};

const iconStyle = {
  cursor: "pointer",
  marginLeft: "0.5rem",
  color: "#9ca3af",
};

const separatorStyle = {
  border: 0,
  borderTop: "1px solid #A8C2D7",
  margin: "0.5rem 0",
};

const detailContainerStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "0.1rem",
  fontSize: "calc(.3rem + .3vw)",
  color: "#1f2937",
};

const iconTextStyle = {
  color: "#3b82f6",
  marginRight: "0.5rem",
  fontSize: "calc(.3rem + .3vw)",
};

const secondaryTextStyle = {
  color: "#3b82f6",
  marginLeft: "1rem",
  marginRight: "0.5rem",
  fontSize: "calc(.3rem + .3vw)",
};

const locationContainerStyle = {
  display: "flex",
  alignItems: "center",
  fontSize: "calc(.3rem + .3vw)",
  color: "#1f2937",
};

const searchIconStyle = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  fontSize: "1rem",
  color: "#3b82f6",

  cursor: "pointer",
};

const CardBookingsList = (props) => {
  const modalServiceDetail = props.modalServiceDetail;
  const service = props.booking;
  const schedule = props.schedule;
  const now = moment(); // Hora actual
  const startTime = moment(schedule.start_time, "HH:mm:ss");
  const endTime = moment(schedule.end_time, "HH:mm:ss");
  const isNowBetween = now.isBetween(startTime, endTime);

  return (
    <div
      className={`d-flex flex-column w-100 align-items-center gap-0  px-3 mx-auto`}
      style={{
        maxWidth: "calc(100vw - 30%)",
        // borderBottom: "1px solid #A8C2D7",
        background: isNowBetween ? "#3177BE" : "#ffffff",
        borderRadius: isNowBetween ? "30px" : "0px",
      }}
    >
      <div className={`d-flex w-100 align-items-center gap-0  px-3 mx-auto`}>
        <div className="flex-grow-1 ">
          <div className="d-flex align-items-center justify-content-between gap-2 small">
            <div className="col-3">
              {" "}
              <span
                style={{ color: isNowBetween ? "#ffffff" : "#394F65" }}
                className=" mb-0"
              >
                {service.service_name}
              </span>{" "}
            </div>
            <div className="col-3">
              {" "}
              <Clock
                className="me-2"
                size={16}
                style={{ color: isNowBetween ? "#ffffff" : "#3177be" }}
              />
              <span style={{ color: isNowBetween ? "#ffffff" : "#394F65" }}>
                {startTime.format("h:mm a ")} - {endTime.format("h:mm a ")}
              </span>
            </div>

            <div className="col-3 d-flex align-items-center">
              <span
                className="me-2"
                style={{ color: isNowBetween ? "#ffffff" : "#394F65" }}
              >
                <Clock
                  style={{ color: isNowBetween ? "#ffffff" : "#3177be" }}
                />
              </span>
              <span
                style={{ color: isNowBetween ? "#ffffff" : "#394F65" }}
                className="me-3"
              >
                {service.duration} min
              </span>
              <Person
                size={16}
                className="me-2"
                style={{ color: isNowBetween ? "#ffffff" : "#3177be" }}
              />
              <span style={{ color: isNowBetween ? "#ffffff" : "#394F65" }}>
                {" "}
                {schedule?.attendees_count || 0}/{schedule.available_slots}{" "}
                cupos
              </span>{" "}
            </div>

            <div className="col-3">
              <Location
                size={16}
                style={{ color: isNowBetween ? "#ffffff" : "#3177be" }}
              />
              <span style={{ color: isNowBetween ? "#ffffff" : "#394F65" }}>
                {" "}
                {service.location_name}
              </span>
            </div>
          </div>
        </div>
        <button className="btn  rounded-circle p-2">
          <SearchInvidual
            width={"1.2rem"}
            onClick={() =>
              modalServiceDetail(
                service.id,
                `${schedule.day} ${moment(schedule.start_time, "HH:mm").format(
                  "HH:mm"
                )}`
              )
            }
          />
        </button>
      </div>

      <div
        style={{
          width: "99%",

          borderBottom: "1px solid #A8C2D7",
        }}
      ></div>
    </div>
  );
};

export default CardBookingsList;
