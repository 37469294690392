import { useEffect, useState } from "react";
import { ButtonMob, instance } from "../../../utils/utils";
import "./swiper-exercise.css";
import Moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/swiper.min.css";
import { ReactComponent as EditIcon } from "../../../assets/elevva-pro-icons/edit-icon.svg";
const ExerciseData = (props) => {
  const exerciseId = props.exerciseId;
  const userId = props.userId;
  const [exerciseDetail, setExerciseDetail] = useState([]);
  SwiperCore.use([Pagination, Autoplay, Navigation]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(null);
  const [modalError, setModalError] = useState(null);
  function showError(errorMessage) {
    setModal(false);

    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalError(
      <div
        className="popUpNewUser justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "10%",
          }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "1%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModalError(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div
            className=" mb-3 py-3"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            <div
              className=" pt-3 my-4"
              style={{
                lineHeight: "100%",
                textAlign: "center",

                color: "var(--elevvabutonLogin_User)",
              }}
            >
              {errorMessage ? (
                errorMessage
              ) : (
                <>
                  {" "}
                  Ha ocurrido un error
                  <strong> intenta de nuevo</strong>
                  <strong> más tarde</strong>
                </>
              )}
            </div>
            <ButtonMob
              handleClick={() => setModalError(null)}
              colorButton={"var(--elevvabutonLogin_User)"}
              text={"Continuar"}
              className={"mb-3 mt-3 letraspopfi2actualizar"}
            />
          </div>
        </div>
      </div>
    );
  }
  const hideModal = () => {
    setModal(null);
  };
  function getExerciseData() {
    instance
      .get(`customers/logs/?customer=${userId}&exercise=${exerciseId}`)
      .then((res) => res.data)
      .then((data) => {
        setExerciseDetail(data);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }
  useEffect(() => {
    getExerciseData();
  }, []);
  function createNewToma(event, state, idToma) {
    let metric = event.target[0].value;

    var formData = new FormData();
    formData.append("metric", metric.trim());

    formData.append("exercise", exerciseId);
    if (state === "post") {
      instance
        .post(`customers/logs/?customer=${userId}`, formData)
        .then((res) => {
          if (res.data) {
            let styleModal = {
              display: "flex",
              position: "fixed",
              zIndex: 999,
              whiteSpace: "pre-line",
            };
            getExerciseData();
            setModal(
              <div
                className="popUpNewUser justify-content-center align-items-center"
                style={styleModal}
              >
                <div
                  className="modalInfoContenido"
                  style={{
                    background: "white",
                    borderRadius: "20px",
                    boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
                    padding: "2% 6%",
                  }}
                >
                  <div
                    className=""
                    style={{
                      position: "absolute",
                      top: "5%",
                      right: "1%",
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      color: " #9DACBD",
                      cursor: "pointer",
                    }}
                    onClick={() => setModal(null)}
                  >
                    <i className="bi bi-x-circle"></i>
                  </div>
                  <div
                    className=" mb-3 py-3"
                    style={{
                      lineHeight: "120%",
                      textAlign: "center",

                      minWidth: "calc(18rem + 1vmin)",

                      color: "var(--elevvabutonLogin_User)",
                    }}
                  >
                    <br />
                    El record
                    <br />
                    <strong> sido agregado</strong>
                    <br />
                    <strong>correctamente.</strong>
                    <br />
                  </div>
                  <form id="form-password-change" onSubmit={hideModal}>
                    <div className="col-12">
                      <div className="col-12 btnAction_pruebas letraspopfi2">
                        <ButtonMob
                          colorButton={"var(--elevvabutonLogin_User)"}
                          text="Confirmar"
                          className={"mb-3 mt-3 letraspopfi2actualizar"}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            );
          }
        })
        .catch((err) => {
          let msg_error = err.response.data;
          if (msg_error.unit_measurement) {
            showError(msg_error.unit_measurement);
          } else {
            if (msg_error) {
              showError();
            }
          }
        });
    } else {
      instance
        .patch(`customers/logs/${idToma}`, formData)
        .then((res) => {
          if (res.data) {
            let styleModal = {
              display: "flex",
              position: "fixed",
              zIndex: 999,
              whiteSpace: "pre-line",
            };
            getExerciseData();
            setModal(
              <div
                className="popUpNewUser justify-content-center align-items-center"
                style={styleModal}
              >
                <div
                  className="modalInfoContenido"
                  style={{
                    background: "white",
                    borderRadius: "20px",
                    boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
                    padding: "2% 6%",
                  }}
                >
                  <div
                    className=""
                    style={{
                      position: "absolute",
                      top: "5%",
                      right: "1%",
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      color: " #9DACBD",
                      cursor: "pointer",
                    }}
                    onClick={() => setModal(null)}
                  >
                    <i className="bi bi-x-circle"></i>
                  </div>
                  <div
                    className=" mb-3 py-3"
                    style={{
                      lineHeight: "120%",
                      textAlign: "center",

                      minWidth: "calc(18rem + 1vmin)",

                      color: "var(--elevvabutonLogin_User)",
                    }}
                  >
                    <br />
                    La toma
                    <br />
                    <strong> sido editado</strong>
                    <br />
                    <strong>correctamente.</strong>
                    <br />
                  </div>
                  <form id="form-password-change" onSubmit={hideModal}>
                    <div className="col-12">
                      <div className="col-12 btnAction_pruebas letraspopfi2">
                        <ButtonMob
                          colorButton={"var(--elevvabutonLogin_User)"}
                          text="Confirmar"
                          className={"mb-3 mt-3 letraspopfi2actualizar"}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            );
          }
        })
        .catch((err) => {
          let msg_error = err.response.data;
          if (msg_error.unit_measurement) {
            showError(msg_error.unit_measurement);
          } else {
            if (msg_error) {
              showError();
            }
          }
        });
    }

    event.preventDefault();
  }
  function editToma(idToma) {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModal(
      <div
        className="popUpNewUser justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
          }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "1%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModal(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div
            className=" mb-3 py-3"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            Editar <strong>Toma</strong>
          </div>
          <form
            id="form-new-toma"
            onSubmit={(e) => {
              createNewToma(e, "patch", idToma);
            }}
          >
            <input
              className="form-control input-style-elevva "
              style={{
                minWidth: "calc(18rem + 1vmin)",
                fontSize: "calc(.4rem + .4vw)",
              }}
              type="number"
              name="metric"
              placeholder="Cantidad"
              required
            />

            <div className="col-12">
              <div className="col-12 btnAction_pruebas letraspopfi2">
                <ButtonMob
                  colorButton={"var(--elevvabutonLogin_User)"}
                  text="Confirmar"
                  className={"mb-3 mt-3 letraspopfi2actualizar"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
  function addToma() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModal(
      <div
        className="popUpNewUser justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
          }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "1%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModal(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div
            className=" mb-3 py-3"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            Agregar <strong>Toma</strong>
          </div>
          <form
            id="form-new-toma"
            onSubmit={(e) => {
              createNewToma(e, "post");
            }}
          >
            <input
              className="form-control input-style-elevva "
              style={{
                minWidth: "calc(18rem + 1vmin)",
                fontSize: "calc(.4rem + .4vw)",
              }}
              type="number"
              name="metric"
              placeholder="Cantidad"
              required
            />

            <div className="col-12">
              <div className="col-12 btnAction_pruebas letraspopfi2">
                <ButtonMob
                  colorButton={"var(--elevvabutonLogin_User)"}
                  text="Confirmar"
                  className={"mb-3 mt-3 letraspopfi2actualizar"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
  const render_img_desk =
    exerciseDetail &&
    exerciseDetail?.results &&
    exerciseDetail?.results.map((exercise, index) => (
      <SwiperSlide
        className="d-flex position-relative flex-column w-auto mx-2"
        key={index}
      >
        {" "}
        <div style={{ border: "1px solid #9DACBD", width: "8rem" }}>
          <div className="d-flex w-100 p-1">
            <span>{Moment(exercise.date).format("MM-DD-YY")}</span>
          </div>
          <div
            className="d-flex align-items-center gap-2 p-1"
            style={{
              backgroundColor: "#DFDFDF",
              borderTop: "1px solid #9DACBD",
              borderBottom: "1px solid #9DACBD",
            }}
          >
            {" "}
            <span>{exercise.metric}</span>
            <span> {exercise.exercise.unit_measurement}</span>
          </div>
          <div className="d-flex w-100 p-1 justify-content-end cursor-pointer">
            <span onClick={() => editToma(exercise.id)}>
              {" "}
              <EditIcon width={"20px"} height={"20px"} />
            </span>
          </div>
          <div></div>
        </div>{" "}
      </SwiperSlide>
    ));

  return (
    <>
      {modal}
      {modalError}
      {exerciseDetail &&
      exerciseDetail?.results &&
      exerciseDetail?.results.length > 0 ? (
        <div
          className="d-flex gap-4 align-items-center"
          style={{ width: "80%" }}
        >
          <div style={{ width: "5%" }}>
            <button
              onClick={addToma}
              className="flex items-center justify-center"
              style={{
                width: "25px",
                height: "25px",
                backgroundColor: "#394F65",
                color: "white",
                border: "none",
                borderRadius: "100%",
                cursor: "pointer",
              }}
            >
              +
            </button>
          </div>
          <div
            className="position-relative w-100"
            style={{ width: "95%", overflow: "hidden", margin: "auto" }}
          >
            <div
              className={
                "d-flex position-absolute  w-100 h-100 swiper-pagination_p"
              }
            >
              <div
                style={{ zIndex: 10 }}
                className={`button-prev-exercise-${exerciseId} swiper-button-prev-exercise`}
              ></div>
              <div
                className={`button-next-exercise-${exerciseId} swiper-button-next-exercise`}
                style={{
                  zIndex: 10,
                  left: "86%",
                  transform: "translateX(-50%)",
                }}
              ></div>
            </div>
            <Swiper
              style={{ width: "80%" }}
              id={`swiper-company-${exerciseId}`}
              keyboard={{
                enabled: true,
              }}
              slidesPerView={"auto"}
              navigation={{
                nextEl: `.button-next-exercise-${exerciseId}`,
                prevEl: `.button-prev-exercise-${exerciseId}`,
              }}
              modules={[Navigation]}
            >
              {render_img_desk}
            </Swiper>
          </div>
        </div>
      ) : (
        <div
          className="d-flex gap-4 align-items-center"
          style={{ width: "80%" }}
        >
          <div style={{ width: "15%" }}>
            {" "}
            <button
              onClick={addToma}
              className="flex items-center justify-center"
              style={{
                width: "25px",
                height: "25px",
                backgroundColor: "#394F65",
                color: "white",
                border: "none",
                borderRadius: "100%",
                cursor: "pointer",
              }}
            >
              +
            </button>
          </div>
          <div style={{ width: "85%" }}>
            <div style={{ border: "1px solid #9DACBD", width: "8rem" }}>
              {" "}
              {/* <div className="d-flex w-100 p-1">
                <span>{Moment().format("MM-DD-YY")}</span>
              </div>
              <div
                className="d-flex align-items-center gap-2 p-1"
                style={{
                  backgroundColor: "#DFDFDF",
                  borderTop: "1px solid #9DACBD",
                  borderBottom: "1px solid #9DACBD",
                }}
              >
                {" "}
                <span>----</span>
              </div>
              <div className="d-flex w-100 p-1 justify-content-end cursor-pointer">
                <span onClick={addToma}>
                  {" "}
                  <EditIcon width={"20px"} height={"20px"} />
                </span>
              </div> */}
              <div></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ExerciseData;
