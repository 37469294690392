import { useEffect, useState } from "react";
import { ReactComponent as ArrowDown } from "../../../assets/elevva-pro-icons/arrowDown.svg";
import { ReactComponent as SearchIconAlone } from "../../../assets/elevva-pro-icons/searchIconAlone.svg";
import { ReactComponent as Congelation } from "../../../assets/elevva-pro-icons/congelation-icon.svg";
import { ReactComponent as IceIcon } from "../../../assets/elevva-pro-icons/icon-congelar.svg";

import TableCustom from "../../../utils/table-custom";
import {
  ButtonMob,
  instanceMob,
  laptopMax1350,
  Loadingspinner,
} from "../../../utils/utils";

import { Spinner } from "react-bootstrap";
import { DropdownList } from "react-widgets";
import changePage from "../functions/changePage";
import ModalAssignPlan from "./modal-assign-plan";
import ModalCustom from "./modal-custom";
import ConfirmDeletionModal from "./modal-delete";
import ModalExpiredPlans from "./modal-expired-plans";
import debounce from "../functions/debounce";
import ModalUsersNoPlans from "./modal-users-no-plan";
import moment from "moment";
import DateInputEnd from "../../admin/date-input-end";
import DateInputStart from "../../admin/date-input-start";

const MembershipsUsers = (props) => {
  const [users, setUsers] = useState([]);
  // const [plans, setPlans] = useState([]);
  const plans = props.plans || [];
  const loading = props.loading;
  const getPlans = props.getPlans;
  const [usersExpired, setUsersExpired] = useState({});
  const [userNearExpired, setUserNearExpired] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const [count, setCount] = useState(10);
  // const [countActualUsers, setCountActualUsers] = useState(0);
  const [page, setPage] = useState(1);
  const [pageNext, setPageNext] = useState(null);
  const [pageBack, setPageBack] = useState(null);
  const [dataBackup, setDataBackup] = useState({});
  const [modal, setModal] = useState(null);
  const [modalFreeze, setModalFreeze] = useState(null);
  const [modalError, setModalError] = useState(null);
  const [modalAssign, setModalAssign] = useState(null);
  const [modalDelete, setModalDelete] = useState(null);
  const [usersNoPlan, setUsersNoPlan] = useState(null);
  const [loaderDelete, setLoaderDelete] = useState(false);
  const [loaderDeleteMembership, setLoaderDeleteMembership] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [ErroraGregado, seterroraGregado] = useState("");
  const [loader, setLoader] = useState(false);
  const [optionFilter, setOptionFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [serviceSelector, setServiceSelector] = useState(null);
  function getUsersPage(value) {
    let url = value ? value : `admin/membership`;

    instanceMob
      .get(url)
      .then((res) => res.data)
      .then((data) => {
        setUsers(data.results);
        setPageNext(data.next);
        setPageBack(data.previous);
      })
      .catch((err) => console.error(err));
  }
  // function getPlans() {
  //   setLoading(true);
  //   instanceMob
  //     .get(`admin/plan`)
  //     .then((res) => res.data)
  //     .then((data) => {
  //       setPlans(data.results);

  //       setLoading(false);
  //     })
  //     .catch((err) => console.error(err));
  // }

  function getUsersExpired() {
    instanceMob
      .get(`admin/membership?filter=expired`)
      .then((res) => res.data)
      .then((data) => {
        setUsersExpired(data);
      })
      .catch((err) => console.error(err));
  }
  function getUsersNearExpired() {
    instanceMob
      .get(`admin/membership?filter=near_to_expire`)
      .then((res) => res.data)
      .then((data) => {
        setUserNearExpired(data);
      })
      .catch((err) => console.error(err));
  }
  function getUsers(filter = "") {
    let filtered = filter.length > 0 ? `${filter}` : "";
    instanceMob
      .get(`admin/membership${filtered}`)
      .then((res) => res.data)
      .then((data) => {
        setUsers(data.results);

        setCount(data.count);
        setPageNext(data.next);
        setPageBack(data.previous);
        setDataBackup(data);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }
  useEffect(() => {
    if (modal && loaderDelete) renewUser();
  }, [loaderDelete]);
  useEffect(() => {
    if (modal && loaderDeleteMembership) deleteUser();
  }, [loaderDeleteMembership]);
  useEffect(() => {
    setIsLoading(true);
    getUsers();
    getUsersNoPlan();
    getUsersExpired();
    getUsersNearExpired();
  }, [plans]);
  useEffect(() => {
    if (modal && userSelected) {
      editUser(userSelected);
    }
  }, [loader]);
  function planUserChangeEdit(event, user) {
    let formData = new FormData();
    formData.append("date", moment(event.target[0].value).format("DD-MM-YYYY"));
    formData.append(
      "plan_id",
      serviceSelector ? serviceSelector : user.plan_id
    );
    console.log(serviceSelector, "----planUserChangeEdit");
    instanceMob
      .patch(
        `admin/membership/${user?.memberships[0].membership_id}/update`,
        formData
      )
      .then((res) => {
        if (res.status == "200" || res.status == "201") {
          setLoader(false);
          setModal(null);

          setModalDelete(
            <ConfirmDeletionModal
              onCancel={() => setModalDelete(null)}
              onConfirm={() => setModalDelete(null)}
              loader={false}
            >
              {/* Inserta cualquier contenido aquí como children */}
              {user.customer_name}
              <br></br>
              <strong>editado correctamente </strong>
            </ConfirmDeletionModal>
          );
          getUsers();
        }
      })
      .catch((err) => {
        let msg_error = err.response.data;
        if (msg_error) {
          setModalError(
            <ModalCustom
              onCancel={() => setModalError(null)}
              onConfirm={() => setModalError(null)}
              loader={false}
            >
              {msg_error ? (
                msg_error
              ) : (
                <>
                  {" "}
                  <br />
                  Algo salió mal
                  <br />
                  <strong> intentalo</strong>
                  <br />
                  <strong>de nuevo.</strong>
                  <br />
                </>
              )}
            </ModalCustom>
          );
        }
      });
  }
  function planUserChange(event, user) {
    setLoader(true);
    let formData = new FormData();
    const { checked, name } = event.target[0];

    if (checked) {
      formData.append(
        "freeze_start_date",
        moment(event.target[1].value).format("DD-MM-YYYY")
      );
      formData.append(
        "freeze_end_date",
        moment(event.target[2].value).format("DD-MM-YYYY")
      );
    }
    formData.append("frozen", checked);
    formData.append(
      "plan_id",
      serviceSelector ? serviceSelector : user.plan_id
    );
    instanceMob
      .patch(
        `admin/membership/${user?.memberships[0].membership_id}/update`,
        formData
      )
      .then((res) => {
        if (res.status == "200" || res.status == "201") {
          setLoader(false);
          setModal(null);

          setModalDelete(
            <ConfirmDeletionModal
              onCancel={() => setModalDelete(null)}
              onConfirm={() => setModalDelete(null)}
              loader={false}
            >
              {/* Inserta cualquier contenido aquí como children */}
              {user.customer_name}
              <br></br>
              <strong>editado correctamente </strong>
            </ConfirmDeletionModal>
          );
          getUsers();
        }
      })
      .catch((err) => {
        let msg_error = err?.response?.data || null;

        if (msg_error) {
          setModalError(
            <ModalCustom
              onCancel={() => setModalError(null)}
              onConfirm={() => setModalError(null)}
              loader={false}
            >
              {msg_error ? (
                msg_error?.error || msg_error
              ) : (
                <>
                  {" "}
                  <br />
                  Algo salió mal
                  <br />
                  <strong> intentalo</strong>
                  <br />
                  <strong>de nuevo.</strong>
                  <br />
                </>
              )}
            </ModalCustom>
          );
        }
        setLoader(false);
      });
  }

  const [formData, setFormData] = useState({
    membership_freeze: false,
    pending_freeze: false,
  });

  useEffect(() => {
    if (modal && userSelected) {
      editUser(userSelected);
    }
  }, [formData]);
  useEffect(() => {
    if (modal && userSelected && serviceSelector) {
      console.log(serviceSelector, "----servicerelector");
      editUser(userSelected);
    }
  }, [serviceSelector]);

  function editUser(user) {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setUserSelected(user);

    const handleChangeFormData = (e) => {
      const { name, value, checked } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
      if(name === "membership_freeze") {
        setFormData((prevData) => ({
          ...prevData,
          pending_freeze: checked,
        }));
      }
    };
    console.log(user);
    setModal(
      <div
        className="popUpServices justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
            maxWidth: "40rem",
            minWidth: "35rem",
          }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => {
              setModal(null);
              setLoader(false);
            }}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div
            className=" mb-3 py-3"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            <h6>Modificar<strong className="mx-1">membresia</strong></h6>
            <small>{user?.memberships[0]?.membership_is_active ? "Membresía activa" : user?.memberships[0]?.membership_freeze
                ? "Membresía Congelada" : "Membresía Inactiva"}</small>
          </div>
          <div
              className=" mb-3"
              style={{
                lineHeight: "100%",
                textAlign: "center",

                color: "var(--elevvabutonLogin_User)",
              }}
          >

          </div>
          <div className="d-flex  w-100 my-1 text-center ">
            <span>{user.customer_name}</span>
          </div>
          <hr></hr>
          <div className="d-flex  w-100 my-1 text-center ">
            {/* <span>{user?.memberships[0]?.plan_name || "Sin plan"}</span> */}
            <DropdownList
              style={{ width: "50%" }}
              busy
              name="plan"
              defaultValue={
                user?.memberships[0]?.plan_id
                  ? plans
                      .filter(
                        (memb) => memb.id == user?.memberships[0]?.plan_id
                      )
                      .map((memb) => ({
                        id: memb.id,
                        name: memb.name,
                        value: memb.id,
                      }))[0]
                  : ""
              }
              busySpinner={<ArrowDown></ArrowDown>}
              className={"input-style-elevva-graphics"}
              id="optionSummary"
              placeholder={"Seleccionar plan"}
              data={plans.map((memb) => ({
                id: memb.id,
                name: memb.name,
                value: memb.id,
              }))}
              dataKey="id"
              textField="name"
              onChange={(value) => setServiceSelector(value.value)}
              renderListItem={({ item }) => (
                <div
                  className="w-100 mb-1"
                  style={{ borderBottom: "1px solid #9DACBD" }}
                >
                  <div>{item.name}</div>
                </div>
              )}
            />
          </div>
          <hr></hr>
          {moment().isBefore(user?.memberships[0]?.membership_start_date) ||
          moment().isAfter(user?.memberships[0]?.membership_end_date) ? (
            <form
              id="form-password-change"
              onSubmit={(e) => {
                e.preventDefault();

                planUserChangeEdit(e, user);
              }}
            >
              <div style={{ width: "15rem" }}>
                <DateInputStart
                  defaultDate={user?.memberships[0]?.membership_start_date}
                />
              </div>
              <div className="col-12">
                {
                  <div className="col-12 btnAction_pruebas letraspopfi2">
                    <ButtonMob
                      disabled={loader}
                      colorButton={"var(--elevvabutonLogin_User)"}
                      text={loader ? "Cargando..." : "Confirmar"}
                      className={"mb-3 mt-3 letraspopfi2actualizar"}
                    />
                  </div>
                }
              </div>
            </form>
          ) : (
            <>
              <span>
                Miembro desde:
                {moment
                  .utc(user?.memberships[0]?.membership_start_date)
                  .locale("es")
                  .format("DD-MMM-YY") || "no date"}
              </span>
              <div className="d-flex  w-100 my-1 text-center ">
                <form
                  id="form-password-change"
                  onSubmit={(e) => {
                    e.preventDefault();
                    const membershipStartDate = moment(
                      user?.memberships[0]?.membership_start_date
                    );

                    if (moment().isBefore(membershipStartDate)) {
                      setModalFreeze(
                        <ConfirmDeletionModal
                          onCancel={() => {
                            setModalFreeze(null);
                          }}
                          onConfirm={() => {
                            setModalFreeze(null);
                          }}
                          loader={false}
                        >
                          <br />
                          No puedes
                          <br />
                          <strong>modificar un plan que no ha iniciado.</strong>
                          <br />
                        </ConfirmDeletionModal>
                      );
                    } else {
                      planUserChange(e, user);
                    }
                  }}
                >
                  {/* <div className="d-flex  w-100 my-2" id="location-select">
            {loading ? (
              <Spinner />
            ) : (
              <DropdownList
                style={{ width: "100%" }}
                busy
                name="plan"
                defaultValue={
                  user?.id
                    ? plans
                        .filter((memb) => memb.id == user.id)
                        .map((memb) => ({
                          id: memb.id,
                          name: memb.name,
                          value: memb.id,
                        }))[0]
                    : ""
                }
                busySpinner={<ArrowDown></ArrowDown>}
                className={"input-style-elevva-graphics"}
                id="optionSummary"
                placeholder={"Seleccionar plan"}
                data={plans.map((memb) => ({
                  id: memb.id,
                  name: memb.name,
                  value: memb.id,
                }))}
                dataKey="id"
                textField="name"
                // onChange={(value) => serviceSelector(value.value)}
                renderListItem={({ item }) => (
                  <div
                    className="w-100 mb-1"
                    style={{ borderBottom: "1px solid #9DACBD" }}
                  >
                    <div>{item.name}</div>
                  </div>
                )}
              />
            )}
          </div> */}
                  <div className="d-flex px-0 w-100 my-2">
                    <div className="d-flex pe-2 py-1 mb-auto">
                      <input
                        defaultChecked={
                          user?.memberships[0]?.membership_freeze ||
                          user?.memberships[0]?.pending_freeze ||
                          false
                        }
                        disabled={
                          user?.memberships[0]?.membership_remaining_freezes ===
                            0 && user?.memberships[0]?.membership_freeze === false
                        }
                        name="membership_freeze"
                        type="checkbox"
                        className="searchAtletas  py-2 mx-auto"
                        style={{
                          border: "1px solid var(--elevvaTextSubtitle)",
                          borderRadius: "20px",
                          fontSize: "calc(.4rem + .4vw)",
                          width: "100%",
                        }}
                        onChange={handleChangeFormData}
                      ></input>{" "}
                    </div>
                    <div
                      className="d-flex flex-column my-0 py-0"
                      style={{ color: "var(--elevvaTextSubtitle)" }}
                    >
                      <span style={{ color: "var(--elevvabutonLogin_User)" }}>
                        Congelar membresía <IceIcon></IceIcon>
                      </span>
                    </div>
                  </div>
                  {(formData["membership_freeze"] || formData["pending_freeze"])&& (
                      <div className="d-flex  w-100 gap-2">
                        <DateInputStart
                          defaultDate={
                            user?.memberships[0]?.membership_freeze_start_date
                          }
                        />
                        <DateInputEnd
                          defaultDate={
                            user?.memberships[0]?.membership_freeze_end_date
                          }
                        />
                        <br/>
                      </div>
                    )}
                    {(formData["pending_freeze"] && moment().isBefore(user?.memberships[0]?.membership_freeze_start_date))&&(
                        <small>
                          Pendiente por congelar
                        </small>
                    )}
                  <div className="col-12">
                    {
                      <div className="col-12 btnAction_pruebas letraspopfi2">
                        <ButtonMob
                          disabled={loader}
                          colorButton={"var(--elevvabutonLogin_User)"}
                          text={loader ? "Cargando..." : "Confirmar"}
                          className={"mb-3 mt-3 letraspopfi2actualizar"}
                        />
                      </div>
                    }
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  function renewUser(user) {
    const handleDelete = () => {
      setLoaderDelete(true);
      let formData = new FormData();
      formData.append("customer", user.customer_id);
      instanceMob
        .post(`admin/membership/plan/renew`, formData)
        .then((res) => {
          if (res.status == "204" || res.status == "201") {
            setLoaderDelete(false);
            getPlans();
          }
        })
        .catch((err) => {
          let msg_error = err.response.data;
          if (msg_error) {
            seterroraGregado(msg_error);
          }
        });
    };
    setModal(
      user ? (
        <ConfirmDeletionModal
          onCancel={() => setModal(null)}
          onConfirm={handleDelete}
          loader={loaderDelete}
        >
          {/* Inserta cualquier contenido aquí como children */}
          ¿Estas seguro de <br></br>
          <strong>renovar el plan de {user?.customer_name}? </strong>
        </ConfirmDeletionModal>
      ) : (
        <ConfirmDeletionModal
          onCancel={() => setModal(null)}
          onConfirm={() => setModal(null)}
          loader={false}
        >
          <br />
          Plan de usuario
          <br />
          <strong> renovado</strong>
          <br />
          <strong>correctamente.</strong>
          <br />
        </ConfirmDeletionModal>
      )
    );
  }
  function showExpired() {
    setOptionFilter("expired");
    getUsers("?filter=expired");
    // setModal(
    //   <ModalCustom
    //     onCancel={() => setModal(null)}
    //     onConfirm={() => setModal(null)}
    //     loader={false}
    //   >
    //     <ModalExpiredPlans filter={"expired"} />
    //   </ModalCustom>
    // );
  }

  function showFreeze() {
    setOptionFilter("freeze");
    getUsers("?filter=freeze");
  }
  function showNotExpired() {
    setOptionFilter("expired-soon");
    getUsers("?filter=near_to_expire");
    // setModal(
    //   <ModalCustom
    //     onCancel={() => setModal(null)}
    //     onConfirm={() => setModal(null)}
    //     loader={false}
    //   >
    //     <ModalExpiredPlans filter={"near_to_expire"} />
    //   </ModalCustom>
    // );
  }
  const [userNoPLan, setUserNoPlan] = useState(null);
  const [planUserNoPlan, setPlanUserNoPlan] = useState(null);
  const [planDate, setPlanDate] = useState(null);

  function assignPlanToUser(user, plan, planDate) {
    let formData = {
      plan: plan,
      customer: user,
      date: moment(planDate).format("DD-MM-YYYY"),
    };

    setLoader(true);
    instanceMob
      .post(`admin/membership/plan/create`, formData)
      .then((res) => {
        if (res.status == "204" || res.status == "201") {
          setLoader(false);
          setModalAssign(null);
          setModal(
            <ModalCustom
              onCancel={() => {
                setModal(null);
                setUserNoPlan(null);
                setPlanUserNoPlan(null);
                setPlanDate(null);
              }}
              onConfirm={() => {
                setModal(null);
                setUserNoPlan(null);
                setPlanUserNoPlan(null);
                setPlanDate(null);
                getUsers();
              }}
              loader={false}
            >
              <br />
              El Plan ha sido
              <br />
              <strong> asignado</strong>
              <br />
              <strong>correctamente.</strong>
              <br />
            </ModalCustom>
          );
        }
      })
      .catch((err) => {
        let msg_error = err.response.data;
        if (msg_error) {
          setModalAssign(null);
          setModal(
            <ModalCustom
              onCancel={() => setModal(null)}
              onConfirm={() => setModal(null)}
              loader={false}
            >
              {msg_error ? (
                msg_error
              ) : (
                <>
                  {" "}
                  <br />
                  Algo salió mal
                  <br />
                  <strong> intentalo</strong>
                  <br />
                  <strong>de nuevo.</strong>
                  <br />
                </>
              )}
            </ModalCustom>
          );
        }
      });
  }

  useEffect(() => {
    if (
      (userNoPLan || planUserNoPlan) &&
      planDate != "Fecha de inicio" &&
      modalAssign
    ) {
      assignPlan();
    }
  }, [userNoPLan, planUserNoPlan, planDate]);
  useEffect(() => {
    if (modalAssign) {
      assignPlan();
    }
  }, [loader]);

  function assignPlan(userData = null) {
    setModalAssign(
      <ModalCustom
        onCancel={() => {
          setUserNoPlan(null);
          setPlanUserNoPlan(null);
          setModalAssign(null);
          setPlanDate(null);
        }}
        onConfirm={() => {
          if (userNoPLan && planUserNoPlan && planDate) {
            assignPlanToUser(userNoPLan, planUserNoPlan, planDate);
          }
        }}
        loader={loader}
      >
        <ModalAssignPlan
          userData={userData}
          userNoPLan={userNoPLan}
          planUserNoPlan={planUserNoPlan}
          setUserNoPlan={setUserNoPlan}
          setPlanUserNoPlan={setPlanUserNoPlan}
          setPlanDate={setPlanDate}
        />
      </ModalCustom>
    );
  }
  //xxxx//
  function deleteUser(user) {
    const handleDelete = () => {
      let membership_id = user?.memberships[0]?.membership_id;
      if (!membership_id) return;
      setLoaderDeleteMembership(true);

      instanceMob
        .delete(`admin/membership/${membership_id}/delete`)
        .then((res) => {
          if (res.status == "204" || res.status == "200") {
            setLoaderDeleteMembership(false);
            getPlans();
          }
        })
        .catch((err) => {
          let msg_error = err.response.data;
          if (msg_error) {
            seterroraGregado(msg_error);
          }
        });
    };

    setModal(
      user ? (
        <ConfirmDeletionModal
          onCancel={() => setModal(null)}
          onConfirm={handleDelete}
          loader={loaderDeleteMembership}
        >
          {/* Inserta cualquier contenido aquí como children */}
          ¿Estas seguro de <br></br>
          <strong>eliminar {user?.name || "la membresia"}? </strong>
        </ConfirmDeletionModal>
      ) : (
        <ConfirmDeletionModal
          onCancel={() => {
            getUsers();
            setModal(null);
          }}
          onConfirm={() => {
            getUsers();
            setModal(null);
          }}
          loader={false}
        >
          <br />
          Membresia
          <br />
          <strong> eliminada</strong>
          <br />
          <strong>correctamente.</strong>
          <br />
        </ConfirmDeletionModal>
      )
    );
  }
  function getUsersNoPlan() {
    let url = `admin/customer/noplan`;

    instanceMob
      .get(url)
      .then((res) => res.data)
      .then((data) => {
        setUsersNoPlan(data);
      })
      .catch((err) => console.error(err));
  }
  function getListFilter(value) {
    let filter_value = value.length > 0 ? `?search=${value}` : "";

    instanceMob
      .get(`admin/membership${filter_value}`)
      .then((res) => res.data)
      .then((data) => {
        setUsers(data.results);
        setPageNext(data.next);
        setPageBack(data.previous);
        setCount(data.count);
      })
      .catch((err) => console.error(err));
  }
  function searchUser(e) {
    let valueLower = e.target.value.toLowerCase();
    if (valueLower == "" || e.target.value.length == 0) {
      setUsers(dataBackup.results);
      setCount(dataBackup.count);
      setPageNext(dataBackup.next);
      setPageBack(dataBackup.previous);
    } else {
      getListFilter(valueLower);
    }
  }
  function showNoPlan() {
    setModal(
      <ModalCustom
        onCancel={() => {
          getUsersNoPlan();
          setModal(null);
        }}
        onConfirm={() => {
          getUsersNoPlan();
          setModal(null);
        }}
        loader={false}
      >
        <ModalUsersNoPlans filter={""} />
      </ModalCustom>
    );
  }

  return (
    <>
      {modal}
      {modalDelete}
      {modalAssign}
      {modalError}
      {modalFreeze}
      <div
        style={{
          width: "70%",
          borderRadius: "10px",
          height: "100%",
        }}
      >
        <div
          className="h-100 d-flex flex-column"
          style={{ justifyContent: "space-evenly" }}
        >
          {/* <div className="my-2 px-2" style={{ height: "auto" }}>
            <div
              style={{
                fontSize: "calc(.4rem + .4vw)",
                color: "var(--elevvaTextTitle)",
              }}
            >
              <div className="d-flex gap-3 w-100">
                <div
                  className="d-flex  align-items-center justify-content-center flex-column"
                  style={{
                    width: "50%",
                    border: "1px solid #B6C1CE",
                    borderRadius: "10px",
                    height: "100%",
                  }}
                >
                  <div className="my-2">
                    <span
                      style={{
                        color: "#394F65",
                        fontSize: "calc(.45rem + .45vw)",
                      }}
                    >
                      Usuarios vencidos
                    </span>
                  </div>
                  <div
                    className="w-100 text-center my-2 py-2"
                    style={{ background: "#DFDFDF" }}
                  >
                    <span
                      style={{
                        color:
                          usersExpired?.results?.length > 0 ? "red" : "#394F65",
                        fontSize: "calc(.6rem + .6vw)",
                        fontWeight: "bold",
                      }}
                    >
                      {usersExpired?.results?.length || 0}
                    </span>
                  </div>
                  <div className="my-2">
                    <button
                      style={{
                        fontSize: "calc(.33rem + .32vw)",
                        marginLeft: "auto",
                      }}
                      className="  d-flex add_location px-4 py-1"
                      onClick={showExpired}
                    >
                      Ver más
                    </button>
                  </div>
                </div>
                <div
                  className="d-flex  align-items-center justify-content-center flex-column"
                  style={{
                    width: "50%",
                    border: "1px solid #B6C1CE",
                    borderRadius: "10px",
                    height: "100%",
                  }}
                >
                  <div className="my-2">
                    <span
                      style={{
                        color: "#394F65",
                        fontSize: "calc(.45rem + .45vw)",
                      }}
                    >
                      Usuarios próximos a vencer
                    </span>
                  </div>
                  <div
                    className="w-100 text-center my-2 py-2"
                    style={{ background: "#DFDFDF" }}
                  >
                    <span
                      style={{
                        color:
                          userNearExpired?.results?.length > 0
                            ? "red"
                            : "#394F65",
                        fontSize: "calc(.6rem + .6vw)",
                        fontWeight: "bold",
                      }}
                    >
                      {userNearExpired?.results?.length || 0}
                    </span>
                  </div>
                  <div className="my-2">
                    <button
                      style={{
                        fontSize: "calc(.33rem + .32vw)",
                        marginLeft: "auto",
                      }}
                      className="  d-flex add_location px-4 py-1"
                      onClick={showNotExpired}
                    >
                      Ver más
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="my-2 px-2" style={{ height: "100%" }}>
            <div
              style={{
                width: "100%",
                border: "1px solid #B6C1CE",
                borderRadius: "10px",
                height: "100%",
              }}
            >
              <div
                className="d-flex py-4 px-5 align-items-center"
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="d-flex gap-3 align-items-center">
                  <span
                    style={{
                      fontSize: "calc(.4rem + .4vw)",
                      color: "var(--elevvaTextTitle)",
                    }}
                  >
                    Usuarios sin asignar
                  </span>
                  <span
                    style={{
                      fontSize: "calc(.4rem + .4vw)",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    {usersNoPlan?.count ? "0" + usersNoPlan.count : "000"}
                  </span>
                  <button
                    style={{
                      fontSize: "calc(.33rem + .32vw)",
                    }}
                    className="  d-flex add_location px-4 py-1"
                    onClick={showNoPlan}
                  >
                    Ver más
                  </button>
                </div>
                <div>
                  <span
                    style={{
                      fontSize: "calc(.4rem + .4vw)",
                      color: "var(--elevvaTextTitle)",
                    }}
                  >
                    Usuarios por plan
                  </span>
                </div>
                <button
                  style={{ padding: "0.3rem 1rem" }}
                  className="d-flex add_location"
                  onClick={assignPlan}
                >
                  Asignar plan +
                </button>
              </div>
              <div className="d-flex mx-3">
                <div className="mx-1" style={{ position: "relative" }}>
                  <input
                    placeholder="Buscar usuario"
                    type="search"
                    className="searchUsersPlan "
                    style={{
                      border: "1px solid var(--elevvaTextSubtitle)",
                      borderRadius: "20px",
                      fontSize: "calc(.33rem + .32vw)",
                      padding: "0.5rem 0.6rem",
                    }}
                    onChange={debounce(searchUser, 500)}
                  ></input>
                  <span className="search-icon-adminPlan">
                    <SearchIconAlone fontSize={10} />
                  </span>
                </div>{" "}
                <div className="d-flex align-items-center  gap-2">
                  <button
                    style={{
                      padding: "0.5rem",
                      lineHeight: "1",
                      background:
                        optionFilter === ""
                          ? "rgb(57, 79, 101)"
                          : "transparent",
                    }}
                    className="  d-flex add_location "
                    onClick={() => {
                      setOptionFilter("");
                      getUsers();
                    }}
                  >
                    <div className="d-flex align-items-center ">
                      <span className="d-flex align-items-center text-center mx-1">
                        {" "}
                        <img
                          width={12}
                          height={12}
                          src={"/assets/person-service-white.svg"}
                          alt={"payment"}
                        />
                      </span>
                      {/* <span
                        className="mx-1 my-auto text-center"
                        style={{
                          fontWeight: "bold",
                          fontSize: "calc(0.7rem + 0.2vw)",
                          color: optionFilter === "" ? "white" : "#394F65",
                        }}
                      >
                        {countActualUsers}
                      </span> */}
                      <span
                        style={{
                          fontSize: "calc(.33rem + .32vw)",
                          color: optionFilter === "" ? "white" : "#394F65",
                        }}
                      >
                        Usuarios vigentes
                      </span>
                    </div>
                  </button>
                  <button
                    style={{
                      padding: "0.5rem",
                      lineHeight: "1",
                      background:
                        optionFilter === "expired"
                          ? "rgb(57, 79, 101)"
                          : "white",
                    }}
                    className="  d-flex add_location "
                    onClick={() => {}}
                  >
                    <div className="d-flex align-items-center ">
                      <div
                        className="d-flex align-items-center"
                        onClick={showExpired}
                      >
                        <span
                          className="mx-1 text-center"
                          style={{
                            color:
                              usersExpired?.results?.length &&
                              usersExpired?.results?.length > 0
                                ? "red"
                                : optionFilter === "expired"
                                ? "white"
                                : "#394F65",
                            fontWeight: "bold",
                            fontSize: "calc(.8rem)",
                          }}
                        >
                          {usersExpired?.count || 0}
                        </span>
                        <span
                          style={{
                            fontSize: "calc(.33rem + .32vw)",
                            color:
                              optionFilter === "expired" ? "white" : "#394F65",
                          }}
                        >
                          Usuarios vencidos
                        </span>
                      </div>
                    </div>
                  </button>
                  <button
                    style={{
                      padding: "0.5rem",
                      lineHeight: "1",
                      background:
                        optionFilter === "expired-soon"
                          ? "rgb(57, 79, 101)"
                          : "white",
                    }}
                    className="  d-flex add_location "
                    onClick={() => {}}
                  >
                    <div className="d-flex align-items-center ">
                      <div
                        className="d-flex align-items-center"
                        onClick={showNotExpired}
                      >
                        <span
                          className="mx-1 text-center"
                          style={{
                            color:
                              usersExpired?.results?.length &&
                              usersExpired?.results?.length > 0
                                ? "red"
                                : "#394F65",
                            fontWeight: "bold",
                            fontSize: "calc(.8rem)",
                          }}
                        >
                          {userNearExpired?.count || 0}
                        </span>
                        <span
                          style={{
                            fontSize: "calc(.33rem + .32vw)",
                            color:
                              optionFilter === "expired-soon"
                                ? "white"
                                : "#394F65",
                          }}
                        >
                          Usuarios próximos a vencer
                        </span>
                      </div>
                    </div>
                  </button>
                  <button
                    style={{
                      padding: "0.4rem 0.7rem",
                      lineHeight: "1",
                      background:
                        optionFilter === "freeze"
                          ? "rgb(57, 79, 101)"
                          : "white",
                    }}
                    className="  d-flex add_location "
                    onClick={showFreeze}
                  >
                    <div className="d-flex align-items-center ">
                      <div
                        className="d-flex align-items-center gap-1 "
                        onClick={() => {}}
                      >
                        <span
                          style={{
                            fontSize: "calc(.33rem + .32vw)",
                            color:
                              optionFilter === "freeze" ? "white" : "#394F65",
                          }}
                        >
                          Congelaciones
                        </span>
                        <span>
                          <Congelation width={12} height={12}></Congelation>
                        </span>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              <div className="d-flex justify-content-center w-100 pt-3">
                <span
                  className="fw-semibold"
                  style={{ fontSize: "calc(.5rem + .4vw)", color: "#394F65" }}
                >
                  {optionFilter === "freeze"
                    ? "Acá veras los usuarios con membresías congeladas o inactivas "
                    : optionFilter === "expired-soon"
                    ? "Acá veras los usuarios con membresías próximas a vencer en 7 días o les quedan 3 accesos "
                    : optionFilter === "expired"
                    ? "Acá veras los usuarios con membresías vencidas o sin accesos"
                    : "Acá veras los usuarios con membresías activas"}
                </span>
              </div>
              <div
                className="py-0"
                style={{ minHeight: "60vh", maxHeight: "58vh" }}
              >
                {isLoading ? (
                  <Loadingspinner customStyle="grow" size="2.5rem" />
                ) : (
                  <TableCustom
                    editUser={(data) => {
                      setServiceSelector(data?.memberships[0]?.plan_id);
                      setFormData((prevData) => ({
                        ...prevData,
                        pending_freeze: data?.memberships[0]?.pending_freeze,
                        membership_freeze:
                          data?.memberships[0]?.membership_freeze,
                      }));
                      editUser(data);
                    }}
                    deleteUser={deleteUser}
                    renewUser={renewUser}
                    changePage={(value) =>
                      changePage(
                        value,
                        getUsersPage,
                        pageNext,
                        pageBack,
                        page,
                        count,
                        setPage
                      )
                    }
                    count={count}
                    page={page}
                    users={users}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MembershipsUsers;
