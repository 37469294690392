import axios from "axios";
import { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { DropdownList } from "react-widgets";
import { ReactComponent as ArrowDown } from "../../assets/elevva-pro-icons/arrowDown.svg";
import { ButtonArrow, ButtonMob, instanceMob } from "../../utils/utils";
import Clases from "./components/clases";
import Locations from "./components/locations";
import ConfirmDeletionModal from "./components/modal-delete";
import ScheduleComponent from "./components/schedule-component";
import Services from "./components/services";
import changePage from "./functions/changePage";
import moment from "moment";

const MobConfig = () => {
  const [listLocations, setListLocations] = useState([]);
  const [listServices, setListServices] = useState([]);
  const [listServicesBackUp, setListServicesBackUp] = useState([]);
  const [listAvailability, setListAvailability] = useState([]);
  const [modalService, setModalService] = useState(null);
  const [modalAvailability, setModalAvailability] = useState(null);
  const [modalAddLocation, setModalAddLocation] = useState(null);
  const [modalEditService, setModalEditService] = useState(null);
  const [modalAddService, setModalAddService] = useState(null);
  const [modalAddClass, setModalAddClass] = useState(null);
  const [modalEditClass, setModalEditClass] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalError, setModalError] = useState(null);

  const [cities, setCities] = useState([]);
  const [ErroraGregado, seterroraGregado] = useState("");
  const [addClassLoader, setAddClassLoader] = useState(false);
  const [displayCorrect, setDisplayCorrect] = useState(null);
  const [loadingEditClass, setLoadingEditClass] = useState(false);

  const [errorModal, setErrorModal] = useState({});

  const [formDataLocation, setFormDataLocation] = useState({
    name: "",
    address: "",
    city: 0,
    id: null,
    days: [],
    start_operation: "",
    close_operation: "",
    photo: "",
  });
  const [formDataService, setFormDataService] = useState({
    service_name: "",
    location_id: "",
    id: null,
    duration: 0,
    slots: 0,
    photo: "",
    extra_locations: [],
  });
  const [formDataAvailability, setFormDataAvailability] = useState({
    location_id: "",
    service_id: "",
    duration: 0,
    slots: 0,
  });

  const [availabilitySelected, setAvailabilitySelected] = useState(null);
  const [formDataAvailabilitySchedule, setFormDataAvailabilitySchedule] =
    useState([
      {
        id: 1,
        weekly_hours: {
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: [],
        },
        sortedKey: "00:00-00:00",
      },
    ]);

  const [loaderAddLocation, setLoaderAddLocation] = useState(false);
  const [loaderAddService, setLoaderAddService] = useState(false);
  const [loaderEditService, setLoaderEditService] = useState(false);
  const [loaderDelete, setLoaderDelete] = useState(false);
  const [dataBackupListAvailability, setDataBackupListAvailability] = useState(
    {}
  );
  const [scheduleSelected, setScheduleSelected] = useState(
    formDataAvailabilitySchedule[0]?.id || 1
  );

  function getListLocations() {
    instanceMob
      .get(`admin/location`)
      .then((res) => res.data)
      .then((data) => {
        setListLocations(data.results);

        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }

  function getListClasses() {
    instanceMob
      .get(`admin/availability`)
      .then((res) => res.data)
      .then((data) => {
        setListAvailability(data.results);
        setDataBackupListAvailability(data);
        setPageNextAvailability(data.next);
        setPageBackAvailability(data.previous);
        setCountAvailability(data.count);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }
  function getListServices() {
    instanceMob
      .get(`admin/service`)
      .then((res) => res.data)
      .then((data) => {
        setListServices(data.results);
        setListServicesBackUp(data);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    if (localStorage.mob_api_key) {
      setIsLoading(true);
      getListLocations();
      getListClasses();
      getListServices();
      axios
        .get("/data/colombia-cities.json")
        .then((res) => setCities(res.data));
    }
  }, []);

  const days = [
    { name: "Dom", value: "sunday", read: "Domingo" },
    { name: "Lun", value: "monday", read: "Lunes" },
    { name: "Mar", value: "tuesday", read: "Martes" },
    { name: "Mie", value: "wednesday", read: "Miércoles" },
    { name: "Jue", value: "thursday", read: "Jueves" },
    { name: "Vie", value: "friday", read: "Viernes" },
    { name: "Sab", value: "saturday", read: "Sábado" },
  ];
  const daysName = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];

  function updateLocation() {
    let formData = new FormData();

    Object.entries(formDataLocation).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          let valueData = value.map((item) => Number(item));
          formData.append(`${key}`, JSON.stringify(valueData));
        }
      } else {
        if (`${key}` == "city") {
          if (value > 0) {
            formData.append(key, value);
          }
        } else {
          if (`${key}` != "id") {
            if (value != "") {
              formData.append(key, value);
            }
          }
        }
      }
    });
    if (formDataLocation.id) {
      setLoaderAddLocation(true);
      instanceMob
        .patch(`admin/location/${formDataLocation.id}`, formData)
        .then((res) => res.data)
        .then((data) => {
          getListLocations();
          setLoaderAddLocation(false);
          setModalAddLocation(null);
          setFormDataLocation({
            name: "",
            address: "",
            city: 0,
            days: [],
            id: null,
            start_operation: "",
            close_operation: "",
            photo: "",
          });
        })
        .catch((err) => setLoaderAddLocation(false));
    }
  }
  function postLocation() {
    let formData = new FormData();
    Object.entries(formDataLocation).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        let valueData = value.map((item) => Number(item));
        formData.append(`${key}`, JSON.stringify(valueData));
      } else {
        if (key !== "id") {
          formData.append(key, value);
        }
      }
    });

    setLoaderAddLocation(true);
    instanceMob
      .post(`admin/location`, formData)
      .then((res) => res.data)
      .then((data) => {
        setLoaderAddLocation(false);
        getListLocations();
        setModalAddLocation(null);
        setFormDataLocation({
          name: "",
          address: "",
          city: 0,
          days: [],
          start_operation: "",
          close_operation: "",
          photo: "",
        });
      })
      .catch((err) => setLoaderAddLocation(false));
  }

  const handleChangeLocation = (e) => {
    const { name, value } = e.target;
    setFormDataLocation((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    setFormDataLocation((prevData) => {
      const updatedDays = checked
        ? [...prevData.days, value] // Si está marcado, agregar el día
        : prevData.days.filter((day) => day !== value); // Si está desmarcado, eliminar el día

      return {
        ...prevData,
        days: updatedDays,
      };
    });
  };
  // const hanlderActivateAction = (locationId) => {
  //   setActionRefresh(!actionRefresh);

  //   if (locationId) {
  //     updateLocation(locationId);
  //   } else {
  //     postLocation();
  //   }
  // };

  const [imageUrl, setImageUrl] = useState(null);
  const [imageUrlService, setImageUrlService] = useState(null);
  useEffect(() => {
    if (imageUrl && fileInputRef.current && fileInputRef.current.files[0]) {
      setFormDataLocation((prevData) => ({
        ...prevData,
        ["photo"]: fileInputRef.current.files[0],
      }));
    }
  }, [imageUrl]);

  const fileInputRef = useRef(null);
  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleImageUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageUploadService = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrlService(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [selectedDays, setSelectedDays] = useState({});
  const [startTime, setStartTime] = useState({});
  const [endTime, setEndTime] = useState({});

  useEffect(() => {
    if (modalAddLocation && loaderDelete) deleteLocation();
  }, [loaderDelete]);
  useEffect(() => {
    if (modalAddLocation && loaderDelete && !modalError) deleteAvailability();
  }, [loaderDelete]);
  useEffect(() => {
    if (modalAddLocation && loaderAddLocation) {
      addLocation();
    }
  }, [loaderAddLocation]);

  {
    /* start services */
  }

  const handleChangeService = (e) => {
    const { name, value } = e.target;
    setFormDataService((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (
      imageUrlService &&
      fileInputRef.current &&
      fileInputRef.current.files[0]
    ) {
      setFormDataService((prevData) => ({
        ...prevData,
        ["photo"]: fileInputRef.current.files[0],
      }));
    }
  }, [imageUrlService]);

  useEffect(() => {
    if (modalAddService && loaderAddService) {
      addService();
    }
  }, [loaderAddService]);
  useEffect(() => {
    if (modalEditService && loaderEditService) {
      editService();
    }
  }, [loaderEditService]);
  useEffect(() => {
    if (modalAddService && formDataService?.id) {
      let filterService = listLocations.find(
        (item) => item.id === formDataService?.id
      );

      addService(filterService ? filterService : null);
    } else {
      modalAddService && addService();
    }
  }, [formDataService]);

  useEffect(() => {
    if (modalEditService && formDataService?.id) {
      let filterService = listLocations.find(
        (item) => item.id === formDataService?.id
      );

      editService(filterService ? filterService : null);
    }
  }, [formDataService]);
  const handleCheckboxChangeServices = (e) => {
    const { value, checked } = e.target;

    setFormDataService((prevData) => {
      const updatedExtraLocations = checked
        ? [...prevData.extra_locations, Number(value)]
        : prevData.extra_locations.filter((day) => day !== value);

      return {
        ...prevData,
        extra_locations: updatedExtraLocations,
      };
    });
  };
  function postServiceEdit() {
    let formData = new FormData();

    if (formDataService.photo != "") {
      formData.append("photo", formDataService.photo);
    }
    if (formDataService.service_name != "") {
      formData.append("name", formDataService.service_name);
    }
    if (formDataService.duration > 0) {
      formData.append("duration", formDataService.duration);
    }
    if (formDataService.slots > 0) {
      formData.append("slots", formDataService.slots);
    }
    if (formDataService.id) {
      setLoaderEditService(true);
      instanceMob
        .patch(`admin/service/${formDataService.id}`, formData)
        .then((res) => res.data)
        .then((data) => {
          getListServices();
          setLoaderEditService(false);
          setModalEditService(null);
          setFormDataService({
            service_name: "",
            location_id: "",
            id: null,
            duration: 0,
            slots: 0,
            photo: "",
            extra_locations: [],
          });
        })
        .catch((err) => setLoaderEditService(false));
    }
  }

  function postService() {
    let genericDays = {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    };

    formDataAvailabilitySchedule.forEach((item, index) => {
      let weekly = item.weekly_hours;
      Object.entries(weekly).forEach(([day, hours]) => {
        if (hours.length > 0) {
          genericDays[day].push(...hours);
        }
      });
    });

    const { id, photo, ...formDataServiceFilter } = formDataService;
    if (formDataServiceFilter.location_id.length == 0) {
      seterroraGregado("Selecciona una locación");
      setTimeout(() => {
        seterroraGregado("");
      }, 3000);
      return;
    }
    let formData = { ...formDataServiceFilter, weekly_hours: genericDays };
    setLoaderAddService(true);
    instanceMob
      .post(`admin/service/availability/create`, formData)
      .then((res) => {
        setLoaderAddService(false);
        getListServices();
        getListClasses();
        setModalAddService(null);
        setFormDataService({
          service_name: "",
          location_id: "",
          id: null,
          duration: 0,
          slots: 0,
          photo: "",
          extra_locations: [],
        });
      })
      .catch((err) => {
        if (err.response) {
          let msg_error = err.response.data;
          seterroraGregado(msg_error);
          setTimeout(() => {
            seterroraGregado("");
          }, 3000);

          console.error(err);
        }
        setLoaderAddService(false);
      });
  }

  useEffect(() => {
    if (modalAddService) {
      addService();
    }
  }, [ErroraGregado]);
  useEffect(() => {
    if (modalEditClass && availabilitySelected) {
      editAvailability(availabilitySelected);
    }
  }, [ErroraGregado]);

  useEffect(() => {
    if (errorModal && modalAddService) {
      addService();
    }
  }, [errorModal]);
  useEffect(() => {
    if (modalEditClass && availabilitySelected) {
      editAvailability(availabilitySelected);
    }
  }, [loadingEditClass]);

  {
    /* finish services */
  }
  // useEffect(() => {
  //   if (formDataLocation.days.length > 0) addLocation();
  // }, [formDataLocation]);

  useEffect(() => {
    if (modalAddLocation && formDataLocation?.id) {
      let filterLocation = listLocations.find(
        (item) => item.id === formDataLocation?.id
      );

      addLocation(filterLocation ? filterLocation : null);
    } else {
      modalAddLocation && addLocation();
    }
  }, [formDataLocation]);
  useEffect(() => {
    if (formDataAvailabilitySchedule.length >= 1 && modalAddClass) {
      addClass();
    }
    if (formDataAvailabilitySchedule.length >= 1 && modalAddService) {
      addService();
    }
    if (formDataAvailabilitySchedule.length >= 1 && modalEditClass) {
      editAvailability(availabilitySelected);
    }
  }, [formDataAvailabilitySchedule]);
  useEffect(() => {
    if (errorModal && modalEditClass && availabilitySelected) {
      editAvailability(availabilitySelected);
    }
  }, [errorModal]);
  useEffect(() => {
    if (errorModal && modalAddClass) {
      addClass();
    }
  }, [errorModal]);
  useEffect(() => {
    if (modalAddClass) {
      addClass();
    }
  }, [ErroraGregado]);
  useEffect(() => {
    if (modalAddClass) {
      addClass();
    }
  }, [addClassLoader]);
  useEffect(() => {
    if (modalEditClass && availabilitySelected) {
      editAvailability(availabilitySelected);
    }
  }, [formDataAvailability]);

  useEffect(() => {
    if (modalAddClass) {
      addClass();
    }
  }, [formDataAvailability]);
  useEffect(() => {
    if (startTime && endTime && modalAddClass) {
      addClass();

      scheduleSelected && handleAddHours(scheduleSelected, true);
    }
    if (startTime && endTime && modalEditClass) {
      editAvailability(availabilitySelected);
      scheduleSelected && handleAddHours(scheduleSelected, true);
    }
  }, [startTime, endTime, scheduleSelected]);
  useEffect(() => {
    if (startTime && endTime && selectedDays && modalAddClass) {
      addClass();

      scheduleSelected && handleAddHours(scheduleSelected);
    }

    if (startTime && endTime && selectedDays && modalAddService) {
      addService();

      scheduleSelected && handleAddHours(scheduleSelected);
    }
    if (selectedDays && modalEditClass) {
      editAvailability(availabilitySelected);

      scheduleSelected && handleAddHours(scheduleSelected);
    }
  }, [selectedDays, scheduleSelected, startTime, endTime]);

  {
    /* availability */
  }
  const [pageNextAvailability, setPageNextAvailability] = useState(null);
  const [pageBackAvailability, setPageBackAvailability] = useState(null);
  const [countAvailability, setCountAvailability] = useState(10);
  const [pageAvailability, setPageAvailability] = useState(1);

  function getListAvalabilitiesFilter(value) {
    instanceMob
      .get(`admin/availability?search=${value}`)
      .then((res) => res.data)
      .then((data) => {
        setListAvailability(data.results);
        setPageNextAvailability(data.next);
        setPageBackAvailability(data.previous);
        setCountAvailability(data.count);
      })
      .catch((err) => console.error(err));
  }

  function searchAvailabilities(e) {
    let valueLower = e.target.value.toLowerCase();
    if (valueLower == "") {
      setListAvailability(dataBackupListAvailability.results);
      setCountAvailability(dataBackupListAvailability.count);
      setPageNextAvailability(dataBackupListAvailability.next);
      setPageBackAvailability(dataBackupListAvailability.previous);
    } else {
      getListAvalabilitiesFilter(valueLower);
    }
  }
  function getListServicesFilter(value) {
    instanceMob
      .get(`admin/service?search=${value}`)
      .then((res) => res.data)
      .then((data) => {
        setListServices(data.results);
      })
      .catch((err) => console.error(err));
  }

  function searchServices(e) {
    let valueLower = e.target.value.toLowerCase();
    if (valueLower == "") {
      setListServices(listServicesBackUp.results);
    } else {
      getListServicesFilter(valueLower);
    }
  }
  function getAvailabilityPage(value) {
    let url = value ? value : `admin/availability`;

    instanceMob
      .get(url)
      .then((res) => res.data)
      .then((data) => {
        setListAvailability(data.results);
        setPageNextAvailability(data.next);
        setPageBackAvailability(data.previous);
      })
      .catch((err) => console.error(err));
  }

  function addLocation(location) {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };

    setModalAddLocation(
      <div
        className="popUpServices justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",

            // overflowY: "scroll",
            // overflowX: "hidden",
          }}
        >
          <PerfectScrollbar
            style={{
              padding: "2% 6%",
              maxWidth: "40rem",
              minWidth: "35rem",
              maxHeight: "40rem",
            }}
          >
            <div
              className=" mb-3 py-3 d-flex align-items-center"
              style={{
                lineHeight: "100%",
                textAlign: "center",

                color: "var(--elevvabutonLogin_User)",
              }}
            >
              <div>
                <ButtonArrow
                  classes=" btnBackPr me-1"
                  colorBack={`var(--elevvaArrowColor)`}
                  handleClick={() => {
                    setModalAddLocation(null);
                    setFormDataLocation({
                      name: "",
                      address: "",
                      id: null,
                      city: 0,
                      days: [],
                      start_operation: "",
                      close_operation: "",
                      photo: "",
                      open: false,
                    });
                  }}
                />
              </div>
              <div>
                Registro de <strong>locación</strong>
              </div>
            </div>
            <div
              className="mx-auto"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "30rem",
              }}
            >
              <div className="d-flex px-4">
                <input
                  defaultValue={location?.name || ""}
                  placeholder="Nombre de locación"
                  name="name"
                  type="search"
                  className="searchAtletas  py-2 mx-auto"
                  style={{
                    border: "1px solid var(--elevvaTextSubtitle)",
                    borderRadius: "20px",
                    fontSize: "calc(.4rem + .4vw)",
                    width: "100%",
                  }}
                  onChange={handleChangeLocation}
                ></input>
              </div>
              <div className="d-flex px-4 ">
                <input
                  defaultValue={location?.address || ""}
                  placeholder="Dirección"
                  type="search"
                  name="address"
                  className="searchAtletas  py-2 mx-auto"
                  style={{
                    border: "1px solid var(--elevvaTextSubtitle)",
                    borderRadius: "20px",
                    fontSize: "calc(.4rem + .4vw)",
                    width: "100%",
                  }}
                  onChange={handleChangeLocation}
                ></input>
              </div>
              <div
                className="d-flex  justify-content-center w-100 "
                id="country-select"
              >
                <DropdownList
                  style={{ width: "90%" }}
                  busy
                  defaultValue={
                    location?.city
                      ? cities
                          .filter((memb) => memb.value === location?.city)
                          .map((memb) => ({
                            id: memb.label,
                            name: memb.label,
                            valor: memb.value,
                          }))[0]
                      : ""
                  }
                  busySpinner={<ArrowDown></ArrowDown>}
                  className={"input-style-elevva-graphics"}
                  id="optionSummary"
                  placeholder={"Ciudad"}
                  data={cities.map((memb) => ({
                    id: memb.label,
                    name: memb.label,
                    valor: memb.value,
                  }))}
                  dataKey="id"
                  textField="name"
                  onChange={(value) => {
                    setFormDataLocation((prevData) => ({
                      ...prevData,
                      ["city"]: value.valor,
                    }));
                  }}
                  renderListItem={({ item }) => (
                    <div
                      className="w-100 mb-1"
                      style={{ borderBottom: "1px solid #9DACBD" }}
                    >
                      <div>{item.name}</div>
                    </div>
                  )}
                />
              </div>
            </div>

            <div>
              <span>Imagen de locación</span>
            </div>
            <div style={{ display: "flex", justifyContent: "left" }}>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageUpload}
              />

              <ButtonMob
                handleClick={handleIconClick}
                colorButton={"var(--elevvabutonLogin_User)"}
                text="Seleccionar imagen"
                className={"mb-3 mt-3 letraspopfi2actualizar mx-0"}
              />
            </div>
            {fileInputRef?.current?.files[0]?.name && (
              <div className="mb-2">
                <span style={{ fontStyle: "italic" }}>
                  {fileInputRef?.current?.files[0]?.name || "Ninguna"}
                </span>
              </div>
            )}
            <span>Días de atención</span>
            <div
              className="d-flex w-100 flex-column gap-2"
              style={{ justifyContent: "left" }}
            >
              {daysName.map((day, index) => (
                <div key={index} className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    value={index}
                    checked={formDataLocation.days.includes(String(index))}
                    onChange={handleCheckboxChange}
                  ></input>
                  <label
                    className="ms-2"
                    style={{ color: "#9DACBD", fontSize: "calc(.4rem + .4vw)" }}
                  >
                    {day}
                  </label>
                </div>
              ))}
            </div>
            <div
              className="my-2 d-flex  flex-column gap-2"
              style={{ maxWidth: "50%" }}
            >
              <label htmlFor="start_operation">
                <span>Horario de apertura</span>
              </label>
              <input
                defaultValue={location?.start_operation || ""}
                onChange={handleChangeLocation}
                type="time"
                name="start_operation"
                placeholder="Hora de apertura"
                style={{ color: "#9DACBD", fontSize: "calc(.4rem + .4vw)" }}
              ></input>
              <label htmlFor="close_operation">
                <span>Horario de cierre</span>
              </label>

              <input
                defaultValue={location?.close_operation || ""}
                onChange={handleChangeLocation}
                type="time"
                name="close_operation"
                placeholder="Hora de cierre"
                style={{ color: "#9DACBD", fontSize: "calc(.4rem + .4vw)" }}
              ></input>
            </div>
            <div className="d-flex w-100 gap-2 mt-3">
              <ButtonMob
                disabled={loaderAddLocation}
                handleClick={location?.id ? updateLocation : postLocation}
                style={{
                  border: "1px solid var(--elevvabutonLogin_User)",
                }}
                colorButton={"var(--elevvabutonLogin_User)"}
                text={
                  loaderAddLocation
                    ? "Cargando..."
                    : location?.id
                    ? "Actualizar"
                    : "Registrar"
                }
                className={`mb-3 mt-3 letraspopfi2actualizar `}
              />
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }

  function deleteLocation(location) {
    const handleDelete = () => {
      setLoaderDelete(true);
      instanceMob
        .delete(`admin/location/${location.id}`)
        .then((res) => {
          if (res.status == "204" || res.status == "200") {
            setLoaderDelete(false);
            getListLocations();
          }
        })
        .catch((err) => {
          let msg_error = err.response.data;
          if (msg_error) {
            seterroraGregado(msg_error);

            setDisplayCorrect("flex");
            setTimeout(() => {
              setDisplayCorrect("none");
            }, 5000);
          }
        });
    };

    setModalAddLocation(
      location ? (
        <ConfirmDeletionModal
          onCancel={() => setModalAddLocation(null)}
          onConfirm={handleDelete}
          loader={loaderDelete}
        >
          {/* Inserta cualquier contenido aquí como children */}
          ¿Estas seguro de <br></br>
          <strong>eliminar {location?.name || "la locación"}? </strong>
        </ConfirmDeletionModal>
      ) : (
        <ConfirmDeletionModal
          onCancel={() => setModalAddLocation(null)}
          onConfirm={() => setModalAddLocation(null)}
          loader={false}
        >
          <br />
          Locación
          <br />
          <strong> eliminada</strong>
          <br />
          <strong>correctamente.</strong>
          <br />
        </ConfirmDeletionModal>
      )
    );
  }
  function deleteService(service) {
    const handleDelete = () => {
      setLoaderDelete(true);
      instanceMob
        .delete(`admin/service/${service.id}`)
        .then((res) => {
          if (res.status == "204" || res.status == "200") {
            setLoaderDelete(false);
            getListServices();
          }
        })
        .catch((err) => {
          let msg_error = err.response.data;
          if (msg_error) {
            seterroraGregado(msg_error);

            setDisplayCorrect("flex");
            setTimeout(() => {
              setDisplayCorrect("none");
            }, 5000);
          }
        });
    };

    setModalAddLocation(
      service ? (
        <ConfirmDeletionModal
          onCancel={() => setModalAddLocation(null)}
          onConfirm={handleDelete}
          loader={loaderDelete}
        >
          {/* Inserta cualquier contenido aquí como children */}
          ¿Estas seguro de <br></br>
          <strong>eliminar {service?.name || "el servicio"}? </strong>
        </ConfirmDeletionModal>
      ) : (
        <ConfirmDeletionModal
          onCancel={() => setModalAddLocation(null)}
          onConfirm={() => setModalAddLocation(null)}
          loader={false}
        >
          <br />
          Servicio
          <br />
          <strong> eliminada</strong>
          <br />
          <strong>correctamente.</strong>
          <br />
        </ConfirmDeletionModal>
      )
    );
  }
  function deleteAvailability(availability) {
    const handleDelete = () => {
      setLoaderDelete(true);
      instanceMob
        .delete(`admin/availability/${availability.id}/delete`)
        .then((res) => {
          if (res.status == "204" || res.status == "200") {
            setLoaderDelete(false);
            getListClasses();
          }
        })
        .catch((err) => {
          let msg_error = err.response.data;
          if (msg_error) {
            setModalError(
              <ConfirmDeletionModal
                onCancel={() => setModalError(null)}
                onConfirm={() => setModalError(null)}
                loader={false}
              >
                {msg_error.detail}
              </ConfirmDeletionModal>
            );
            setLoaderDelete(false);
          }
        });
    };

    setModalAddLocation(
      availability ? (
        <ConfirmDeletionModal
          onCancel={() => setModalAddLocation(null)}
          onConfirm={handleDelete}
          loader={loaderDelete}
        >
          {/* Inserta cualquier contenido aquí como children */}
          ¿Estas seguro de <br></br>
          <strong>eliminar {availability?.name || "la clase"}? </strong>
        </ConfirmDeletionModal>
      ) : (
        <ConfirmDeletionModal
          onCancel={() => setModalAddLocation(null)}
          onConfirm={() => setModalAddLocation(null)}
          loader={false}
        >
          <br />
          Clase
          <br />
          <strong> eliminada</strong>
          <br />
          <strong>correctamente.</strong>
          <br />
        </ConfirmDeletionModal>
      )
    );
  }

  const handleDaySelectionKey = (day, scheduleId) => {
    setSelectedDays((prev) => {
      const updatedDays = { ...prev };

      if (!updatedDays[scheduleId]) {
        updatedDays[scheduleId] = [];
      }

      // Agregar el nuevo día sin importar si ya existe
      updatedDays[scheduleId] = [...updatedDays[scheduleId], day].flat();

      return updatedDays;
    });
  };
  const handleDaySelection = (day, scheduleId) => {
    setSelectedDays((prev) => {
      const updatedDays = { ...prev };
      if (!updatedDays[scheduleId]) {
        updatedDays[scheduleId] = [];
      }

      if (updatedDays[scheduleId].includes(day)) {
        updatedDays[scheduleId] = updatedDays[scheduleId].filter(
          (d) => d !== day
        );
      } else {
        updatedDays[scheduleId] = [...updatedDays[scheduleId], day];
      }

      return { ...updatedDays };
    });
  };

  const handleAddHours = (id, replace = false) => {
    if (!startTime[id] || !endTime[id]) {
      // alert("Por favor ingresa ambos horarios.");
      return;
    }
    if (!selectedDays[id]) return;

    setFormDataAvailabilitySchedule((prev) => {
      const updatedAvailability = [...prev];

      // Encontrar el índice del objeto con el ID especificado
      const indexToUpdate = updatedAvailability.findIndex(
        (availability) => availability.id === id
      );

      // Si no se encuentra el ID, retorna el estado actual
      if (indexToUpdate === -1) {
        console.error(`No se encontró el ID: ${id}`);
        return prev;
      }

      // Clonar el objeto específico a actualizar
      const targetAvailability = { ...updatedAvailability[indexToUpdate] };

      // Clonar weekly_hours para el objeto seleccionado
      const updatedWeeklyHours = { ...targetAvailability.weekly_hours };

      // Actualizar los días de weekly_hours
      Object.keys(updatedWeeklyHours).forEach((day) => {
        if (!selectedDays[id].includes(day)) {
          // Si el día no está seleccionado, reinicia a un array vacío
          updatedWeeklyHours[day] = [];
        } else {
          // Si el día está seleccionado, agrega los horarios
          if (updatedWeeklyHours[day] && updatedWeeklyHours[day].length > 0) {
            if (replace) {
              updatedWeeklyHours[day] = [[startTime[id], endTime[id]]];
            } else {
              updatedWeeklyHours[day] = [...updatedWeeklyHours[day]];
            }
          } else {
            updatedWeeklyHours[day] = [
              ...updatedWeeklyHours[day],
              [startTime[id], endTime[id]],
            ];
          }
        }
      });

      // Reemplazar weekly_hours en el objeto específico
      targetAvailability.weekly_hours = updatedWeeklyHours;

      // Actualizar el objeto en el array
      updatedAvailability[indexToUpdate] = targetAvailability;

      return updatedAvailability;
    });
  };
  const addSchedule = () => {
    setFormDataAvailabilitySchedule((prev) => {
      const updatedAvailability = [...prev];
      const newSchedule = {
        id: prev.length + 1,
        weekly_hours: {
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: [],
        },
        sortedKey: "00:00-00:00",
      };
      updatedAvailability.push(newSchedule);
      return updatedAvailability;
    });

    // setStartTime(null);
    // setEndTime(null);
    // setSelectedDays({});
  };

  const updateTime = (setter, scheduleId, value) => {
    setter((prev) => ({
      ...prev,
      [scheduleId]: value,
    }));
  };

  function patchAvailability(availability) {
    let formData = {};

    if (
      formDataAvailability.location_id.length > 0 ||
      formDataAvailability.location_id != 0
    ) {
      formData.location = formDataAvailability.location_id;
    }

    if (
      formDataAvailability.service_id.length > 0 ||
      formDataAvailability.service_id != 0
    ) {
      formData.service = formDataAvailability.service_id;
    }

    if (formDataAvailability.slots > 0) {
      formData.slots = formDataAvailability.slots;
    }

    if (formDataAvailability.duration > 0) {
      formData.duration = formDataAvailability.duration;
    }

    if (formDataAvailabilitySchedule.length > 0) {
      let initial_weekly_hours = availability.weekly_schedule
        .map((element) => element.times.map((item) => item.timetable_id))
        .flat();

      // let dataTimeTables = formDataAvailabilitySchedule.map((item) => {
      //   let keyWithNonEmptyArray = Object.entries(item.weekly_hours).find(
      //     ([key, value]) => value.length > 0
      //   );
      //   if (initial_weekly_hours.includes(item.id)) {
      //     return {
      //       id: item.id,
      //       start: keyWithNonEmptyArray[1][0][0],
      //       end: keyWithNonEmptyArray[1][0][1],
      //       day_of_week: days.findIndex(
      //         (day) => day.value === keyWithNonEmptyArray[0]
      //       ),
      //     };
      //   } else {
      //     return {
      //       start: keyWithNonEmptyArray[1][0][0],
      //       end: keyWithNonEmptyArray[1][0][1],
      //       day_of_week: days.findIndex(
      //         (day) => day.value === keyWithNonEmptyArray[0]
      //       ),
      //     };
      //   }
      let dataTimeTables = formDataAvailabilitySchedule.flatMap((item) => {
        let keyWithNonEmptyArray = Object.entries(item.weekly_hours).find(
          ([key, value]) => value.length > 0
        );

        let keyWithNonEmptyArrayDays = Object.entries(item.weekly_hours)
          .filter(([key, value]) => Array.isArray(value) && value.length > 0)
          .map(([key]) => key);
        return keyWithNonEmptyArrayDays.map((dayTime) => {
          return {
            start: keyWithNonEmptyArray[1][0][0],
            end: keyWithNonEmptyArray[1][0][1],
            day_of_week: days.findIndex((day) => day.value === dayTime),
          };
        });
      });

      formData.timetables = dataTimeTables;
    }
    setLoadingEditClass(true);
    instanceMob
      .patch(`admin/availability/${availability.id}/update`, formData)
      .then((res) => {
        setLoadingEditClass(false);
        setModalEditClass(null);
        getListClasses();
      })
      .catch((err) => {
        if (err.response) {
          setLoadingEditClass(false);
          let msg_error = err.response.data;
          seterroraGregado(msg_error);
          setTimeout(() => {
            seterroraGregado("");
          }, 3000);
          console.error(err);
        }
      });
  }
  function updateAvailability() {
    let genericDays = {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    };

    formDataAvailabilitySchedule.forEach((item, index) => {
      let weekly = item.weekly_hours;
      Object.entries(weekly).forEach(([day, hours]) => {
        if (hours.length > 0) {
          genericDays[day].push(...hours);
        }
      });
    });

    let formData = { ...formDataAvailability, weekly_hours: genericDays };
    setAddClassLoader(true);
    instanceMob
      .post(`admin/availability/create`, formData)
      .then((res) => {
        setAddClassLoader(false);
        setModalAddClass(null);
        getListClasses();
      })
      .catch((err) => {
        if (err.response) {
          setAddClassLoader(false);
          let msg_error = err.response.data;
          if (msg_error.detail) {
            msg_error = msg_error.detail;
          }
          seterroraGregado(msg_error);
          setTimeout(() => {
            seterroraGregado("");
          }, 3000);
          console.error(err);
        }
      });

    // const promises = formDataAvailabilitySchedule.map((item, index) => {
    //   let nuevoObjeto = {
    //     ...formDataAvailability,
    //     weekly_hours: item.weekly_hours,
    //   };

    //   return instanceMob
    //     .post(`admin/availability/create`, nuevoObjeto)
    //     .then((res) => res.data)
    //     .catch((err) => {
    //       if (err.response) {
    //         let msg_error = err.response.data;
    //         seterroraGregado(msg_error);
    //         setTimeout(() => {
    //           seterroraGregado("");
    //         }, 3000);
    //         console.error(err);
    //       }
    //     });
    // });

    // Promise.all(promises).then(() => {
    //   setModalAddClass(null);
    //   getListClasses();
    // });
  }
  function editAvailability(availability) {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalEditClass(
      <div
        className="popUpServices justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
            maxWidth: "40rem",
            minWidth: "35rem",
          }}
        >
          <div
            className=" mb-3 py-3 d-flex align-items-center"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            <div>
              <ButtonArrow
                classes=" btnBackPr me-1"
                colorBack={`var(--elevvaArrowColor)`}
                handleClick={() => setModalEditClass(null)}
              />
            </div>
            <div>
              Editar <strong>clase</strong>
            </div>
          </div>
          {ErroraGregado && ErroraGregado.length > 0 && (
            <div className="my-1 errorsCreateAccount">{ErroraGregado}</div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "20rem",
            }}
          >
            <div
              className="mx-auto"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "30rem",
              }}
            >
              <div className="d-flex  w-100 " id="location-select">
                <DropdownList
                  style={{ width: "90%" }}
                  busy
                  defaultValue={
                    listLocations
                      .filter((e) => e.id == availability?.location_id)
                      .map((memb) => ({
                        id: memb.id,
                        name: memb.name,
                        value: memb.id,
                      }))[0]
                  }
                  busySpinner={<ArrowDown></ArrowDown>}
                  className={"input-style-elevva-graphics"}
                  id="optionSummary"
                  placeholder={"Locaciones"}
                  data={listLocations.map((memb) => ({
                    id: memb.id,
                    name: memb.name,
                    value: memb.id,
                  }))}
                  dataKey="id"
                  textField="name"
                  onChange={(value) => {
                    setFormDataAvailability((prevData) => ({
                      ...prevData,
                      ["location_id"]: value.value,
                    }));
                  }}
                  renderListItem={({ item }) => (
                    <div
                      className="w-100 mb-1"
                      style={{ borderBottom: "1px solid #9DACBD" }}
                    >
                      <div>{item.name}</div>
                    </div>
                  )}
                />
              </div>
              <div className="d-flex   w-100 " id="location-select">
                <DropdownList
                  style={{ width: "90%" }}
                  busy
                  defaultValue={
                    listServices
                      .filter((e) => e.id == availability?.service_id)
                      .map((memb) => ({
                        id: memb.id,
                        name: memb.name,
                        value: memb.id,
                      }))[0]
                  }
                  busySpinner={<ArrowDown></ArrowDown>}
                  className={"input-style-elevva-graphics"}
                  id="optionSummary"
                  placeholder={"Servicios"}
                  data={listServices.map((memb) => ({
                    id: memb.id,
                    name: memb.name,
                    value: memb.id,
                  }))}
                  dataKey="id"
                  textField="name"
                  onChange={(value) => {
                    setFormDataAvailability((prevData) => ({
                      ...prevData,
                      ["service_id"]: value.value,
                    }));
                  }}
                  renderListItem={({ item }) => (
                    <div
                      className="w-100 mb-1"
                      style={{ borderBottom: "1px solid #9DACBD" }}
                    >
                      <div>{item.name}</div>
                    </div>
                  )}
                />
              </div>
              <div className="d-flex px-0 " style={{ width: "50%" }}>
                <input
                  defaultValue={availability?.duration || ""}
                  placeholder="Duración"
                  type="number"
                  name="duration"
                  className="searchAtletas  py-2 mx-auto"
                  style={{
                    border: "1px solid var(--elevvaTextSubtitle)",
                    borderRadius: "20px",
                    fontSize: "calc(.4rem + .4vw)",
                    width: "100%",
                  }}
                  onChange={(e) => {
                    setFormDataAvailability((prevData) => ({
                      ...prevData,
                      ["duration"]: Number(e.target.value),
                    }));
                  }}
                ></input>
              </div>
              <div className="d-flex px-0 " style={{ width: "50%" }}>
                <input
                  defaultValue={availability?.slots || ""}
                  placeholder="Cupo"
                  type="number"
                  name="slots"
                  className="searchAtletas  py-2 mx-auto"
                  style={{
                    border: "1px solid var(--elevvaTextSubtitle)",
                    borderRadius: "20px",
                    fontSize: "calc(.4rem + .4vw)",
                    width: "100%",
                  }}
                  onChange={(e) => {
                    setFormDataAvailability((prevData) => ({
                      ...prevData,
                      ["slots"]: Number(e.target.value),
                    }));
                  }}
                ></input>
              </div>
            </div>
          </div>
          <PerfectScrollbar
            className="py-2"
            style={{
              maxHeight: "16rem",
              padding: "0 2rem",
              overflowY: "auto",
              marginTop: "1rem",
            }}
          >
            {formDataAvailabilitySchedule
              .sort((a, b) => {
                const hasValidSortedKeyA =
                  a.sortedKey && a.sortedKey.includes("-");
                const hasValidSortedKeyB =
                  b.sortedKey && b.sortedKey.includes("-");

                if (hasValidSortedKeyA && hasValidSortedKeyB) {
                  const timeA = moment(a.sortedKey.split("-")[0], "HH:mm");
                  const timeB = moment(b.sortedKey.split("-")[0], "HH:mm");

                  return timeA.diff(timeB); // Ordena por sortedKey si es válido
                }

                // Si sortedKey no es válido en alguno, ordenar por id
                return b.id - a.id;
              })
              .map((schedule, index) => (
                <ScheduleComponent
                  key={schedule.id}
                  schedule={schedule}
                  index={index + 1}
                  days={days}
                  endTime={endTime[schedule.id]}
                  startTime={startTime[schedule.id]}
                  errorModal={errorModal[schedule.id]}
                  setErrorModal={(e) =>
                    setErrorModal((prev) => ({
                      ...prev,
                      [schedule.id]: `${e}`,
                    }))
                  }
                  deleteSchedule={(e) => {
                    setFormDataAvailabilitySchedule(
                      formDataAvailabilitySchedule.filter(
                        (schedule) => schedule.id !== e
                      )
                    );

                    setSelectedDays((prev) => {
                      if (!(e in prev)) {
                        return prev;
                      }

                      const updatedDays = { ...prev };
                      delete updatedDays[e];
                      return updatedDays;
                    });
                    setScheduleSelected(formDataAvailabilitySchedule[0].id);
                  }}
                  handleDaySelection={(day, scheduleId) =>
                    handleDaySelection(day, scheduleId)
                  }
                  selectedDays={selectedDays[schedule.id] || []}
                  setStartTime={(e) => updateTime(setStartTime, schedule.id, e)}
                  setEndTime={(e) => updateTime(setEndTime, schedule.id, e)}
                  setScheduleSelected={(e) => setScheduleSelected(e)}
                />
              ))}
          </PerfectScrollbar>
          <div className="d-flex align-items-center text-center mx-auto justify-content-center my-2 ">
            <button
              onClick={addSchedule}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                backgroundColor: "var(--elevvabutonLogin_User)",

                color: "#fff",
                fontSize: "calc(.4rem + .4vw)",
                fontWeight: "500",
                cursor: "pointer",
                border: "none",
              }}
            >
              +
            </button>
            <span className="ms-2">Agregar horario adicional</span>
          </div>

          <div className="d-flex w-100 gap-2 mt-3">
            <ButtonMob
              disabled={loadingEditClass}
              handleClick={() => patchAvailability(availability)}
              style={{ border: "1px solid var(--elevvabutonLogin_User)" }}
              colorButton={"var(--elevvabutonLogin_User)"}
              text={loadingEditClass ? "Cargando..." : "Actualizar"}
              className={"mb-3 mt-3 letraspopfi2actualizar "}
            />
          </div>
        </div>
      </div>
    );
  }
  function addClass() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };

    setModalAddClass(
      <div
        className="popUpServices justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
            maxWidth: "40rem",
            minWidth: "35rem",
          }}
        >
          <div
            className=" mb-3 py-3 d-flex align-items-center"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            <div>
              <ButtonArrow
                classes=" btnBackPr me-1"
                colorBack={`var(--elevvaArrowColor)`}
                handleClick={() => setModalAddClass(null)}
              />
            </div>
            <div>
              Registro de <strong>clase</strong>
            </div>
          </div>
          {ErroraGregado && ErroraGregado.length > 0 && (
            <div className="my-1 errorsCreateAccount">{ErroraGregado}</div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "20rem",
            }}
          >
            <div
              className="mx-auto"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "30rem",
              }}
            >
              <div className="d-flex  w-100 " id="location-select">
                <DropdownList
                  style={{ width: "90%" }}
                  busy
                  busySpinner={<ArrowDown></ArrowDown>}
                  className={"input-style-elevva-graphics"}
                  id="optionSummary"
                  placeholder={"Locaciones"}
                  data={listLocations.map((memb) => ({
                    id: memb.id,
                    name: memb.name,
                    value: memb.id,
                  }))}
                  dataKey="id"
                  textField="name"
                  onChange={(value) => {
                    setFormDataAvailability((prevData) => ({
                      ...prevData,
                      ["location_id"]: value.value,
                    }));
                  }}
                  renderListItem={({ item }) => (
                    <div
                      className="w-100 mb-1"
                      style={{ borderBottom: "1px solid #9DACBD" }}
                    >
                      <div>{item.name}</div>
                    </div>
                  )}
                />
              </div>
              <div className="d-flex   w-100 " id="location-select">
                <DropdownList
                  style={{ width: "90%" }}
                  busy
                  busySpinner={<ArrowDown></ArrowDown>}
                  className={"input-style-elevva-graphics"}
                  id="optionSummary"
                  placeholder={"Servicios"}
                  data={listServices.map((memb) => ({
                    id: memb.id,
                    name: memb.name,
                    value: memb.id,
                  }))}
                  dataKey="id"
                  textField="name"
                  onChange={(value) => {
                    setFormDataAvailability((prevData) => ({
                      ...prevData,
                      ["service_id"]: value.value,
                    }));
                  }}
                  renderListItem={({ item }) => (
                    <div
                      className="w-100 mb-1"
                      style={{ borderBottom: "1px solid #9DACBD" }}
                    >
                      <div>{item.name}</div>
                    </div>
                  )}
                />
              </div>
              <div className="d-flex px-0 " style={{ width: "50%" }}>
                <input
                  placeholder="Duración"
                  type="number"
                  name="duration"
                  className="searchAtletas  py-2 mx-auto"
                  style={{
                    border: "1px solid var(--elevvaTextSubtitle)",
                    borderRadius: "20px",
                    fontSize: "calc(.4rem + .4vw)",
                    width: "100%",
                  }}
                  onChange={(e) => {
                    setFormDataAvailability((prevData) => ({
                      ...prevData,
                      ["duration"]: Number(e.target.value),
                    }));
                  }}
                ></input>
              </div>
              <div className="d-flex px-0 " style={{ width: "50%" }}>
                <input
                  placeholder="Cupo"
                  type="number"
                  name="slots"
                  className="searchAtletas  py-2 mx-auto"
                  style={{
                    border: "1px solid var(--elevvaTextSubtitle)",
                    borderRadius: "20px",
                    fontSize: "calc(.4rem + .4vw)",
                    width: "100%",
                  }}
                  onChange={(e) => {
                    setFormDataAvailability((prevData) => ({
                      ...prevData,
                      ["slots"]: Number(e.target.value),
                    }));
                  }}
                ></input>
              </div>
            </div>
          </div>
          <PerfectScrollbar
            className="py-2"
            style={{
              maxHeight: "16rem",
              padding: "0 2rem",
              overflowY: "auto",
              marginTop: "1rem",
            }}
          >
            {formDataAvailabilitySchedule.map((schedule, index) => (
              <ScheduleComponent
                key={schedule.id}
                schedule={schedule}
                index={index + 1}
                days={days}
                endTime={endTime[schedule.id]}
                startTime={startTime[schedule.id]}
                errorModal={errorModal[schedule.id]}
                setErrorModal={(e) =>
                  setErrorModal((prev) => ({
                    ...prev,
                    [schedule.id]: `${e}`,
                  }))
                }
                deleteSchedule={(e) => {
                  setFormDataAvailabilitySchedule(
                    formDataAvailabilitySchedule.filter(
                      (schedule) => schedule.id !== e
                    )
                  );

                  setSelectedDays((prev) => {
                    if (!(e in prev)) {
                      return prev;
                    }

                    const updatedDays = { ...prev };
                    delete updatedDays[e];
                    return updatedDays;
                  });
                  setScheduleSelected(formDataAvailabilitySchedule[0].id);
                }}
                handleDaySelection={(day, scheduleId) =>
                  handleDaySelection(day, scheduleId)
                }
                selectedDays={selectedDays[schedule.id] || []}
                setStartTime={(e) => updateTime(setStartTime, schedule.id, e)}
                setEndTime={(e) => updateTime(setEndTime, schedule.id, e)}
                setScheduleSelected={(e) => setScheduleSelected(e)}
              />
            ))}
          </PerfectScrollbar>
          <div className="d-flex align-items-center text-center mx-auto justify-content-center my-2 ">
            <button
              onClick={addSchedule}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                backgroundColor: "var(--elevvabutonLogin_User)",

                color: "#fff",
                fontSize: "calc(.4rem + .4vw)",
                fontWeight: "500",
                cursor: "pointer",
                border: "none",
              }}
            >
              +
            </button>
            <span className="ms-2">Agregar horario adicional</span>
          </div>

          <div className="d-flex w-100 gap-2 mt-3">
            <ButtonMob
              disabled={addClassLoader}
              handleClick={updateAvailability}
              style={{ border: "1px solid var(--elevvabutonLogin_User)" }}
              colorButton={"var(--elevvabutonLogin_User)"}
              text={addClassLoader ? "Cargando..." : "Guardar"}
              className={"mb-3 mt-3 letraspopfi2actualizar "}
            />
          </div>
        </div>
      </div>
    );
  }
  function editService(service) {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalEditService(
      <div
        className="popUpServices justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
            maxWidth: "40rem",
            minWidth: "35rem",
          }}
        >
          <div
            className=" mb-3 py-3 d-flex align-items-center"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            <div>
              <ButtonArrow
                classes=" btnBackPr me-1"
                colorBack={`var(--elevvaArrowColor)`}
                handleClick={() => {
                  setModalEditService(null);
                  setFormDataService({
                    service_name: "",
                    location_id: "",
                    id: null,
                    duration: 0,
                    slots: 0,
                    photo: "",
                    extra_locations: [],
                  });
                }}
              />
            </div>{" "}
            <div>
              {" "}
              Registro o edición de <strong>servicio</strong>
            </div>
          </div>
          {ErroraGregado && ErroraGregado.length > 0 && (
            <div className="my-1 errorsCreateAccount">{ErroraGregado}</div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "20rem",
            }}
          >
            <div
              className="mx-auto"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "30rem",
              }}
            >
              <div className="d-flex px-0 w-100">
                <input
                  defaultValue={service?.name || ""}
                  placeholder="Nombre del servicio"
                  name="service_name"
                  type="search"
                  className="searchAtletas  py-2 mx-auto"
                  style={{
                    border: "1px solid var(--elevvaTextSubtitle)",
                    borderRadius: "20px",
                    fontSize: "calc(.4rem + .4vw)",
                    width: "100%",
                  }}
                  onChange={handleChangeService}
                ></input>
              </div>

              <div className="d-flex px-0 " style={{ width: "50%" }}>
                <input
                  placeholder="Duración"
                  type="number"
                  defaultValue={service?.duration || ""}
                  name="duration"
                  className="searchAtletas  py-2 mx-auto"
                  style={{
                    border: "1px solid var(--elevvaTextSubtitle)",
                    borderRadius: "20px",
                    fontSize: "calc(.4rem + .4vw)",
                    width: "100%",
                  }}
                  onChange={(e) => {
                    setFormDataService((prevData) => ({
                      ...prevData,
                      ["duration"]: Number(e.target.value),
                    }));
                  }}
                ></input>
              </div>
              <div className="d-flex px-0 " style={{ width: "50%" }}>
                <input
                  placeholder="Cupo"
                  defaultValue={service?.slots || ""}
                  type="number"
                  name="slots"
                  className="searchAtletas  py-2 mx-auto"
                  style={{
                    border: "1px solid var(--elevvaTextSubtitle)",
                    borderRadius: "20px",
                    fontSize: "calc(.4rem + .4vw)",
                    width: "100%",
                  }}
                  onChange={(e) => {
                    setFormDataService((prevData) => ({
                      ...prevData,
                      ["slots"]: Number(e.target.value),
                    }));
                  }}
                ></input>
              </div>
            </div>
          </div>

          <div>
            <span>Imagen de servicio</span>
          </div>
          <div style={{ display: "flex", justifyContent: "left" }}>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageUploadService}
            />

            <ButtonMob
              handleClick={handleIconClick}
              colorButton={"var(--elevvabutonLogin_User)"}
              text="Seleccionar imagen"
              className={"mb-3 mt-3 letraspopfi2actualizar mx-0"}
            />
          </div>
          {fileInputRef?.current?.files[0]?.name && (
            <div className="mb-2">
              <span style={{ fontStyle: "italic" }}>
                {fileInputRef?.current?.files[0]?.name || "Ninguna"}
              </span>
            </div>
          )}
          <div className="d-flex w-100 gap-2 mt-3">
            <ButtonMob
              disabled={loaderEditService}
              handleClick={postServiceEdit}
              style={{ border: "1px solid var(--elevvabutonLogin_User)" }}
              colorButton={"var(--elevvabutonLogin_User)"}
              text={loaderEditService ? "Cargando..." : "Actualizar"}
              className={"mb-3 mt-3 letraspopfi2actualizar "}
            />
          </div>
        </div>
      </div>
    );
  }
  function addService(service) {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalAddService(
      <div
        className="popUpServices justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
            maxWidth: "40rem",
            minWidth: "35rem",
          }}
        >
          <div
            className=" mb-3 py-3 d-flex align-items-center"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            <div>
              <ButtonArrow
                classes=" btnBackPr me-1"
                colorBack={`var(--elevvaArrowColor)`}
                handleClick={() => {
                  setModalAddService(null);
                  setFormDataService({
                    service_name: "",
                    location_id: "",
                    id: null,
                    duration: 0,
                    slots: 0,
                    photo: "",
                    extra_locations: [],
                  });
                }}
              />
            </div>{" "}
            <div>
              {" "}
              Registro o edición de <strong>servicio</strong>
            </div>
          </div>
          {ErroraGregado && ErroraGregado.length > 0 && (
            <div className="my-1 errorsCreateAccount text-center">
              {ErroraGregado}
            </div>
          )}
          <PerfectScrollbar
            style={{
              maxHeight: "30rem",

              overflowY: "auto",
              marginTop: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "20rem",
              }}
            >
              <div
                className="mx-auto"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  width: "30rem",
                }}
              >
                <div className="d-flex px-0 w-100">
                  <input
                    defaultValue={service?.name || ""}
                    placeholder="Nombre del servicio"
                    name="service_name"
                    type="search"
                    className="searchAtletas  py-2 mx-auto"
                    style={{
                      border: "1px solid var(--elevvaTextSubtitle)",
                      borderRadius: "20px",
                      fontSize: "calc(.4rem + .4vw)",
                      width: "100%",
                    }}
                    onChange={handleChangeService}
                  ></input>
                </div>
                <div className="d-flex  w-100 " id="location-select">
                  <DropdownList
                    style={{ width: "90%" }}
                    busy
                    busySpinner={<ArrowDown></ArrowDown>}
                    className={"input-style-elevva-graphics"}
                    id="optionSummary"
                    placeholder={"Locación"}
                    data={listLocations.map((memb) => ({
                      id: memb.id,
                      name: memb.name,
                      value: memb.id,
                    }))}
                    dataKey="id"
                    textField="name"
                    onChange={(value) => {
                      setFormDataService((prevData) => ({
                        ...prevData,
                        ["location_id"]: value.value,
                      }));
                    }}
                    renderListItem={({ item }) => (
                      <div
                        className="w-100 mb-1"
                        style={{ borderBottom: "1px solid #9DACBD" }}
                      >
                        <div>{item.name}</div>
                      </div>
                    )}
                  />
                </div>
                <div className="d-flex px-0 " style={{ width: "50%" }}>
                  <input
                    placeholder="Duración"
                    type="number"
                    defaultValue={service?.duration || ""}
                    name="duration"
                    className="searchAtletas  py-2 mx-auto"
                    style={{
                      border: "1px solid var(--elevvaTextSubtitle)",
                      borderRadius: "20px",
                      fontSize: "calc(.4rem + .4vw)",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setFormDataService((prevData) => ({
                        ...prevData,
                        ["duration"]: Number(e.target.value),
                      }));
                    }}
                  ></input>
                </div>
                <div className="d-flex px-0 " style={{ width: "50%" }}>
                  <input
                    placeholder="Cupo"
                    defaultValue={service?.slots || ""}
                    type="number"
                    name="slots"
                    className="searchAtletas  py-2 mx-auto"
                    style={{
                      border: "1px solid var(--elevvaTextSubtitle)",
                      borderRadius: "20px",
                      fontSize: "calc(.4rem + .4vw)",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setFormDataService((prevData) => ({
                        ...prevData,
                        ["slots"]: Number(e.target.value),
                      }));
                    }}
                  ></input>
                </div>
              </div>
            </div>
            <div
              className="py-2"
              style={{
                // maxHeight: "16rem",
                padding: "0 2rem",
                overflowY: "auto",
                marginTop: "1rem",
              }}
            >
              {formDataAvailabilitySchedule.map((schedule, index) => (
                <ScheduleComponent
                  key={schedule.id}
                  schedule={schedule}
                  index={index + 1}
                  days={days}
                  endTime={endTime[schedule.id]}
                  startTime={startTime[schedule.id]}
                  errorModal={errorModal[schedule.id]}
                  setErrorModal={(e) =>
                    setErrorModal((prev) => ({
                      ...prev,
                      [schedule.id]: `${e}`,
                    }))
                  }
                  deleteSchedule={(e) => {
                    setFormDataAvailabilitySchedule(
                      formDataAvailabilitySchedule.filter(
                        (schedule) => schedule.id !== e
                      )
                    );

                    setSelectedDays((prev) => {
                      if (!(e in prev)) {
                        return prev;
                      }

                      const updatedDays = { ...prev };
                      delete updatedDays[e];
                      return updatedDays;
                    });
                    setScheduleSelected(formDataAvailabilitySchedule[0].id);
                  }}
                  handleDaySelection={(day, scheduleId) =>
                    handleDaySelection(day, scheduleId)
                  }
                  selectedDays={selectedDays[schedule.id] || []}
                  setStartTime={(e) => updateTime(setStartTime, schedule.id, e)}
                  setEndTime={(e) => updateTime(setEndTime, schedule.id, e)}
                  setScheduleSelected={(e) => setScheduleSelected(e)}
                />
              ))}
            </div>

            <div className="d-flex align-items-center text-center mx-auto justify-content-center my-2 ">
              <button
                onClick={addSchedule}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  backgroundColor: "var(--elevvabutonLogin_User)",

                  color: "#fff",
                  fontSize: "calc(.4rem + .4vw)",
                  fontWeight: "500",
                  cursor: "pointer",
                  border: "none",
                }}
              >
                +
              </button>
              <span className="ms-2">Agregar horario adicional</span>
            </div>
            <span>
              ¿Deseas agregar este servicio y horarios a otras locaciones?
            </span>
            <div
              className="d-flex w-100 flex-column gap-2"
              style={{ justifyContent: "left" }}
            >
              <div
                className="py-2"
                style={{
                  // maxHeight: "6rem",
                  padding: "0 2rem",
                  overflowY: "auto",
                  marginTop: "1rem",
                }}
              >
                {listLocations.map((location) => (
                  <div key={location.id} className="d-flex align-items-center">
                    {" "}
                    <input
                      type="checkbox"
                      value={location.id}
                      checked={formDataService.extra_locations.includes(
                        location.id
                      )}
                      onChange={handleCheckboxChangeServices}
                    ></input>
                    <label
                      className="ms-2"
                      style={{
                        color: "#9DACBD",
                        fontSize: "calc(.4rem + .4vw)",
                      }}
                    >
                      {location.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </PerfectScrollbar>
          <div className="d-flex w-100 gap-2 mt-3">
            <ButtonMob
              disabled={loaderAddService}
              handleClick={postService}
              style={{ border: "1px solid var(--elevvabutonLogin_User)" }}
              colorButton={"var(--elevvabutonLogin_User)"}
              text={
                loaderAddService
                  ? "Cargando..."
                  : service?.id
                  ? "Actualizar"
                  : "Registrar"
              }
              className={"mb-3 mt-3 letraspopfi2actualizar "}
            />
          </div>
        </div>
      </div>
    );
  }

  // setModalService(
  //   <div
  //     className="popUpServices justify-content-center align-items-center"
  //     style={styleModal}
  //   >
  //     <div
  //       className="modalInfoContenido"
  //       style={{
  //         background: "white",
  //         borderRadius: "20px",
  //         boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
  //         padding: "2% 6%",
  //         maxWidth: "30rem",
  //       }}
  //     >
  //       <div
  //         className=" mb-3 py-3"
  //         style={{
  //           lineHeight: "100%",
  //           textAlign: "center",

  //           color: "var(--elevvabutonLogin_User)",
  //         }}
  //       >
  //         Resumen de servicio
  //       </div>
  //       <PerfectScrollbar
  //         style={{ maxHeight: "25rem", padding: "0 1rem" }}
  //       >
  //         {" "}
  //         {demo.map((e) => (
  //           <div>
  //             {" "}
  //             <div className="text-center">
  //               <span style={{ color: "var(--elevvabutonLogin_User)" }}>
  //                 Funcional / 45 min
  //               </span>
  //             </div>
  //             <hr style={separatorStyle} />
  //             <div className="text-center">
  //               <span style={{ color: "var(--elevvabutonLogin_User)" }}>
  //                 Cupos<br></br>20
  //               </span>
  //             </div>
  //             <hr style={separatorStyle} />
  //             <div className="d-flex w-100 gap-4">
  //               <div>
  //                 {" "}
  //                 <div style={{ color: "#ffff" }}>.</div>
  //                 <div>{e}</div>
  //               </div>
  //               <div>
  //                 <div className="text-center">Colina</div>
  //                 <div style={{ color: "#9DACBD", textAlign: "left" }}>
  //                   06:00 / 07:00 / 08:00 / 09:00 / 10:00 / 11:00 /
  //                   12:00 / 13:00 15:00 / 16:00 / 17:00 / 18:00 / 19:00
  //                   / 20:00 / 21:00
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         ))}
  //       </PerfectScrollbar>
  //       <div className="d-flex w-100 gap-2 mt-3">
  //         <ButtonMob
  //           style={{ border: "1px solid var(--elevvabutonLogin_User)" }}
  //           colorButton={"var(--elevvabutonLogin_User)"}
  //           text="Confirmar"
  //           className={"mb-3 mt-3 letraspopfi2actualizar "}
  //         />
  //         <ButtonMob
  //           handleClick={() => setModalService(null)}
  //           colorButton={"transparent"}
  //           text="Regresar"
  //           className={
  //             "mb-3 mt-3 letraspopfi2actualizar button-service-card"
  //           }
  //         />
  //       </div>
  //     </div>
  //   </div>
  // );
  function modalServiceDetail(serviceId) {
    instanceMob
      .get(`admin/service/${serviceId}`)
      .then((res) => {
        if (res.status == "204" || res.status == "200") {
          const data = res.data;
          setModalService(
            <ConfirmDeletionModal
              onCancel={() => setModalService(null)}
              onConfirm={() => setModalService(null)}
            >
              <div style={{ minWidth: "20rem" }}>
                <div className="mb-3">
                  <span className="font-bold">Resumen de servicio</span>
                </div>
                <div className="d-flex gap-3 align-items-center justify-content-center">
                  <div style={{ marginBottom: "auto", paddingTop: "1rem" }}>
                    <img
                      src={data.photo}
                      alt="Logo"
                      style={{
                        width: "6rem",
                        height: "6rem",
                        borderRadius: "50%",
                        marginRight: "calc(.4rem + .4vw)",
                      }}
                    />
                  </div>
                  <div
                    className="d-flex flex-column "
                    style={{ textAlign: "left" }}
                  >
                    <span>{data.name}</span>
                    <span>{data.duration} min </span>
                    <span>{data.slots} cupos</span>
                    <div className="d-flex flex-column ">
                      {listLocations.map((e) => {
                        if (data.location.includes(e.id)) {
                          return <span>{e.name}</span>;
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <hr></hr>
            </ConfirmDeletionModal>
          );
        }
      })
      .catch((err) => {
        let msg_error = err.response.data;
        if (msg_error) {
          seterroraGregado(msg_error);

          setDisplayCorrect("flex");
          setTimeout(() => {
            setDisplayCorrect("none");
          }, 5000);
        }
      });
  }

  function modalAvailabilityDetail(availability) {
    console.log(availability, "availability", listServices);
    let serviceFilter = listServices.find(
      (e) => e.id == availability.service_id
    );
    instanceMob
      .get(`admin/availability/${availability.id}`)
      .then((res) => {
        if (res.status == "204" || res.status == "200") {
          const data = res.data;
          console.log(data.schedule);

          setModalAvailability(
            <ConfirmDeletionModal
              onCancel={() => setModalAvailability(null)}
              onConfirm={() => setModalAvailability(null)}
            >
              <div style={{ minWidth: "20rem" }}>
                <div className="mb-3">
                  <span className="font-bold">Resumen de clase</span>
                </div>
                <div className="d-flex gap-3 align-items-center ">
                  <div>
                    <img
                      src={serviceFilter.photo}
                      alt="Logo"
                      style={{
                        width: "6rem",
                        height: "6rem",
                        borderRadius: "50%",
                        marginRight: "calc(.4rem + .4vw)",
                      }}
                    />
                  </div>
                  <div
                    className="d-flex flex-column "
                    style={{ textAlign: "left" }}
                  >
                    <span>{serviceFilter.name}</span>
                    <span>Duración: {availability.duration} min</span>
                    <span>Cupos: {data.slots}</span>
                  </div>
                </div>
                <hr></hr>
                <PerfectScrollbar
                  style={{ height: "10rem" }}
                  className="d-flex flex-column "
                >
                  {Object.entries(data.schedule).map(([key, value]) => (
                    <div key={key}>
                      <div className="my-2">
                        <span>
                          <strong>{availability.location_name}</strong>
                        </span>
                      </div>{" "}
                      <div className="d-flex gap-3">
                        <div>{days.find((e) => e.value == key)?.read}</div>
                        <div>
                          {value.map((resume, i) => {
                            return resume.map((r) => {
                              return (
                                <div key={i + "detail-value"}>
                                  {" "}
                                  <span>
                                    {moment(r.start_time, "HH:mm:ss").format(
                                      "h:mm a "
                                    )}
                                    -{" "}
                                    {moment(r.end_time, "HH:mm:ss").format(
                                      "h:mm a "
                                    )}
                                  </span>
                                </div>
                              );
                            });
                          })}
                        </div>
                      </div>
                    </div>
                  ))}
                </PerfectScrollbar>
              </div>
              <hr></hr>
            </ConfirmDeletionModal>
          );
        }
      })
      .catch((err) => {
        let msg_error = err.response.data;
        if (msg_error) {
          seterroraGregado(msg_error);

          setDisplayCorrect("flex");
          setTimeout(() => {
            setDisplayCorrect("none");
          }, 5000);
        }
      });
  }

  return (
    <>
      {modalService}
      {modalAvailability}
      {modalAddLocation}
      {modalAddService}
      {modalAddClass}
      {modalEditClass}
      {modalEditService}
      {modalError}

      <div
        className="d-flex gap-3 flex-column"
        style={{
          width: "60%",

          height: "100%",
        }}
      >
        <div
          style={{
            border: "1px solid #B6C1CE",
            borderRadius: "10px",
            height: "45%",
            width: "100%",
          }}
        >
          <Locations
            modalAddLocation={addLocation}
            locations={listLocations}
            deleteLocation={deleteLocation}
            editLocation={(e) => {
              if (e?.id) {
                setFormDataLocation((prevData) => ({
                  ...prevData,
                  ["id"]: e.id,
                  ["days"]: e.days,
                }));
              }
              addLocation(e);
            }}
            cities={cities}
            isLoading={isLoading}
          />
        </div>
        <div
          style={{
            border: "1px solid #B6C1CE",
            borderRadius: "10px",
            height: "55%",
            width: "100%",
          }}
        >
          <Services
            modalServiceDetail={modalServiceDetail}
            searchServices={searchServices}
            addService={addService}
            deleteService={deleteService}
            services={listServices}
            isLoading={isLoading}
            editService={(e) => {
              if (e?.id) {
                setFormDataService((prevData) => ({
                  ...prevData,
                  ["id"]: e.id,
                }));
              }
              editService(e);
            }}

            // TODO
            // editService={(e) => {
            //   if (e?.id) {
            //     setFormDataService((prevData) => ({
            //       ...prevData,
            //       ["id"]: e.id,
            //       ["extra_locations"]: e.locations.map((e) => e.id),
            //     }));
            //   }
            //   addService(e);
            // }}
          />
        </div>
      </div>
      <div
        className="d-flex gap-3 flex-column"
        style={{
          width: "40%",

          height: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            border: "1px solid #B6C1CE",
            borderRadius: "10px",
            height: "100%",
          }}
        >
          <Clases
            editAvailability={(e) => {
              if (e?.id) {
                setAvailabilitySelected(e);
                let schedule = e.weekly_schedule;
                setStartTime({});
                setEndTime({});
                setSelectedDays({});
                if (schedule) {
                  setFormDataAvailabilitySchedule([]);

                  setFormDataAvailability({
                    ["location_id"]: "",
                    ["service_id"]: "",
                    ["slots"]: 0,
                    ["duration"]: 0,
                  });
                  editAvailability(e);

                  const groupedSchedule = schedule.reduce(
                    (acc, { day, times }) => {
                      times.forEach(({ timetable_id, start, end }) => {
                        let key = `${start}-${end}`;
                        if (!acc[key]) {
                          acc[key] = {
                            start,
                            end,
                            days: [],
                            timetable_id: [],
                            weekly_hours: {
                              monday: [],
                              tuesday: [],
                              wednesday: [],
                              thursday: [],
                              friday: [],
                              saturday: [],
                              sunday: [],
                            },
                          };
                        }

                        // Agregar día y ID de horario
                        acc[key].days.push(day);
                        updateTime(setStartTime, timetable_id, start);
                        updateTime(setEndTime, timetable_id, end);

                        acc[key].timetable_id.push(timetable_id);
                        acc[key].weekly_hours[days[day]?.value].push([
                          start,
                          end,
                        ]);
                      });

                      return acc;
                    },
                    {}
                  );
                  const getDaysWithData = (weekly_hours) => {
                    return Object.entries(weekly_hours)
                      .filter(
                        ([_, hours]) => Array.isArray(hours) && hours.length > 0
                      )
                      .map(([day]) => day);
                  };
                  // Convertimos el objeto en un array de valores
                  let formedSchedule = Object.entries(groupedSchedule).map(
                    (item) => {
                      handleDaySelectionKey(
                        getDaysWithData(item[1].weekly_hours),
                        item[1].timetable_id[0]
                      );

                      return {
                        id: item[1].timetable_id[0], // Tomamos el primer ID (puedes ajustar si es necesario)
                        weekly_hours: item[1].weekly_hours,
                        sortedKey: item[0],
                      };
                    }
                  );

                  setFormDataAvailabilitySchedule(formedSchedule);
                }
              }
            }}
            addClass={() => {
              setSelectedDays({});
              setEndTime({});
              setSelectedDays({});
              setFormDataAvailabilitySchedule([
                {
                  id: 1,
                  weekly_hours: {
                    monday: [],
                    tuesday: [],
                    wednesday: [],
                    thursday: [],
                    friday: [],
                    saturday: [],
                    sunday: [],
                  },
                },
              ]);
              setStartTime({});
              setEndTime({});
              setFormDataAvailability({
                ["location_id"]: "",
                ["service_id"]: "",
                ["slots"]: 0,
                ["duration"]: 0,
              });
              addClass();
            }}
            isLoading={isLoading}
            availabilitys={listAvailability}
            searchAvailabilities={searchAvailabilities}
            modalAvailabilityDetail={modalAvailabilityDetail}
            page={pageAvailability}
            count={countAvailability}
            deleteAvailability={deleteAvailability}
            changePage={(e) =>
              changePage(
                e,
                getAvailabilityPage,
                pageNextAvailability,
                pageBackAvailability,
                pageAvailability,
                countAvailability,
                setPageAvailability
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default MobConfig;
