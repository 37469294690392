import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useNavigate } from "react-router";


const TrainingSessions = ({ sessions }) => {
const text_size="calc(0.5rem + 0.4vw)"
const navigate = useNavigate();

    return (
        <Container className="mt-0">
            <div className="text-center my-3">
                <img
                    alt="logo_alta_black_2"
                    style={{ padding: "0% 20%" }}
                    src={
                        process.env.PUBLIC_URL +
                        "/assets/img/logo_small_elevva_metrics.png"
                    }
                />
            </div>

            <div className="text-center my-3">
                <span className="text-color-live-details" style={{ fontSize: "calc(0.8rem + 0.5vw)" }}>
                    Selecciona tu sesión de entrenamiento </span> </div>
           <PerfectScrollbar className="px-3 w-100" style={{ height: "calc(100vh - 20rem)" }}> 
            <Row className="mt-3 w-100">
                {Array(3).fill(sessions).flat().map((session, index) => (
                    <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-3">
                        <Card className="shadow-lg cursor-pointer"    onClick={ () => navigate(`/live-sesions/${session.id}`)
                       
                    }>
                            <Card.Body className="text-center">
                                <div className="d-flex align-items-center gap-3">
                                    <span className="text-color-live-details" style={{ fontSize: text_size}} > {session.time}</span>
                                    <span className="text-color-live-details" style={{ fontSize: text_size}} >{session.type}</span>
                                </div>
                                <hr className="my-1"></hr>
                                <div className="d-flex align-items-center gap-3">
                                    <span className="text-color-live-details" style={{ fontSize: text_size}}><img className="" src="/assets/icons-multiple-live/clock-blue.svg" alt="clock-blue" /> {session.duration}</span>
                                    <span style={{ fontSize: text_size}} className="text-color-live-details"> <img  src="/assets/icons-multiple-live/person-service.svg" alt="person-service" /> {session.slots}</span>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            </PerfectScrollbar>
        </Container>
    );
};

export default TrainingSessions;
