import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import icoPositivo from "../../assets/newIcons/ICO-POSITIVO.svg";
import icoNegativo from "../../assets/newIcons/ICO-NEGATIVO.svg";
import "./loginStart.css";
import {
  ButtonArrow,
  ButtonMob,
  ModalInfo,
  NuevaAxiosURL,
} from "../../utils/utils";
// modal bootstrap

const RecoverPassword = (props) => {
  const elevvaT = props.elevvaT;
  const navigate = useNavigate();

  const [modalInfo, setModalInfo] = useState(null);

  const handleSubmit = (event) => {
    const email = event.target[0].value.trim();

    var formData = new FormData();
    formData.append("email", email);
    axios
      .post(`${NuevaAxiosURL}users/auth/forgot-password/`, formData)
      .then((res) => {
        const data = res.status;
        if (data == 200 || data == 202) {
          setModalInfo(
            <ModalInfo
              error="none"
              textButtonAction="Entendido"
              handleCancellcargue={() => navigate("/")}
              handleReservar={() => navigate("/")}
              iconoWidth={"3rem"}
              textSizeUp={"1.2rem"}
              icono={icoPositivo}
              text={` <br/> Hemos enviado tu nueva  <br/> contraseña a tu correo  <br/> <br/>`}
            />
          );
        }
      })
      .catch((err) => {
        setModalInfo(
          <ModalInfo
            error="none"
            textButtonAction="Entendido"
            handleCancellcargue={() => setModalInfo(null)}
            handleReservar={() => setModalInfo(null)}
            iconoWidth={"3rem"}
            textSizeUp={"1.2rem"}
            icono={icoNegativo}
            text={` <br/> El correo no se <br/> encuentra registrado  <br/> <br/>`}
          />
        );
      });

    event.preventDefault();
  };

  const FormRecover = () => {
    return (
      <div className="total-width-login">
        <span className="text-color-subtitle text-left">Ingreso usuario</span>
        <form
          className="LoginForm Registro"
          style={{
            gap: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          onSubmit={(e) => handleSubmit(e)}
        >
          <h1 className=" m-0" style={{ fontWeight: "300" }}>
            Recupera <h1 style={{ fontWeight: "bold" }}>tu contraseña</h1>
          </h1>
          <span style={{ color: "#9DACBD" }}>
            Introduce el correo electrónico en el que quieres recibir la
            información para cambiar tu contraseña
          </span>
          <input
            type="email"
            placeholder={elevvaT("email_text")}
            name="mail"
            id={props.idMail}
            className="form-control input-style-elevva mx-auto"
            required
          ></input>

          <div className="d-flex justify-content-center ">
            <ButtonMob
              className="m-0 buttonLoginStart"
              colorButton={`var(--elevvabutonLogin_User)`}
              text={"Enviar"}
            />
          </div>
        </form>
      </div>
    );
  };
  return (
    <>
      {modalInfo}
      <div className="d-md-none mobile-login w-100 ">
        <div style={{ height: "calc(100vh - 65vh)" }}>
          <div
            className="containerBGClase"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/assets/img/createUser_web_image.jpg"
              })`,
              backgroundSize: "cover",
            }}
          >
            <div className="button-back-start">
              <ButtonArrow
                colorBack={`var(--elevvaArrowColor)`}
                handleClick={() => navigate(-1)}
              />
            </div>
          </div>
        </div>
        <div
          className="mobile-welcome-login-row  text-left w-100"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",

            alignItems: "center",
          }}
        >
          <FormRecover
            idMail={"mailResponsive"}
            idPassword={"passResponsive"}
          />
        </div>
      </div>
      <div className=" d-none d-md-flex col-12 ">
        <div
          className="backgrounImgLoginStart"
          style={{
            width: "53%",
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/assets/img/createUser_web_image.jpg"
            })`,
          }}
        ></div>
        <div
          className=""
          style={{
            width: "50%",
            margin: "auto",
            zIndex: "2",
            height: "100%",
            position: "absolute",
            left: "50%",
            justifyContent: "center",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <div className="box-Welcome-login text-left col-12 d-flex">
            <div className="w-100 mx-auto d-flex">
              <div className="total-width-arrow">
                <ButtonArrow
                  colorBack={`var(--elevvaArrowColor)`}
                  handleClick={() => navigate(-1)}
                />
              </div>
              <FormRecover idMail={"mailWeb"} idPassword={"passWeb"} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoverPassword;
