export default function changePage(
  value,
  getPageFunction,
  pageNext,
  pageBack,
  page,
  count,
  setPage
) {
  if (value === "next") {
    getPageFunction(pageNext);

    if (page * 10 - 10 >= count) {
      setPage(count);
    } else {
      setPage(page + 1);
    }
  }

  if (value === "previous") {
    getPageFunction(pageBack);

    if (page - 1 === 0) {
      setPage(1);
    } else {
      setPage(page - 1);
    }
  }
}
