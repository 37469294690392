const Card = ({ name, img, zone, status }) => {
  const colors = {
    active: "#004CFF",
    resting: "#D2D2D2",
    alert: "#00FC6D",
    waiting: "#FC8600",
    warning: "#FF0000",
  };
const text_size="calc(0.6rem + 0.5vw)"
const text_size_band="calc(0.45rem + 0.4vw)"
const text_size_metrics="calc(0.6rem + 0.5vw)"
  return (
    <div className={`card pt-3 border-2 px-3 pb-1 shadow-lg d-flex flex-column`} style={{ backgroundColor: colors[status],  width: "100%", height: "auto",border:"none",borderRadius:"0.7rem",minHeight:"100%" }}>
      <div className="d-flex align-items-center mb-1" style={{color:"white"}}>
      <div className="d-flex align-items-center">
                       
                        <img
                          className=" me-4"
                          style={{
                            objectFit: "cover",
                            width:"calc(2.8rem + 0.2vw)" ,
                            maxHeight: "100%",
                            maxWidth: "100%",
                            height: "calc(2.8rem + 0.2vw)" ,
                            borderRadius: "50%",
                          }}
                          src={
                           img
                          }
                        ></img>
                      </div>
                      <div className="d-flex flex-column gap-1" >
                        <div style={{maxWidth:"80%",lineHeight:"1"}}>
                      <span className="fw-bold" style={{fontSize: text_size,}} >{name}</span>
                      </div>
                      <span className="fw-bold" style={{fontSize: text_size_band}}>
                      <img className="me-2" src="/assets/icons-multiple-live/watch-heart.svg" alt="watch-card-heart" />CL831-0028646
                      </span>
                      
                      </div>
       
      </div>
      <div className="py-2 d-flex flex-column gap-2" style={{color:"white",fontSize: text_size}}>
        <div className="d-flex align-items-center" style={{ justifyContent: "start" }} >
          <div className="d-flex align-items-center w-50 gap-1">
          <div className=" align-items-center" >
          <img className="ms-2 fw-bold" src="/assets/icons-multiple-live/fire-white.svg" alt="fire-card" />  
          </div>
          <div className="d-flex align-items-center h-100 text-center">
          <span style={{fontSize: text_size_metrics}}>000</span>
          </div>
          </div>
       
          <span className="w-50 d align-items-center" style={{fontSize: text_size_metrics}}>
          <img className="ms-2" src="/assets/icons-multiple-live/heart-white.svg" alt="heart-card" />  120 
          </span>
        </div>
        <div className="d-flex align-items-center" style={{ justifyContent: "space-between"}}> 
         



        <div className="d-flex align-items-center w-50 gap-1">
  {/* Contenedor de la imagen */}
  <div className="d-flex align-items-center">
    <img className="ms-2 fw-bold" src="/assets/icons-multiple-live/watch-white.svg" alt="watch-card" />  
  </div>
  {/* Contenedor del texto */}
  <div className="d-flex align-items-center text-center">
    <span style={{ fontSize: text_size_metrics }}>00:00:00</span>
  </div>
</div>


       
        <span className=" fw-normal"  style={{color:"white",fontSize: text_size_metrics}}> {zone}</span>
        </div>
     
    
      </div>
   
    </div>
  );
};
export default Card;
