import { DropdownList } from "react-widgets";
import { ReactComponent as ArrowDown } from "../../../assets/elevva-pro-icons/arrowDown.svg";
import { instanceMob } from "../../../utils/utils";
import debounce from "../functions/debounce";
import { useEffect, useState } from "react";
import { ReactComponent as DeleteTag } from "../../../assets/elevva-pro-icons/delete-tag.svg";
import { Spinner } from "react-bootstrap";

const PlanServicesComponent = (props) => {
  const index = props.index;
  const service = props.service;
  const [listServices, setListServices] = useState([]);
  const serviceSelector = props.serviceSelector;
  const setQuote = props.setQuote;
  const setScheduleSelected = props.setScheduleSelected;
  const deleteSchedule = props.deleteSchedule;
  const [loading, setLoading] = useState(false);
  const [quotaBoolean, setQuotaBoolean] = useState(false);
  console.log(service, "service--", quotaBoolean);
  function getListServices() {
    setLoading(true);
    instanceMob
      .get(`admin/service`)
      .then((res) => res.data)
      .then((data) => {
        setLoading(false);
        setListServices(data.results);
      })
      .catch((err) => console.error(err));
  }
  console.log(listServices);
  useEffect(() => {
    getListServices();
    setQuotaBoolean(service.quota == -1);
  }, [service.service]);

  return (
    <div className="my-2">
      <div className="d-flex justify-content-between mx-auto gap-3">
        <span style={{ fontSize: "calc(.4rem + .4vw)" }}>
          Servicio {service.id}
        </span>
        {service.id !== 1 && (
          <div>
            <DeleteTag
              className="cursor-pointer"
              onClick={() => deleteSchedule(service.id, service.service)}
            />
          </div>
        )}
      </div>
      <div className="d-flex  w-100 my-2" id="location-select">
        {loading ? (
          <Spinner />
        ) : (
          <DropdownList
            style={{ width: "100%" }}
            busy
            defaultValue={
              service?.service
                ? listServices
                    .filter((memb) => memb.id == service.service)
                    .map((memb) => ({
                      id: memb.id,
                      name: memb.name,
                      value: memb.id,
                    }))[0]
                : ""
            }
            busySpinner={<ArrowDown></ArrowDown>}
            className={"input-style-elevva-graphics"}
            id="optionSummary"
            placeholder={"Seleccionar servicio"}
            data={listServices.map((memb) => ({
              id: memb.id,
              name: memb.name,
              value: memb.id,
            }))}
            dataKey="id"
            textField="name"
            onChange={(value) => serviceSelector(value.value)}
            renderListItem={({ item }) => (
              <div
                className="w-100 mb-1"
                style={{ borderBottom: "1px solid #9DACBD" }}
              >
                <div>{item.name}</div>
              </div>
            )}
          />
        )}
      </div>
      <input
        defaultValue={service.quota && service.quota != -1 ? service.quota : ""}
        placeholder="Accesos"
        name="quote"
        disabled={quotaBoolean}
        type="number"
        className="searchAtletas  py-2 mx-auto"
        style={{
          border: "1px solid var(--elevvaTextSubtitle)",
          borderRadius: "20px",
          fontSize: "calc(.4rem + .4vw)",
          width: "100%",
        }}
        onChange={(e) => {
          setScheduleSelected(service.id);
          debounce(setQuote(e.target.value), 500);
        }}
      ></input>
      <div>
        <input
          checked={quotaBoolean}
          type="checkbox"
          name="quote"
          onChange={(e) => {
            setQuotaBoolean(e.target.checked);
            setScheduleSelected(service.id);
            setQuote(e ? -1 : 0);
          }}
        ></input>
        <span className="ms-2">Ilimitados</span>
      </div>
    </div>
  );
};

export default PlanServicesComponent;
