import { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useParams } from "react-router";
import LogoSupremacyBig from "../../assets/assets/elevva-mora_big.png";
import { ButtonMob, instance } from "../../utils/utils";
import UserDetail from "./components/detailUser";
import { ReactComponent as SearchIconAlone } from "../../assets/elevva-pro-icons/searchIconAlone.svg";
import { ReactComponent as FiltersTable } from "../../assets/elevva-pro-icons/filters-table.svg";

import ExerciseData from "./components/exercise-data";
import UserDetailCompare from "./components/detail-user-compare";
import { ProgressBar } from "react-bootstrap";

const PR = () => {
  const { id } = useParams();
  const [userDetail, setUserDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [allUser, setAllUser] = useState([]);
  const [valueSearch, setValueSearch] = useState("");
  const [logs, setLogs] = useState([]);
  const [modal, setModal] = useState(null);
  const [modalError, setModalError] = useState(null);
  const [exerciseSelected, setExerciseSelected] = useState(null);
  const [compareUser, setCompareUser] = useState(null);
  const [dataCompare, setDataCompare] = useState(null);
  const inputSearchRef = useRef(null);
  const [tags, setTags] = useState([]);
  const [filterByTag, setFilterByTag] = useState("");
  const [modalFilters, setModalFilters] = useState(null);
  const hideModal = () => {
    setModal(null);
  };

  useEffect(() => {
    if (dataCompare) {
      setDataCompare(null);
    }
  }, [compareUser]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputSearchRef.current &&
        !inputSearchRef.current.contains(event.target)
      ) {
        setAllUser([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Limpieza del evento al desmontar el componente
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputSearchRef]);

  const compare = () => {
    if (userDetail && compareUser) {
      let formData = new FormData();
      formData.append("user1", userDetail.user_id);
      formData.append("user2", compareUser.user_id);
      instance
        .post(`memberships/corp/company/members/vs`, formData)
        .then((res) => res.data)
        .then((data) => {
          console.log(data, "---------");
          setDataCompare(data);
          setLoading(false);
        })
        .catch((err) => console.error(err));
    } else {
      showError("Debes seleccionar al menos dos usuarios para comparar");
    }
  };
  const showNoDataText = () => {
    return (
      <div
        className="d-flex justify-content-center align-items-center flex-column"
        style={{ height: "calc(100% - 10rem)" }}
      >
        <img
          alt="logo_alta_black_2"
          style={{ padding: "0% 20%" }}
          src={
            process.env.PUBLIC_URL + "/assets/img/logo_small_elevva_metrics.png"
          }
        />
        <br></br>
        <div style={{ maxWidth: "50%" }} className="text-center mx-auto">
          <span
            style={{ textAlign: "center", fontSize: "calc( 0.8rem + 0.5vw )" }}
          >
            No cuentas con información suficiente para visualizar
          </span>
        </div>
        <ButtonMob
          handleClick={createRecord}
          colorButton={"var(--elevvabutonLogin_User)"}
          text="Agregar record"
          className={"px-2 letraspopfi2actualizar"}
        />
      </div>
    );
  };

  function showError(errorMessage) {
    setModal(false);

    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalError(
      <div
        className="popUpNewUser justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "10%",
          }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "1%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModalError(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div
            className=" mb-3 py-3"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            <div
              className=" pt-3 my-4"
              style={{
                lineHeight: "100%",
                textAlign: "center",

                color: "var(--elevvabutonLogin_User)",
              }}
            >
              {errorMessage ? (
                errorMessage
              ) : (
                <>
                  {" "}
                  Ha ocurrido un error
                  <strong> intenta de nuevo</strong>
                  <strong> más tarde</strong>
                </>
              )}
            </div>
            <ButtonMob
              handleClick={() => setModalError(null)}
              colorButton={"var(--elevvabutonLogin_User)"}
              text={"Continuar"}
              className={"mb-3 mt-3 letraspopfi2actualizar"}
            />
          </div>
        </div>
      </div>
    );
  }
  function handlerFilterByTag(idTag) {
    instance
      .get(`memberships/corp/company/members?active=True&tags=${idTag}`)
      .then((res) => res.data)
      .then((data) => {
        setUserDetail(data);
      })
      .catch((err) => console.error(err));
  }
  useEffect(() => {
    if (filterByTag) {
      handlerFilterByTag(filterByTag);
    }
    setModalFilters(null);
  }, [filterByTag]);

  function getUser() {
    instance
      .get(`memberships/corp/company/members/${id}`)
      .then((res) => res.data)
      .then((data) => {
        setUserDetail(data);

        setLoading(false);
      })
      .catch((err) => console.error(err));
  }
  function getExercises() {
    instance
      .get(`trainings/corp/exercises`)
      .then((res) => res.data)
      .then((data) => {
        data && data.length > 0 && setLogs(data);
      })
      .catch((err) => console.error(err));
  }

  function getTags() {
    instance
      .get("memberships/corp/tags")
      .then((res) => res.data)
      .then((data) => {
        setTags(data.results);
      })
      .catch((err) => console.error(err));
  }
  function getUsers() {
    let filterTag = filterByTag ? `&tags=${filterByTag}` : "";
    instance
      .get(`memberships/corp/company/members?active=True${filterTag}`)
      .then((res) => res.data)
      .then((data) => {
        setAllUser(data);
      })
      .catch((err) => console.error(err));
  }
  useEffect(() => {
    getUser();
    getExercises();
    getTags();
    // getUsers();
  }, [id]);
  function filtersUser() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalFilters(
      <div
        className="popUpFiltersInPr justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
          }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "1%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModalFilters(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div
            className=" mb-3 p-3"
            style={{
              lineHeight: "120%",
              textAlign: "center",

              minWidth: "calc(18rem + 1vmin)",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            <PerfectScrollbar style={{ maxHeight: "15rem", padding: "0 1rem" }}>
              {tags.map((tag, i) => (
                <div
                  onClick={() => setFilterByTag(tag.id)}
                  className="d-flex py-2 cursor-pointer"
                  key={i}
                  style={{
                    borderBottom: "1px solid #9DACBD",
                    justifyContent: "flex-start",
                    minWidth: "10rem",
                    fontSize: "calc(.4rem + .4vw)",
                  }}
                >
                  {tag.name}
                </div>
              ))}
            </PerfectScrollbar>
            <div className="d-flex w-100 mx-auto mt-3 jusfy-content-center">
              {" "}
              <button
                style={{ padding: "0.6rem 1rem", lineHeight: "1" }}
                className="  d-flex add_location "
                onClick={() => setFilterByTag(null)}
              >
                Borrar filtros
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  async function changeSearchCustomer(valuesearch) {
    if (valuesearch.length > 0 || valuesearch == "") {
      setCompareUser(null);
      setValueSearch(valuesearch);
      await instance
        .get(
          `memberships/corp/company/members?search=${valuesearch}&active=true`
        )
        .then((res) => res.data)
        .then((data) => {
          setAllUser(data);
          setLoading(false);
        })
        .catch((err) => console.error(err));
    } else {
      setAllUser([]);
    }
  }
  function createNewRecord(event, state) {
    let name = event.target[0].value;
    let unit_measurement = event.target[1].value;

    var formData = new FormData();
    formData.append("name", name.trim());
    formData.append("summary", name.trim());
    formData.append("unit_measurement", unit_measurement.trim());
    if (state === "post") {
      instance
        .post(`trainings/corp/exercises/create`, formData)
        .then((res) => {
          if (res.data) {
            let styleModal = {
              display: "flex",
              position: "fixed",
              zIndex: 999,
              whiteSpace: "pre-line",
            };
            getExercises();
            setModal(
              <div
                className="popUpNewUser justify-content-center align-items-center"
                style={styleModal}
              >
                <div
                  className="modalInfoContenido"
                  style={{
                    background: "white",
                    borderRadius: "20px",
                    boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
                    padding: "2% 6%",
                  }}
                >
                  <div
                    className=""
                    style={{
                      position: "absolute",
                      top: "5%",
                      right: "1%",
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      color: " #9DACBD",
                      cursor: "pointer",
                    }}
                    onClick={() => setModal(null)}
                  >
                    <i className="bi bi-x-circle"></i>
                  </div>
                  <div
                    className=" mb-3 py-3"
                    style={{
                      lineHeight: "120%",
                      textAlign: "center",

                      minWidth: "calc(18rem + 1vmin)",

                      color: "var(--elevvabutonLogin_User)",
                    }}
                  >
                    <br />
                    El record
                    <br />
                    <strong> sido agregado</strong>
                    <br />
                    <strong>correctamente.</strong>
                    <br />
                  </div>
                  <form id="form-password-change" onSubmit={hideModal}>
                    <div className="col-12">
                      <div className="col-12 btnAction_pruebas letraspopfi2">
                        <ButtonMob
                          colorButton={"var(--elevvabutonLogin_User)"}
                          text="Confirmar"
                          className={"mb-3 mt-3 letraspopfi2actualizar"}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            );
          }
        })
        .catch((err) => {
          let msg_error = err.response.data;
          if (msg_error.unit_measurement) {
            showError(msg_error.unit_measurement);
          } else {
            if (msg_error) {
              showError();
            }
          }
        });
    } else {
      instance
        .patch(`trainings/corp/exercises/${exerciseSelected}`, formData)
        .then((res) => {
          if (res.data) {
            let styleModal = {
              display: "flex",
              position: "fixed",
              zIndex: 999,
              whiteSpace: "pre-line",
            };
            getExercises();
            setModal(
              <div
                className="popUpNewUser justify-content-center align-items-center"
                style={styleModal}
              >
                <div
                  className="modalInfoContenido"
                  style={{
                    background: "white",
                    borderRadius: "20px",
                    boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
                    padding: "2% 6%",
                  }}
                >
                  <div
                    className=""
                    style={{
                      position: "absolute",
                      top: "5%",
                      right: "1%",
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      color: " #9DACBD",
                      cursor: "pointer",
                    }}
                    onClick={() => setModal(null)}
                  >
                    <i className="bi bi-x-circle"></i>
                  </div>
                  <div
                    className=" mb-3 py-3"
                    style={{
                      lineHeight: "120%",
                      textAlign: "center",

                      minWidth: "calc(18rem + 1vmin)",

                      color: "var(--elevvabutonLogin_User)",
                    }}
                  >
                    <br />
                    El record
                    <br />
                    <strong> sido editado</strong>
                    <br />
                    <strong>correctamente.</strong>
                    <br />
                  </div>
                  <form id="form-password-change" onSubmit={hideModal}>
                    <div className="col-12">
                      <div className="col-12 btnAction_pruebas letraspopfi2">
                        <ButtonMob
                          colorButton={"var(--elevvabutonLogin_User)"}
                          text="Confirmar"
                          className={"mb-3 mt-3 letraspopfi2actualizar"}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            );
          }
        })
        .catch((err) => {
          let msg_error = err.response.data;
          if (msg_error.unit_measurement) {
            showError(msg_error.unit_measurement);
          } else {
            if (msg_error) {
              showError();
            }
          }
        });
    }

    event.preventDefault();
  }

  function modifyRecord() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    if (!exerciseSelected) {
      showError("No se ha seleccionado un ejercicio");
    } else {
      setModal(
        <div
          className="popUpNewUser justify-content-center align-items-center"
          style={styleModal}
        >
          <div
            className="modalInfoContenido"
            style={{
              background: "white",
              borderRadius: "20px",
              boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
              padding: "2% 6%",
            }}
          >
            <div
              className=""
              style={{
                position: "absolute",
                top: "5%",
                right: "1%",
                fontWeight: "bold",
                fontSize: "1.5rem",
                color: " #9DACBD",
                cursor: "pointer",
              }}
              onClick={() => setModal(null)}
            >
              <i className="bi bi-x-circle"></i>
            </div>
            <div
              className=" mb-3 py-3"
              style={{
                lineHeight: "100%",
                textAlign: "center",

                color: "var(--elevvabutonLogin_User)",
              }}
            >
              Modificar <strong>Record</strong>
            </div>
            <form
              id="form-new-record"
              onSubmit={(e) => {
                createNewRecord(e, "patch");
              }}
            >
              <input
                className="form-control input-style-elevva "
                style={{
                  minWidth: "calc(18rem + 1vmin)",
                  fontSize: "calc(.4rem + .4vw)",
                }}
                name="tester"
                placeholder="Describe nuevo record"
                required
              />
              <br />
              <input
                className="form-control input-style-elevva "
                style={{
                  minWidth: "calc(18rem + 1vmin)",
                  fontSize: "calc(.4rem + .4vw)",
                }}
                name="tester"
                placeholder="Unidad de medida (Kg, reps, m/s)"
                required
              />

              <div className="col-12">
                <div className="col-12 btnAction_pruebas letraspopfi2">
                  <ButtonMob
                    colorButton={"var(--elevvabutonLogin_User)"}
                    text="Confirmar"
                    className={"mb-3 mt-3 letraspopfi2actualizar"}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    }
  }

  function createRecord() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModal(
      <div
        className="popUpNewUser justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
          }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "1%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModal(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div
            className=" mb-3 py-3"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            Nuevo <strong>Record</strong>
          </div>
          <form
            id="form-new-record"
            onSubmit={(e) => {
              createNewRecord(e, "post");
            }}
          >
            <input
              className="form-control input-style-elevva "
              style={{
                minWidth: "calc(18rem + 1vmin)",
                fontSize: "calc(.4rem + .4vw)",
              }}
              name="tester"
              placeholder="Describe nuevo record"
              required
            />
            <br />
            <input
              className="form-control input-style-elevva "
              style={{
                minWidth: "calc(18rem + 1vmin)",
                fontSize: "calc(.4rem + .4vw)",
              }}
              name="tester"
              placeholder="Unidad de medida (Kg, reps, m/s)"
              required
            />

            <div className="col-12">
              <div className="col-12 btnAction_pruebas letraspopfi2">
                <ButtonMob
                  colorButton={"var(--elevvabutonLogin_User)"}
                  text="Confirmar"
                  className={"mb-3 mt-3 letraspopfi2actualizar"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        flexGrow: "1",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {modal}
      {modalFilters}
      {modalError}
      <div
        className="d-flex position-relative"
        style={{
          height: "60px",
          background: "var(--backgroundOnboarding)",
          columnGap: "2rem",
        }}
      >
        <div className="logo-supremacy">
          <img style={{ width: "6rem" }} src={LogoSupremacyBig}></img>
        </div>
      </div>
      <div
        style={{
          flexGrow: "1",
          overflow: "auto",
          columnGap: "calc(.5rem + .5vw)",
        }}
        className="d-flex py-2 px-3"
      >
        <div
          style={{
            width: "53%",
            maxWidth: "60%",
            border: "1px solid #B6C1CE",
            borderRadius: "10px",
            height: "100%",
            padding: "1rem 0.6rem",
          }}
        >
          <div className="d-flex justify-content-between px-3">
            <div
              className="d-flex justify-content-between align-items-center gap-3"
              style={{ width: "30%" }}
            >
              <UserDetail userDetail={userDetail} />
            </div>
            {logs.length > 0 && (
              <div
                className="d-flex justify-content-between align-items-center gap-3"
                style={{ width: "70%" }}
              >
                {" "}
                <ButtonMob
                  handleClick={createRecord}
                  colorButton={"var(--elevvabutonLogin_User)"}
                  text="Agregar nuevo record"
                  className={"px-0 letras-button-users"}
                />
                <ButtonMob
                  handleClick={modifyRecord}
                  colorButton={"var(--elevvabutonLogin_User)"}
                  text="Modificar record"
                  className={"px-0 letras-button-users"}
                />
                <ButtonMob
                  colorButton={"var(--elevvabutonLogin_User)"}
                  text="Eliminar record"
                  className={"px-0 letras-button-users"}
                />
              </div>
            )}
          </div>
          <PerfectScrollbar
            className="px-2"
            style={{
              maxHeight: "calc(100vh - 60px - 12rem)",
              overflowY: "scroll",
              marginTop: "1rem",
            }}
          >
            {logs.length > 0
              ? logs.map((e, i) => (
                  <div
                    key={i + "-log"}
                    className="d-flex justify-content-start align-items-center gap-3 px-3 py-3"
                    style={{
                      width: "100%",
                      borderBottom: "1px solid #B6C1CE",
                    }}
                  >
                    <div
                      style={{ width: "20%" }}
                      className="d-flex justify-content-start align-items-center gap-3"
                    >
                      {" "}
                      <input
                        name="logsList"
                        type="radio"
                        onChange={() => {
                          setExerciseSelected(e.id);
                        }}
                      ></input>{" "}
                      <span
                        style={{
                          display: "inline-block",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {" "}
                        {e.name}
                      </span>
                    </div>{" "}
                    <ExerciseData exerciseId={e.id} userId={id} />
                  </div>
                ))
              : showNoDataText()}
          </PerfectScrollbar>
        </div>
        <div
          style={{
            width: "47%",
            minWidth: "40%",
            border: "1px solid #B6C1CE",
            borderRadius: "10px",
            height: "100%",
            padding: "1rem 0.6rem",
          }}
        >
          <div className="relative px-3" style={{ color: "#394F65" }}>
            <strong>Comparar atletas</strong>
            <div className="d-flex  justify-content-between w-100 gap-4">
              {" "}
              <div
                className="d-flex my-0  align-items-center gap-1"
                style={{ position: "relative", width: "70%" }}
              >
                <div
                  className="py-3"
                  style={{
                    position: "relative",
                    marginLeft: "auto",
                    width: "100%",
                  }}
                >
                  <input
                    onClick={getUsers}
                    placeholder="Buscar usuario"
                    type="search"
                    className="searchAtletas py-1  w-100"
                    style={{
                      border: "1px solid var(--elevvaTextSubtitle)",
                      borderRadius: "20px",
                    }}
                    value={
                      compareUser?.full_name
                        ? compareUser?.full_name
                        : valueSearch
                    }
                    onChange={(e) => changeSearchCustomer(e.target.value)}
                  ></input>
                  <span className="search-icon-admin">
                    <SearchIconAlone fontSize={10} />
                  </span>
                </div>
                {allUser.results && allUser.results.length > 0 && (
                  <div
                    ref={inputSearchRef}
                    className=" w-100 p-3"
                    style={{
                      borderRadius: "20px",
                      border: "1px solid var(--elevvaTextSubtitle)",
                      zIndex: 1,
                      overflow: "hidden",
                      position: "absolute",
                      top: "4rem",
                      backgroundColor: "white",
                    }}
                  >
                    <PerfectScrollbar
                      style={{ maxHeight: "calc(10rem)" }}
                      className="d-flex flex-column "
                    >
                      {allUser.results.map(
                        (user, i) =>
                          user.user_id !== userDetail?.user_id && (
                            <div
                              key={i}
                              className="d-flex align-items-center py-2 gap-3"
                              style={{ borderBottom: "1px solid #B6C1CE" }}
                            >
                              {" "}
                              <input
                                className="cursor-pointer"
                                name="userList"
                                type="radio"
                                onChange={() => {
                                  setCompareUser(user);
                                  setAllUser([]);
                                }}
                              ></input>{" "}
                              <span className="size-table-users">
                                {user.full_name}
                              </span>
                            </div>
                          )
                      )}
                    </PerfectScrollbar>
                  </div>
                )}
                <div style={{ width: "10%" }}>
                  <FiltersTable
                    className="cursor-pointer"
                    onClick={filtersUser}
                  />
                </div>
              </div>
              <div className="my-auto" style={{ width: "30%" }}>
                <div style={{ maxWidth: "90%" }} className="d-flex">
                  <ButtonMob
                    handleClick={compare}
                    text="Comparar atletas"
                    colorButton={"var(--elevvabutonLogin_User)"}
                    className={"px-0 letras-button-users buttonCompare my-0"}
                  />
                </div>
              </div>
            </div>
          </div>
          {dataCompare && dataCompare.length > 0 ? (
            <>
              {" "}
              <div className="d-flex justify-content-between gap-5">
                <div style={{ width: "50%" }}>
                  <UserDetailCompare userDetail={userDetail} />
                </div>
                <div className="mt-auto">
                  <span>VS</span>
                </div>
                <div style={{ width: "50%" }}>
                  <UserDetailCompare userDetail={compareUser} />
                </div>
              </div>
              <PerfectScrollbar
                className="px-4"
                style={{ maxHeight: "calc(100% - 20rem)" }}
              >
                {dataCompare &&
                  dataCompare.length > 0 &&
                  dataCompare.map((e, i) => (
                    <div
                      key={i}
                      className="d-flex justify-content-between gap-3 align-items-center p-2"
                    >
                      <div style={{ width: "40%" }}>
                        <div className="d-flex justify-content-end gap-2 ">
                          {" "}
                          <span className="letras-button-users me-3">
                            {e.user1_metric}
                            {e.measurement}
                          </span>
                        </div>
                        <div style={{ position: "relative" }}>
                          <div className="d-flex justify-content-between gap-2 pt-2">
                            <div className="w-100">
                              {" "}
                              <ProgressBar
                                now={e.user1_metric > e.user2_metric ? 100 : 0}
                                max={100}
                                className="my-custom-bar my-custom-bar-left"
                              ></ProgressBar>
                            </div>
                            <div className="w-100">
                              {" "}
                              <ProgressBar
                                now={
                                  e.user1_metric > e.user2_metric
                                    ? 100
                                    : e.user1_metric == e.user2_metric
                                    ? 100
                                    : 0
                                }
                                max={100}
                                className="my-custom-bar my-custom-bar-left"
                              ></ProgressBar>
                            </div>
                            <div className="w-100" style={{ zIndex: 3 }}>
                              {" "}
                              <ProgressBar
                                now={100}
                                max={100}
                                className="my-custom-bar my-custom-bar-left"
                              ></ProgressBar>
                            </div>
                          </div>
                          <div
                            className="mt-1"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                width: "8px",
                                height: "8px",
                                borderRadius: "100%",
                                background: "#394F65",
                              }}
                            ></div>
                            <div
                              className="flex-1"
                              style={{
                                height: "2px",
                                width: "100%",
                                background: "#394F65",
                                zIndex: 3,
                              }}
                            ></div>
                          </div>
                          <div className=" background-mask-left"></div>
                          <div className="triangulo-left"></div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "20%",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            display: "inline-block",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          className="letras-button-users mt-3"
                        >
                          {e.exercise}
                        </span>
                      </div>
                      <div style={{ width: "40%" }}>
                        <div className="d-flex justify-content-start gap-2">
                          {" "}
                          <span
                            className="ms-3 letras-button-users"
                            style={{ zIndex: 5 }}
                          >
                            {e.user2_metric}
                            {e.measurement}
                          </span>{" "}
                        </div>
                        <div style={{ position: "relative" }}>
                          <div className="d-flex justify-content-between gap-2 pt-2">
                            <div className="w-100" style={{ zIndex: 3 }}>
                              <ProgressBar
                                now={100}
                                max={100}
                                className="my-custom-bar my-custom-bar-right"
                              ></ProgressBar>
                            </div>
                            <div className="w-100">
                              {" "}
                              <ProgressBar
                                now={
                                  e.user2_metric > e.user1_metric
                                    ? 100
                                    : e.user2_metric == e.user1_metric
                                    ? 100
                                    : 0
                                }
                                max={100}
                                className="my-custom-bar my-custom-bar-right"
                              ></ProgressBar>
                            </div>
                            <div className="w-100">
                              {" "}
                              <ProgressBar
                                now={e.user2_metric > e.user1_metric ? 100 : 0}
                                max={100}
                                className="my-custom-bar my-custom-bar-right"
                              ></ProgressBar>
                            </div>
                          </div>
                          <div
                            className="mt-1"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              className="flex-1"
                              style={{
                                height: "2px",
                                width: "100%",
                                background: "#394F65",
                                zIndex: 3,
                              }}
                            ></div>
                            <div
                              style={{
                                width: "8px",
                                height: "8px",
                                borderRadius: "100%",
                                background: "#394F65",
                              }}
                            ></div>
                          </div>
                          <div className=" background-mask"></div>
                          <div className="triangulo"></div>
                        </div>
                      </div>
                    </div>
                  ))}
              </PerfectScrollbar>
            </>
          ) : (
            showNoDataText()
          )}
        </div>
      </div>
    </div>
  );
};

export default PR;
