import { ButtonMob, instance, laptop1600 } from "../../utils/utils";
import { ReactComponent as EditIcon } from "../../assets/elevva-pro-icons/edit-icon.svg";
import { ReactComponent as EditIconWhite } from "../../assets/elevva-pro-icons/edit-icon-white.svg";
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";

const ComponentCompany = (props) => {
  const fileInputRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [modal, setModal] = useState(null);
  const [confirm, setModalConfirm] = useState(null);
  const [ErroraGregado, seterroraGregado] = useState(null);
  const [displayCorrect, setDisplayCorrect] = useState(null);
  const [detailCompany, setDetailCompany] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (displayCorrect) handlePasswordUpdate();
  }, [displayCorrect]);
  const hideModal = () => {
    setModal(null);
  };
  function Change_password(event) {
    let change1 = event.target[0].value;
    let change2 = event.target[1].value;

    var formData = new FormData();
    formData.append("password", change1.trim()); //pass1
    formData.append("password2", change2.trim()); //pass1

    instance
      .post(`users/auth/change-password/`, formData)
      .then((res) => {
        if (res.data) {
          setModalConfirm(null);
          let styleModal = {
            display: "flex",
            position: "fixed",
            zIndex: 999,
            whiteSpace: "pre-line",
          };
          setModal(
            <div
              className="popUpPassword justify-content-center align-items-center"
              style={styleModal}
            >
              <div
                className="modalInfoContenido"
                style={{
                  background: "white",
                  borderRadius: "20px",
                  boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
                  padding: "2% 6%",
                }}
              >
                <div
                  className=""
                  style={{
                    position: "absolute",
                    top: "5%",
                    right: "1%",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    color: " #9DACBD",
                    cursor: "pointer",
                  }}
                  onClick={() => setModal(null)}
                >
                  <i className="bi bi-x-circle"></i>
                </div>
                <div
                  className=" mb-3 py-3"
                  style={{
                    lineHeight: "100%",
                    textAlign: "center",

                    minWidth: "calc(18rem + 1vmin)",

                    color: "var(--elevvabutonLogin_User)",
                  }}
                >
                  <br />
                  Tu contraseña ha <br /> sido modificada <br /> correctamente.
                  <br />
                </div>
                <form id="form-password-change" onSubmit={hideModal}>
                  <div className="col-12">
                    <div className="col-12 btnAction_pruebas letraspopfi2">
                      <ButtonMob
                        colorButton={"var(--elevvabutonLogin_User)"}
                        text="Confirmar"
                        className={"mb-3 mt-3 letraspopfi2actualizar"}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          );
        }
      })
      .catch((err) => {
        let msg_error = err.response.data;
        if (msg_error) {
          console.log(msg_error);
          seterroraGregado(msg_error);

          setDisplayCorrect("flex");
          setTimeout(() => {
            setDisplayCorrect("none");
          }, 5000);
        }
      });

    event.preventDefault();
  }
  function handlePasswordUpdate() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModal(
      <div
        className="popUpPassword justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
          }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "1%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModal(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div
            className=" mb-3 py-3"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            Cambia <strong>tu contraseña</strong>
          </div>
          <form
            id="form-password-change"
            onSubmit={(e) => {
              Change_password(e);
            }}
          >
            {" "}
            <input
              className="form-control input-style-elevva "
              style={{
                minWidth: "calc(18rem + 1vmin)",
                fontSize: "calc(.4rem + .4vw)",
              }}
              name="tester"
              placeholder="Ingresa tu nueva contraseña"
              required
            />
            <br />
            <input
              className="form-control input-style-elevva "
              style={{
                minWidth: "calc(18rem + 1vmin)",
                fontSize: "calc(.4rem + .4vw)",
              }}
              name="tester"
              placeholder="Confirma tu nueva contraseña"
              required
            />
            <div
              id="passmail_error2"
              className=""
              style={{
                display: `${displayCorrect}`,
                color: "var(--elevva)",
                maxWidth: "calc(18rem + 1vmin)",
                fontSize: "calc(.3rem + .3vw)",
              }}
            >
              {ErroraGregado ? ErroraGregado.password : null}
            </div>
            <div className="col-12">
              <div className="col-12 btnAction_pruebas letraspopfi2">
                <ButtonMob
                  colorButton={"var(--elevvabutonLogin_User)"}
                  text="Confirmar"
                  className={"mb-3 mt-3 letraspopfi2actualizar"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
  function postImage() {
    let formData = new FormData();
    formData.append("logo", fileInputRef.current.files[0]);
    formData.append("company_id", detailCompany.id);
    instance
      .post(`memberships/corp/company/update`, formData)
      .then((res) => res.data)
      .then((data) => {
        console.log(data, "data");
      })
      .catch((err) => console.error(err));
  }
  const handleImageUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
      postImage();
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Click en el input file
    }
  };

  function getCompanyDetail() {
    setLoading(true);
    instance
      .get(`memberships/corp/company`)
      .then((res) => res.data)
      .then((data) => {
        setDetailCompany(data);
        setImageUrl(data.logo);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }
  useEffect(() => {
    getCompanyDetail();
  }, []);
  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <div className="d-flex flex-column gap-2 h-100 justify-content-center ">
      {/* logo */}
      {modal}
      {loading ? (
        <div className="d-flex justify-content-center position-relative">
          <Spinner />
        </div>
      ) : (
        <div className="d-flex justify-content-center position-relative">
          <img
            src={imageUrl}
            alt="Avatar"
            onClick={handleClick}
            style={{
              cursor: "pointer",
              width: laptop1600.matches
                ? "calc(11rem + .5vw)"
                : "calc(8rem + .5vw)",
              height: laptop1600.matches
                ? "calc(11rem + .5vw)"
                : "calc(8rem + .5vw)",
              maxWidth: laptop1600.matches
                ? "calc(11rem + .5vw)"
                : "calc(8rem + .5vw)",
              maxHeight: laptop1600.matches
                ? "calc(11rem + .5vw)"
                : "calc(8rem + .5vw)",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            style={{ display: "none" }} // Oculta el input
            onChange={handleImageUpload}
          />
          <div
            className="d-flex position-absolute p-2"
            style={{
              width: "auto",
              height: "auto",
              maxWidth: "calc(2rem + .5vw)",
              maxHeight: "calc(2rem + .5vw)",
              bottom: 0,
              left: "60%",
              borderRadius: "100%",
              background: "#B5C1CD",
              cursor: "pointer",
            }}
          >
            <EditIconWhite
              width={"100%"}
              height={"100%"}
              onClick={handleIconClick}
            />
          </div>
        </div>
      )}
      {/* name */}
      <div className={laptop1600.matches ? "my-5" : "my-4"}>
        <div className="">
          <div
            className="d-flex w-100  align-items-center px-2"
            style={{ justifyContent: "space-between" }}
          >
            {" "}
            <div className="d-flex" style={{ width: "90%" }}>
              {" "}
              {loading ? (
                <Spinner />
              ) : (
                <span
                  style={{
                    fontSize: laptop1600.matches
                      ? "calc(1.3rem + .5vw)"
                      : "calc(0.9rem + .5vw)",
                    fontWeight: "bold",
                    border: "none",
                    color: "var(--elevvaTextTitle)",
                    lineHeight: "1",
                  }}
                >
                  {detailCompany?.name || "----"}
                </span>
              )}
            </div>
            <div
              className="d-flex"
              style={{
                width: "10%",
                marginTop: "auto",
              }}
            ></div>
          </div>
          <hr style={{ margin: "0.5rem 0" }}></hr>
        </div>
        {/* nit  */}
        <div className="">
          <div
            className="d-flex w-100 justify-content-around align-items-center px-2"
            style={{ justifyContent: "space-between" }}
          >
            {" "}
            <div className="d-flex flex-column" style={{ width: "90%" }}>
              {" "}
              <label
                className="letraPequeña"
                style={{
                  fontWeight: "bold",

                  color: "var(--elevvaTextTitle)",
                  lineHeight: "1",
                }}
              >
                CC / Nit
              </label>
              {loading ? (
                <Spinner />
              ) : (
                <input
                  disabled
                  className="letraPequeña"
                  style={{
                    border: "none",
                    color: "var(--elevvaTextTitle)",
                    lineHeight: "1",
                  }}
                  rows={2}
                  defaultValue={detailCompany?.nit || "----"}
                />
              )}
            </div>
            <div
              className="d-flex"
              style={{
                width: "10%",
                marginTop: "auto",
                marginRight: "auto",
              }}
            ></div>
          </div>
          <hr style={{ margin: "0.5rem 0" }}></hr>
        </div>

        {/* address  */}
        <div className="">
          <div
            className="d-flex w-100 justify-content-around align-items-center px-2"
            style={{ justifyContent: "space-between" }}
          >
            {" "}
            <div className="d-flex flex-column" style={{ width: "90%" }}>
              {" "}
              <label
                className="letraPequeña"
                style={{
                  fontWeight: "bold",

                  color: "var(--elevvaTextTitle)",
                  lineHeight: "1",
                }}
              >
                Dirección
              </label>
              {loading ? (
                <Spinner />
              ) : (
                <input
                  disabled
                  className="letraPequeña"
                  style={{
                    border: "none",
                    color: "var(--elevvaTextTitle)",
                    lineHeight: "1",
                  }}
                  rows={2}
                  defaultValue={detailCompany?.address || "----"}
                />
              )}
            </div>
            <div
              className="d-flex"
              style={{
                width: "10%",
                marginTop: "auto",
                marginRight: "auto",
              }}
            ></div>
          </div>
          <hr style={{ margin: "0.5rem 0" }}></hr>
        </div>
        {/* city  */}
        <div className="">
          <div
            className="d-flex w-100 justify-content-around align-items-center px-2"
            style={{ justifyContent: "space-between" }}
          >
            {" "}
            <div className="d-flex flex-column" style={{ width: "90%" }}>
              {" "}
              <label
                className="letraPequeña"
                style={{
                  fontWeight: "bold",

                  color: "var(--elevvaTextTitle)",
                  lineHeight: "1",
                }}
              >
                Ciudad
              </label>
              {loading ? (
                <Spinner />
              ) : (
                <input
                  disabled
                  className="letraPequeña"
                  style={{
                    border: "none",
                    color: "var(--elevvaTextTitle)",
                    lineHeight: "1",
                  }}
                  rows={2}
                  defaultValue={detailCompany?.city || "----"}
                />
              )}
            </div>
            <div
              className="d-flex"
              style={{
                width: "10%",
                marginTop: "auto",
                marginRight: "auto",
              }}
            ></div>
          </div>
          <hr style={{ margin: "0.5rem 0" }}></hr>
        </div>

        {/* contact  */}
        <div className="">
          <div
            className="d-flex w-100 justify-content-around align-items-center px-2"
            style={{ justifyContent: "space-between" }}
          >
            {" "}
            <div className="d-flex flex-column" style={{ width: "90%" }}>
              {" "}
              <label
                className="letraPequeña"
                style={{
                  fontWeight: "bold",

                  color: "var(--elevvaTextTitle)",
                  lineHeight: "1",
                }}
              >
                Número contacto
              </label>
              {loading ? (
                <Spinner />
              ) : (
                <input
                  disabled
                  className="letraPequeña"
                  style={{
                    border: "none",
                    color: "var(--elevvaTextTitle)",
                    lineHeight: "1",
                  }}
                  rows={2}
                  defaultValue={detailCompany?.contact_number || "----"}
                />
              )}
            </div>
            <div
              className="d-flex"
              style={{
                width: "10%",
                marginTop: "auto",
                marginRight: "auto",
              }}
            ></div>
          </div>
          <hr style={{ margin: "0.5rem 0" }}></hr>
        </div>
        {/* email  */}
        <div className="">
          <div
            className="d-flex w-100 justify-content-around align-items-center px-2"
            style={{ justifyContent: "space-between" }}
          >
            {" "}
            <div className="d-flex flex-column" style={{ width: "90%" }}>
              {" "}
              <label
                className="letraPequeña"
                style={{
                  fontWeight: "bold",

                  color: "var(--elevvaTextTitle)",
                  lineHeight: "1",
                }}
              >
                Correo
              </label>
              {loading ? (
                <Spinner />
              ) : (
                <input
                  disabled
                  className="letraPequeña"
                  style={{
                    border: "none",
                    color: "var(--elevvaTextTitle)",
                    lineHeight: "1",
                  }}
                  rows={2}
                  defaultValue={detailCompany?.email || "----"}
                />
              )}
            </div>
            <div
              className="d-flex"
              style={{
                width: "10%",
                marginTop: "auto",
                marginRight: "auto",
              }}
            ></div>
          </div>
          <hr style={{ margin: "0.5rem 0" }}></hr>
        </div>
      </div>
      <div>
        <button
          style={{ padding: "0.6rem 1rem", lineHeight: "1" }}
          className="  d-flex add_location mx-auto "
          onClick={handlePasswordUpdate}
        >
          Cambiar tu contraseña
        </button>
      </div>
    </div>
  );
};
export default ComponentCompany;
