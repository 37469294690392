import { useState } from "react";
import LogoSupremacyBig from "../../assets/assets/elevva-mora_big.png";
import Clases from "./components/clases";
import Locations from "./components/locations";
import Services from "./components/services";
import { ButtonArrow, ButtonMob, laptop1300 } from "../../utils/utils";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { ReactComponent as ArrowDown } from "../../assets/elevva-pro-icons/arrowDown.svg";
import { DropdownList } from "react-widgets";
import { ReactComponent as Clock } from "../../assets/elevva-pro-icons/clock-service.svg";

const separatorStyle = {
  border: 0,
  borderTop: "1px solid #A8C2D7",
  margin: "0.5rem 0",
};
const Mob = () => {
  const [modalService, setModalService] = useState(null);
  const [modalAddLocation, setModalAddLocation] = useState(null);
  const demo = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    "Domingo",
  ];
  const days = ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"];
  const cities = [
    {
      id: 1,
      name: "Cali",
      state: "Valle",
    },
    {
      id: 2,
      name: "Medellín",
      state: "Antioquia",
    },
    {
      id: 3,
      name: "Barranquilla",
      state: "Atlaña",
    },
  ];
  function addLocation() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalAddLocation(
      <div
        className="popUpServices justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
            maxWidth: "40rem",
            minWidth: "35rem",
          }}
        >
          <div
            className=" mb-3 py-3 d-flex align-items-center"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            <div>
              <ButtonArrow
                classes=" btnBackPr me-1"
                colorBack={`var(--elevvaArrowColor)`}
                handleClick={() => setModalAddLocation(null)}
              />
            </div>{" "}
            <div>
              {" "}
              Registro de <strong>locación</strong>
            </div>
          </div>
          <div
            className="mx-auto"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "30rem",
            }}
          >
            <div className="d-flex px-4">
              <input
                placeholder="Nombre de locación"
                type="search"
                className="searchAtletas  py-2 mx-auto"
                style={{
                  border: "1px solid var(--elevvaTextSubtitle)",
                  borderRadius: "20px",
                  fontSize: "calc(.4rem + .4vw)",
                  width: "100%",
                }}
                onChange={() => {}}
              ></input>
            </div>
            <div className="d-flex px-4 ">
              <input
                placeholder="Dirección"
                type="search"
                className="searchAtletas  py-2 mx-auto"
                style={{
                  border: "1px solid var(--elevvaTextSubtitle)",
                  borderRadius: "20px",
                  fontSize: "calc(.4rem + .4vw)",
                  width: "100%",
                }}
                onChange={() => {}}
              ></input>
            </div>
            <div className="d-flex  justify-content-center w-100 ">
              <DropdownList
                style={{ width: "90%" }}
                busy
                busySpinner={<ArrowDown></ArrowDown>}
                defaultValue={{ id: 2, name: "Medellín", state: "Antioquia" }}
                className={"input-style-elevva-graphics"}
                id="optionSummary"
                placeholder={"Selecciona"}
                data={cities.map((memb) => ({
                  id: memb.id,
                  name: memb.name,
                  valor: memb.state,
                }))}
                dataKey="id"
                textField="name"
                onChange={(value) => {}}
                renderListItem={({ item }) => (
                  <div
                    className="w-100 mb-1"
                    style={{ borderBottom: "1px solid #9DACBD" }}
                  >
                    <div>{item.name}</div>
                  </div>
                )}
              />
            </div>
          </div>

          <div>
            <span>Imagen de locación</span>
          </div>
          <div style={{ display: "flex", justifyContent: "left" }}>
            <ButtonMob
              colorButton={"var(--elevvabutonLogin_User)"}
              text="Seleccionar imagen"
              className={"mb-3 mt-3 letraspopfi2actualizar mx-0"}
            />
          </div>
          <span>Días de atención</span>
          <div
            className="d-flex w-100 flex-column gap-2"
            style={{ justifyContent: "left" }}
          >
            <div className="d-flex align-items-center">
              {" "}
              <input type="checkbox" value={"lunes"}></input>
              <label
                className="ms-2"
                style={{ color: "#9DACBD", fontSize: "calc(.4rem + .4vw)" }}
              >
                Lunes
              </label>
            </div>
            <div className="d-flex align-items-center">
              {" "}
              <input type="checkbox" value={"martes"}></input>
              <label
                className="ms-2"
                style={{ color: "#9DACBD", fontSize: "calc(.4rem + .4vw)" }}
              >
                martes
              </label>
            </div>
            <div className="d-flex align-items-center">
              {" "}
              <input type="checkbox" value={"miércoles"}></input>
              <label
                className="ms-2"
                style={{ color: "#9DACBD", fontSize: "calc(.4rem + .4vw)" }}
              >
                miércoles
              </label>
            </div>
            <div className="d-flex align-items-center">
              {" "}
              <input type="checkbox" value={"jueves"}></input>
              <label
                className="ms-2"
                style={{ color: "#9DACBD", fontSize: "calc(.4rem + .4vw)" }}
              >
                jueves
              </label>
            </div>
            <div className="d-flex align-items-center">
              {" "}
              <input type="checkbox" value={"viernes"}></input>
              <label
                className="ms-2"
                style={{ color: "#9DACBD", fontSize: "calc(.4rem + .4vw)" }}
              >
                viernes
              </label>
            </div>
            <div className="d-flex align-items-center">
              {" "}
              <input type="checkbox" value={"sabado"}></input>
              <label
                className="ms-2"
                style={{ color: "#9DACBD", fontSize: "calc(.4rem + .4vw)" }}
              >
                sábado
              </label>
            </div>
            <div className="d-flex align-items-center">
              {" "}
              <input type="checkbox" value={"domingo"}></input>
              <label
                className="ms-2"
                style={{ color: "#9DACBD", fontSize: "calc(.4rem + .4vw)" }}
              >
                domingo
              </label>
            </div>
          </div>
          <div
            className="my-2 d-flex  flex-column gap-2"
            style={{ maxWidth: "50%" }}
          >
            <input
              type="text"
              placeholder="Hora de apertura"
              style={{ color: "#9DACBD", fontSize: "calc(.4rem + .4vw)" }}
            ></input>
            <input
              type="text"
              placeholder="Hora de cierre"
              style={{ color: "#9DACBD", fontSize: "calc(.4rem + .4vw)" }}
            ></input>
          </div>
          <div className="d-flex w-100 gap-2 mt-3">
            <ButtonMob
              style={{ border: "1px solid var(--elevvabutonLogin_User)" }}
              colorButton={"var(--elevvabutonLogin_User)"}
              text="Confirmar"
              className={"mb-3 mt-3 letraspopfi2actualizar "}
            />
          </div>
        </div>
      </div>
    );
  }
  function addService() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalAddLocation(
      <div
        className="popUpServices justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
            maxWidth: "40rem",
            minWidth: "35rem",
          }}
        >
          <div
            className=" mb-3 py-3 d-flex align-items-center"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            <div>
              <ButtonArrow
                classes=" btnBackPr me-1"
                colorBack={`var(--elevvaArrowColor)`}
                handleClick={() => setModalAddLocation(null)}
              />
            </div>{" "}
            <div>
              {" "}
              Registro de <strong>servicio</strong>
            </div>
          </div>
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "20rem",
            }}
          >
            <div className="d-flex ">
              <input
                placeholder="Nombre del servicio"
                type="search"
                className="searchAtletas  py-2 "
                style={{
                  border: "1px solid var(--elevvaTextSubtitle)",
                  borderRadius: "20px",
                  fontSize: "calc(.4rem + .4vw)",
                  width: "100%",
                }}
                onChange={() => {}}
              ></input>
            </div>

            <div className="d-flex w-100 ">
              <DropdownList
                style={{ width: "90%" }}
                busy
                busySpinner={<ArrowDown></ArrowDown>}
                defaultValue={{ id: 2, name: "Medellín", state: "Antioquia" }}
                className={"input-style-elevva-graphics"}
                id="optionSummary"
                placeholder={"Selecciona"}
                data={cities.map((memb) => ({
                  id: memb.id,
                  name: memb.name,
                  valor: memb.state,
                }))}
                dataKey="id"
                textField="name"
                onChange={(value) => {}}
                renderListItem={({ item }) => (
                  <div
                    className="w-100 mb-1"
                    style={{ borderBottom: "1px solid #9DACBD" }}
                  >
                    <div>{item.name}</div>
                  </div>
                )}
              />
            </div>
          </div>
          <div
            className="my-2 d-flex  flex-column gap-2"
            style={{ maxWidth: "50%" }}
          >
            <input
              type="text"
              placeholder="Duración"
              style={{ color: "#9DACBD", fontSize: "calc(.4rem + .4vw)" }}
            ></input>
            <input
              type="text"
              placeholder="Cupo"
              style={{ color: "#9DACBD", fontSize: "calc(.4rem + .4vw)" }}
            ></input>
          </div>
          <div>
            <span>Horario</span>
          </div>
          <hr style={separatorStyle} />
          <div className="d-flex">
            <div className="d-flex justify-content-between mx-auto gap-3">
              <div className="d-flex flex-column">
                <span>Horario de inicio</span>
                <div>
                  {" "}
                  <input
                    type="text"
                    placeholder=""
                    style={{ color: "#9DACBD", fontSize: "calc(.4rem + .4vw)" }}
                    className="me-2"
                  ></input>
                  <Clock />
                </div>
              </div>
              <div className="d-flex flex-column">
                <span>Horario de finalización</span>
                <div>
                  {" "}
                  <input
                    type="text"
                    placeholder=""
                    style={{ color: "#9DACBD", fontSize: "calc(.4rem + .4vw)" }}
                    className="me-2"
                  ></input>
                  <Clock />
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <div
            className="my-2"
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "0.3rem",
            }}
          >
            {days.map((day, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "#E3E3E3",
                  color: "#9DACBD",
                  fontSize: "calc(.4rem + .4vw)",
                  fontWeight: "500",
                }}
              >
                {day}
              </div>
            ))}
          </div>
          <div className="d-flex align-items-center text-center mx-auto justify-content-center my-2 ">
            {" "}
            <button
              style={{
                background: "var(--elevvabutonLogin_User)",
                color: "#ffff",
                borderRadius: "100%",
              }}
            >
              +
            </button>{" "}
            <span className="ms-2">Agregar horario adicional</span>
          </div>
          <span>
            ¿Deseas agregar este servicio y horarios a otras locaciones?
          </span>
          <div
            className="d-flex w-100 flex-column gap-2"
            style={{ justifyContent: "left" }}
          >
            <div className="d-flex align-items-center">
              {" "}
              <input type="checkbox" value={"Colina"}></input>
              <label
                className="ms-2"
                style={{ color: "#9DACBD", fontSize: "calc(.4rem + .4vw)" }}
              >
                Colina
              </label>
            </div>
            <div className="d-flex align-items-center">
              {" "}
              <input type="checkbox" value={"Polo Club"}></input>
              <label
                className="ms-2"
                style={{ color: "#9DACBD", fontSize: "calc(.4rem + .4vw)" }}
              >
                Polo Club
              </label>
            </div>
            <div className="d-flex align-items-center">
              {" "}
              <input type="checkbox" value={"Suba"}></input>
              <label
                className="ms-2"
                style={{ color: "#9DACBD", fontSize: "calc(.4rem + .4vw)" }}
              >
                Suba
              </label>
            </div>
          </div>

          <div className="d-flex w-100 gap-2 mt-3">
            <ButtonMob
              style={{ border: "1px solid var(--elevvabutonLogin_User)" }}
              colorButton={"var(--elevvabutonLogin_User)"}
              text="Guardar"
              className={"mb-3 mt-3 letraspopfi2actualizar "}
            />
          </div>
        </div>
      </div>
    );
  }
  function modalServiceDetail() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalService(
      <div
        className="popUpServices justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
            maxWidth: "30rem",
          }}
        >
          <div
            className=" mb-3 py-3"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            Resumen de servicio
          </div>
          <PerfectScrollbar style={{ maxHeight: "25rem", padding: "0 1rem" }}>
            {" "}
            {demo.map((e) => (
              <div>
                {" "}
                <div className="text-center">
                  <span style={{ color: "var(--elevvabutonLogin_User)" }}>
                    Funcional / 45 min
                  </span>
                </div>
                <hr style={separatorStyle} />
                <div className="text-center">
                  <span style={{ color: "var(--elevvabutonLogin_User)" }}>
                    Cupos<br></br>20
                  </span>
                </div>
                <hr style={separatorStyle} />
                <div className="d-flex w-100 gap-4">
                  <div>
                    {" "}
                    <div style={{ color: "#ffff" }}>.</div>
                    <div>{e}</div>
                  </div>
                  <div>
                    <div className="text-center">Colina</div>
                    <div style={{ color: "#9DACBD", textAlign: "left" }}>
                      06:00 / 07:00 / 08:00 / 09:00 / 10:00 / 11:00 / 12:00 /
                      13:00 15:00 / 16:00 / 17:00 / 18:00 / 19:00 / 20:00 /
                      21:00
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </PerfectScrollbar>
          <div className="d-flex w-100 gap-2 mt-3">
            <ButtonMob
              style={{ border: "1px solid var(--elevvabutonLogin_User)" }}
              colorButton={"var(--elevvabutonLogin_User)"}
              text="Confirmar"
              className={"mb-3 mt-3 letraspopfi2actualizar "}
            />
            <ButtonMob
              handleClick={() => setModalService(null)}
              colorButton={"transparent"}
              text="Regresar"
              className={"mb-3 mt-3 letraspopfi2actualizar button-service-card"}
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        flexGrow: "1",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {modalService}
      {modalAddLocation}
      <div
        className="d-flex position-relative"
        style={{
          height: "60px",
          background: "var(--backgroundOnboarding)",
          columnGap: "2rem",
        }}
      >
        <div className="logo-supremacy">
          <img style={{ width: "6rem" }} src={LogoSupremacyBig}></img>
        </div>
      </div>
      <div
        style={{
          flexGrow: "1",
        }}
        className="d-flex "
      >
        <div
          style={{
            width: "100%",

            borderRadius: "10px",
            height: "calc(100% - 1.5rem)",
            padding: "3rem 3rem 0rem 3rem",
            flexGrow: "1",
            overflow: "auto",
            columnGap: "calc(.5rem + .5vw)",
          }}
          className="d-flex m-3"
        >
          <div
            style={{
              width: "100%",
              border: "1px solid #B6C1CE",
              borderRadius: "10px",
              height: "calc(100% - 1.5rem)",

              flexGrow: "1",

              columnGap: "calc(.5rem + .5vw)",
              position: "relative",
            }}
            className="d-flex p-3 "
          >
            <div
              style={{
                top: "-34px",
                position: "absolute",
                zIndex: "2",
                borderRadius: "10px",
              }}
            >
              <button
                className="px-3 py-1"
                style={{
                  border: "1px solid #B6C1CE",
                  borderBottom: "2px solid #ffff",
                  borderTopLeftRadius: "13px",
                  borderTopRightRadius: "13px",
                  background: "#ffff",
                  color: "var(--elevvaTextTitle)",
                  fontSize: "calc(.4rem + .4vw)",
                }}
              >
                <strong>Configuración</strong>
              </button>
            </div>
            <div
              style={{
                top: "-34px",
                left: "10rem",
                position: "absolute",
                zIndex: "1",
                borderRadius: "10px",
              }}
            >
              <button
                className="px-3 py-1"
                style={{
                  border: "1px solid #B6C1CE",
                  borderBottom: "2px solid #ffff",
                  borderTopLeftRadius: "13px",
                  borderTopRightRadius: "13px",
                  background: "#ffff",
                  color: "var(--elevvaTextTitle)",
                  fontSize: "calc(.4rem + .4vw)",
                }}
              >
                <strong>Membresías</strong>
              </button>
            </div>
            <div
              style={{
                top: "-34px",
                left: "18.2rem",
                position: "absolute",

                borderRadius: "10px",
              }}
            >
              <button
                className="px-3 py-1"
                style={{
                  border: "1px solid #B6C1CE",
                  borderBottom: "2px solid #ffff",
                  borderTopLeftRadius: "13px",
                  borderTopRightRadius: "13px",
                  background: "#ffff",
                  fontSize: "calc(.4rem + .4vw)",
                  color: "var(--elevvaTextTitle)",
                }}
              >
                <strong>Reservas</strong>
              </button>
            </div>
            <div
              className="d-flex gap-3 flex-column"
              style={{
                width: "60%",

                height: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  border: "1px solid #B6C1CE",
                  borderRadius: "10px",
                  height: "35%",
                }}
              >
                <div className="h-100">
                  <Locations modalAddLocation={addLocation} />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  border: "1px solid #B6C1CE",
                  borderRadius: "10px",
                  height: "65%",
                }}
              >
                <Services
                  modalServiceDetail={modalServiceDetail}
                  addService={addService}
                />
              </div>
            </div>
            <div
              className="d-flex gap-3 flex-column"
              style={{
                width: "40%",

                height: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  border: "1px solid #B6C1CE",
                  borderRadius: "10px",
                  height: "100%",
                }}
              >
                <Clases />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Mob;
