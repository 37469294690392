import TableCustomPlans from "../../../utils/table-custom-plans";
import {
  ButtonArrow,
  ButtonMob,
  instanceMob,
  Loadingspinner,
} from "../../../utils/utils";
import { ReactComponent as SearchIconAlone } from "../../../assets/elevva-pro-icons/searchIconAlone.svg";
import { ReactComponent as ArrowDown } from "../../../assets/elevva-pro-icons/arrowDown.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { ReactComponent as FiltersTable } from "../../../assets/elevva-pro-icons/filters-table.svg";
import { useEffect, useState } from "react";
import changePage from "../functions/changePage";
import debounce from "../functions/debounce";
import ConfirmDeletionModal from "./modal-delete";
import { DropdownList } from "react-widgets";
import PlanServicesComponent from "./plan-services-component";

const MembershipsPlans = () => {
  const [plans, setPlans] = useState([]);
  const [planSelected, setPlanSelected] = useState([]);
  const [countPlan, setCountPlan] = useState(10);
  const [pagePlan, setPagePlan] = useState(1);
  const [loader, setLoader] = useState(false);

  const [pageNextPlan, setPageNextPlan] = useState(null);
  const [pageBackPlan, setPageBackPlan] = useState(null);
  const [dataBackupPlan, setDataBackupPlan] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [ErroraGregado, seterroraGregado] = useState("");
  const [modal, setModal] = useState(null);
  const [loaderDelete, setLoaderDelete] = useState(false);
  const [serviceSelected, setServiceSelected] = useState({});
  const [access, setAccess] = useState({});
  const [formDataServices, setFormDataServices] = useState([
    {
      id: 1,
      service: 0,
      quota: 0,
    },
  ]);
  const [scheduleSelected, setScheduleSelected] = useState(
    formDataServices[0].id
  );
  const [formData, setFormData] = useState({
    name: "",
    id: null,
    total_spots_per_service: 0,
    renewal_cycle_time: 0,
    renewal_cycle_value: 0,
    services: [],
    description: "",
  });
  const update = (setter, serviceId, value) => {
    setter((prev) => ({
      ...prev,
      [serviceId]: value,
    }));
  };
  const handleAdd = (id, replace = false) => {
    console.log(id, access, serviceSelected);
    if (!access[id] || !serviceSelected[id]) {
      return;
    }
    setFormDataServices((prev) => {
      const updatedAvailability = [...prev];
      if (replace) {
        const indexToUpdate = updatedAvailability.findIndex(
          (availability) => availability.id === id
        );
        console.log("indexToUpdate", indexToUpdate);
        if (indexToUpdate === -1) {
          console.error(`No se encontró el ID: ${id}`);
          return prev;
        }
        updatedAvailability[indexToUpdate] = {
          ...updatedAvailability[indexToUpdate],
          service: serviceSelected[id],
          quota: Number(access[id]),
        };
      }
      return updatedAvailability;
    });
  };

  useEffect(() => {
    if (modal) {
      if (formData?.id) {
        let filterPlan = plans.find((item) => item.id === formData?.id);

        addOrEditPlan(filterPlan ? filterPlan : null);
      } else {
        modal && addOrEditPlan();
      }
    }
  }, [ErroraGregado]);

  useEffect(() => {
    if (scheduleSelected && modal) {
      if (formData?.id) {
        let filterPlan = plans.find((item) => item.id === formData?.id);

        addOrEditPlan(filterPlan ? filterPlan : null);
      } else {
        modal && addOrEditPlan();
      }
      scheduleSelected && handleAdd(scheduleSelected, true);
    }
  }, [serviceSelected, scheduleSelected]);

  useEffect(() => {
    if (scheduleSelected && modal) {
      if (formData?.id) {
        let filterPlan = plans.find((item) => item.id === formData?.id);

        addOrEditPlan(filterPlan ? filterPlan : null);
      } else {
        modal && addOrEditPlan();
      }
      scheduleSelected && handleAdd(scheduleSelected, true);
    }
  }, [access, scheduleSelected]);
  function getPlans() {
    setIsLoading(true);
    instanceMob
      .get(`admin/plan`)
      .then((res) => res.data)
      .then((data) => {
        setPlans(data.results);

        setCountPlan(data.count);
        setPageNextPlan(data.next);
        setPageBackPlan(data.previous);
        setDataBackupPlan(data);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }

  function getListFilter(value) {
    instanceMob
      .get(`admin/plan?search=${value}`)
      .then((res) => res.data)
      .then((data) => {
        setPlans(data.results);
        setPageNextPlan(data.next);
        setPageBackPlan(data.previous);
        setCountPlan(data.count);
      })
      .catch((err) => console.error(err));
  }

  function deletePlan(service) {
    const handleDelete = () => {
      setLoaderDelete(true);
      instanceMob
        .delete(`admin/plan/${service.id}`)
        .then((res) => {
          if (res.status == "204" || res.status == "200") {
            setLoaderDelete(false);
            getPlans();
          }
        })
        .catch((err) => {
          let msg_error = err.response.data;
          if (msg_error) {
            seterroraGregado(msg_error);
          }
        });
    };

    setModal(
      service ? (
        <ConfirmDeletionModal
          onCancel={() => setModal(null)}
          onConfirm={handleDelete}
          loader={loaderDelete}
        >
          {/* Inserta cualquier contenido aquí como children */}
          ¿Estas seguro de <br></br>
          <strong>eliminar {service?.name || "el plan"}? </strong>
        </ConfirmDeletionModal>
      ) : (
        <ConfirmDeletionModal
          onCancel={() => setModal(null)}
          onConfirm={() => setModal(null)}
          loader={false}
        >
          <br />
          Plan
          <br />
          <strong> eliminada</strong>
          <br />
          <strong>correctamente.</strong>
          <br />
        </ConfirmDeletionModal>
      )
    );
  }

  function searchPlans(e) {
    let valueLower = e.target.value.toLowerCase();
    if (valueLower == "") {
      setPlans(dataBackupPlan.results);
      setCountPlan(dataBackupPlan.count);
      setPageNextPlan(dataBackupPlan.next);
      setPageBackPlan(dataBackupPlan.previous);
    } else {
      getListFilter(valueLower);
    }
  }

  function getPlansPage(value) {
    let url = value ? value : `admin/plan`;
    setIsLoading(true);
    instanceMob
      .get(url)
      .then((res) => res.data)
      .then((data) => {
        setPlans(data.results);
        setPageNextPlan(data.next);
        setPageBackPlan(data.previous);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    if (formData && modal) {
      if (formData?.id) {
        let filterPlan = plans.find((item) => item.id === formData?.id);

        addOrEditPlan(filterPlan ? filterPlan : null);
      } else {
        modal && addOrEditPlan();
      }
    }
  }, [formDataServices]);
  useEffect(() => {
    if (formData && modal) {
      if (formData?.id) {
        let filterPlan = plans.find((item) => item.id === formData?.id);

        addOrEditPlan(filterPlan ? filterPlan : null);
      } else {
        modal && addOrEditPlan();
      }
    }
  }, [formData]);
  useEffect(() => {
    if (access && modal) {
      if (formData?.id) {
        let filterPlan = plans.find((item) => item.id === formData?.id);

        addOrEditPlan(filterPlan ? filterPlan : null);
      } else {
        modal && addOrEditPlan();
      }
    }
  }, [access]);
  useEffect(() => {
    if (scheduleSelected && modal) {
      if (formData?.id) {
        let filterPlan = plans.find((item) => item.id === formData?.id);

        addOrEditPlan(filterPlan ? filterPlan : null);
      } else {
        modal && addOrEditPlan();
      }
    }
  }, [scheduleSelected]);
  useEffect(() => {
    if (serviceSelected && modal) {
      if (formData?.id) {
        let filterPlan = plans.find((item) => item.id === formData?.id);

        addOrEditPlan(filterPlan ? filterPlan : null);
      } else {
        modal && addOrEditPlan();
      }
    }
  }, [serviceSelected]);

  console.log("formData", formDataServices, access, serviceSelected);
  useEffect(() => {
    getPlans();
  }, []);

  useEffect(() => {
    if (modal && loaderDelete) deletePlan();
  }, [loaderDelete]);

  const renewalCycle = [
    { id: 1, name: "Diario", value: 10 },
    { id: 2, name: "Mensual", value: 20 },
    { id: 3, name: "Anual", value: 30 },
  ];

  const handleChangeFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const addPlanService = () => {
    console.log(formDataServices, "formDataServices");

    let serviceAll = Object.values(serviceSelected);
    let accessAll = Object.values(access);
    if (serviceAll.length == 0 || accessAll.length == 0) return;

    setFormDataServices((prev) => {
      const updatedAvailability = [...prev];
      const newSchedule = {
        id: prev.length + 1,
        service: 0,
        quota: 0,
      };
      updatedAvailability.push(newSchedule);
      return updatedAvailability;
    });
  };
  function updatePlan() {
    const { id, ...formDataFilter } = formData;
    const filteredData = Object.entries(formDataFilter).reduce(
      (acc, [key, value]) => {
        if (typeof value === "number" && value > 0) {
          acc[key] = value;
        } else if (typeof value === "string" && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    let formDataUpdate = {
      ...filteredData,
      services: formDataServices.map((item) => {
        return {
          service: item.service,
          quota: item.quota,
        };
      }),
    };
    setLoader(true);
    instanceMob
      .patch(`admin/plan/${id}/update`, formDataUpdate)
      .then((res) => {
        setLoader(false);
        getPlans();
        setModal(null);
        setFormData({
          name: "",
          id: null,
          total_spots_per_service: 0,
          renewal_cycle_time: 0,
          renewal_cycle_value: 0,
          services: [],
          description: "",
        });
        setFormDataServices([
          {
            id: 1,
            service: 0,
            quota: 0,
          },
        ]);
      })
      .catch((err) => {
        if (err.response) {
          let msg_error = err.response.data;
          seterroraGregado(msg_error);
          setTimeout(() => {
            seterroraGregado("");
          }, 3000);

          console.error(err);
        }
        setLoader(false);
      });
  }
  function registerPlan() {
    const { id, ...formDataFilter } = formData;
    let formDataCreate = {
      ...formDataFilter,
      services: formDataServices.map((item) => {
        return {
          service: item.service,
          quota: item.quota,
        };
      }),
    };
    setLoader(true);
    instanceMob
      .post(`admin/plan/create`, formDataCreate)
      .then((res) => {
        setLoader(false);
        getPlans();
        setModal(null);
        setFormData({
          name: "",
          id: null,
          total_spots_per_service: 0,
          renewal_cycle_time: 0,
          renewal_cycle_value: 0,
          services: [],
          description: "",
        });
        setFormDataServices([
          {
            id: 1,
            service: 0,
            quota: 0,
          },
        ]);
      })
      .catch((err) => {
        if (err.response) {
          let msg_error = err.response.data;
          seterroraGregado(msg_error);
          setTimeout(() => {
            seterroraGregado("");
          }, 3000);

          console.error(err);
        }
        setLoader(false);
      });
  }

  useEffect(() => {
    if (modal && loader) {
      if (formData?.id) {
        let filterPlan = plans.find((item) => item.id === formData?.id);

        addOrEditPlan(filterPlan ? filterPlan : null);
      } else {
        modal && addOrEditPlan();
      }
    }
  }, [loader]);

  useEffect(() => {
    if (modal && formData?.id) {
      let filterPlan = plans.find((item) => item.id === formData?.id);

      addOrEditPlan(filterPlan ? filterPlan : null);
    } else {
      modal && addOrEditPlan();
    }
  }, [formData]);
  function addOrEditPlan(plan) {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };

    setModal(
      <div
        className="popUpServices justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
            maxWidth: "40rem",
            minWidth: "35rem",
          }}
        >
          <div
            className=" mb-3 py-3 d-flex align-items-center"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            <div>
              <ButtonArrow
                classes=" btnBackPr me-1"
                colorBack={`var(--elevvaArrowColor)`}
                handleClick={() => {
                  setModal(null);
                  setFormData({
                    name: "",
                    total_spots_per_service: 0,
                    renewal_cycle_time: 0,
                    renewal_cycle_value: 0,
                    services: [],
                    description: "",
                  });

                  setFormDataServices([
                    {
                      id: 1,
                      service: 0,
                      quota: 0,
                    },
                  ]);
                }}
              />
            </div>
            <div>
              Registro o edición de <strong>plan</strong>
            </div>
          </div>
          {ErroraGregado && ErroraGregado.length > 0 && (
            <div className="my-1 errorsCreateAccount">{ErroraGregado}</div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "20rem",
            }}
          >
            <div
              className="mx-auto"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "30rem",
              }}
            >
              <div className="d-flex px-0 w-100">
                <input
                  defaultValue={plan?.name || ""}
                  placeholder="Nombre "
                  name="name"
                  type="search"
                  className="searchAtletas  py-2 mx-auto"
                  style={{
                    border: "1px solid var(--elevvaTextSubtitle)",
                    borderRadius: "20px",
                    fontSize: "calc(.4rem + .4vw)",
                    width: "100%",
                  }}
                  onChange={handleChangeFormData}
                ></input>
              </div>
              <input
                defaultValue={plan?.description || ""}
                placeholder="Descripción"
                name="description"
                type="search"
                className="searchAtletas  py-2 mx-auto"
                style={{
                  border: "1px solid var(--elevvaTextSubtitle)",
                  borderRadius: "20px",
                  fontSize: "calc(.4rem + .4vw)",
                  width: "100%",
                }}
                onChange={handleChangeFormData}
              ></input>
              <div className="d-flex  w-100 " id="location-select">
                <DropdownList
                  style={{ width: "100%" }}
                  busy
                  defaultValue={
                    plan?.renewal_cycle_time
                      ? renewalCycle
                          .filter(
                            (memb) => memb.value === plan?.renewal_cycle_time
                          )
                          .map((memb) => ({
                            id: memb.label,
                            name: memb.name,
                            value: memb.value,
                          }))[0]
                      : ""
                  }
                  busySpinner={<ArrowDown></ArrowDown>}
                  className={"input-style-elevva-graphics"}
                  id="optionSummary"
                  placeholder={"Ciclo de renovación"}
                  data={renewalCycle.map((memb) => ({
                    id: memb.id,
                    name: memb.name,
                    value: memb.value,
                  }))}
                  dataKey="id"
                  textField="name"
                  onChange={(value) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      ["renewal_cycle_time"]: value.value,
                    }));
                  }}
                  renderListItem={({ item }) => (
                    <div
                      className="w-100 mb-1"
                      style={{ borderBottom: "1px solid #9DACBD" }}
                    >
                      <div>{item.name}</div>
                    </div>
                  )}
                />
              </div>
              <input
                defaultValue={plan?.renewal_cycle_value || ""}
                placeholder="Valor de renovación"
                name="renewal_cycle_value"
                type="search"
                className="searchAtletas  py-2 mx-auto"
                style={{
                  border: "1px solid var(--elevvaTextSubtitle)",
                  borderRadius: "20px",
                  fontSize: "calc(.4rem + .4vw)",
                  width: "100%",
                }}
                onChange={handleChangeFormData}
              ></input>
            </div>
          </div>

          <PerfectScrollbar
            className="py-2"
            style={{
              maxHeight: "16rem",
              padding: "0 2rem",
              overflowY: "auto",
              marginTop: "1rem",
            }}
          >
            {formDataServices.map((service, index) => {
              return (
                <PlanServicesComponent
                  key={index}
                  index={index}
                  setQuote={(value) => update(setAccess, service.id, value)}
                  service={service}
                  serviceSelector={(value) =>
                    update(setServiceSelected, service.id, value)
                  }
                  setScheduleSelected={(e) => setScheduleSelected(e)}
                  deleteSchedule={(e) => {
                    setFormDataServices(
                      formDataServices.filter((schedule) => schedule.id !== e)
                    );

                    setAccess((prev) => {
                      if (!(e in prev)) {
                        return prev;
                      }

                      const updatedDays = { ...prev };
                      delete updatedDays[e];
                      return updatedDays;
                    });
                    setServiceSelected((prev) => {
                      if (!(e in prev)) {
                        return prev;
                      }

                      const updatedDays = { ...prev };
                      delete updatedDays[e];
                      return updatedDays;
                    });
                    setScheduleSelected(formDataServices[0].id);
                  }}
                />
              );
            })}
          </PerfectScrollbar>
          <div className="d-flex align-items-center text-center mx-auto justify-content-center my-2 ">
            <button
              onClick={addPlanService}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                backgroundColor: "var(--elevvabutonLogin_User)",

                color: "#fff",
                fontSize: "calc(.4rem + .4vw)",
                fontWeight: "500",
                cursor: "pointer",
                border: "none",
              }}
            >
              +
            </button>
            <span className="ms-2">Agregar servicio</span>
          </div>

          <div className="d-flex w-100 gap-2 mt-3">
            <ButtonMob
              handleClick={plan?.id ? updatePlan : registerPlan}
              style={{ border: "1px solid var(--elevvabutonLogin_User)" }}
              colorButton={"var(--elevvabutonLogin_User)"}
              text={
                loader ? "Cargando..." : plan?.id ? "Actualizar" : "Registrar"
              }
              className={"mb-3 mt-3 letraspopfi2actualizar "}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {" "}
      {modal}{" "}
      <div
        style={{
          width: "50%",
          border: "1px solid #B6C1CE",
          borderRadius: "10px",
          height: "100%",
        }}
      >
        <div className="h-100 ">
          <div className="my-2 " style={{ marginLeft: "40%" }}>
            <div
              className="d-flex  p-2"
              style={{ justifyContent: "space-between" }}
            >
              <div>
                <span
                  style={{
                    fontSize: "calc(.4rem + .4vw)",
                    color: "var(--elevvaTextTitle)",
                  }}
                >
                  Planes
                </span>
              </div>
              <button
                style={{ marginRight: "2%", padding: "0.5rem 1rem" }}
                className="d-flex add_location"
                onClick={addOrEditPlan}
              >
                Agregar plan +
              </button>
            </div>
          </div>
          <div style={{ justifyContent: "flex-end" }} className="d-flex mx-3">
            <div
              className="mx-3"
              style={{ position: "relative", marginLeft: "auto" }}
            >
              <input
                placeholder="Buscar plan"
                type="search"
                className="searchUsers "
                style={{
                  border: "1px solid var(--elevvaTextSubtitle)",
                  borderRadius: "20px",
                  fontSize: "calc(.33rem + .32vw)",
                  padding: "0.4rem 1rem",
                }}
                onChange={debounce(searchPlans, 500)}
              ></input>
              <span className="search-icon-admin">
                <SearchIconAlone fontSize={10} />
              </span>
            </div>{" "}
            {/* <div className="d-flex align-items-center me-1">
              <FiltersTable
                style={{ width: "100%", padding: "2%" }}
                className="cursor-pointer"
                onClick={() => {}}
              />
            </div> */}
          </div>
          <div>
            {isLoading ? (
              <Loadingspinner customStyle="grow" size="2.5rem" />
            ) : (
              <TableCustomPlans
                changePage={(value) =>
                  changePage(
                    value,
                    getPlansPage,
                    pageNextPlan,
                    pageBackPlan,
                    pagePlan,
                    countPlan,
                    setPagePlan
                  )
                }
                count={countPlan}
                page={pagePlan}
                plans={plans}
                deletePlan={deletePlan}
                editPlan={(e) => {
                  if (e?.id) {
                    setFormData((prevData) => ({
                      ...prevData,
                      ["id"]: e.id,
                    }));

                    if (e.services && e.services.length > 0) {
                      setFormDataServices(
                        e.services.map((service, index) => ({
                          id: index + 1,
                          service: service.service_id,
                          quota: Number(service.quota),
                        }))
                      );

                      e.services.map((service, index) => {
                        update(setAccess, index + 1, Number(service.quota));
                      });

                      e.services.map((service, index) => {
                        update(
                          setServiceSelected,
                          index + 1,
                          service.service_id
                        );
                      });
                    }
                  }
                  addOrEditPlan(e);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MembershipsPlans;
