import React from "react";
import { Group } from "@visx/group";
import { AreaClosed, LinePath } from "@visx/shape";
import { AxisLeft, AxisBottom, AxisScale, Axis } from "@visx/axis";
import { curveMonotoneX,curveLinear } from "@visx/curve";
import { AppleStock } from "@visx/mock-data/lib/mocks/appleStock";
import moment from "moment";
import { GlyphSeries, Grid, LineSeries, Tooltip, XYChart } from "@visx/xychart";

// Initialize some variables
const axisColor = "black";
const axisBottomTickLabelProps = {
  textAnchor: "middle" as const,
  fontFamily: "Arial",
  fontSize: 10,
  fill: axisColor
};
const axisLeftTickLabelProps = {
  dx: "-0.25em",
  dy: "0.25em",
  fontFamily: "Arial",
  fontSize: 10,
  textAnchor: "end" as const,
  fill: axisColor
};

// accessors
 const getDate = (d: AppleStock) => new Date(d.date);
// const getDate = (d: AppleStock,dataOne:AppleStock[]) =>{
//   // console.log(d);
//   let secondLine= Object.values( dataOne.map((e:any)=>e.date));
//   // console.log(secondLine,d.date)
//   // return new Date(d.date)
//   if(secondLine.includes(d.date)){
//     return new Date(d.date)
//   }else return 0
//   };
const getDateTwo = (d: AppleStock,dataTwo:AppleStock[]) =>{
  // console.log(d);
  let secondLine= Object.values( dataTwo.map((e:any)=>e.date));
  // console.log(secondLine,d.date)
  // return new Date(d.date)
  if(secondLine.includes(d.date)){
    return new Date(d.date)
  }else return 0
  };
const getStockValue = (d: AppleStock) => d.close;
// const getStockValue = (d: AppleStock,dataOne:AppleStock[]) => {
//   // console.log(d);
//   let secondLine= Object.values( dataOne.map((e:any)=>e.date));
//   // console.log(secondLine,d.date)
//   // return new Date(d.date)
//   if(secondLine.includes(d.date) && secondLine.length>0){
//     return d.close
//   }else return 0
//   };
const getStockValueTwo = (d: AppleStock,dataTwo:AppleStock[]) => {
  // console.log(d);
  let secondLine= Object.values( dataTwo.map((e:any)=>e.date));
  // console.log(secondLine,d.date)
  // return new Date(d.date)
  if(secondLine.includes(d.date) && secondLine.length>0){
    return d.close
  }else return 0
  };

export default function AreaChartLine({
  data,
  width,
  height,
  yMax,
  margin,
  xScale,
  yScale,
  hideBottomAxis = false,
  hideLeftAxis = false,
  top,
  left,
  children,
  dataPointOut,dataPointOver
}: {
  data: AppleStock[];
  dataOne?: AppleStock[];
  dataTwo?: AppleStock[];
  xScale: AxisScale<number>;
  yScale: AxisScale<number>;
  width: number;
  height:number;
  yMax: number;
  margin: { top: number; right: number; bottom: number; left: number };
  hideBottomAxis?: boolean;
  hideLeftAxis?: boolean;
  top?: number;
  left?: number;
  children?: React.ReactNode;
  dataPointOut?:any;
  dataPointOver?:any
}) {
  if (width < 10) return null;
  // console.log(xScale)
  const formatDate = (date:any) => {
    // Utiliza tus propias lógicas de formateo de fechas aquí
    // Por ejemplo, puedes usar libraries como date-fns o moment.js
    const fecha = moment('2007-05-31T06:53:02-05:00').locale('es');
    return fecha.format('dddd, D [de] MMMM [de] YYYY, h:mm:ss a')
  };
  const accessors = {
    xAccessor: (d: AppleStock) => new Date(d.date),
    yAccessor: (d: AppleStock) => d.close
  };
  const glyphColor = (d: AppleStock): string | undefined => {
    return "green";
   
  };
  console.log("---muchas datas",yScale,xScale,data)
  const colors = ['#43b284', '#fab255']
  return (
    <Group left={left || margin.left} top={top || margin.top}>
       <LinePath
                           
                            stroke={"#394F65"}
                            strokeWidth={1}
                            data={data}
                            // x={(d) => getDate(d,dataOne || [])!==0 ?xScale(getDate(d,dataOne || [])) ?? 0:0}
                            // y={(d) =>getStockValue(d,dataOne || []) !==0? yScale(getStockValue(d,dataOne || [])) ?? 0:0}
                            // // defined={ (d) => {console.log('---pro-----',d); return d.close !== null} }
                            // defined={(d, i) => {
                            // //  console.log("dataresta--",d)
                            //   if (dataOne &&i >3) return d.close !== null;
                            //   return false; // Devuelve false en caso de que no cumpla la condición
                            // }}
                             x={(d) => xScale(getDate(d)) ?? 0}
                             y={(d) => yScale(getStockValue(d)) ?? 0}
                            curve={curveLinear}
                            shapeRendering="geometricPrecision"
                            />
                             {/* <LinePath
                            curve={curveLinear}
                            shapeRendering="geometricPrecision"
                           stroke={"red"}
                           strokeWidth={1}
                           data={data}
                           x={(d) => getDateTwo(d,dataTwo || [])!==0 ?xScale(getDateTwo(d,dataTwo || [])) ?? 0:0}
                           y={(d) =>getStockValueTwo(d,dataTwo || []) !==0? yScale(getStockValueTwo(d,dataTwo || [])) ?? 0:0}
                           defined={(d, i) => {
                            // console.log('---pro2-----', d.close !== null, i);
                            if (dataTwo &&i < dataTwo.length) return d.close !== null;
                            return false; // Devuelve false en caso de que no cumpla la condición
                          }}
                          //  curve={ curveCardinal }
                           // x={(d) => xScale(getDate(d)) ?? 0}
                           // y={(d) => yScale(getStockValue(d)) ?? 0}
                          
                           /> */}
                                 {/* <LinePath
                           
                           stroke={"green"}
                           strokeWidth={3}
                           data={dataTwo}
                             x={(d) => xScale(getDate(d,data)) ?? 0}
                            y={(d) => yScale(getStockValue(d,data)) ?? 0}
                          
                           /> */}
                             {/* <LinePath
                          
                           stroke={"#005FAB"}
                           strokeWidth={1}
                           data={[
                            {
                                "date": "2007-04-24T06:53:02.063073-05:00",
                                "close": 73.24
                            },
                            {
                                "date": "2007-04-25T06:53:02.063073-05:00",
                                "close": 35.35
                            },
                            {
                                "date": "2007-05-27T05:53:02.063073-05:00",
                                "close": 228.84
                            },{
                              "date": "2007-06-01T06:53:02.063073-05:00",
                              "close": 48.84
                          }]}
                           x={(d) => xScale(getDate(d)) || 0}
                           y={(d) => yScale(getStockValue(d)) || 0}
                          
                           /> */}
                                                    {/* {[dataOne,dataTwo].map((sData, i) => {
                              console.log("sData",sData)
                              return(
    <LinePath defined={()=>true}
         key={i}
         stroke={"blue"}
         curve={curveLinear}
         shapeRendering="geometricPrecision"
         strokeWidth={3}
         data={sData}
          x={(d) => {console.log(d,i);return xScale(getDateL(d)) || 0}}
         y={(d) => yScale(getStockValueL(d)) || 0}
    />
)}
)} */}
                             {/* {[dataOne,dataTwo].map((sData, i) => {
                              console.log("sData",sData)
                              return(
    <LinePath defined={()=>true}
         key={i}
         stroke={colors[i]}
         strokeWidth={3}
         data={sData}
          x={(d) => {console.log(d,i);return xScale(getDateL(d)) || 0}}
         y={(d) => yScale(getStockValueL(d)) || 0}
    />
)}
)} */}




      {!hideBottomAxis && (
        <AxisBottom 
          top={yMax}
          scale={xScale}
         
      // Personalizar el formato del eje X según tus necesidades
          //  tickFormat={(date) => formatDate(date)} 
          numTicks={width > 520 ? 10 : 5}
          stroke={axisColor}
          tickStroke={axisColor}
          // tickFormat={(value:any, index:number, array:any) => {
            
          //   const firstDate = moment(array[0].value);
          //   const lastDate = moment(array[array.length - 1].value);
          //   const diff = lastDate.diff(firstDate, 'hours');
          //   // console.log(array[0],firstDate.format('L'),lastDate,diff)
          //     if (diff < 24) {
          //       return lastDate.locale('es').format('h:mm:ss a');
          //     } else if (diff >= 24 && diff < 120) {
          //       return moment(value).locale('es').format('dddd LT');
          //     } else if (diff >= 120 && diff < 720) {
          //       return moment(value).locale('es').format('L');
          //     } else if (diff >= 720 && diff < 8640) {
          //       return moment(value).locale('es').format('L');
          //     }else {
          //       return moment(value).locale('es').format('YYYY');
          //     }
            
          // }}
          
          tickLabelProps={() => axisBottomTickLabelProps}
        />
      )}
      {!hideLeftAxis && (
        <AxisLeft
          scale={yScale}
          // numTicks={5}
          stroke={axisColor}
          tickStroke={axisColor}
          tickLabelProps={() => axisLeftTickLabelProps}
        />
      )}
          {dataPointOver?data.map((d, j) => (
        <circle
          key={0 + Math.random()}
          r={4}
          cx={xScale(getDate(d)) || 0}
          cy={yScale(getStockValue(d)) || 0}
          onMouseOver={(e) => dataPointOver(e,d)}
          onMouseOut={(e) => {
            // dataPointOver(e);
            dataPointOut(e);
          }}
          fill="blue"
        />
      )):null}
      {children}
    </Group>
  );
}
