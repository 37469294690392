import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const data = [
  { level: 5, color: "#ff0000", time: "00:00:00", width: "15%" },
  { level: 4, color: "#ff9900", time: "00:00:00", width: "0%" },
  { level: 3, color: "#00ff00", time: "00:05:20", width: "30%" },
  { level: 2, color: "#0000ff", time: "00:25:30", width: "100%" },
  { level: 1, color: "#bcbcbc", time: "00:01:31", width: "20%" },
];

const GraphicProgress = () => {
  return (
    <div className="progress-container">
      {data.map((item, index) => (
        <div key={index} className="progress-row">
          {/* Número con color */}
          <div className="level-box" style={{ backgroundColor: item.color }}>
            {item.level}
          </div>
          {/* Barra de progreso */}
          <div className="progress-bar-custom">
            <div
              className="progress-fill"
              style={{ backgroundColor: item.color, width: item.width }}
            ></div>
          </div>
          {/* Tiempo */}
          <div className="time-label">{item.time}</div>
        </div>
      ))}
    </div>
  );
};

export default GraphicProgress;
