import React, { useRef, useState, useMemo, useEffect } from "react";
import { scaleTime, scaleLinear, scaleBand, scaleOrdinal } from "@visx/scale";
import cityTemperature, {
  CityTemperature
} from "@visx/mock-data/lib/mocks/cityTemperature";
import appleStock, { AppleStock } from "@visx/mock-data/lib/mocks/appleStock";

import { Brush } from "@visx/brush";
import { Bounds } from "@visx/brush/lib/types";
import BaseBrush, {
  BaseBrushState,
  UpdateBrush
} from "@visx/brush/lib/BaseBrush";
import { BrushHandleRenderProps } from '@visx/brush/lib/BrushHandle';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { SeriesPoint } from '@visx/shape/lib/types';
import { PatternLines } from "@visx/pattern/lib";
import { LinearGradient } from "@visx/gradient/lib";
import AreaChart from "./areaChart.tsx";
import { Grid } from "@visx/grid";
import { Group } from "@visx/group";
import { AxisLeft, AxisRight } from "@visx/axis";
import Moment from "moment";
export interface Dummie {
  date: string;
  "FC Promedio": string;
  "FC Max": string;
  Distancia: string;
}

type TooltipData = {
  bar: SeriesPoint<Dummie>;
  key: CityName;
  index: number;
  height: number;
  width: number;
  x: number;
  y: number;
  color: string;
  value:number
};
// Initialize some variables
const brushMargin = { top: 20, bottom: 20, left: 50, right: 20 };
const chartSeparation = 10;
const PATTERN_ID = "brush_pattern";
const GRADIENT_ID = "brush_gradient";
export const accentColor = "var(--elevva)";
export const background = "#ffff";
const axisColor = "#fff";

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
};
const axisBottomTickLabelProps = {
  textAnchor: "middle" as const,
  fontFamily: "Arial",
  fontSize: 10,
  fill: axisColor
};
const axisLeftTickLabelProps = {
  dx: "0.55em",
  dy: "0.25em",
   fontFamily: "Arial",
   fontSize: 10,
  textAnchor: "end" as const,
  // fill: axisColor
};

const blue = "#0098FF";
export const green = "#D400A9";
const purple = "#8AE7E0";
export const background2 = "#ffff";
const selectedBrushStyle = {
  fill: `url(#${PATTERN_ID})`,
  stroke: '#666',
  strokeOpacity: 0.9,
  fillOpacity: 0.4,
};

type CityName = "FC Promedio" | "FC Max" | "Distancia" | "Calorías";
// const DataDummie =[{date: "2011-10-01T06:53:02.063073-05:00", "FC Promedio": '63.4', "FC Max": '62.7', "Distancia": '72.2'},
// {date: '2011-10-02T01:53:02.063073-05:00', "FC Promedio": '58.0', "FC Max": '59.9',"Distancia": '67.7'},{date: '2011-10-03T06:53:02.063073-05:00', "FC Promedio": '58.0', "FC Max": '59.9',"Distancia": '67.7'},
// {date: '2011-10-04T12:53:02.063073-05:00', "FC Promedio": '58.0', "FC Max": '59.9',"Distancia": '67.7'},{date: '2011-10-05T06:53:02.063073-05:00', "FC Promedio": '58.0', "FC Max": '59.9',"Distancia": '67.7'},
// {date: '2011-10-06T02:53:02.063073-05:00', "FC Promedio": '58.0', "FC Max": '59.9',"Distancia": '67.7'},{date: '2011-10-07T09:53:02.063073-05:00', "FC Promedio": '58.0', "FC Max": '59.9',"Distancia": '67.7'},
// {date: '2011-10-08T15:53:02.063073-05:00', "FC Promedio": '58.0', "FC Max": '59.9',"Distancia": '67.7'},{date: '2011-10-09T01:53:02.063073-05:00', "FC Promedio": '58.0', "FC Max": '59.9',"Distancia": '67.7'}]


let stock = appleStock.slice(0);
// accessors
const getDate = (d: Dummie) => {
  // return d?.date.slice(0,11)
   return (d?.date) ;
};
// const getDate = (d: Dummie) => d.date;



export type BrushProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  compact?: boolean;
  data:any,dataUsers:any
};

function BrushChart({
  compact = false,
  width,
  height,
  data,
  dataUsers,
  margin = {
    top: 20,
    left: 50,
    bottom: 20,
    right: 20
  }
}: BrushProps) {
  const brushRef = useRef<BaseBrush | null>(null);
  const [filteredStock, setFilteredStock] = useState(data);
  useEffect(() => {
    setFilteredStock(data)
   
  }, [data])
  console.log(data,dataUsers);
  const assignColor = (property: string): string => {
    switch (property) {
      case 'Calorías':
        return '#FF0000'; // Color para la propiedad distancia
      case 'Distancia':
        return '#8AE7E0'; // Color para la propiedad peso
      case 'FC Max':
        return '#D400A9'; // Color para la propiedad altura
      // Agrega más casos para otras propiedades si es necesario
      default:
        return '#0098FF'; // Color por defecto
    }
  };
  const keys = Object.keys(data[0]).filter((d) => d !== "date") as CityName[];
  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range:  keys.map(assignColor),
  });
  const onBrushChange = (domain: Bounds | null) => {
    if (!domain) return;
 const stockCopy = data.filter((s) => {
      // console.log(domain?.xValues, getDate(s),s.date)
       const x = getDate(s);
     //  return fecha > x0 && fecha < x1;
      let dates=domain?.xValues?.includes((s.date));
      console.log(domain?.xValues,(s.date))
      return dates;
    });
    const modifiedData = stockCopy.map(item => ({
      ...item,
      date: (item.date)
    }));
    setFilteredStock(modifiedData);
  };
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
  useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });
  const innerHeight = height - margin.top - margin.bottom;
  const topChartBottomMargin = compact
    ? chartSeparation / 2
    : chartSeparation + 60;
  const topChartHeight = 0.9 * innerHeight - topChartBottomMargin;
  const bottomChartHeight = innerHeight - topChartHeight - chartSeparation;

  // bounds
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = Math.max(topChartHeight, 0);

  const xBrushMax = Math.max(width - brushMargin.left - brushMargin.right, 0);
  const yBrushMax = Math.max(
    bottomChartHeight - brushMargin.top - brushMargin.bottom,
    0
  );
 
  const dateScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        domain: filteredStock.map(getDate),
        padding: data.length>2?0.6:0.7
      }),
    [xMax, filteredStock]
  );

  const tempScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: [
          0,
          Object.keys(data[0]).includes("Calorías") && Object.keys(data[0]).length ==2 || Object.keys(data[0]).includes("Distancia") && Object.keys(data[0]).length ==2?  Math.max(
            ...data.map((d) => Math.max(...keys.map((key) => Number(d[key]))))
          ): Object.keys(data[0]).includes("Calorías") &&  Object.keys(data[0]).includes("Distancia")  && Object.keys(data[0]).length ==3?Math.max(
            ...data.map((d) => Math.max(...keys.filter(de=> de != "Distancia").map((key) => Number(d[key]))))
          ):Math.max(
            ...data.map((d) => Math.max(...keys.filter(de=> de != "Calorías" && de != "Distancia").map((key) => Number(d[key]))))
          )
        ]
      }),
    [yMax, filteredStock]
  );
  const tempScaleRight = useMemo(
    () =>
    scaleLinear<number>({
      domain: [
        0,
        Object.keys(data[0]).includes("Calorías") &&  Object.keys(data[0]).includes("Distancia")  && Object.keys(data[0]).length ==3?Math.max(
          ...data.map((d) => Math.max(...keys.filter(de=> de == "Calorías").map((key) => Number(d[key]))))
        ): Math.max(
          ...data.map((d) => Math.max(...keys.filter(de=> de == "Calorías" || de == "Distancia").map((key) => Number(d[key]))))
        )
      ], range: [yMax, 0],
    }),
    [yMax, filteredStock]
  );


  const cityScale = useMemo(
    () =>
      scaleBand<string>({
        domain: keys,
        range: [0, dateScale.bandwidth()],
        padding: 0.1
      }),
    [xMax, filteredStock]
  );

  const brushDateScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xBrushMax],
        domain: data.map(getDate),
        padding: 0.2
      }),
    [xBrushMax,filteredStock]
  );

  const brushCityScale = useMemo(
    () =>
      scaleBand<string>({
        domain: keys,
        range: [0, brushDateScale.bandwidth()],
        padding: data.length>2?0.4:0.5
      }),
    [xBrushMax,filteredStock]
  );

  const brushStockScale = useMemo(
    () =>
      scaleLinear({
        range: [yBrushMax, 0],
        domain: [
          0,
          Math.max(
            ...data.map((d) => Math.max(...keys.map((key) => Number(d[key]))))
          )
        ],
        nice: true
      }),
    [yBrushMax,filteredStock]
  );

  const initialBrushPosition = useMemo(
    () => {
      if(data.length>0){
      console.log(data)
        
        const sortedData = data.sort((a:any, b:any) => a.date - b.date); 
       
    return { 
       start: { x: brushDateScale(getDate(sortedData[0])) },
      end: { x: brushDateScale(getDate(sortedData[data.length - 1])) }
    }
      }
    
    },
    [brushDateScale,data]
  );

  // event handlers
  const handleClearClick = () => {
    if (brushRef?.current) {
      setFilteredStock(data);
      brushRef.current.reset();
    }
  };

  // const handleResetClick = () => {
  //   if (brushRef?.current) {
  //     const updater: UpdateBrush = (prevBrush) => {
  //       const newExtent = brushRef.current!.getExtent(
  //         initialBrushPosition.start,
  //         initialBrushPosition.end
  //       );

  //       const newState: BaseBrushState = {
  //         ...prevBrush,
  //         start: { y: newExtent.y0, x: newExtent.x0 },
  //         end: { y: newExtent.y1, x: newExtent.x1 },
  //         extent: newExtent
  //       };

  //       return newState;
  //     };
  //     brushRef.current.updateBrush(updater);
  //   }
  // };
// We need to manually offset the handles for them to be rendered at the right position
function BrushHandle({ x, height, isBrushActive }: BrushHandleRenderProps) {
  const pathWidth = 8;
  const pathHeight = 15;
  if (!isBrushActive) {
    return null;
  }
  return (
    <Group left={x + pathWidth / 2} top={(height - pathHeight) / 2}>
      <path
        fill="#f2f2f2"
        d="M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5 M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12"
        stroke="#999999"
        strokeWidth="1"
        style={{ cursor: 'ew-resize' }}
      />
    </Group>
  );
}
  return (
    <div>
      <svg ref={containerRef} width={width} height={height} style={{paddingRight:Object.keys(data[0]).includes("Calorías") || Object.keys(data[0]).includes("Distancia")?"0":'20px'}}>
        <LinearGradient
          id={GRADIENT_ID}
          from={background}
          to={background2}
          rotate={45}
        />
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill={`url(#${GRADIENT_ID})`}
          rx={14}
        />
          <Grid
          top={margin.top}
          left={margin.left}
          xScale={dateScale}
          yScale={tempScale}
          width={xMax}
          height={yMax}
          stroke="black"
          strokeOpacity={0.1}
          xOffset={dateScale.bandwidth() / 2}
        />
        <AreaChart
          data={filteredStock}
          keys={keys}
          width={width}
          margin={{ ...margin, bottom: topChartBottomMargin }}
          yMax={yMax}
          xScale={dateScale}
          dateScale={dateScale}
         
          cityScale={cityScale}
          tempScale={tempScale}
          tempScaleRight={tempScaleRight}
          gradientColor={background2}
          colorScale={colorScale}
          getDate={getDate}
          hideTooltip={hideTooltip}
          showTooltip={showTooltip}
          dataUsers={dataUsers}
        />

      

        <AreaChart
          hideBottomAxis
          hideLeftAxis
          data={data}
          keys={keys}
          width={width}
          yMax={yBrushMax}
          tempScaleRight={tempScaleRight}
        
          xScale={dateScale}
          dateScale={brushDateScale}
          cityScale={brushCityScale}
          tempScale={brushStockScale}
          gradientColor={background2}
          colorScale={colorScale}
          getDate={getDate}
          margin={brushMargin}
          top={topChartHeight + topChartBottomMargin + margin.top}
          showTooltip={showTooltip}
          hideTooltip={hideTooltip}
          dataUsers={dataUsers}
        >
          <PatternLines
            id={PATTERN_ID}
            height={8}
            width={8}
            stroke={"blue"}
            strokeWidth={1}
            orientation={["diagonal"]}
          />
          <Brush
            xScale={brushDateScale}
            yScale={brushStockScale}
            width={xBrushMax}
            height={yBrushMax}
            margin={brushMargin}
            handleSize={8}
            innerRef={brushRef}
            resizeTriggerAreas={["left", "right"]}
            initialBrushPosition={initialBrushPosition}
            onChange={onBrushChange}
            brushDirection="horizontal"
            onClick={() => setFilteredStock(data)}
            useWindowMoveEvents
            selectedBoxStyle={selectedBrushStyle}
            renderBrushHandle={(props) => <BrushHandle {...props} />}
          />
        </AreaChart>
      </svg>
        {tooltipOpen && tooltipData && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div style={{ color: colorScale(tooltipData.key) }}>
            <strong>{tooltipData.key}</strong>
          </div>
          <div>{tooltipData?.value}{tooltipData.key == "Calorías"?" kcal":tooltipData.key == "Distancia"?" metros":" ppm"}</div>
          <div>
          
          </div>
        </TooltipInPortal>
      )}
      {/* <button onClick={handleClearClick}>Clear</button>&nbsp;
      <button onClick={handleResetClick}>Reset</button> */}
    </div>
  );
}

export default BrushChart;
