import CardClass from "./card-class";
import { ReactComponent as ArrowLeft } from "../../../assets/elevva-pro-icons/table-arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../assets/elevva-pro-icons/table-arrow-right.svg";
const Clases = (props) => {
  return (
    <div>
      {" "}
      <div className="my-2" style={{ marginLeft: "40%" }}>
        {" "}
        <span
          style={{
            fontSize: "calc(.4rem + .4vw)",
            color: "var(--elevvaTextTitle)",
          }}
        >
          Clases <strong>disponibles</strong>{" "}
        </span>
      </div>
      <div className="d-flex px-4">
        <input
          placeholder="Ver clases"
          type="search"
          className="searchAtletas  py-1 mx-auto"
          style={{
            border: "1px solid var(--elevvaTextSubtitle)",
            borderRadius: "20px",
            fontSize: "calc(.4rem + .4vw)",
            width: "60%",
          }}
          onChange={() => {}}
        ></input>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
          gap: `1rem`,
          padding: `1rem`,
        }}
      >
        <CardClass />
        <CardClass />
        <CardClass />
        <CardClass />
      </div>
      <div
        style={{ padding: "0rem 1rem 2rem 1rem" }}
        className="d-flex justify-content-end align-items-center gap-3"
      >
        <span>1 de 9 de 8</span>
        <div onClick={() => {}} className="cursor-pointer">
          <ArrowLeft />
        </div>
        <div onClick={() => {}} className="cursor-pointer">
          <ArrowRight />
        </div>
      </div>
    </div>
  );
};

export default Clases;
