import { use, useEffect } from "react";
import { useState } from "react";

const DateInputStart = (props) => {
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);
  const [trainingDay, setTrainingDay] = useState("Fecha de inicio");

  useEffect(() => {
    if (props.setPlanDate) {
      props.setPlanDate(trainingDay);
    }
  }, [trainingDay]);

  useEffect(() => {
    if (props.defaultDate) {
      setIsPlaceholderVisible(false);
      setTrainingDay(new Date(props.defaultDate).toISOString().split("T")[0]);
    }
  }, [props?.defaultDate]);

  return (
    <input
      id="date-start-user"
      required
      className="form-control input-style-elevva py-2"
      style={{
        color: "#9DACBD",
        fontSize: "calc(0.4rem + 0.4vw)",
      }}
      name="date-start-user"
      type={isPlaceholderVisible ? "text" : "date"} // ✅ Cambiamos a tipo texto
      placeholder="Fecha de inicio"
      value={isPlaceholderVisible ? "Fecha de inicio" : trainingDay} // ✅ Mostramos el texto como valor
      onFocus={() => {
        if (isPlaceholderVisible) {
          setIsPlaceholderVisible(false);
        }
      }}
      onBlur={() => {
        if (!trainingDay) {
          setTrainingDay("Fecha de inicio"); // Restauramos el texto si está vacío
          setIsPlaceholderVisible(true);
        }
      }}
      onChange={(e) => setTrainingDay(e.target.value)}
    />
  );
};

export default DateInputStart;
