import React from "react";
import moment from "moment";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { ReactComponent as DeleteTag } from "../../../assets/elevva-pro-icons/delete-tag.svg";
import { ReactComponent as EditTag } from "../../../assets/elevva-pro-icons/edit-tag.svg";
import { ReactComponent as SearchInvidual } from "../../../assets/elevva-pro-icons/search-individual-table.svg";
import { laptop1600 } from "../../../utils/utils";
import { ReactComponent as Person } from "../../../assets/elevva-pro-icons/person-service.svg";
import { ReactComponent as Clock } from "../../../assets/elevva-pro-icons/clock-service.svg";

const cardStyle = {
  width: laptop1600.matches ? "12rem" : "10rem",
  border: "1px solid #d1d5db",
  borderRadius: "5px",
  height: "auto",
  maxHeight: laptop1600.matches ? "16rem" : "16rem",
  minHeight: laptop1600.matches ? "14.5rem" : "14.5rem",
  fontFamily: "Arial, sans-serif",
  backgroundColor: "white",
  overflow: "hidden",
};
const iconStyle = {
  cursor: "pointer",
  marginLeft: "0.5rem",
  color: "#9ca3af",
};
const searchIconStyle = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  fontSize: "1rem",
  color: "#3b82f6",
  marginBottom: "auto",
  cursor: "pointer",
  padding: "0.5rem 0.5rem",
};

const titleStyle = {
  fontSize: "calc(.4rem + .4vw)",
  fontWeight: "bold",
  minHeight: "2.5rem",
  display: "flex",
  alignItems: "center",
  color: "#374151",
};

const categoryStyle = {
  backgroundColor: "#e5e7eb",
  display: "flex",
  padding: "0 1rem",
  textAlign: "left",
  color: "#374151",
  alignItems: "center",
  height: "2.3rem",
};

const locationStyle = {
  margin: "5px 0",
  fontSize: "calc(.4rem + .4vw)",
  color: "#6b7280",
  padding: "0 1rem",
};
const iconTextStyle = {
  color: "#3b82f6",
  marginRight: "0.5rem",
  fontSize: "calc(.3rem + .3vw)",
};

const secondaryTextStyle = {
  color: "#3b82f6",
  marginLeft: "1rem",
  marginRight: "0.5rem",
  fontSize: "calc(.3rem + .3vw)",
};

const detailContainerStyle = {
  display: "flex",
  alignItems: "center",
  margin: laptop1600.matches ? "1rem" : "0.8rem 0.5rem",
  fontSize: "calc(.3rem + .3vw)",
  color: "#1f2937",
};

const CardClass = (props) => {
  const availability = props.availability;
  const daysName = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];

  return (
    <div key={"availability-" + availability.id} style={cardStyle}>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ padding: "0rem 0.5rem" }}
      >
        <span style={titleStyle}>{availability.location_name}</span>
        <div className="d-flex align-items-center">
          <span style={iconStyle}>
            <EditTag onClick={() => props.editAvailability(availability)} />
          </span>
          <span style={iconStyle}>
            <DeleteTag onClick={() => props.deleteAvailability(availability)} />
          </span>
        </div>
      </div>
      <div style={categoryStyle}>
        <span style={{ fontSize: "calc(.4rem + .4vw)", lineHeight: "1" }}>
          {availability.service_name}
        </span>
      </div>
      <div style={detailContainerStyle}>
        <span style={iconTextStyle}>
          <Clock />
        </span>
        <span style={{ fontSize: "calc(.3rem + .3vw)" }}>
          {availability.duration} min
        </span>
        <span style={secondaryTextStyle}>
          <Person />{" "}
        </span>
        <span style={{ fontSize: "calc(.3rem + .3vw)" }}>
          {availability?.slots || 0} cupos
        </span>
      </div>
      <div style={locationStyle}>
        <PerfectScrollbar style={{ maxHeight: "4rem", overflow: "auto" }}>
          {availability.weekly_schedule.map((schedule, index) => (
            <p
              key={index + "schedule"}
              style={{ fontSize: "calc(.4rem + .4vw)" }}
            >
              <strong> {daysName[schedule.day]}</strong>
              <br />
              {schedule.times.map((time) => (
                <span
                  key={time.start + "-time"}
                  style={{ fontSize: "calc(.4rem + .4vw)" }}
                >
                  <li>
                    {moment(time.start, "HH:mm:ss").format("h:mm a ")}-{" "}
                    {moment(time.end, "HH:mm:ss").format("h:mm a")}
                  </li>
                </span>
              ))}
            </p>
          ))}
        </PerfectScrollbar>
      </div>
      <div style={searchIconStyle}>
        <SearchInvidual
          width={"1.2rem"}
          onClick={() => props.modalAvailabilityDetail(availability)}
        />
      </div>
    </div>
  );
};

export default CardClass;
