import { ButtonMob } from "../../utils/utils";

const TrainingPlan = (props) => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100 flex-column">
      <img
        alt="logo_alta_black_2"
        style={{ padding: "0% 20%" }}
        src={
          process.env.PUBLIC_URL + "/assets/img/logo_small_elevva_metrics.png"
        }
      />
      <br></br>
      <div style={{ maxWidth: "80%" }} className="text-center mx-auto">
        <span
          style={{ textAlign: "center", fontSize: "calc( 0.55rem + 0.5vw )" }}
        >
          Crea el plan de entrenamiento para los miembros de tu equipo
        </span>
      </div>
      {/* <ButtonMob
        colorButton={"var(--elevvabutonLogin_User)"}
        text="Crear plan de membresia"
        className={"px-2 letraspopfi2actualizar"}
      /> */}
    </div>
  );
};
export default TrainingPlan;
