
import { useState } from "react";
import TrainingSessions from "./components/grid-training";
import NavBarSessions from "./containers/navBarSessions";


const sessions = [
  {id: 1, time: "06:00 a.m", type: "Crossfit", duration: "60 min", slots: "8 / 24 cupos" },
  { id: 2, time: "07:00 a.m", type: "Crossfit", duration: "60 min", slots: "8 / 24 cupos" },
  {   id: 3, time: "08:00 a.m", type: "Crossfit", duration: "60 min", slots: "8 / 24 cupos" },
  {   id: 4, time: "09:00 a.m", type: "Crossfit", duration: "60 min", slots: "8 / 24 cupos" },


];

export default function LiveSesions() {
 
  return (
    <div style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
  <NavBarSessions/>
      <div className="w-100 p-5 " style={{ height: "calc(100vh - 60px)" }} >
        <div className="container-fluid border rounded h-100" style={{ border: "1 px solid #9DACBD" }}>
          <TrainingSessions sessions={sessions} />
        </div>
      </div>
    </div>
  );
}
