import { SeleccionaMembresia } from "../dashboard/utils";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Moment from "moment";
import { ReactComponent as LogoSupremacy } from "../../assets/assets/logo-elevva-supremacy.svg";
import LogoSupremacyBig from "../../assets/assets/elevva-mora_big.png";
import { useEffect, useRef, useState } from "react";
import {
  ButtonMob,
  Loadingspinner,
  ModalInfo,
  instance,
  laptop1350,
  laptop1600,
  metricFormat,
} from "../../utils/utils";
import { ReactComponent as Peso } from "../../assets/newIcons/OBJ-PESO.svg";
import { ReactComponent as Tension } from "../../assets/newIcons/OBJ-TENSION.svg";
import { ReactComponent as Diabetes } from "../../assets/newIcons/OBJ-DIABETES.svg";
import { ReactComponent as Nutricion } from "../../assets/newIcons/OBJ-NUTRICION.svg";
import { ReactComponent as Estado } from "../../assets/newIcons/OBJ-ESTADO.svg";
import { ReactComponent as ArrowUpMetric } from "../../assets/metricas/up-down.svg";
import { ReactComponent as ArrowDownMetric } from "../../assets/metricas/downArrow.svg";
import imageDefault from "../../assets/corporative/imageDefault.png";
import ReactHtmlParser from "html-react-parser";
import "./graphics.css";
import { ReactComponent as ArrowLeft } from "../../assets/corporative/arrow-left.svg";
import { DropdownList, Multiselect } from "react-widgets";
import { ReactComponent as ArrowDown } from "../../assets/elevva-pro-icons/arrowDown.svg";
import { ReactComponent as ArrowUpWhite } from "../../assets/elevva-pro-icons/arrowWhite-up.svg";
import { ReactComponent as ArrowDownWhite } from "../../assets/elevva-pro-icons/arrowWhite-down.svg";
import { ReactComponent as Caneca } from "../../assets/elevva-pro-icons/caneca.svg";
import { ReactComponent as Alerta } from "../../assets/elevva-pro-icons/alert-observaciones.svg";
import { ReactComponent as SaveIcon } from "../../assets/elevva-pro-icons/save-icon.svg";
import { ReactComponent as Tiempo } from "../../assets/metricas/tiempo.svg";
import { ReactComponent as FrecuenciaImage } from "../../assets/metricas/frecuencia_cardiaca.svg";
import { ReactComponent as CaloriasImage } from "../../assets/metricas/Calorias.svg";
import { ReactComponent as DistanciaImage } from "../../assets/metricas/Distancia.svg";
import { ReactComponent as FcMaximaImage } from "../../assets/metricas/FC_maxima.svg";
import { ReactComponent as FcPromedioImage } from "../../assets/metricas/FC_PROMEDIO.svg";
import { ReactComponent as PesoImage } from "../../assets/metricas/peso_image.svg";
import { ReactComponent as GrasaVisceralImage } from "../../assets/metricas/Grasa_visceral_image.svg";
import { ReactComponent as AguaCorporalImage } from "../../assets/metricas/agua_corporal_image.svg";
import { ReactComponent as MasaEsqueleticaImage } from "../../assets/metricas/masa_esqueletica_image.svg";
import { ReactComponent as MusculoEsqueleticoImage } from "../../assets/metricas/musculo_esqueletico_image.svg";
import { ReactComponent as ProteinaImage } from "../../assets/metricas/proteina_image.svg";
import { ReactComponent as ImcImage } from "../../assets/metricas/imc_image.svg";
import { ReactComponent as GrasaCorporalImage } from "../../assets/metricas/grasa_corporal_image.svg";
import { ReactComponent as MasaMuscularImage } from "../../assets/metricas/masa_muscular_image.svg";
import { ReactComponent as EdadCorporalImage } from "../../assets/metricas/edad_corporal_image.svg";
import { ReactComponent as BmrImage } from "../../assets/metricas/bmr_image.svg";
import { ReactComponent as TipoLesionImage } from "../../assets/metricas/tipo_lesion_image.svg";
import { ReactComponent as GrasaSubcutaneaImage } from "../../assets/metricas/grasa_subcutanea_image.svg";
import { ReactComponent as TensionArterialImage } from "../../assets/metricas/Tension_arterial.svg";
import { ReactComponent as EdadRealImage } from "../../assets/metricas/edad_real.svg";
import { ReactComponent as SdnnImage } from "../../assets/metricas/sdnn.svg";
import { ReactComponent as IconCircle } from "../../assets/metricas/icon-circle-colors.svg";
import { ReactComponent as ArrowFc } from "../../assets/metricas/arrowFc.svg";
import { ReactComponent as IconTension } from "../../assets/metricas/icon-tension_arterial.svg";
import { ReactComponent as RmssdImage } from "../../assets/metricas/rmssd.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { DateRangePicker, Stack } from "rsuite";
import { CustomProvider } from "rsuite";
import "rsuite/DateRangePicker/styles/index.css";
import es_ES from "rsuite/locales/es_ES";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import { ParentSize } from "@visx/responsive";
import BrushChart from "./barGraphic.tsx";
import LineChart from "./lineChart.tsx";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import axios from "axios";
import { PPACalculatorChart } from "./ChartTwo.tsx";
import moment from "moment";
import { MetricsGraphics } from "./graphicMetrics.tsx";
import { PPACalculatorTest } from "./prueba.tsx";

const Graphics = (props) => {
  const elevvaT = props.elevvaT;
  SwiperCore.use([Pagination, Autoplay, Navigation]);
  const [MembershipOptionId, setMembershipOptionId] = useState(null);
  const [totalTrainings, setTotalTrainings] = useState([]);
  const [selectOneDiscipline, setselectOneDiscipline] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [totalMemberships, settotalMemberships] = useState(null);
  const [totalGraphicsSave, settotalGraphicsSave] = useState(null);
  const [trainingIdOption, settrainingIdOption] = useState(null);
  const [ValueSaveGrafic, setValueSaveGrafic] = useState([]);
  const [SelectedGraphicBoolean, setSelectedGraphicBoolean] = useState(false);
  const [OpenM, setOpenM] = useState(false);
  const [totalCustomers, settotalCustomers] = useState(null);
  const [BackupCustomers, setBackupCustomers] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [IdCustomer, setIdCustomer] = useState([]);
  const [IdGraphic, setIdGraphic] = useState(null);
  const [swiperTrainings, setSwiperTrainings] = useState(null);
  const [modal, setModal] = useState(null);
  const [SearchActive, setSearchActive] = useState(false);
  const [Paises, setPaises] = useState(false);
  const [country, setCountry] = useState("");
  const [selectedItemsObjectives, setSelectedItemsObjectives] = useState([]);
  const [Logs, setLogs] = useState([]);
  const [DataResult, setDataResult] = useState([]);
  const [DataUserAge, setDataUserAge] = useState([]);
  const [GraficBarData, setGraficBarData] = useState([]);
  const [NewDataUsers, setNewDataUsers] = useState([]);
  const [DataResultMetrics, setDataResultMetrics] = useState([]);
  const [Modalpicker, setModalpicker] = useState(null);
  const [observationText, setobservationText] = useState("");
  const [titleTextGraphic, settitleTextGraphic] = useState("");
  const [NotPosible, setNotPosible] = useState(null);
  const [InjurieId, setInjurieId] = useState(null);
  const [loading, setloading] = useState(true);
  const [tooltip, setTooltip] = useState({
    visible: false,
    x: 0,
    y: 0,
    content: "",
  });

  const showTooltip = (event, content, title) => {
    console.log(title);
    setTooltip({
      visible: true,
      x: event.clientX,
      y: event.clientY,
      content: content,
      title: title,
    });
  };
  const hideTooltip = () => {
    setTooltip({ visible: false, x: 0, y: 0, content: "" });
  };
  // const prueba = [
  //   {
  //     id: 1,
  //     name: "Elevva Fundadores",
  //     description:
  //       "Los primeros usuarios son especiales y tienen acceso a beneficios exclusivos en la plataforma Elevva como una forma de agradecerles por su apoyo inicial.",
  //     is_active: true,
  //     is_corporate: true,
  //     fee: "0.00",
  //     has_freemium: false,
  //   },
  //   {
  //     id: 2,
  //     name: "Prueba",
  //     description:
  //       "Los primeros usuarios son especiales y tienen acceso a beneficios exclusivos en la plataforma Elevva como una forma de agradecerles por su apoyo inicial.",
  //     is_active: true,
  //     is_corporate: true,
  //     fee: "0.00",
  //     has_freemium: false,
  //   },
  // ];
  const colors = [
    "#73D2DE",
    "#00AC20",
    "#FBBA00",
    "#005FAB",
    "#FF42EF",
    "#FF7900",
    "#468089",
    "#FF0000",
    "#10232B",
    "#ff0080",
    "#F77F00",
    "#B3FF00",
  ];

  useEffect(() => {
    setloading(true);
    const stringNuevo = selectOneDiscipline.join(",");
    let nuevoLog = Logs.filter((e) => Number(e) < 100);
    const stringCustomers = IdCustomer.join(",");
    const stringLogs =
      Logs.includes("18") && Logs.length > 1
        ? Logs.filter((num) => num != "18").join(",")
        : Logs.join(",");

    setDataResult([]);
    setGraficBarData([]);

    setDataResultMetrics([]);

    const fetchData = async () => {
      try {
        if (selectOneDiscipline.length > 0 && nuevoLog.length > 0) {
          const res = await instance.get(
            `customers/analytics/healthlog/?sessions=${stringNuevo}&types=${nuevoLog[0]}&step_size=seconds&start_date=${startDate}&end_date=${endDate}`
          );

          const mor = res.data;

          if (mor) {
            const newData = {};
            // let colors = [
            //   "#394F65",
            //   "#005FAB",
            //   "#C3423F",
            //   "#468089",
            //   "#FBBA00",
            //   "#73D2DE",
            // ];

            // let timeDiff= (item)=>new Date(item) - firstDate
            for (let key in mor) {
              newData[key] = {};
              newData[key].data = mor[key][0].map((element, index) => {
                let firstDate = new Date(mor[key][0][0][0]);
                let timeDiff = new Date(element[0]) - firstDate;

                // let filterSwiper = swiperTrainings.filter((e) => e.id == key);
                let inidiceCustomer = swiperTrainings.filter(
                  (e) => e.id == key
                );
                let customerSelect = totalCustomers.filter(
                  (name) => name.id == inidiceCustomer[0]?.customer
                );

                return {
                  date: Number(timeDiff),
                  price: element[1],
                  dateOriginal: element[0],
                  user: customerSelect[0] ? customerSelect[0].first_name : key,
                };
              });
              newData[key].title = key;
              newData[key].color =
                colors[Object.keys(mor).findIndex((item) => item == key)];
            }
            if (Logs.includes("1006")) {
              let userImage = swiperTrainings.filter((e) =>
                Object.keys(mor).includes(String(e.id))
              );
              const newDataUsers = userImage.map((item) => {
                const customerData = totalCustomers.find(
                  (customer) => customer.id === item.customer
                );
                return {
                  customer: customerData ? customerData : item.customer,
                };
              });

              setDataUserAge(newDataUsers);
            }
            setloading(false);

            setDataResult(Object.values(newData));
          }
        }
      } catch (error) {
        setloading(false);
        console.error(error);
      }
    };
    const fetchDataBar = async () => {
      try {
        if (selectOneDiscipline.length > 0) {
          const res = await instance.get(
            `customers/analytics/healthlog/?sessions=${stringNuevo}&types=${"99"}&start_date=${startDate}&end_date=${endDate}`
          );

          const data = res.data;

          if (data) {
            const keyMap = {
              distance: "Distancia",
              hr_med: "FC Promedio",
              session_date: "date",
              kcal: "Calorías",
              hr_max: "FC Max",
            };
            // let timeDiff= (item)=>new Date(item) - firstDate
            const newData = {};
            for (const key in data) {
              newData[key] = data[key].map((item, index) => {
                const newItem = {};
                for (const prop in item) {
                  if (prop === "session_date") {
                    newItem[keyMap[prop] || prop] = moment(item[prop]);
                  } else {
                    if (Logs.includes("1004") && prop === "hr_med") {
                      newItem[keyMap[prop] || prop] = item[prop];
                    } else if (Logs.includes("1005") && prop === "hr_max") {
                      newItem[keyMap[prop] || prop] = item[prop];
                    } else if (Logs.includes("1001") && prop === "distance") {
                      newItem[keyMap[prop] || prop] = item[prop];
                    } else if (Logs.includes("1002") && prop === "kcal") {
                      newItem[keyMap[prop] || prop] = item[prop];
                    }
                  }
                }
                return newItem;
              });
            }
            const newDatad = Object.values(newData).map((array) => array[0]);

            let userImage = swiperTrainings.filter((e) =>
              Object.keys(data).includes(String(e.id))
            );
            const newDataUsers = userImage.map((item) => {
              const customerData = totalCustomers.find(
                (customer) => customer.id === item.customer
              );
              return {
                date: item.date,
                customer: customerData ? customerData : item.customer,
              };
            });
            // reorder
            function reorderProperties(obj, order) {
              const reorderedObj = {};
              for (const key of order) {
                if (obj.hasOwnProperty(key)) {
                  reorderedObj[key] = obj[key];
                }
              }
              // Añadir las propiedades restantes que no están en el orden específico
              for (const key in obj) {
                if (!reorderedObj.hasOwnProperty(key)) {
                  reorderedObj[key] = obj[key];
                }
              }
              return reorderedObj;
            }

            const order = ["FC Promedio", "FC Max", "Distancia", "Calorías"];
            const reorderedData = newDatad.map((item) =>
              reorderProperties(item, order)
            );
            setloading(false);
            setNewDataUsers(newDataUsers);
            setGraficBarData(reorderedData);
          }
        }
      } catch (error) {
        setloading(false);
        console.error(error);
      }
    };
    const fetchDataMetrics = async () => {
      try {
        if (nuevoLog.length > 0) {
          if (Logs.includes("18") && Logs.length == 1) {
            const responseData = IdCustomer.reduce((acc, customer) => {
              // Encuentra el cliente correspondiente en totalCustomers
              const customerData = totalCustomers.find((c) => c.id == customer);

              // Si no se encuentra el cliente, omite este paso
              if (!customerData) return acc;

              // Agrega la estructura deseada al objeto acumulador
              acc[customer] = [
                {
                  18: [
                    { date: startDate, value: customerData.age },
                    { date: endDate, value: customerData.age },
                  ],
                },
              ];

              return acc;
            }, {});

            const dataX = [];

            for (const key in responseData) {
              responseData[key].forEach((item, index) => {
                const seriesKey = Object.keys(item)[0];
                const keyNumber = parseInt(key);
                if (isNaN(keyNumber)) {
                  return; // O manejar el error de alguna otra manera
                }
                const adjustedIndex = (keyNumber + index) % (colors.length - 1);
                const colorIndex =
                  adjustedIndex < 0
                    ? adjustedIndex + colors.length
                    : adjustedIndex;

                const seriesData = item[seriesKey].map((entry) => ({
                  date: moment(entry.date).format().toString(),
                  price: entry.value,
                }));

                dataX.push({
                  title: seriesKey,
                  color: colors[colorIndex],
                  data: seriesData,
                  user: key,
                });
              });
            }
            setloading(false);
            setDataResultMetrics(dataX);
          } else {
            const res = await instance.get(
              Logs.includes("19") || Logs.includes("20")
                ? `customers/analytics/injuries/?customers=${stringCustomers}&parts=${
                    Logs.includes("19") && InjurieId
                      ? InjurieId
                      : `1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17`
                  }&start_date=${startDate}&end_date=${endDate}`
                : Logs.includes("16") || Logs.includes("17")
                ? `customers/analytics/healthlog/?sessions=${stringNuevo}&types=${nuevoLog[0]}&step_size=seconds&start_date=${startDate}&end_date=${endDate}`
                : `customers/analytics/healthlog/?customers=${stringCustomers}&types=${
                    Logs.includes("14") ? stringLogs + ",15" : stringLogs
                  }&step_size=days&start_date=${startDate}&end_date=${endDate}`
            );

            const responseData = res.data;

            if (responseData && Object.keys(responseData).length > 0) {
              if (Logs.includes("18")) {
                Object.keys(responseData).forEach((key) => {
                  responseData[key].push({
                    18: [
                      {
                        date: startDate,
                        value: totalCustomers.find(
                          (customer) => customer.id == key
                        )?.age,
                      },
                      {
                        date: endDate,
                        value: totalCustomers.find(
                          (customer) => customer.id == key
                        )?.age,
                      },
                    ],
                  });
                });
              }
              if (Logs.includes("19") || Logs.includes("20")) {
                Object.keys(responseData).forEach((key) => {
                  let item = responseData[String(key)];
                  const numberLog = Logs.includes("19") ? 19 : 20;
                  if (numberLog == 19) {
                    responseData[String(key)].push({
                      19: item.map((injurie) => {
                        return {
                          date: injurie?.date,
                          value: injurie?.total,
                          injuries: injurie?.injuries,
                        };
                      }),
                    });
                    responseData[String(key)] = responseData[
                      String(key)
                    ].filter((item) => item.hasOwnProperty("19"));
                  } else {
                    responseData[String(key)].push({
                      20: item.map((injurie) => {
                        return {
                          date: injurie?.date,
                          value: injurie?.total,
                          injuries: injurie?.injuries,
                        };
                      }),
                    });

                    responseData[String(key)] = responseData[
                      String(key)
                    ].filter((item) => item.hasOwnProperty("20"));
                  }
                });
              }
              // let timeDiff= (item)=>new Date(item) - firstDate
              const dataX = [];

              for (const key in responseData) {
                responseData[key].forEach((item, index) => {
                  const seriesKey = Object.keys(item)[0];
                  const seriesData =
                    Logs.includes("19") || Logs.includes("20")
                      ? item[seriesKey].map((entry) => ({
                          date: moment(entry.date).format().toString(),
                          price: entry.value,
                          injurie: entry.injuries,
                        }))
                      : item[seriesKey].map((entry) => ({
                          date: moment(entry.date).format().toString(),
                          price: entry.value,
                        }));
                  if (seriesData.length > 0) {
                    const keyOccurrences = {};
                    let globalIndex = 0;

                    const processKeys = (data) => {
                      for (const mainKey in data) {
                        const array = data[mainKey];
                        array.forEach((item) => {
                          const key = Object.keys(item)[0];
                          if (!keyOccurrences[mainKey]) {
                            keyOccurrences[mainKey] = {};
                          }
                          if (!keyOccurrences[mainKey][key]) {
                            keyOccurrences[mainKey][key] = [];
                          }
                          keyOccurrences[mainKey][key].push(globalIndex);
                          globalIndex++;
                        });
                      }
                    };

                    processKeys(responseData);

                    // console.log(Object.keys(responseData),Object.keys(responseData).findIndex(item=>item==key))
                    dataX.push({
                      title: seriesKey,
                      color:
                        Logs.includes("16") || Logs.includes("17")
                          ? colors[
                              Object.keys(responseData).findIndex(
                                (item) => item == key
                              )
                            ]
                          : colors[keyOccurrences[key][seriesKey][0]],
                      data: seriesData,
                      user:
                        Logs.includes("16") || Logs.includes("17")
                          ? swiperTrainings.find((swip) => swip.id == key)
                              ?.customer
                          : key,
                    });
                  }
                });
              }
              setloading(false);
              setDataResultMetrics(dataX);
            } else {
              setloading(false);
              setDataResultMetrics([]);
            }
          }
        }
      } catch (error) {
        setloading(false);
        console.error(error);
      }
    };
    if (IdGraphic == 1) {
      if (Logs.includes("1006") || Logs.includes("99")) {
        fetchData();
      }
      if (
        Logs.includes("16") ||
        Logs.includes("17") ||
        Logs.includes("14") ||
        Logs.includes("19") ||
        Logs.includes("20")
      ) {
        fetchDataMetrics();
      } else {
        if (
          Logs.includes("1001") ||
          Logs.includes("1004") ||
          Logs.includes("1002") ||
          Logs.includes("1005")
        ) {
          fetchDataBar();
        }
      }
    } else {
      setSwiperTrainings(null);
      fetchDataMetrics();
    }
    if (IdGraphic) {
      reviewName();
    }
    // setTimeout(() => {

    //   setloading(false);
    // }, 1000);
  }, [
    Logs,
    startDate,
    endDate,
    selectOneDiscipline,
    IdGraphic,
    IdCustomer,
    swiperTrainings,
    InjurieId,
  ]);

  useEffect(() => {
    if (localStorage.elevvaSwiper && IdGraphic == 1) {
      setSwiperTrainings(JSON.parse(localStorage.elevvaSwiper));
    } else {
      setSwiperTrainings(null);
    }
  }, [IdGraphic]);
  useEffect(() => {
    if (IdCustomer.length > 0) {
      if (SelectedGraphicBoolean) {
        setSelectedGraphicBoolean(false);
      } else {
        setselectOneDiscipline([]);
      }
      const stringCustomers = IdCustomer.join(",");
      instance
        .get(
          `customers/analytics/sessions/?customers=${stringCustomers}&disciplines=${trainingIdOption}&start_date=${startDate}&end_date=${endDate}`
        )
        .then((res) => {
          const mor = res.data;
          if (mor) {
            localStorage.elevvaSwiper = JSON.stringify(mor);
            setSwiperTrainings(mor);
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
          }
        });
      // instance
      // .get(
      //   `customers/analytics/healthlog/?customers=37&types=1,2&step_size=days&start_date=2024-01-01&end_date=2024-04-30`
      // )
      // .then( (res) => {
      //   const mor = res.data;
      //   if (mor) {

      //   }
      // })
      // .catch((err) => {
      //   if (err.response) {
      //     console.log(err.response);
      //   }
      // });
    }
  }, [IdCustomer, trainingIdOption]);
  // useEffect(() => {
  //   let composicion = [
  //     "Proteína",
  //     "Peso",
  //     "IMC",
  //     "Masa muscular",
  //     "Grasa corporal",
  //     "Grasa subcutánea",
  //     "Grasa visceral",
  //     "Masa esquelética",
  //     "Agua corporal",
  //     "Músculo esquelético",
  //     "BMR",
  //   ];
  //   let ALLCHECK = document.querySelectorAll('input[name="metrics"]');
  //   ALLCHECK.forEach((checkboxElement) => {
  //     if (IdGraphic && IdGraphic == 2) {
  //       if (!composicion.includes(checkboxElement.dataset.custom)) {
  //         checkboxElement.parentNode.style = "opacity:0.6";
  //         checkboxElement.disabled = true;
  //       }
  //     } else {
  //       checkboxElement.parentNode.style = "opacity:1";
  //       checkboxElement.disabled = false;
  //     }
  //   });
  // }, [IdGraphic]);
  useEffect(() => {
    let ALLCHECK = document.querySelectorAll('input[name="customerList"]');
    if (Logs.includes("1006") && IdCustomer.length == 1) {
      ALLCHECK.forEach((checkboxElement) => {
        if (!checkboxElement.checked) {
          checkboxElement.disabled = true;
        }
      });
    } else {
      if (Logs.includes("1006") && IdCustomer.length > 1) {
        setNotPosible(
          `No puedes comparar <br><strong>las zonas de frecuencia de 2 o más atletas</strong>`
        );
      } else {
        ALLCHECK.forEach((checkboxElement) => {
          if (!checkboxElement.checked) {
            checkboxElement.disabled = false;
          }
        });
      }
    }
  }, [Logs, IdCustomer]);

  const getTotalTrainings = () => {
    instance
      .get("trainings/disciplines/")
      .then((res) => res.data)
      .then((data) => {
        if (data) {
          console.log(data, "getTotalTrainings");
          setTotalTrainings(data);
        }
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    instance
      .get("memberships/corp/memberships")
      .then((res) => res.data)
      .then((data) => {
        if (data.length == 1) {
          setMembershipOptionId(data[0].id);
        }
        settotalMemberships(data);
      })
      .catch((err) => console.error(err));
    instance
      .get("memberships/corp/graphdata/")
      .then((res) => res.data)
      .then((data) => {
        settotalGraphicsSave(data);
      })
      .catch((err) => console.error(err));
    getTotalTrainings();
    picker();
    axios.get("/data/paises.json").then((res) => setPaises(res.data));
    localStorage.removeItem("elevvaSwiper");
    localStorage.removeItem("elevvaLogs");
  }, []);

  const handleDateApplyEvent = (event, picker) => {
    if (event) {
      let startDate = event[0];
      let endDate = event[1];
      if (startDate && endDate) {
        // console.log(moment(event[0]).format("DD-MM-YYYY"));

        setStartDate(Moment(startDate).format("YYYY-MM-DD"));
        setEndDate(Moment(endDate).format("YYYY-MM-DD"));
      }
    } else {
      setDateRange(null);
      setEndDate(Moment());
      setStartDate(Moment().subtract(7, "days"));
    }
  };
  function onlyOne(checkbox) {
    setselectOneDiscipline([]);
    if (IdCustomer.includes(checkbox.value) && !checkbox.checked) {
      let filtro = IdCustomer.filter((e) => e != checkbox.value);
      setIdCustomer(filtro);
    } else {
      if (checkbox.checked) {
        setIdCustomer((prevArray) => [...prevArray, checkbox.value]);
      }
    }
    // if (checkbox.checked) {
    //   console.log(checkbox);
    //   setIdCustomer((prevState) => [...prevState, checkbox.value]);
    //   // setIdCustomer(checkbox.value);
    // }
  }
  function resetTotal() {
    // if (localStorage.elevvaLogs && idGraphic == 1) {
    //   let storageLogs = JSON.parse(localStorage.elevvaLogs);
    //   setLogs(storageLogs);
    //   console.log(storageLogs);
    // } else {
    setLogs([]);

    let ALLCHECK = document.querySelectorAll('input[name="metrics"]');
    ALLCHECK.forEach((checkboxElement) => {
      checkboxElement.parentNode.style = "opacity:1";
      checkboxElement.checked = false;
    });
  }
  function reset() {
    setLogs([]);
    let ALLCHECK = document.querySelectorAll(".sesion-checkbox");
    ALLCHECK.forEach((checkboxElement) => {
      checkboxElement.parentNode.style = "opacity:1";
      checkboxElement.checked = false;
      checkboxElement.disabled = false;
    });
  }
  function reset_two() {
    setLogs([]);
    let ALLCHECK = document.querySelectorAll(".sesion-checkbox-corporal");
    ALLCHECK.forEach((checkboxElement) => {
      checkboxElement.parentNode.style = "opacity:1";
      checkboxElement.checked = false;
      checkboxElement.disabled = false;
    });
  }

  function checkealo(checkbox) {
    let selectedButton = checkbox.dataset.custom;
    let frecuencia_cardiaca = ["Tiempo", "Zonas FC", "FC"];
    let SDNN = ["SDNN"];
    let RMSSD = ["RMSSD"];
    let tension = ["Tensión"];
    let edad_corporal = ["Edad Corporal", "Edad real"];
    let BMR = ["BMR"];
    let IMC = ["IMC"];
    let Proteina = ["Proteína"];
    let AguaCorporal = ["Agua corporal"];
    let total_lesiones = ["Total lesiones"];
    let tipo_lesion = ["Tipo de lesión"];
    let frecuencia_cardiaca_promedio = [
      "Tiempo",
      "Distancia",
      "FC Máxima",
      "Calorías",
      "FC Promedio",
    ];
    let composicion = [
      "Peso",
      "Masa muscular",
      "Grasa corporal",
      "Grasa subcutánea",
      "Grasa visceral",
      "Masa esquelética",
      "Músculo esquelético",
    ];
    let ALLCHECK = document.querySelectorAll('input[name="metrics"]');
    let checkedCount = 0;
    let i = 0;
    let agruparesiones = [];

    while (checkedCount < 3 && i < ALLCHECK.length) {
      if (
        (frecuencia_cardiaca.includes(ALLCHECK[i].dataset.custom) &&
          frecuencia_cardiaca.includes(selectedButton)) ||
        (frecuencia_cardiaca_promedio.includes(ALLCHECK[i].dataset.custom) &&
          frecuencia_cardiaca_promedio.includes(selectedButton)) ||
        (SDNN.includes(ALLCHECK[i].dataset.custom) &&
          SDNN.includes(selectedButton)) ||
        (RMSSD.includes(ALLCHECK[i].dataset.custom) &&
          RMSSD.includes(selectedButton)) ||
        (tension.includes(ALLCHECK[i].dataset.custom) &&
          tension.includes(selectedButton)) ||
        (edad_corporal.includes(ALLCHECK[i].dataset.custom) &&
          edad_corporal.includes(selectedButton)) ||
        (IMC.includes(ALLCHECK[i].dataset.custom) &&
          IMC.includes(selectedButton)) ||
        (AguaCorporal.includes(ALLCHECK[i].dataset.custom) &&
          AguaCorporal.includes(selectedButton)) ||
        (Proteina.includes(ALLCHECK[i].dataset.custom) &&
          Proteina.includes(selectedButton)) ||
        (BMR.includes(ALLCHECK[i].dataset.custom) &&
          BMR.includes(selectedButton)) ||
        (total_lesiones.includes(ALLCHECK[i].dataset.custom) &&
          total_lesiones.includes(selectedButton)) ||
        (tipo_lesion.includes(ALLCHECK[i].dataset.custom) &&
          tipo_lesion.includes(selectedButton)) ||
        (composicion.includes(ALLCHECK[i].dataset.custom) &&
          composicion.includes(selectedButton))
      ) {
        ALLCHECK[i].parentNode.style = "opacity:1";
        ALLCHECK[i].disabled = false;
      } else {
        ALLCHECK[i].parentNode.style = "opacity:0.6";
        ALLCHECK[i].disabled = true;
      }

      if (ALLCHECK[i] && ALLCHECK[i].checked) {
        agruparesiones.push(ALLCHECK[i].value);
        checkedCount++;
      }
      i++;
    }
    localStorage.elevvaLogs = JSON.stringify(agruparesiones);

    setLogs(agruparesiones);

    if (checkedCount >= 3) {
      ALLCHECK.forEach((checkboxElement) => {
        if (checkboxElement.checked == false) {
          checkboxElement.parentNode.style = "opacity:0.6";
          checkboxElement.disabled = true;
        }
      });
      return; // Si no hay al menos 3 elementos marcados, termina la función
    }
    if (checkedCount == 0) {
      if (IdGraphic == 1) {
        reset();
      } else {
        reset_two();
      }
    }
  }
  function onlyGrafic(checkbox) {
    resetTotal(checkbox.value);

    setIdGraphic(checkbox.value);
  }
  useEffect(() => {
    if (MembershipOptionId) {
      instance
        .get(`memberships/corp/${MembershipOptionId}/members/`)
        .then((res) => res.data)
        .then((data) => {
          settotalCustomers(data);

          // setIdCustomer(data[0].customer);
        })
        .catch((err) => console.error(err));
    }
  }, [MembershipOptionId]);
  async function deleteFilteredAdvanced() {
    setCountry("");
    setSelectedItemsObjectives([]);
    setBackupCustomers(null);
    if (MembershipOptionId) {
      await instance
        .get(`memberships/corp/${MembershipOptionId}/members/`)
        .then((res) => res.data)
        .then((data) => {
          settotalCustomers(data);

          // setIdCustomer(data[0].customer);
        })
        .catch((err) => console.error(err));
    }
  }

  function chargeInfo(value, e) {
    const {
      MembershipOption,
      id_raphic,
      total_customers,
      dateRange,
      trainingIdOption,
      disciplines,
      metrics,
      swiperT,
    } = value.data;

    if (ValueSaveGrafic.includes(value.id)) {
      let all_check_ListGraph = document.querySelectorAll(
        'input[name="customerListGraph"]'
      );
      all_check_ListGraph.forEach((checkboxElement) => {
        checkboxElement.checked = false;
      });
      let filtro = ValueSaveGrafic.filter((e) => e != value.id);

      setValueSaveGrafic(filtro);
    } else {
      setValueSaveGrafic((prevArray) => [...prevArray, value.id]);
      setSelectedGraphicBoolean(true);
      let all_check_IdGraphic = document.querySelectorAll(
        'input[name="graphics"]'
      );
      let all_check_IdCustomer = document.querySelectorAll(
        'input[name="customerList"]'
      );
      let all_check_metrics = document.querySelectorAll(
        'input[name="metrics"]'
      );

      all_check_IdGraphic.forEach((checkboxElement) => {
        if (checkboxElement.value == id_raphic) {
          checkboxElement.checked = true;
        }
      });
      all_check_IdCustomer.forEach((checkboxElement) => {
        if (total_customers.includes(String(checkboxElement.value))) {
          checkboxElement.checked = true;
        } else {
          checkboxElement.checked = false;
        }
      });
      all_check_metrics.forEach((checkboxElement) => {
        if (metrics.includes(String(checkboxElement.value))) {
          checkboxElement.checked = true;
        } else {
          checkboxElement.checked = false;
        }
      });

      if (id_raphic == 1) {
        settrainingIdOption(trainingIdOption);
        setselectOneDiscipline(disciplines);
      }
      localStorage.elevvaLogs = JSON.stringify(metrics);
      setLogs(metrics);
      localStorage.elevvaSwiper = JSON.stringify(swiperT);
      setSwiperTrainings(swiperT);
      setIdCustomer(total_customers);
      setIdGraphic(id_raphic);
      setDateRange([
        new Date(Moment(dateRange[0]).format()),
        new Date(Moment(dateRange[1]).format()),
      ]);
      setStartDate(dateRange[0]);
      setEndDate(dateRange[1]);
    }

    //  setMembershipOptionId(2);
  }
  function changeOptionGraphics(valuesearch) {
    if (totalGraphicsSave) {
      if (valuesearch != "") {
        let valueLower = valuesearch.toLowerCase();
        let filterNames = totalGraphicsSave.filter((memb) =>
          memb.name.toLowerCase().includes(valueLower)
        );
        settotalGraphicsSave(filterNames);
      } else {
        instance
          .get("memberships/corp/graphdata/")
          .then((res) => res.data)
          .then((data) => {
            settotalGraphicsSave(data);
          })
          .catch((err) => console.error(err));
      }
    }
  }
  function changeOptionVideos(valuesearch) {
    if (totalCustomers) {
      if (valuesearch != "") {
        let valueLower = valuesearch.toLowerCase();
        let filterNames = totalCustomers.filter(
          (memb) =>
            memb.last_name.toLowerCase().includes(valueLower) ||
            memb.first_name.toLowerCase().includes(valueLower)
        );
        settotalCustomers(filterNames);
      } else {
        if (BackupCustomers) {
          settotalCustomers(BackupCustomers);
        } else {
          if (MembershipOptionId) {
            instance
              .get(`memberships/corp/${MembershipOptionId}/members/`)
              .then((res) => res.data)
              .then((data) => {
                settotalCustomers(data);

                // setIdCustomer(data[0].customer);
              })
              .catch((err) => console.error(err));
          }
        }
      }
    } else {
      if (valuesearch != "") {
        let styleModal = {
          display: "flex",
          position: "fixed",
          zIndex: 999,
          whiteSpace: "pre-line",
        };
        setModal(
          <div
            className="popUpPassword justify-content-center align-items-center"
            style={styleModal}
          >
            <div
              className="modalInfoContenido"
              style={{
                background: "white",
                borderRadius: "20px",
                boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
                padding: "2% 6%",
              }}
            >
              <div
                className=""
                style={{
                  position: "absolute",
                  top: "5%",
                  right: "1%",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  color: " #9DACBD",
                  cursor: "pointer",
                }}
                onClick={() => setModal(null)}
              >
                <i className="bi bi-x-circle"></i>
              </div>
              <div
                className=" mb-3 py-3"
                style={{
                  lineHeight: "100%",
                  textAlign: "center",

                  minWidth: "calc(18rem + 1vmin)",

                  color: "var(--elevvabutonLogin_User)",
                }}
              >
                <br />
                Debes seleccionar <br /> una membresía.
                <br />
              </div>
            </div>
          </div>
        );
      }
    }
    // setfiltervideos(videofiltro);
  }

  const optionLogs = [
    {
      id: 99,
      name: `FC`,
      image: <FrecuenciaImage />,
      nameComplete: "Frecuencia Cardíaca",
    },
    {
      id: 1001,
      name: `Distancia`,
      nameComplete: "Distancia",
      image: <DistanciaImage />,
    },
    {
      id: 1002,
      name: `Calorías`,
      nameComplete: "Calorías",
      image: <CaloriasImage />,
    },
    { id: 1003, name: `Tiempo`, image: <Tiempo />, nameComplete: "Tiempo" },
    {
      id: 1004,
      name: `FC Promedio`,
      nameComplete: "FC Promedio",
      image: <FcPromedioImage />,
    },
    {
      id: 1005,
      name: `FC Máxima`,
      nameComplete: "FC Máxima",
      image: <FcMaximaImage />,
    },
    { id: 1006, name: `Zonas FC`, nameComplete: "Zonas FC", image: null },
    { id: 16, name: `RMSSD`, nameComplete: "RMSSD", image: <RmssdImage /> },
    { id: 17, name: `SDNN`, nameComplete: "SDNN", image: <SdnnImage /> },
    {
      id: 14,
      name: `Tensión`,
      nameComplete: "Tensión Arterial",
      image: <TensionArterialImage />,
    },
    {
      id: 20,
      name: `Total lesiones`,
      nameComplete: "Total lesiones",
      image: <TipoLesionImage />,
    },
    {
      id: 19,
      name: `Tipo de lesión`,
      nameComplete: "Tipo de lesión",
      image: <TipoLesionImage />,
    },
    {
      id: 13,
      name: `Edad Corporal`,
      nameComplete: "Edad Corporal",
      image: <EdadCorporalImage />,
    },
    {
      id: 18,
      name: `Edad real`,
      nameComplete: "Edad real",
      image: <EdadRealImage />,
    },
    // { id: 17, name: `Estatura`, nameComplete: "Estatura" },
    { id: 1, name: `Peso`, nameComplete: "Peso", image: <PesoImage /> },
    {
      id: 3,
      name: `Grasa corporal`,
      nameComplete: "Grasa corporal",
      image: <GrasaCorporalImage />,
    },
    {
      id: 5,
      name: `Grasa subcutánea`,
      nameComplete: "Grasa subcutánea",
      image: <GrasaSubcutaneaImage />,
    },
    {
      id: 11,
      name: `Proteína`,
      nameComplete: "Proteína",
      image: <ProteinaImage />,
    },
    {
      id: 6,
      name: `Grasa visceral`,
      nameComplete: "Grasa visceral",
      image: <GrasaVisceralImage />,
    },
    {
      id: 7,
      name: `Agua corporal`,
      nameComplete: "Agua corporal",
      image: <AguaCorporalImage />,
    },
    {
      id: 8,
      name: `Músculo esquelético`,
      nameComplete: "Músculo esquelético",
      image: <MusculoEsqueleticoImage />,
    },
    { id: 2, name: `IMC`, nameComplete: "IMC", image: <ImcImage /> },
    {
      id: 10,
      name: `Masa muscular`,
      nameComplete: "Masa muscular",
      image: <MasaMuscularImage />,
    },
    {
      id: 9,
      name: `Masa esquelética`,
      nameComplete: "Masa esquelética",
      image: <MasaEsqueleticaImage />,
    },
    { id: 12, name: `BMR`, nameComplete: "BMR", image: <BmrImage /> },
  ];
  const optionGraphicMetrics = [
    { id: 1001, name: `Distancia` },
    { id: 1002, name: `Calorías` },
    { id: 1003, name: `Tiempo` },
  ];
  const totalInjuries = [
    { id: 8, name: `Lesión Abdomen` },
    { id: 9, name: `Lesión Ingle Cadera` },
    { id: 1, name: `Lesión Cuello` },
    { id: 3, name: `Lesión hombro derecho` },
    { id: 5, name: `Lesión codo derecho` },
    { id: 7, name: `Lesión Mano derecha` },
    { id: 2, name: `Lesión hombro izquierdo` },
    { id: 4, name: `Lesión codo izquierdo` },
    { id: 6, name: `Lesión Mano izquierda` },
    { id: 10, name: `Lesión rodilla izquierda` },
    { id: 11, name: `Lesión rodilla derecha` },
    { id: 12, name: `Lesión pie izquierdo` },
    { id: 13, name: `Lesión pie derecho` },
    { id: 14, name: `Lesión columna alta` },
    { id: 17, name: `Lesión lumbares` },
    { id: 15, name: `Lesión gluteós` },
    { id: 16, name: `Lesión columna baja` },
  ];
  const optionGraphicMetricsCardio = [
    { id: 99, name: `FC` },
    { id: 1004, name: `FC Promedio` },
    { id: 1005, name: `FC Máxima` },
    { id: 1006, name: `Zonas FC` },
    { id: 16, name: `RMSSD` },
    { id: 17, name: `SDNN` },
  ];
  const optionGraphicMetricsSalud = [
    { id: 14, name: `Tensión` },
    { id: 20, name: `Total lesiones` },
    { id: 19, name: `Tipo de lesión` },
    { id: 13, name: `Edad Corporal` },
    { id: 18, name: `Edad real` },
  ];
  const optionGraphicMetricsCompo = [
    { id: 1, name: `Peso` },
    { id: 3, name: `Grasa corporal` },
    { id: 5, name: `Grasa subcutánea` },
    { id: 11, name: `Proteína` },
    { id: 6, name: `Grasa visceral` },
    { id: 7, name: `Agua corporal` },
    { id: 8, name: `Músculo esquelético` },
    { id: 2, name: `IMC` },
    { id: 10, name: `Masa muscular` },
    { id: 9, name: `Masa esquelética` },
    { id: 12, name: `BMR` },
  ];
  const optionGraphic = [
    { id: 1, name: `GRÁFICA DE ACTIVIDAD <br/>POR SESIÓN` },
    { id: 2, name: `GRÁFICA DE COMPOSICIÓN <br/>CORPORAL` },
  ];
  // const totalTrainings = [
  //   { id: 4, name: `Reposo` },
  //   { id: 3, name: `Caminar` },
  //   { id: 5, name: `Libre` },
  //   { id: 2, name: `Bicicleta` },
  //   { id: 1, name: `Correr` },
  // ];

  function addOrRemoveSesion(id) {
    if (selectOneDiscipline.includes(id)) {
      let filtro = selectOneDiscipline.filter((e) => e != id);

      setselectOneDiscipline(filtro);
    } else {
      if (selectOneDiscipline.length < 6) {
        setselectOneDiscipline((prevArray) => [...prevArray, id]);
      } else {
        alert("no puedes seleccionar más de 6 sesiones");
      }
    }
  }
  function devolverColor(id) {
    let sel = selectOneDiscipline.sort((a, b) => a - b).indexOf(id);
    console.log(
      "selectOneDiscipline",
      selectOneDiscipline.sort((a, b) => a - b)
    );
    let color = colors[sel];

    return `3px solid ${color}`;
  }
  const render_img_desk =
    swiperTrainings &&
    totalCustomers &&
    swiperTrainings.map((imagen, index) => {
      return (
        <SwiperSlide key={index}>
          <div
            onClick={() => addOrRemoveSesion(imagen.id)}
            className="d-flex position-relative flex-column w-auto"
            style={{
              cursor: "pointer",
              borderRadius: "20px",
              border: "1px solid gray",
              padding: "3% 0%",
              minWidth: laptop1600.matches ? "7rem" : "80px",
              minHeight: laptop1600.matches ? "8.7rem" : "6rem",
            }}
          >
            <span
              className="p-1 mx-auto"
              style={{
                fontSize: "calc(0.4rem + 0.4vw)",
                lineHeight: laptop1600.matches ? "auto" : "1",
                maxWidth: "90%",
              }}
            >
              {
                totalCustomers.filter((e) => e.id == imagen.customer)[0]
                  ?.first_name
              }
            </span>

            <span
              className="m-auto d-block"
              style={
                selectOneDiscipline && selectOneDiscipline.includes(imagen.id)
                  ? { width: "60%", borderBottom: devolverColor(imagen.id) }
                  : null
              }
            ></span>

            <span
              className="my-1"
              style={
                selectOneDiscipline && selectOneDiscipline.includes(imagen.id)
                  ? {
                      color: "#fff",
                      background: "#394F65",
                      fontWeight: "bold",
                      fontSize: "calc(0.4rem + 0.4vw)",
                    }
                  : {
                      color: "#394F65",
                      background: "#fff",
                      fontWeight: "bold",
                      fontSize: "calc(0.4rem + 0.4vw)",
                    }
              }
            >
              {imagen.discipline.name}
            </span>
            <span
              style={{
                fontSize: "calc(0.4rem + 0.4vw)",
                lineHeight: laptop1600.matches ? "auto" : "1",
              }}
              className="p-1"
            >
              {Moment(imagen.date).format("MMM DD-YY")} <br></br>
              {Moment(imagen.date).format("dddd")}
            </span>
          </div>
        </SwiperSlide>
      );
    });
  const objetivesCustomer = [
    { name: "Bajar de peso", icon: <Peso />, code: 10 },
    { name: "Mejorar mi salud", icon: <Diabetes />, code: 20 },
    { name: "Fortalecimiento de mi cuerpo", icon: <Estado />, code: 30 },
    { name: "Ganar masa muscular", icon: <Nutricion />, code: 40 },
    { name: "Tonificar mi cuerpo", icon: <Peso />, code: 50 },
    { name: "Recuperar y cuidar lesión", icon: <Tension />, code: 60 },
    { name: "Hipertensión", icon: <Tension />, code: 70 },
    { name: "Controlar diabetes", icon: <Diabetes />, code: 80 },
  ];
  const lines = [
    { label: "BAJO", y: 30 },
    { label: "NORMAL", y: 70 },
    { label: "ALTO", y: 110 },
    { label: "MUY ALTO", y: 150 },
  ];
  const widthLine = 200;
  const heightLine = 200;
  const padding = 20;
  function searchAdvanced() {
    let newcountry = country ? country : "";
    let newOnjectives =
      selectedItemsObjectives.length > 0
        ? selectedItemsObjectives.join(",")
        : "";

    instance
      .get(
        `memberships/corp/${MembershipOptionId}/members/?country=${newcountry}&objectives=${newOnjectives}`
      )
      .then((res) => res.data)
      .then((data) => {
        settotalCustomers(data);
        setBackupCustomers(data);
        setSearchActive(false);

        // setIdCustomer(data[0].customer);
      })
      .catch((err) => console.error(err));
  }

  const totality = [
    "rgba(210,210,210,0.5)",
    "rgba(0,76,255,0.5)",
    "rgba(0,252,109,0.5)",
    "rgba(252,134,0,0.5)",
    "rgba(255,0,0,0.5)",
  ];
  function reviewName() {
    /* observaciones */
    let observacionesFC = `<br><strong> Frecuencia Cardíaca en Reposo (FCR):</strong> Adultos mayores (>18 años): 60-100 lpm.<br> 
    <strong>Frecuencia Cardíaca Máxima (FCM):</strong> Fórmula básica: 220 - Edad , Rango general: 160-220 lpm. <br>
    <strong>Frecuencia Cardíaca durante el Ejercicio:</strong>
    <strong>Frecuencia cardíaca objetivo para el entrenamiento aeróbico:</strong> Generalmente entre el 50% y el 85% de la FCM, dependiendo de los objetivos de entrenamiento y el nivel de condición física.
    Para personas mayores y aquellos con ciertas condiciones médicas, los rangos pueden ajustarse para ser más conservadores.`;
    let observacionesCFCMAXIMA = `<strong>Frecuencia Cardíaca Máxima (FCM):</strong> Fórmula básica: 220 - Edad , Rango general: 160-220 lpm`;
    let observacionesZONASFC = `<br><br>Las zonas de entrenamiento cardiovascular se dividen en cinco rangos basados en el porcentaje de la frecuencia cardíaca máxima (FC máx). La Zona de Recuperación (50-60% de la FC máx) es para la recuperación activa; la Zona de Quema de Grasa (60-70% de la FC máx) favorece la quema de grasa; la Zona de Entrenamiento Aeróbico (70-80% de la FC máx) mejora la resistencia cardiovascular; la Zona de Umbral Anaeróbico (80-90% de la FC máx) incrementa la resistencia y la tolerancia al ácido láctico; y la Zona Máxima (90-100% de la FC máx) es para el desarrollo de la velocidad y la potencia máxima. Cada zona tiene objetivos específicos y ejercicios que se adaptan a diferentes niveles de intensidad, contribuyendo así a mejorar la capacidad cardiovascular y aeróbica.`;
    let observacionesSDNN = `<strong>Rango normal:</strong> Aproximadamente 50-100 ms. Es importante tener en cuenta que estos rangos pueden variar según la edad, el sexo, el nivel de condición física y otros factores individuales. Además, estos valores son solo rangos generales y no indican necesariamente una condición de salud específica. `;
    let observacionesRMSSD = `<br><strong>RMSSD:</strong><br> El rango normal de RMSSD puede variar dependiendo de la fuente y la población estudiada, pero generalmente, un RMSSD entre 20 y 50 ms se considera común y dentro de lo normal para la mayoría de las personas. Aquí se detallan los rangos de RMSSD típicamente considerados normales:<br>
    <strong>1. Baja VFC (RMSSD < 20 ms):</strong><br>
    <ul><li>Puede indicar niveles altos de estrés, fatiga o problemas de salud cardiovascular.</li></ul>
    <strong>2. Rango Normal (RMSSD 20-50 ms):</strong><br>
    <ul>
    <li> Indica un estado de salud típico, con un balance adecuado entre la actividad simpática y parasimpática.</li>
    <li>Representa una respuesta cardiovascular saludable.</li>
    </ul>
    <strong>3. Alta VFC (RMSSD > 50 ms):</strong><br>
    <ul>
    <li>Asociado con excelente condición física y robusta salud cardiovascular.</li>
    <li>Mayor predominio de la actividad parasimpática, beneficioso para la recuperación y el manejo del estrés.<br>
    Es importante recordar que estos rangos son aproximados y pueden variar según el contexto individual y la metodología de medición. La interpretación de RMSSD debe hacerse en el contexto de un análisis integral de la salud, preferiblemente con la orientación de un profesional de la salud.</li> <ul>`;
    let observacionesTENSION = `<br><strong>Presión Arterial Sistólica:</strong> Es la presión en las arterias cuando el corazón se contrae y bombea sangre hacia el cuerpo.Rango normal: Menos de 120 mmHg.    <br>                                                                                           <strong> Presión Arterial Diastólica:</strong> Es la presión en las arterias cuando el corazón está en reposo entre latidos. Rango normal: Menos de 80 mmHg.                                                                                            
    <br>  <strong> Presión Arterial Normal:</strong> Una presión arterial normal es cuando la sistólica es inferior a 120 mmHg y la diastólica es inferior a 80 mmHg, escrita como "120/80 mmHg".Rango normal Aproximadamente 20-50 ms. Es importante tener en cuenta que estos rangos pueden variar según la edad, el sexo, el nivel de condición física y otros factores individuales. Además, estos valores son solo rangos generales y no indican necesariamente una condición de salud específica. <br> `;
    let observacionesMASAMUSCULAR = `<br><strong>Masa muscular</strong><br>  En general, se considera que un porcentaje de masa muscular magra saludable oscila entre el 25% y el 38% en hombres y entre el 31% y el 43% en mujeres. `;
    let observacionesGRASASUBCUTANEA = `<br><strong>Grasa subcutánea</strong><br>  Se considera que un nivel de grasa subcutánea normal moderado oscila entre el 10-20% para hombres y entre el 18-28% para mujeres. Estos valores proporcionan una guía general para evaluar la cantidad de grasa subcutánea en el cuerpo, pero es importante tener en cuenta que los rangos pueden variar según las circunstancias individuales y las preferencias de salud.<br> `;
    let observacionesGRASACORPORAL = `<br><strong>Grasa corporal</strong><br>En términos generales, para hombres y mujeres en diferentes grupos de edad, se considera lo siguiente: Para hombres, en el rango de edad de 20 a 39 años, se define como bajo en grasa si es menor al 8%, saludable entre 8-20%, sobrepesos entre 20-25%, y sobrepeso si es del 25% o más; para hombres de 40 a 59 años, los rangos son <11%, 11-22%, 22-28%, y 28% o más, respectivamente; mientras que para hombres de 60 a 79 años, se definen como <13%, 13-25%, 25-30%, y 30% o más, respectivamente. Para mujeres, en el grupo de edad de 20 a 39 años, se considera bajo en grasa si es menor al 21%, saludable entre 21-33%, sobrepesos entre 33-39%, y sobrepeso si es del 39% o más; para mujeres de 40 a 59 años, los rangos son <23%, 23-35%, 35-40%, y 40% o más, respectivamente; y para mujeres de 60 a 79 años, se definen como <24%, 24-36%, 36-42%, y 42% o más, respectivamente. <br>`;
    let observacionesGRASAVISCERAL = `<br><strong>Grasa visceral</strong><br> Los rangos de grasa visceral pueden variar según la fuente y el método de medición utilizado, pero en general, se considera lo siguiente: un nivel de grasa visceral bajo es menos de 10 cm² para hombres y menos de 5 cm² para mujeres; un nivel moderado se encuentra entre 10-100 cm² para hombres y entre 5-80 cm² para mujeres; y un nivel alto es más de 100 cm² para hombres y más de 80 cm² para mujeres.<br> `;
    let observacionesIMC = `<br><strong>IMC</strong><br> El índice de masa corporal (IMC) e Las clasificaciones generales del IMC para adultos son las siguientes: Bajo peso: IMC menor a 18.5, Peso normal: IMC entre 18.5 y 24.9, Sobrepeso: IMC entre 25 y 29.9, y Obesidad: IMC de 30 o más. <br>`;
    let observacionesAGUACORPORAL = `<br><strong>Agua corporal </strong><br> Los rangos de agua corporal pueden variar según
    la fuente y el método de medición utilizado, pero en general, se considera lo
    siguiente: Nivel de agua corporal normal moderado: Entre 45-60% (para hombres) y entre 50-65 % (para mujeres) <br>`;
    let observacionesMUSCULOESQUELETICO = `<br><strong>Músculo esquelético</strong><br> Los rangos de musculo esqueletico pueden variar según la fuente y el método de medición utilizado, pero en general, se considera lo siguiente:· Nivel de musculo esquletico normal: 46% (para hombres) y 45 % (para mujeres)<br>`;
    let observacionesMASAESQUELETICA = `<br><strong>Masa esquelética</strong><br> Los rangos de masa esquelética pueden variar según la fuente y el método de medición utilizado, pero en general, se considera lo siguiente:Nivel de masa esquelética normal: Entre 3-5% (para hombres) y entre 2-3 % (para mujeres)<br>`;

    let observacionesBMR = `<br><strong>BMR </strong> (Basal Metabolic Rate) o Tasa Metabólica Basal es la cantidad mínima de energía que el cuerpo necesita para mantener sus funciones vitales en reposo durante 24 horas. Estas funciones incluyen la respiración, circulación sanguínea, control de la temperatura, crecimiento celular, y actividad cerebral y nerviosa. El BMR varía según la edad, sexo, peso, altura, composición corporal, factores genéticos y hormonales. Conocer el BMR es útil para diseñar dietas y programas de ejercicio, ya que indica cuántas calorías se necesitan para mantener el peso actual sin actividad física adicional.`;
    let observacionesPROTEINA = `<br><strong>Proteína </strong><br> Los rangos de Proteína  pueden variar según la fuente y el método de medición utilizado, pero en general, se considera lo siguiente: Nivel de proteína normal: Entre 16-20 %. `;
    /* titles names graphics */

    if (
      Logs.includes("10") ||
      Logs.includes("5") ||
      Logs.includes("3") ||
      Logs.includes("2") ||
      Logs.includes("6") ||
      Logs.includes("8") ||
      Logs.includes("9") ||
      Logs.includes("7") ||
      Logs.includes("99") ||
      Logs.includes("1006") ||
      Logs.includes("14") ||
      Logs.includes("16") ||
      Logs.includes("17") ||
      Logs.includes("1005") ||
      Logs.includes("1001") ||
      Logs.includes("1002") ||
      Logs.includes("1003") ||
      Logs.includes("1004") ||
      Logs.includes("20") ||
      Logs.includes("19") ||
      Logs.includes("13") ||
      Logs.includes("18") ||
      Logs.includes("1") ||
      Logs.includes("11") ||
      Logs.includes("12")
    ) {
      let ob = Logs.includes("10") ? observacionesMASAMUSCULAR : "";
      let startTite = `Gráfica de `;
      let TitleSesion = ` en sesión de entrenamiento`;
      let TitleSesions = ` en varias sesiones de entrenamiento`;
      let TitleCustomers = ` y comparando atletas`;
      let LogsTitles = Logs.map((log) => {
        let findElement = optionLogs.find((e) => e.id == log);

        return findElement ? findElement.nameComplete : "";
      });
      let LogsTitlesString = LogsTitles.join(", ");
      setobservationText(
        ob
          .concat(Logs.includes("5") ? observacionesGRASASUBCUTANEA : "")
          .concat(Logs.includes("3") ? observacionesGRASACORPORAL : "")
          .concat(Logs.includes("2") ? observacionesIMC : "")
          .concat(Logs.includes("6") ? observacionesGRASAVISCERAL : "")
          .concat(Logs.includes("7") ? observacionesAGUACORPORAL : "")
          .concat(Logs.includes("8") ? observacionesMUSCULOESQUELETICO : "")
          .concat(Logs.includes("9") ? observacionesMASAESQUELETICA : "")
          .concat(Logs.includes("99") ? observacionesFC : "")
          .concat(Logs.includes("1006") ? observacionesZONASFC : "")
          .concat(Logs.includes("14") ? observacionesTENSION : "")
          .concat(Logs.includes("16") ? observacionesRMSSD : "")
          .concat(Logs.includes("17") ? observacionesSDNN : "")
          .concat(Logs.includes("1005") ? observacionesCFCMAXIMA : "")
          .concat(Logs.includes("1006") ? observacionesZONASFC : "")
          .concat(Logs.includes("12") ? observacionesBMR : "")
          .concat(Logs.includes("11") ? observacionesPROTEINA : "")
      );

      settitleTextGraphic(
        startTite
          .concat(LogsTitlesString)
          .concat(selectOneDiscipline.length == 1 ? TitleSesion : TitleSesions)
          .concat(IdCustomer.length == 1 ? "" : TitleCustomers)
      );
    } else {
      settitleTextGraphic("");
      setobservationText("");
    }
  }
  function deleteGraphicSaveModal(id) {
    instance
      .delete(`memberships/corp/graphdata/${id}`)
      .then((res) => {
        if (res.status == "204") {
          let nuevo_saveTotals = totalGraphicsSave.filter(
            (save) => save.id !== id
          );

          settotalGraphicsSave(nuevo_saveTotals);
          setModal(null);
        }
      })

      .catch((err) => console.error(err));
  }
  function deleteGraphicSave(memb) {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModal(
      <div
        className="popUpDeleteGraphic justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
          }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "1%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModal(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div
            className=" mb-3 py-3"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              minWidth: "calc(18rem + 1vmin)",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            <br />
            <br />
            ¿Estás seguro(a) de <br /> borrar la gráfica <i>
              {memb.name}{" "}
            </i>? <br /> <br />
            <br />
            <ButtonMob
              handleClick={() => deleteGraphicSaveModal(memb.id)}
              colorButton={"var(--elevvabutonLogin_User)"}
              text="Confirmar"
              className={"mb-3 mt-3 letraspopfi2actualizar"}
            />
          </div>
        </div>
      </div>
    );
  }

  function save_graphic(event) {
    let nameGraphic = event.target[0].value;
    let formData = new FormData();
    let file = {
      MembershipOption: MembershipOptionId,
      id_raphic: IdGraphic,
      total_customers: IdCustomer,
      dateRange: [startDate, endDate],
      trainingIdOption: trainingIdOption,
      disciplines: selectOneDiscipline,
      metrics: Logs,
      swiperT: swiperTrainings
        ? swiperTrainings
        : JSON.parse(localStorage.elevvaSwiper),
    };

    formData.append("name", nameGraphic);
    formData.append("data", JSON.stringify(file));

    instance
      .post(`memberships/corp/graphdata/`, formData)
      .then((response) => {
        changeOptionGraphics("");
        setModal(null);
      })
      .catch((error) => {
        console.log(error);
      });
    event.preventDefault();
  }
  function ModalObservation() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModal(
      <div
        className="popUpDeleteGraphic justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
          }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "1%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModal(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div
            className=" mb-3 py-3"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              minWidth: "calc(18rem + 1vmin)",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            <br />
            <br />
            La información suministrada por
            <br />
            nuestros dispositivos de <br />
            medición{" "}
            <strong>
              {" "}
              son únicamente de <br /> carácter informativo; todas las <br />
              observaciones y decisiones de <br /> entrenamiento estarán a cargo{" "}
              <br /> del entrenador.
            </strong>{" "}
            <br />
            <br />
            <ButtonMob
              handleClick={() => setModal(null)}
              colorButton={"var(--elevvabutonLogin_User)"}
              text="Entendido"
              className={"mb-3 mt-3 letraspopfi2actualizar"}
            />{" "}
          </div>
        </div>
      </div>
    );
  }
  function GraphicSave() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModal(
      <div
        className="popUpDeleteGraphic justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
          }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "1%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModal(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div
            className=" mb-3 py-3"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              minWidth: "calc(18rem + 1vmin)",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            <br />
            <br />
            Por favor, introduce el nombre
            <br />
            para guardar el archivo <br /> <br />
            <br />
            <form
              id="form-save"
              onSubmit={(e) => {
                save_graphic(e);
              }}
            >
              <input
                className="form-control input-style-elevva "
                style={{
                  minWidth: "calc(18rem + 1vmin)",
                  fontSize: "calc(.4rem + .4vw)",
                }}
                name="tester"
                placeholder="Nombre archivo"
                required
              />
              <ButtonMob
                colorButton={"var(--elevvabutonLogin_User)"}
                text="Guardar"
                className={"mb-3 mt-3 letraspopfi2actualizar"}
              />{" "}
            </form>
          </div>
        </div>
      </div>
    );
  }
  const [dateRange, setDateRange] = useState(null);
  useEffect(() => {
    picker();
  }, [dateRange]);

  function picker() {
    setModalpicker(
      <CustomProvider locale={es_ES}>
        <Stack direction="column" alignItems="flex-start" spacing={6}>
          {dateRange ? (
            <DateRangePicker
              value={dateRange}
              onChange={handleDateApplyEvent}
              format="dd/MM/yyyy"
              character=" – "
              caretAs={ArrowDown}
              placeholder="SELECCIONA FECHA"
            />
          ) : (
            <DateRangePicker
              onChange={handleDateApplyEvent}
              format="dd/MM/yyyy"
              character=" – "
              caretAs={ArrowDown}
              placeholder="SELECCIONA FECHA"
            />
          )}
        </Stack>
      </CustomProvider>
    );
  }

  return (
    <div style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
      {modal}
      <div
        className="d-flex position-relative"
        style={{
          height: "60px",
          background: "var(--backgroundOnboarding)",
          columnGap: "2rem",
        }}
      >
        <SeleccionaMembresia
          styleComponent="input-style-elevva-corporativo"
          textStart="SELECCIONA MEMBRESÍA"
          totalMemberships={totalMemberships && totalMemberships}
          MembershipOptionId={MembershipOptionId}
          membershipIdOption={(e) => setMembershipOptionId(e)}
        />{" "}
        {Modalpicker}
        <div className="logo-supremacy">
          <img style={{ width: "6rem" }} src={LogoSupremacyBig}></img>
          {/* <LogoSupremacy /> */}
        </div>
      </div>
      <div
        style={{
          flexGrow: "1",
          overflow: "auto",
          columnGap: "calc(.5rem + .5vw)",
        }}
        className="d-flex py-2 px-3"
      >
        <div
          style={{
            width: "15%",

            borderRadius: "10px",
            height: "100%",
            padding: "1rem",
          }}
        >
          <div
            style={
              MembershipOptionId && endDate && startDate
                ? {
                    borderRadius: "10px",
                    height: "25%",
                    color: "var(--elevvaTextSubtitle)",
                  }
                : {
                    borderRadius: "10px",
                    height: "25%",
                    color: "var(--elevvaTextSubtitle)",
                    opacity: "0.4",
                    pointerEvents: "none",
                  }
            }
          >
            <span className="text-graphics-responsive">
              SELECCIÓN DE GRÁFICA
            </span>
            {optionGraphic.map((grap) => (
              <li
                className="text-left d-flex my-1 radialesOption"
                key={"customer" + grap.id}
              >
                {" "}
                <input
                  style={{ cursor: "pointer" }}
                  className="input-left-radio"
                  name="graphics"
                  type="radio"
                  id={"graphic" + grap.name}
                  value={grap.id}
                  onClick={(e) => onlyGrafic(e.target)}
                />{" "}
                <div
                  className="text-graphics-responsive"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {ReactHtmlParser(grap.name)}
                </div>
              </li>
            ))}
          </div>

          <div
            style={
              MembershipOptionId && endDate && startDate && IdGraphic
                ? {
                    border: "1px solid #B6C1CE",
                    borderRadius: "10px",
                    height: "75%",
                    padding: "0.7rem",
                  }
                : {
                    border: "1px solid #B6C1CE",
                    borderRadius: "10px",
                    height: "75%",
                    padding: "0.7rem",
                    opacity: "0.4",
                    pointerEvents: "none",
                  }
            }
          >
            <div
              style={{
                fontSize: "calc(.4rem + .4vw)",
                textAlign: "center",
                color: "var(--elevvaTextSubtitle)",
              }}
            >
              Comparar atletas
            </div>
            <br></br>
            <input
              placeholder="Ingresa atleta"
              type="search"
              className="searchAtletas py-1 mb-2 w-100"
              style={{
                border: "1px solid var(--elevvaTextSubtitle)",
                borderRadius: "20px",
              }}
              onChange={(e) => changeOptionVideos(e.target.value)}
            ></input>
            <div
              className="d-flex w-100 flex-column"
              style={{ textAlign: "center", rowGap: "0.5rem" }}
            >
              <button
                onClick={() => setSearchActive(true)}
                title="Búsqueda Avanzada"
                className="px-1 py-2 w-100"
                style={{
                  background: "#B5C1CD",
                  color: "#fff",
                  fontSize: "calc(.4rem + .4vw)",
                  border: "none",
                  borderRadius: "20px",
                }}
              >
                {laptop1600.matches ? "Búsqueda Avanzada" : "Búsqueda"}{" "}
                <ArrowDownWhite></ArrowDownWhite>
              </button>
              {selectedItemsObjectives.length > 0 || country.length > 0 ? (
                <button
                  onClick={deleteFilteredAdvanced}
                  title="Borrar Búsqueda"
                  className="px-1 py-2 w-100"
                  style={{
                    background: "#B5C1CD",
                    color: "#fff",
                    fontSize: "calc(.4rem + .4vw)",
                    border: "none",
                    borderRadius: "20px",
                  }}
                >
                  {laptop1600.matches ? "Borrar búsqueda" : "Borrar"}{" "}
                </button>
              ) : null}
            </div>
            <div className="d-flex w-100" style={{ justifyContent: "center" }}>
              {SearchActive ? (
                <div className="w-100 my-1">
                  <div
                    className="d-flex w-100 flex-column"
                    style={{
                      textAlign: "center",
                      maxWidth: laptop1350.matches ? "auto" : "12rem",
                    }}
                  >
                    <DropdownList
                      busy
                      busySpinner={<ArrowDown></ArrowDown>}
                      className={"input-style-elevva-graphics w-100 my-1"}
                      id="newScrollNacionality"
                      placeholder={"Nacionalidad"}
                      data={
                        Paises &&
                        Paises.map((timezone) => ({
                          id: timezone.isoCode,
                          name: timezone.name,
                        }))
                      }
                      dataKey="id"
                      textField="name"
                      onChange={(value) => setCountry(value.name)}
                      renderListItem={({ item }) => (
                        <div
                          className="w-100 mb-1"
                          style={{ borderBottom: "1px solid #9DACBD" }}
                        >
                          <div className="fw-bold">{item.name}</div>
                        </div>
                      )}
                    />
                    <Multiselect
                      busy
                      busySpinner={<ArrowDown></ArrowDown>}
                      className={
                        "input-style-elevva-graphics w-100 my-1 objective_style"
                      }
                      id="newScrollObjective"
                      placeholder={
                        laptop1350.matches
                          ? "Objetivo de bienestar"
                          : "Objetivos"
                      }
                      data={
                        objetivesCustomer &&
                        objetivesCustomer.map((timezone) => ({
                          id: timezone.code,
                          name: timezone.name,
                        }))
                      }
                      dataKey="id"
                      textField="name"
                      onChange={(value) => {
                        const selectedValues = value.map((item) => item.id);
                        setSelectedItemsObjectives(selectedValues);
                      }}
                      renderListItem={({ item }) => (
                        <div
                          className="w-100 mb-1"
                          style={{ borderBottom: "1px solid #9DACBD" }}
                        >
                          <div className="fw-bold">{item.name}</div>
                        </div>
                      )}
                    />
                    <button
                      onClick={searchAdvanced}
                      title="Buscar"
                      className="px-1 py-2 w-100 my-1"
                      style={{
                        background: "#394F65",
                        color: "#fff",
                        fontSize: "calc(.4rem + .4vw)",
                        border: "none",
                        borderRadius: "20px",
                      }}
                    >
                      Buscar
                    </button>
                  </div>
                </div>
              ) : (
                <PerfectScrollbar
                  className="w-100"
                  style={{
                    padding: "0",
                    listStyle: "none",
                    overflow: "auto",
                    height: "calc(40vmin + 0.5vw)",
                  }}
                >
                  {totalCustomers &&
                    totalCustomers.map((memb) => (
                      <li
                        className="text-left d-flex my-1"
                        key={"customer" + memb.id}
                      >
                        {" "}
                        <input
                          type="checkbox"
                          id={"customerList" + memb.customer}
                          name="customerList"
                          value={memb.customer}
                          onClick={(e) => onlyOne(e.target)}
                        />{" "}
                        <div
                          className="d-flex w-100"
                          style={{
                            padding: "calc(0.4rem + 0.4vw) 0",
                            fontSize: "calc(.4rem + .3vw)",
                            whiteSpace: "nowrap",
                            color: "#9DACBD",
                            borderBottom: "1px solid #9DACBD",
                          }}
                        >
                          <div className="d-flex align-items-center">
                            {" "}
                            <img
                              className="ms-2 me-1"
                              style={{
                                objectFit: "cover",
                                width: laptop1600.matches ? "30px" : "25px",
                                maxHeight: "100%",
                                maxWidth: "100%",
                                height: laptop1600.matches ? "30px" : "25px",
                                borderRadius: "50%",
                              }}
                              src={
                                memb.profile_pic
                                  ? memb.profile_pic
                                  : imageDefault
                              }
                            ></img>{" "}
                          </div>

                          <div
                            className={
                              laptop1600.matches
                                ? "d-flex align-items-center"
                                : ""
                            }
                            style={{ lineHeight: "0.9" }}
                          >
                            {laptop1600.matches ? (
                              <span
                                style={{
                                  fontSize: "calc(.4rem + .3vw)",
                                  lineHeight: "1.2",
                                }}
                              >
                                {memb.first_name.length +
                                  memb.last_name.length >
                                21
                                  ? memb.first_name +
                                    " " +
                                    memb.last_name.slice(0, 3) +
                                    ".."
                                  : memb.first_name + " " + memb.last_name}{" "}
                              </span>
                            ) : (
                              <span
                                style={{
                                  fontSize: "calc(.4rem + .3vw)",
                                  lineHeight: "1.2",
                                }}
                              >
                                {memb.first_name.length > 9
                                  ? memb.first_name.slice(0, 8) + ".."
                                  : memb.first_name}{" "}
                                <br></br>
                                {memb.last_name.length > 9
                                  ? memb.last_name.slice(0, 8) + ".."
                                  : memb.last_name}{" "}
                              </span>
                            )}
                          </div>
                        </div>
                      </li>
                    ))}
                </PerfectScrollbar>
              )}
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-column position-relative"
          style={
            MembershipOptionId && endDate && startDate && IdGraphic
              ? {
                  width: "85%",
                  height: "100%",
                  rowGap: "calc(.3rem + .3vw)",
                }
              : {
                  width: "85%",
                  height: "100%",
                  rowGap: "calc(.3rem + .3vw)",
                  opacity: "0.7",
                  pointerEvents: "none",
                }
          }
        >
          <div
            className="d-flex "
            style={
              IdCustomer.length > 0
                ? {
                    border: "1px solid #B6C1CE",
                    borderRadius: "10px",
                    width: "auto",
                    height: "20%",
                    columnGap: "1rem",
                  }
                : {
                    opacity: "0.4",
                    pointerEvents: "none",
                    border: "1px solid #B6C1CE",
                    borderRadius: "10px",
                    width: "auto",
                    height: "20%",
                    columnGap: "1rem",
                  }
            }
          >
            <div
              className="d-flex position-relative px-4"
              style={
                IdGraphic && IdGraphic == 1
                  ? null
                  : { opacity: "0.4", pointerEvents: "none" }
              }
            >
              <DropdownList
                busy
                busySpinner={<ArrowDown></ArrowDown>}
                className={"input-style-elevva-graphics"}
                id="optionTraining"
                value={
                  trainingIdOption
                    ? {
                        id: totalTrainings.filter(
                          (e) => e.id == trainingIdOption
                        )[0].id,
                        name: totalTrainings.filter(
                          (e) => e.id == trainingIdOption
                        )[0].name,
                      }
                    : null
                }
                placeholder={"Selecciona tipo de sesión"}
                data={
                  totalTrainings &&
                  totalTrainings.map((memb) => ({
                    id: memb.id,
                    name: memb.name,
                  }))
                }
                dataKey="id"
                textField="name"
                onChange={(value) => settrainingIdOption(value.id)}
                renderListItem={({ item }) => (
                  <div
                    className="w-100 mb-1"
                    style={{ borderBottom: "1px solid #9DACBD" }}
                  >
                    <div className="fw-bold">{item.name}</div>
                  </div>
                )}
              />
              {swiperTrainings && swiperTrainings.length > 0 ? (
                <div className="resultados-trainings-subtittle">
                  <span className="w-auto">
                    Tienes {swiperTrainings.length}{" "}
                    {swiperTrainings.length == 1 ? "resultado" : "resultados"}
                  </span>
                </div>
              ) : (
                <div className="resultados-trainings-subtittle">
                  <span className="w-auto">No hay resultados</span>
                </div>
              )}
            </div>
            {IdGraphic && IdGraphic == 1 ? (
              <div
                className="w-100 h-100 position-relative "
                style={{ overflow: "hidden" }}
              >
                {swiperTrainings && swiperTrainings.length > 0 ? (
                  <>
                    {" "}
                    <div
                      className={
                        "d-flex position-absolute  w-100 h-100 swiper-pagination_p"
                      }
                    >
                      <div
                        className={`swiper-button-prev-numbers tonin-prev`}
                      ></div>
                      <div
                        className={`swiper-button-next-numbers  tonin-next`}
                        style={{
                          left: "90%",
                          transform: "translateX(-50%)",
                        }}
                      ></div>
                    </div>
                    <Swiper
                      id="slider-training-swiper"
                      navigation={{
                        nextEl: `.swiper-button-next-numbers`,
                        prevEl: `.swiper-button-prev-numbers`,
                      }}
                      style={{ margin: "auto " }}
                      slidesPerView={laptop1600.matches ? 7 : 6}
                      spaceBetween={30}
                      // pagination={{
                      //   clickable: true,
                      // }}
                      slidesOffsetBefore={0}
                      slidesOffsetAfter={10}
                      modules={[Navigation]}
                      // onUpdate={(swiper) => swiper.updateSlides()}
                      className="mySwiper"
                    >
                      {render_img_desk}
                    </Swiper>
                  </>
                ) : null}
              </div>
            ) : (
              <div
                className="w-100 d-flex justify-content-center align-items-center"
                style={{ opacity: "0.4" }}
              >
                <span>
                  Esta opción no esta disponible
                  <strong> para gráficas de composición corporal</strong>
                </span>
              </div>
            )}
          </div>
          <div
            className="d-flex px-4 w-100 position-relative"
            style={{
              borderRadius: "10px",
              width: "100%",
              height: "2.5rem",
              columnGap: "1rem",
              background: "#B5C1CD",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <span
                onClick={() => setOpenM(!OpenM)}
                style={{ color: "#fff", fontSize: "calc(0.5rem + 0.5vmin)" }}
              >
                SELECCIONA MÉTRICAS{" "}
                <span className="ms-2" style={{ cursor: "pointer" }}>
                  {" "}
                  {OpenM ? (
                    <ArrowDownWhite></ArrowDownWhite>
                  ) : (
                    <ArrowUpWhite></ArrowUpWhite>
                  )}
                </span>
              </span>
              {/* <span
                onClick={reset}
                className="ms-4"
                style={{
                  color: "#fff",
                  fontSize: "calc(0.5rem + 0.5vmin)",
                  cursor: "pointer",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={17}
                  height={17}
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="#FFFF"
                    d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z"
                  />
                </svg>
              </span> */}
              <span
                style={{
                  color: "#fff",
                  fontSize: "calc(0.5rem + 0.5vmin)",
                  position: "absolute",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  left: "50%",
                  textAlign: "center",
                }}
              >
                {titleTextGraphic}
              </span>
            </div>
            <div onClick={GraphicSave}>
              <span
                style={{ color: "#fff", fontSize: "calc(0.5rem + 0.5vmin)" }}
              >
                <span className="me-2" style={{ cursor: "pointer" }}>
                  <SaveIcon
                    width={laptop1600.matches ? 25 : 20}
                    height={laptop1600.matches ? 25 : 20}
                  ></SaveIcon>
                </span>
                GUARDAR{" "}
              </span>
            </div>
          </div>

          <div
            className={`${
              OpenM ? "d-flex" : "d-none"
            } px-2 py-3 position-absolute top-position-metrics`}
            style={{
              transform: "translateY(-50%)",
              borderRadius: "10px",
              width: "100%",
              height: "auto",
              columnGap: "calc(.3rem + .3vw)",
              background: "#B5C1CD",
              alignItems: "center",
              zIndex: "2",
            }}
          >
            <div
              className="d-flex px-2"
              style={{ columnGap: laptop1600.matches ? "2rem" : "0.6rem" }}
            >
              <div className="d-flex flex-column">
                <span
                  style={{
                    fontSize: "calc(0.35rem + 0.4vw)",
                    color: "#394F65",
                    fontWeight: "600",
                  }}
                >
                  Volumen
                </span>
                {optionGraphicMetrics.map((grap) => (
                  <li
                    style={{ opacity: IdGraphic == 1 ? "1" : "0.6" }}
                    className="text-left d-flex my-1 radialesOption "
                    key={"customer" + grap.id}
                  >
                    {" "}
                    <input
                      disabled={IdGraphic == 1 ? false : true}
                      className="left-input-metrics-select round-checkbox cursor-pointer sesion-checkbox"
                      data-custom={grap.name}
                      name="metrics"
                      type="checkbox"
                      id={"graphic" + grap.name}
                      value={grap.id}
                      onClick={(e) => checkealo(e.target)}
                    />{" "}
                    <div
                      style={{
                        fontSize: "calc(.35rem + .4vw)",
                        whiteSpace: "nowrap",
                        color: "#fff",
                      }}
                    >
                      {ReactHtmlParser(grap.name)}
                    </div>
                  </li>
                ))}
              </div>
              <div className="d-flex flex-column" style={{ flexWrap: "wrap" }}>
                <span
                  style={{
                    fontSize: "calc(0.4rem + 0.4vw)",
                    color: "#394F65",
                    fontWeight: "600",
                  }}
                >
                  Cardio
                </span>
                <div className="grid-total">
                  {optionGraphicMetricsCardio.map((grap) => (
                    <li
                      style={{ opacity: IdGraphic == 1 ? "1" : "0.6" }}
                      className="text-left d-flex my-1 radialesOption "
                      key={"customer" + grap.id}
                    >
                      {" "}
                      <input
                        disabled={IdGraphic == 1 ? false : true}
                        className="left-input-metrics-select round-checkbox cursor-pointer sesion-checkbox"
                        data-custom={grap.name}
                        name="metrics"
                        type="checkbox"
                        id={"graphic" + grap.name}
                        value={grap.id}
                        onClick={(e) => checkealo(e.target)}
                      />{" "}
                      <div
                        style={{
                          fontSize: "calc(.35rem + .4vw)",
                          whiteSpace: "nowrap",
                          color: "#fff",
                        }}
                      >
                        {ReactHtmlParser(grap.name)}
                      </div>
                    </li>
                  ))}
                </div>
              </div>
              <div className="d-flex flex-column" style={{ flexWrap: "wrap" }}>
                <span
                  style={{
                    fontSize: "calc(0.4rem + 0.4vw)",
                    color: "#394F65",
                    fontWeight: "600",
                  }}
                >
                  Salud
                </span>
                <div className="grid-total">
                  {optionGraphicMetricsSalud.map((grap) => (
                    <li
                      style={{ opacity: IdGraphic == 2 ? "1" : "0.6" }}
                      className="text-left d-flex my-1 radialesOption align-items-center"
                      key={"customer" + grap.id}
                    >
                      {" "}
                      <input
                        disabled={IdGraphic == 2 ? false : true}
                        data-custom={grap.name}
                        className="left-input-metrics-select round-checkbox cursor-pointer sesion-checkbox-corporal"
                        name="metrics"
                        type="checkbox"
                        id={"graphic" + grap.name}
                        value={grap.id}
                        onClick={(e) => checkealo(e.target)}
                      />{" "}
                      {grap.id == "19" ? (
                        <DropdownList
                          busy
                          busySpinner={<ArrowDownWhite></ArrowDownWhite>}
                          className={"lesion-tipe-style"}
                          id="newScrollInjuries"
                          placeholder={ReactHtmlParser(grap.name)}
                          data={
                            totalInjuries &&
                            totalInjuries.map((memb) => ({
                              id: memb.id,
                              name: memb.name,
                            }))
                          }
                          dataKey="id"
                          textField="name"
                          onChange={(value) => setInjurieId(value.id)}
                          renderListItem={({ item }) => (
                            <div
                              className="w-100 mb-1"
                              style={{ borderBottom: "1px solid #9DACBD" }}
                            >
                              <div>{item.name}</div>
                            </div>
                          )}
                        />
                      ) : (
                        <div
                          style={{
                            fontSize: "calc(.35rem + .4vw)",
                            whiteSpace: "nowrap",
                            color: "#fff",
                          }}
                        >
                          {ReactHtmlParser(grap.name)}
                        </div>
                      )}
                    </li>
                  ))}
                </div>
              </div>
              <div className="d-flex flex-column" style={{ flexWrap: "wrap" }}>
                <span
                  style={{
                    fontSize: "calc(0.4rem + 0.4vw)",
                    color: "#394F65",
                    fontWeight: "600",
                  }}
                >
                  Composición corporal
                </span>
                <div className="grid-total-compo">
                  {optionGraphicMetricsCompo.map((grap) => (
                    <li
                      style={{ opacity: IdGraphic == 2 ? "1" : "0.6" }}
                      className="text-left d-flex my-1 radialesOption "
                      key={"customer" + grap.id}
                    >
                      {" "}
                      <input
                        disabled={IdGraphic == 2 ? false : true}
                        data-custom={grap.name}
                        className="left-input-metrics-select round-checkbox cursor-pointer sesion-checkbox-corporal"
                        name="metrics"
                        type="checkbox"
                        id={"graphic" + grap.name}
                        value={grap.id}
                        onClick={(e) => checkealo(e.target)}
                      />{" "}
                      <div
                        style={{
                          fontSize: "calc(.35rem + .4vw)",
                          whiteSpace: "nowrap",
                          color: "#fff",
                        }}
                      >
                        {ReactHtmlParser(grap.name)}
                      </div>
                    </li>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* grapics */}
          {MembershipOptionId == null ? (
            <div
              className="d-flex justify-content-center "
              style={
                IdCustomer.length > 0 && Logs.length > 0
                  ? {
                      height: "65%",
                      alignItems: "center",
                      flexDirection: "column",
                    }
                  : {
                      height: "65%",
                      alignItems: "center",
                      flexDirection: "column",
                      opacity: "1",
                    }
              }
            >
              <img
                alt="logo_alta_black_2"
                style={{ padding: "0% 20%" }}
                src={
                  process.env.PUBLIC_URL +
                  "/assets/img/logo_small_elevva_metrics.png"
                }
              />
              <br></br>
              <span style={{ textAlign: "center" }}>
                Selecciona el tipo de <br></br> membresia
              </span>
            </div>
          ) : startDate == null ? (
            <div
              className="d-flex justify-content-center "
              style={
                IdCustomer.length > 0 && Logs.length > 0
                  ? {
                      height: "65%",
                      alignItems: "center",
                      flexDirection: "column",
                    }
                  : {
                      height: "65%",
                      alignItems: "center",
                      flexDirection: "column",
                      opacity: "1",
                    }
              }
            >
              <img
                alt="logo_alta_black_2"
                style={{ padding: "0% 20%" }}
                src={
                  process.env.PUBLIC_URL +
                  "/assets/img/logo_small_elevva_metrics.png"
                }
              />
              <br></br>
              <span style={{ textAlign: "center" }}>
                Selecciona el período <br></br> que deseas visualizar
              </span>
            </div>
          ) : IdGraphic == null ? (
            <div
              className="d-flex justify-content-center "
              style={
                IdCustomer.length > 0 && Logs.length > 0
                  ? {
                      height: "65%",
                      alignItems: "center",
                      flexDirection: "column",
                    }
                  : {
                      height: "65%",
                      alignItems: "center",
                      flexDirection: "column",
                      opacity: "1",
                    }
              }
            >
              <img
                alt="logo_alta_black_2"
                style={{ padding: "0% 20%" }}
                src={
                  process.env.PUBLIC_URL +
                  "/assets/img/logo_small_elevva_metrics.png"
                }
              />
              <br></br>
              <span style={{ textAlign: "center" }}>
                Selecciona el tipo de <br></br> gráfica que deseas <br></br>{" "}
                visualizar
              </span>
            </div>
          ) : IdCustomer.length == 0 ? (
            <div
              className="d-flex justify-content-center "
              style={
                IdCustomer.length > 0 && Logs.length > 0
                  ? {
                      height: "65%",
                      alignItems: "center",
                      flexDirection: "column",
                    }
                  : {
                      height: "65%",
                      alignItems: "center",
                      flexDirection: "column",
                      opacity: "1",
                    }
              }
            >
              <img
                alt="logo_alta_black_2"
                style={{ padding: "0% 20%" }}
                src={
                  process.env.PUBLIC_URL +
                  "/assets/img/logo_small_elevva_metrics.png"
                }
              />
              <br></br>
              <span style={{ textAlign: "center" }}>
                Selecciona el usuario o <br></br> usuarios que deseas <br></br>{" "}
                visualizar
              </span>
            </div>
          ) : trainingIdOption == null && IdGraphic == 1 ? (
            <div
              className="d-flex justify-content-center "
              style={
                IdCustomer.length > 0 && Logs.length > 0
                  ? {
                      height: "65%",
                      alignItems: "center",
                      flexDirection: "column",
                    }
                  : {
                      height: "65%",
                      alignItems: "center",
                      flexDirection: "column",
                      opacity: "1",
                    }
              }
            >
              <img
                alt="logo_alta_black_2"
                style={{ padding: "0% 20%" }}
                src={
                  process.env.PUBLIC_URL +
                  "/assets/img/logo_small_elevva_metrics.png"
                }
              />
              <br></br>
              <span style={{ textAlign: "center" }}>
                Selecciona tipo de <br></br> de sesión
              </span>
            </div>
          ) : selectOneDiscipline.length == 0 && IdGraphic == 1 ? (
            <div
              className="d-flex justify-content-center "
              style={
                IdCustomer.length > 0 && Logs.length > 0
                  ? {
                      height: "65%",
                      alignItems: "center",
                      flexDirection: "column",
                    }
                  : {
                      height: "65%",
                      alignItems: "center",
                      flexDirection: "column",
                      opacity: "1",
                    }
              }
            >
              <img
                alt="logo_alta_black_2"
                style={{ padding: "0% 20%" }}
                src={
                  process.env.PUBLIC_URL +
                  "/assets/img/logo_small_elevva_metrics.png"
                }
              />
              <br></br>
              <span style={{ textAlign: "center" }}>
                Selecciona sesión<br></br>para visualizar
              </span>
            </div>
          ) : Logs.length == 0 ? (
            <div
              className="d-flex justify-content-center "
              style={
                IdCustomer.length > 0 && Logs.length > 0
                  ? {
                      height: "65%",
                      alignItems: "center",
                      flexDirection: "column",
                    }
                  : {
                      height: "65%",
                      alignItems: "center",
                      flexDirection: "column",
                      opacity: "1",
                    }
              }
            >
              <img
                alt="logo_alta_black_2"
                style={{ padding: "0% 20%" }}
                src={
                  process.env.PUBLIC_URL +
                  "/assets/img/logo_small_elevva_metrics.png"
                }
              />
              <br></br>
              <span style={{ textAlign: "center" }}>
                Selecciona métricas <br></br> para visualizar
              </span>
            </div>
          ) : NotPosible ? (
            <div
              className="d-flex justify-content-center "
              style={
                IdCustomer.length > 0 && Logs.length > 0
                  ? {
                      height: "65%",
                      alignItems: "center",
                      flexDirection: "column",
                    }
                  : {
                      height: "65%",
                      alignItems: "center",
                      flexDirection: "column",
                      opacity: "1",
                    }
              }
            >
              <span style={{ textAlign: "center" }}>
                {ReactHtmlParser(NotPosible)}
              </span>{" "}
              <br></br>{" "}
              <button
                onClick={() => {
                  setLogs(Logs.filter((e) => e != "1006"));
                  let element = document.getElementById("graphicZonas FC");
                  element.checked = false;
                  setNotPosible(null);
                }}
                title="Entendido"
                className="px-1 py-2 w-100 my-1"
                style={{
                  background: "#394F65",
                  maxWidth: "7rem",
                  color: "#fff",
                  fontSize: "calc(.4rem + .4vw)",
                  border: "none",
                  borderRadius: "20px",
                }}
              >
                Entendido
              </button>
            </div>
          ) : (
            <div
              className="d-flex my-auto"
              style={{
                width: "100%",
                height: "70%",
                padding: "0 0 0 0%",
              }}
            >
              <div
                style={{
                  width: "80%",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "9%",
                    width: "100%",
                  }}
                >
                  <div className="d-flex">
                    {optionLogs

                      .filter((e) => Logs.includes(String(e.id)))
                      .map((log) => (
                        <div className="d-flex justify-content-center align-items-center mx-1">
                          <span className="d-flex">{log?.image}</span>{" "}
                          {log.image ? (
                            <div className="d-flex">
                              <span
                                className="ps-1 m-auto"
                                style={{
                                  fontSize: "calc(0.4rem + 0.4vw)",
                                  color: "#9DACBD",
                                }}
                              >
                                {log.nameComplete}
                              </span>{" "}
                              {(Logs.includes("12") &&
                                DataResultMetrics.length > 0) ||
                              (Logs.includes("7") &&
                                Logs.includes("11") &&
                                DataResultMetrics.length > 0) ||
                              DataResultMetrics.length > 0 ||
                              (Logs.includes("5") &&
                                DataResultMetrics.length > 0) ||
                              (Logs.includes("13") &&
                                DataResultMetrics.length > 0) ||
                              (Logs.includes("18") &&
                                DataResultMetrics.length > 0) ||
                              (Logs.includes("9") &&
                                DataResultMetrics.length > 0) ||
                              (Logs.includes("6") &&
                                DataResultMetrics.length > 0) ||
                              (Logs.includes("1") &&
                                DataResultMetrics.length > 0) ||
                              (Logs.includes("19") &&
                                DataResultMetrics.length > 0) ||
                              (Logs.includes("20") &&
                                DataResultMetrics.length > 0) ||
                              (Logs.includes("10") &&
                                DataResultMetrics.length > 0) ? (
                                DataResultMetrics.length > 2 &&
                                Logs.length > 2 ? (
                                  <DropdownList
                                    busy
                                    busySpinner={<ArrowDown></ArrowDown>}
                                    className={
                                      "input-style-elevva-optionGraphic"
                                    }
                                    id="optionMetrics"
                                    placeholder={"Ver usuario"}
                                    data={
                                      DataResultMetrics &&
                                      DataResultMetrics.filter(
                                        (e) => log?.id == e.title
                                      ).map((color) => ({
                                        id: color.user,
                                        user: color.user,
                                        color: color.color,
                                      }))
                                    }
                                    dataKey="id"
                                    renderListItem={({ item }) => (
                                      <div
                                        className="w-100 mb-1"
                                        style={{
                                          borderBottom: "1px solid #9DACBD",
                                        }}
                                      >
                                        <div className="my-auto d-flex">
                                          <span
                                            className="my-auto mx-2"
                                            style={{
                                              color: item.color,
                                            }}
                                          >
                                            <IconCircle width={25}></IconCircle>
                                          </span>
                                          {item && item.user ? (
                                            <span
                                              className="my-auto mx-1"
                                              style={{
                                                fontSize:
                                                  "calc(0.4rem + 0.4vw)",
                                                color: "#9DACBD",
                                                lineHeight: "1",
                                              }}
                                            >
                                              {totalCustomers.filter(
                                                (name) =>
                                                  name.customer == item.user
                                              )[0].first_name[0] + "." ||
                                                null}{" "}
                                              {
                                                totalCustomers.filter(
                                                  (name) =>
                                                    name.customer == item.user
                                                )[0].last_name
                                              }{" "}
                                              {log?.id == item.title &&
                                              log?.id == "18"
                                                ? totalCustomers.filter(
                                                    (name) =>
                                                      name.customer == item.user
                                                  )[0].age
                                                : null}
                                            </span>
                                          ) : null}
                                        </div>
                                      </div>
                                    )}
                                  />
                                ) : (
                                  DataResultMetrics.map((color, index) =>
                                    log?.id == color.title ? (
                                      <div className="my-auto d-flex">
                                        <span
                                          className="my-auto mx-2"
                                          style={{
                                            color: color.color,
                                          }}
                                        >
                                          <IconCircle width={25}></IconCircle>
                                        </span>
                                        {color && color.user ? (
                                          <span
                                            className="my-auto mx-1"
                                            style={{
                                              fontSize: "calc(0.4rem + 0.4vw)",
                                              color: "#9DACBD",
                                              lineHeight: "1",
                                            }}
                                          >
                                            {totalCustomers.filter(
                                              (name) =>
                                                name.customer == color.user
                                            )[0].first_name[0] + "." ||
                                              null}{" "}
                                            {
                                              totalCustomers.filter(
                                                (name) =>
                                                  name.customer == color.user
                                              )[0].last_name
                                            }{" "}
                                            {log?.id == color.title &&
                                            log?.id == "18"
                                              ? totalCustomers.filter(
                                                  (name) =>
                                                    name.customer == color.user
                                                )[0].age
                                              : null}
                                          </span>
                                        ) : null}
                                      </div>
                                    ) : null
                                  )
                                )
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      ))}{" "}
                    {Logs.includes("1006")
                      ? totality.map((color, index) => (
                          <div className="my-auto d-flex">
                            <span
                              className="my-auto mx-1"
                              style={{
                                width: "15px",
                                height: "15px",
                                backgroundColor: color,
                              }}
                            ></span>
                            <span
                              className="my-auto mx-1"
                              style={{
                                fontSize: "calc(0.4rem + 0.4vw)",
                                color: "#9DACBD",
                              }}
                            >
                              Zona {index - -1}
                            </span>
                          </div>
                        ))
                      : null}
                    {Logs.includes("99") &&
                    selectOneDiscipline.length > 0 &&
                    swiperTrainings
                      ? swiperTrainings
                          .filter((e) => selectOneDiscipline.includes(e.id))
                          .map((color, index) => (
                            <div className="my-auto d-flex">
                              <span
                                className="my-auto mx-2"
                                style={{
                                  color: colors[index],
                                }}
                              >
                                <ArrowFc width={25}></ArrowFc>
                              </span>
                              <span
                                className="my-auto mx-1"
                                style={{
                                  fontSize: "calc(0.4rem + 0.4vw)",
                                  color: "#9DACBD",
                                }}
                              >
                                Sesión {Moment(color.date).format("MMM DD-YY")}
                              </span>
                            </div>
                          ))
                      : null}
                    {Logs.includes("14") && DataResultMetrics
                      ? DataResultMetrics.map((color, index) => (
                          <div className="my-auto d-flex">
                            <span
                              className="my-auto mx-2"
                              style={{
                                color: color.color,
                              }}
                            >
                              <IconTension width={25}></IconTension>
                            </span>
                            <span
                              className="my-auto mx-1"
                              style={{
                                fontSize: "calc(0.4rem + 0.4vw)",
                                color: "#9DACBD",
                                lineHeight: "1",
                              }}
                            >
                              {color.title == 14 ? "SISTÓLICA" : "DIASTÓLICA"}
                              <br></br>
                              {totalCustomers.filter(
                                (name) => String(name.customer) == color.user
                              )[0]?.first_name + "." || null}{" "}
                              {
                                totalCustomers.filter(
                                  (name) => String(name.customer) == color.user
                                )[0]?.last_name
                              }
                            </span>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div
                  style={{
                    height: "92%",
                  }}
                >
                  <ParentSize key={"gorgeosGraphic"}>
                    {/* {({ width, height }) => (
                    DataResultMetrics.length > 0 ?<PPACalculatorTest
                      width={width}
                      height={height}
                      series={DataResultMetrics}
//                       series={[
//     {
//         "title": "1",
//         "color": "#C3423F",
//         "data": [
//             {
//                 "year": "2023-01-13",
//                 "price": 75.4000015258789
//             },
//             {
//                 "year": "2023-01-27",
//                 "price": 73.9000015258789
//             },
//             {
//                 "year": "2023-10-10",
//                 "price": 75.30000305175781
//             },
//             {
//                 "year": "2023-11-09",
//                 "price": 74.56666819254558
//             },
//             {
//                 "year": "2023-11-10",
//                 "price": 73.9000015258789
//             },
//             {
//                 "year": "2023-11-22",
//                 "price": 76
//             },
//             {
//                 "year": "2023-11-24",
//                 "price": 76
//             }
//         ]
//     },
//     {
//         "title": "1",
//         "color": "#005FAB",
//         "data": [
//             {
//                 "year": "2023-03-15",
//                 "price": 82.5
//             },
//             {
//                 "year": "2023-07-12",
//                 "price": 79.9000015258789
//             },
//             {
//                 "year": "2023-10-26",
//                 "price": 75.4000015258789
//             }
//         ]
//     }
// ]}
                      // series={[
                      //   {
                      //     title: "Historic",
                      //     color: "rgb(118, 101, 160)",
                      //     data: [
                      //       { year: "2019-04-07", price: 38.2 },
                      //       { year: "2020-09-14", price: 53.2 },
                      //       { year: "2021-06-25", price: 47.7 },
                      //       { year: "2022-08-17", price: 65.2 },
                      //       { year: "2023-11-30", price: 73.2 },
                      //       { year: "2024-02-13", price: 54.2 },
                      //       { year: "2025-05-05", price: 73.2 },
                      //       { year: "2026-01-10", price: 89 },
                      //       { year: "2027-10-20", price: 99.2 },
                      //       { year: "2028-03-28", price: 105.2 },
                      //       { year: "2029-07-23", price: 54.2 },
                      //       { year: "2030-12-22", price: 45.2 },
                      //       { year: "2031-01-01", price: 60.2 },
                      //     ],
                      //   },
                      //   {
                      //     title: "BEIS",
                      //     color: "rgb(0, 183, 255)",
                      //     data: [
                      //       { year: "2022-04-16", price: 53.2 },
                      //       { year: "2023-09-05", price: 73.2 },
                      //       { year: "2024-08-12", price: 76.2 },
                      //       { year: "2025-07-07", price: 73 },
                      //       { year: "2026-12-29", price: 63 },
                      //       { year: "2027-11-02", price: 69.2 },
                      //       { year: "2028-06-30", price: 100 },
                      //       { year: "2029-02-28", price: 104.5 },
                      //       { year: "2030-05-20", price: 82 },
                      //       { year: "2031-10-05", price: 64 },
                      //     ],
                      //   },
                      //   {
                      //     title: "RON",
                      //     color: "rgb(38, 232, 179)",
                      //     data: [
                      //       { year: "2030-08-06", price: 14 },
                      //       { year: "2031-02-17", price: 94 },
                      //     ],
                      //   },
                      // ]}
                    />:null
                  )} */}
                    {({ width, height }) =>
                      GraficBarData.length > 0 ? (
                        <BrushChart
                          width={width}
                          height={height}
                          data={GraficBarData}
                          dataUsers={NewDataUsers}
                        />
                      ) : DataResultMetrics.length > 0 ? (
                        <>
                          <MetricsGraphics
                            option={Logs}
                            PerfectScrollbar={PerfectScrollbar}
                            optionLogs={optionLogs}
                            width={width}
                            height={
                              (DataResultMetrics.length > 0 &&
                                DataResultMetrics.map((e) => e.title).includes(
                                  "14"
                                )) ||
                              (DataResultMetrics.length > 0 &&
                                DataResultMetrics.map((e) => e.title).includes(
                                  "20"
                                )) ||
                              (DataResultMetrics.length > 0 &&
                                DataResultMetrics.map((e) => e.title).includes(
                                  "19"
                                )) ||
                              (DataResultMetrics.length > 0 &&
                                DataResultMetrics.map((e) => e.title).includes(
                                  "18"
                                )) ||
                              (DataResultMetrics.length > 0 &&
                                DataResultMetrics.map((e) => e.title).includes(
                                  "13"
                                )) ||
                              (DataResultMetrics.length > 0 &&
                                DataResultMetrics.map((e) => e.title).includes(
                                  "12"
                                )) ||
                              (DataResultMetrics.length > 0 &&
                                DataResultMetrics.map((e) => e.title).includes(
                                  "16"
                                )) ||
                              (DataResultMetrics.length > 0 &&
                                DataResultMetrics.map((e) => e.title).includes(
                                  "17"
                                )) ||
                              (DataResultMetrics.length > 0 &&
                                DataResultMetrics.map((e) => e.title).includes(
                                  "19"
                                )) ||
                              (DataResultMetrics.length > 0 &&
                                DataResultMetrics.map((e) => e.title).includes(
                                  "20"
                                ))
                                ? height
                                : height - 40
                            }
                            series={DataResultMetrics}
                            totalCustomers={totalCustomers}
                            ArrowUp={ArrowUpMetric}
                            ArrowDown={ArrowDownMetric}
                            // annotateYear={startYear}
                          />
                          {(DataResultMetrics.length > 0 &&
                            DataResultMetrics.map((e) => e.title).includes(
                              "14"
                            )) ||
                          (DataResultMetrics.length > 0 &&
                            DataResultMetrics.map((e) => e.title).includes(
                              "20"
                            )) ||
                          (DataResultMetrics.length > 0 &&
                            DataResultMetrics.map((e) => e.title).includes(
                              "19"
                            )) ||
                          (DataResultMetrics.length > 0 &&
                            DataResultMetrics.map((e) => e.title).includes(
                              "18"
                            )) ||
                          (DataResultMetrics.length > 0 &&
                            DataResultMetrics.map((e) => e.title).includes(
                              "13"
                            )) ||
                          (DataResultMetrics.length > 0 &&
                            DataResultMetrics.map((e) => e.title).includes(
                              "12"
                            )) ||
                          (DataResultMetrics.length > 0 &&
                            DataResultMetrics.map((e) => e.title).includes(
                              "16"
                            )) ||
                          (DataResultMetrics.length > 0 &&
                            DataResultMetrics.map((e) => e.title).includes(
                              "17"
                            )) ||
                          (DataResultMetrics.length > 0 &&
                            DataResultMetrics.map((e) => e.title).includes(
                              "19"
                            )) ||
                          (DataResultMetrics.length > 0 &&
                            DataResultMetrics.map((e) => e.title).includes(
                              "20"
                            )) ? null : (
                            <div
                              className="mx-auto"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "20px",
                                width: width - width * 0.1,
                                justifyContent: "space-around",
                              }}
                            >
                              {lines.map((line, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    position: "relative",
                                  }}
                                >
                                  <svg width={"100%"} height={20}>
                                    <line
                                      x1={padding}
                                      y1={13}
                                      x2={"100%"}
                                      y2={13}
                                      strokeWidth={4}
                                      stroke="#A8C2D7"
                                    />
                                    {DataResultMetrics.filter(
                                      (e) => e.data.length > 0
                                    ).map((metric, index) => {
                                      const valor =
                                        metric.data[metric.data.length - 1]
                                          ?.price;
                                      if (
                                        line.label ===
                                        metricFormat(
                                          metric.user,
                                          metric.title,
                                          valor,
                                          totalCustomers
                                        )
                                      ) {
                                        return (
                                          <circle
                                            key={metric.title} // Añade una clave única para cada círculo
                                            cx={`${
                                              50 +
                                              (index > 0 ? index * 3 : index)
                                            }%`}
                                            cy={13}
                                            r={4}
                                            fill="white"
                                            stroke={metric.color}
                                            strokeWidth={2}
                                            onMouseOver={(e) =>
                                              showTooltip(
                                                e,
                                                `<img  class="imagenTooltip" src=${
                                                  totalCustomers.filter(
                                                    (user) =>
                                                      user.id == metric.user
                                                  )[0].profile_pic
                                                } alt="tooltip" /><span class="mx-1 my-auto" style="font-size:10px;text-align:center">${
                                                  totalCustomers.filter(
                                                    (user) =>
                                                      user.id == metric.user
                                                  )[0].first_name
                                                }<br>${
                                                  totalCustomers.filter(
                                                    (user) =>
                                                      user.id == metric.user
                                                  )[0].last_name
                                                }</span>`,
                                                metricFormat(
                                                  metric.user,
                                                  metric.title,
                                                  valor,
                                                  totalCustomers
                                                )
                                              )
                                            }
                                            onMouseOut={hideTooltip}
                                          >
                                            {" "}
                                          </circle>
                                        );
                                      }
                                      return null; // Retorna null si no es 'ALTO' para no renderizar nada
                                    })}
                                  </svg>
                                  {tooltip.visible &&
                                    tooltip.title == line.label && (
                                      <div
                                        className="d-flex"
                                        style={{
                                          position: "absolute",
                                          top: -50,
                                          left: "50%",
                                          padding: "4% 5%",
                                          background: "white",
                                          borderRadius: "20px",
                                          boxShadow:
                                            "0px 5px 5px 4px rgba(157,172,189,0.7)",
                                          padding: "5px",
                                          zIndex: 1000,
                                          transform: "translateX(-50%)",
                                          minWidth: "7rem",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: tooltip.content,
                                        }}
                                      />
                                    )}

                                  <span
                                    style={{
                                      fontSize: "10px",
                                      color: "black",
                                      margin: "0",
                                    }}
                                  >
                                    {line.label}
                                  </span>
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      ) : DataResult.length > 0 ? (
                        <PPACalculatorChart
                          option={Logs}
                          width={width}
                          height={height}
                          series={DataResult}
                          DataUserAge={DataUserAge}
                          // annotateYear={startYear}
                        />
                      ) : loading ? (
                        <div
                          className="d-flex justify-content-center h-100"
                          style={{ alignItems: "center" }}
                        >
                          <Loadingspinner customStyle="grow" size="2rem" />
                        </div>
                      ) : DataResultMetrics.length == 0 ||
                        NewDataUsers.length == 0 ||
                        DataResult.length == 0 ||
                        (DataResult.length == 1 &&
                          DataResult[0].data.length == 0) ? (
                        <div
                          className="d-flex justify-content-center h-100"
                          style={{ alignItems: "center" }}
                        >
                          <span style={{ textAlign: "center" }}>
                            <i>
                              {" "}
                              En el período de tiempo seleccionado no se
                              encuentra <br></br> data de la variable deseada
                              para análisis.
                            </i>
                          </span>
                        </div>
                      ) : null
                    }
                    {/* <BrushChart width={width} height={height} /> */}
                    {/* <BrushChart width={width} height={height} /> */}
                  </ParentSize>
                  {/* <ParentSize>
                {({ width, height }) =>
                  IdGraphic == 1 ? (
                    DataResult ? (
                      <LineChart
                        stock={DataResult}
                        width={width}
                        height={height}
                      />
                    ) : null
                  ) : (<div><span>Selecciona métricas</span></div>
                   
                  )
                  
                }
              </ParentSize> */}
                  {/* <BrushChart width={width} height={height} /> */}
                </div>
              </div>
              <div
                style={{
                  width: "17%",
                  marginLeft: "3%",
                }}
              >
                <div
                  style={{
                    borderRadius: "10px",
                    height: "70%",
                  }}
                >
                  <input
                    placeholder="Buscar gráfica"
                    type="search"
                    className="searchAtletas py-1 mb-2 w-100"
                    style={{
                      border: "1px solid var(--elevvaTextSubtitle)",
                      borderRadius: "20px",
                    }}
                    onChange={(e) => changeOptionGraphics(e.target.value)}
                  ></input>
                  <div className="d-flex" style={{ justifyContent: "center" }}>
                    <PerfectScrollbar
                      className="w-100"
                      style={{
                        padding: "0",
                        listStyle: "none",
                        overflowX: "auto",
                        height: "20vh",
                        paddingRight: "10%",
                      }}
                    >
                      {totalGraphicsSave && totalGraphicsSave.length > 0 ? (
                        totalGraphicsSave.map((memb) => (
                          <li
                            className="text-left d-flex my-1 w-100"
                            key={"customer" + memb.id}
                          >
                            <div
                              className="d-flex radio_graphic"
                              style={{ width: "15%" }}
                            >
                              <input
                                className="me-2 cursor-pointer"
                                type="radio"
                                id={"graphicList" + memb.id}
                                name="customerListGraph"
                                value={memb.user.id}
                                onClick={(e) => chargeInfo(memb, e)}
                              />{" "}
                            </div>
                            <div
                              className="d-flex"
                              style={{
                                width: "85%",
                                borderBottom: "1px solid #9DACBD",
                              }}
                            >
                              <div
                                title={memb.name}
                                className="text-center"
                                style={{
                                  padding: "0.7rem 0",
                                  fontSize: "calc(.4rem + .3vw)",
                                  whiteSpace: "nowrap",
                                  color: "#9DACBD",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "65%",
                                }}
                              >
                                {memb.name}
                              </div>
                              <div
                                onClick={() => deleteGraphicSave(memb)}
                                className="d-flex m-auto"
                                style={{
                                  alignItems: "center",
                                  width: "25%",
                                  justifyContent: "flex-end",
                                  cursor: "pointer",
                                }}
                              >
                                <Caneca></Caneca>
                              </div>
                            </div>
                          </li>
                        ))
                      ) : (
                        <span
                          className="w-100 d-flex m-auto"
                          style={{
                            justifyContent: "center",

                            color: "#9DACBD",
                            fontSize: "calc(.4rem + .4vw)",
                          }}
                        >
                          No hay resultados
                        </span>
                      )}
                    </PerfectScrollbar>
                  </div>
                  <br></br>{" "}
                  <div className="d-flex" style={{ justifyContent: "center" }}>
                    <PerfectScrollbar
                      className="w-100"
                      style={{
                        padding: "0",
                        listStyle: "none",
                        overflowX: "auto",
                        height: "25vh",
                        paddingRight: "10%",
                      }}
                    >
                      <div className="d-flex">
                        <span
                          style={{
                            width: "90%",
                            color: "#9DACBD",
                            fontSize: "calc(.4rem + .4vw)",
                          }}
                        >
                          OBSERVACIONES <br></br> DE ELEVVA
                        </span>
                        <span
                          style={{ width: "10%" }}
                          onMouseOver={ModalObservation}
                        >
                          <Alerta></Alerta>
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "#9DACBD",
                            fontSize: "calc(.4rem + .4vw)",
                          }}
                        >
                          {ReactHtmlParser(observationText)}
                        </span>
                      </div>
                    </PerfectScrollbar>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Graphics;
