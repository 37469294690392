import GraphicProgress from "./graphic-progress";
import MetricRanges from "./metric-ranges";

const CardMetrics = ({ name, img, zone, status }) => {
    const colors = {
      active: "#004CFF",
      resting: "#D2D2D2",
      alert: "#00FC6D",
      waiting: "#FC8600",
      warning: "#FF0000",
    };
  const text_size="calc(0.5rem + 0.5vw)"
  const text_size_band="calc(0.4rem + 0.4vw)"

    return (
      <div className={`card pt-3 border-1 px-3 pb-1 shadow-sm d-flex flex-column`} style={{   width: "100%", height: "auto",borderRadius:"0.7rem",minHeight:"100%" }}>
        <div className="d-flex align-items-center mb-1" style={{color:"white"}}>
        <div className="d-flex align-items-center">
                         
                          <img
                            className=" me-3"
                            style={{
                              objectFit: "cover",
                              width:"calc(2rem + 0.2vw)" ,
                              maxHeight: "100%",
                              maxWidth: "100%",
                              height: "calc(2rem + 0.2vw)" ,
                              borderRadius: "50%",
                            }}
                            src={
                             img
                            }
                          ></img>
                        </div>
                        <div className="d-flex flex-column gap-1" >
                          <div style={{maxWidth:"100%",lineHeight:"1"}}>
                        <span className="fw-bold text-color-live-details" style={{fontSize: text_size,}} >{name}</span>
                        </div>
                        <span  style={{fontSize: text_size_band,color:"#ADBAC4"}}>
                        <img className="me-2" src="/assets/icons-multiple-live/watch-heart-metric.svg" alt="watch-card-heart" />CL831-0028646
                        </span>
                        
                        </div>
                       
         
        </div>
        <MetricRanges />
        <GraphicProgress/>
     
      </div>
    );
  };
  export default CardMetrics;
  