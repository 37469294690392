import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { laptopMax1350 } from "./utils";
import { ReactComponent as SearchInvidual } from "../assets/elevva-pro-icons/search-individual-table.svg";
import { ReactComponent as ArrowLeft } from "../assets/elevva-pro-icons/table-arrow-left.svg";
import { ReactComponent as ArrowRight } from "../assets/elevva-pro-icons/table-arrow-right.svg";
import { ReactComponent as EditIcon } from "../assets/elevva-pro-icons/edit-icon.svg";
import { ReactComponent as DeleteTag } from "../assets/elevva-pro-icons/delete-tag.svg";
import { ReactComponent as ReloadIcon } from "../assets/elevva-pro-icons/arrow-reload.svg";

import moment from "moment";
import ModalCustom from "../components/Mob/components/modal-custom";
import { useState } from "react";

const TableCustom = (props) => {
  const users = props.users;
  const page = props.page;
  const count = props.count;
  const changePage = props.changePage;

  const deleteUser = props.deleteUser;
  const editUser = props.editUser;
  const renewUser = props.renewUser;
  const [modal, setModal] = useState(null);
  const showQuota = (user) => {
    setModal(
      <ModalCustom
        onCancel={() => setModal(null)}
        onConfirm={() => setModal(null)}
        loader={false}
      >
        <PerfectScrollbar
          className="px-4"
          style={{
            maxHeight: "calc(40vh)",
            overflowY: "scroll",
          }}
        >
          <div className="overflow-x-auto mx-4">
            <table className="min-w-full border border-gray-300 rounded-lg">
              <thead>
                <tr className="bg-gray-100 text-left text-sm">
                  <th className="p-2 border">#</th>
                  <th className="p-2 border">Servicio</th>
                  <th className="p-2 border">Accesos restantes</th>
                </tr>
              </thead>
              <tbody>
                {user.memberships?.map((e, index) => (
                  <tr key={index} className="border text-sm">
                    <td className="p-2 border">{index + 1}</td>
                    <td className="p-2 border">
                      {e.service_name ?? "Sin nombre"}
                    </td>
                    <td className="p-2 border">
                      {e.remaining_quota === -1
                        ? "Ilimitados"
                        : e.remaining_quota > 0
                        ? e.remaining_quota
                        : 0}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </PerfectScrollbar>
      </ModalCustom>
    );
  };
  console.log(users);
  return (
    <div className={laptopMax1350.matches ? "px-3 py-2" : "p-3"}>
      {modal}
      <div style={{ width: "100%" }}>
        <PerfectScrollbar
          className="px-4"
          style={{
            maxHeight: "calc(75vh - 12rem)",
            overflowY: "scroll",
          }}
        >
          <table style={{ width: "100%", backgroundColor: "white" }}>
            <thead
              className="size-table-users"
              style={{
                width: "100%",
                overflow: "scroll",
                position: "sticky",
                top: "0",
                backgroundColor: "white",
              }}
            >
              <tr>
                <th
                  style={{
                    fontWeight: "normal",
                    width: "20%",
                  }}
                >
                  <div className="d-flex gap-2 align-items-center ">
                    {" "}
                    <span className="size-table-users">Nombre</span>{" "}
                  </div>
                </th>
                <th
                  style={{
                    fontWeight: "normal",
                    width: "15%",
                  }}
                >
                  Correo Electrónico
                </th>
                <th
                  style={{
                    fontWeight: "normal",
                    width: "20%",
                  }}
                >
                  plan
                </th>
                <th
                  style={{
                    fontWeight: "normal",
                    width: "15%",
                  }}
                >
                  Congelaciones<br></br> restantes
                </th>
                <th
                  style={{
                    fontWeight: "normal",
                    width: "15%",
                  }}
                >
                  Accesos<br></br> restantes
                </th>
                <th
                  style={{
                    fontWeight: "normal",
                    width: "10%",
                  }}
                >
                  Vencimiento
                </th>
              </tr>
            </thead>
            <tbody className="w-100">
              {users.length > 0 &&
                users.map((user) => (
                  <tr
                    key={user.user_id}
                    className="size-table-users"
                    style={{
                      borderBottom: "1px solid #A8C2D7",
                      color: "#394F65",
                    }}
                  >
                    <td
                      style={{ width: "20%" }}
                      className="flex gap-2 align-items-center justify-content-center py-3"
                    >
                      <div className="d-flex gap-2 align-items-center ">
                        <div className="size-table-users">
                          {user.customer_name}
                        </div>
                      </div>
                    </td>
                    <td style={{ width: "20%" }}>{user.customer_email}</td>
                    <td style={{ width: "20%" }}>
                      {user.memberships.length > 0
                        ? user.memberships[0]?.plan_name
                        : "----"}
                      <br></br>
                      <span>
                        {moment().isSameOrBefore(
                          moment(user.memberships[0]?.membership_start_date)
                        ) && (
                          <span style={{ fontSize: "10px" }}>
                            Inicio:{" "}
                            {moment(
                              user.memberships[0]?.membership_start_date
                            ).format("DD-MMM-YY")}
                          </span>
                        )}
                      </span>
                    </td>
                    <td style={{ width: "15%" }}>
                      <div className="d-flex align-items-center gap-2">
                        <div style={{ lineHeight: "1" }}>
                          <span className="size-table-users">
                            {user.memberships.length > 0
                              ? user?.memberships[0]
                                  ?.membership_remaining_freezes || 0
                              : "----"}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td style={{ width: "15%" }}>
                      <div className="d-flex align-items-center gap-2">
                        <div style={{ lineHeight: "1", cursor: "pointer" }}>
                          <SearchInvidual
                            width={"1.2rem"}
                            onClick={() => showQuota(user)}
                          />
                          {/* <span className="size-table-users"> */}
                          {/* <SearchInvidual width={"1.2rem"} /> */}
                          {/* {user.memberships.length > 0
                              ? 
                              user.memberships.map((e) => (
                                  <>
                                    {e.remaining_quota == -1
                                      ? "Ilimitados"
                                      : e.remaining_quota > 0
                                      ? e.remaining_quota
                                      : 0}
                                    <br />
                                  </>
                                ))
                              : "----"} */}
                          {/* </span> */}
                        </div>
                      </div>
                    </td>
                    <td style={{ width: "5%" }}>
                      {user?.memberships[0]?.membership_end_date
                        ? moment(
                            user?.memberships[0].membership_end_date
                          )?.format("DD-MMM-YY")
                        : "----"}
                    </td>
                    <td style={{ width: "5%", textAlign: "right" }}>
                      <div className="d-flex gap-2 align-items-center">
                        <div>
                          <ReloadIcon
                            className="cursor-pointer"
                            onClick={() => renewUser(user)}
                            width={"1rem"}
                            height={"1rem"}
                          />
                        </div>

                        <div>
                          {" "}
                          <EditIcon
                            onClick={() => editUser(user)}
                            className="cursor-pointer"
                            width={"1rem"}
                            height={"1rem"}
                          />
                        </div>
                        <div>
                          <DeleteTag
                            onClick={() => deleteUser(user)}
                            className="cursor-pointer"
                            width={"1rem"}
                            height={"1rem"}
                          />
                        </div>
                        {/* <div>
                          {" "}
                          <DeleteTag
                            onClick={() => deleteUser(user)}
                            className="cursor-pointer"
                            width={"20px"}
                            height={"20px"}
                          />
                        </div> */}
                      </div>
                      {/* <button
                        onClick={() => setUserDetail(user)}
                        className="pe-3"
                        style={{
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <SearchInvidual />
                      </button> */}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </PerfectScrollbar>
        <div className="d-flex justify-content-end align-items-center gap-3 mt-2">
          <span>
            {Math.min((page - 1) * 10 + 1, count)} -{" "}
            {Math.min(page * 10, count)} de {count}
          </span>
          <div
            onClick={page > 1 ? () => changePage("previous") : undefined}
            className={`cursor-pointer ${
              page > 1 ? "text-black" : "text-gray-400 cursor-not-allowed"
            }`}
          >
            <ArrowLeft />
          </div>
          <div
            onClick={page * 10 < count ? () => changePage("next") : undefined}
            className={`cursor-pointer ${
              page * 10 < count
                ? "text-black"
                : "text-gray-400 cursor-not-allowed"
            }`}
          >
            <ArrowRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableCustom;
