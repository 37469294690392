import React, { useRef, useState, useMemo, useEffect } from "react";
import { Group } from "@visx/group";
import { scaleTime, scaleLinear } from "@visx/scale";
import appleStock, { AppleStock } from "@visx/mock-data/lib/mocks/appleStock";
import { Brush } from "@visx/brush";
import { Bounds } from "@visx/brush/lib/types";
import BaseBrush from "@visx/brush/lib/BaseBrush";
import { max, extent } from "d3-array";
import AreaChartLine from "./AreaChartLine.tsx";
import moment from "moment";
import "moment/locale/es";
import { BrushHandleRenderProps } from '@visx/brush/lib/BrushHandle';
import { Grid } from "@visx/grid";
import { LinearGradient } from "@visx/gradient/lib/index";
import { useTooltip, useTooltipInPortal, defaultStyles, TooltipWithBounds } from '@visx/tooltip';
import { localPoint } from "@visx/event";
import { tickFormat } from "@visx/vendor/d3-scale";

export interface DummieLine {
  date: string;
  "FC Promedio": string;
  "FC Max": string;
  Distancia: string;
}
// Initialize some variables
// const stock = [
//   {
//     "date": "2007-04-20T06:53:02.063073-05:00",
//     "close": 73.24
// },
// {
//     "date": "2007-04-21T06:53:02.063073-05:00",
//     "close": 35.35
// },
// {
//     "date": "2007-04-22T05:53:02.063073-05:00",
//     "close": 18.84
// },{
//   "date": "2007-04-23T06:53:02.063073-05:00",
//   "close": 48.84
// },
//   {
//       "date": "2007-04-24T06:53:02.063073-05:00",
//       "close": 93.24
//   },
//   {
//       "date": "2007-04-25T06:53:02.063073-05:00",
//       "close": 5.35
//   },
//   {
//       "date": "2007-04-26T06:53:02.063073-05:00",
//       "close": 98.84
//   },
//   {
//       "date": "2007-04-27T06:53:02.063073-05:00",
//       "close": 99.92
//   },
//   {
//       "date": "2007-04-30T06:53:02.063073-05:00",
//       "close": 99.8
//   },
//   {
//       "date": "2007-05-01T06:53:02.063073-05:00",
//       "close": 99.47
//   },
//   {
//       "date": "2007-05-02T06:53:02.063073-05:00",
//       "close": 50.39
//   },
//   {
//       "date": "2007-05-03T06:53:02.063073-05:00",
//       "close": 300.4
//   },
//   {
//       "date": "2007-05-04T06:53:02.063073-05:00",
//       "close": 10.81
//   },
//   {
//       "date": "2007-05-07T06:53:02.063073-05:00",
//       "close": 103.92
//   },
//   {
//       "date": "2007-05-08T06:53:02.063073-05:00",
//       "close": 105.06
//   },
//   {
//       "date": "2007-05-09T06:53:02.063073-05:00",
//       "close": 106.88
//   },
//   {
//       "date": "2007-05-10T06:53:02.063073-05:00",
//       "close": 107.34
//   },
//   {
//       "date": "2007-05-11T06:53:02.063073-05:00",
//       "close": 108.74
//   },
//   {
//       "date": "2007-05-14T06:53:02.063073-05:00",
//       "close": 109.36
//   },
//   {
//       "date": "2007-05-15T06:53:02.063073-05:00",
//       "close": 107.52
//   },
//   {
//       "date": "2007-05-16T06:53:02.063073-05:00",
//       "close": 107.34
//   },
//   {
//       "date": "2007-05-17T06:53:02.063073-05:00",
//       "close": 109.44
//   },
//   {
//       "date": "2007-05-18T06:53:02.063073-05:00",
//       "close": 110.02
//   },
//   {
//       "date": "2007-05-21T06:53:02.063073-05:00",
//       "close": 111.98
//   },
//   {
//       "date": "2007-05-22T06:53:02.063073-05:00",
//       "close": 113.54
//   },
//   {
//       "date": "2007-05-23T06:53:02.063073-05:00",
//       "close": 112.89
//   },
//   {
//       "date": "2007-05-24T06:53:02.063073-05:00",
//       "close": 110.69
//   },
//   {
//       "date": "2007-05-25T06:53:02.063073-05:00",
//       "close": 113.62
//   },
//   {
//       "date": "2007-05-29T06:53:02.063073-05:00",
//       "close": 114.35
//   },
//   {
//       "date": "2007-05-30T06:53:02.063073-05:00",
//       "close": 118.77
//   },
//   {
//       "date": "2007-05-31T06:53:02.063073-05:00",
//       "close": 121.19
//   },
//   {
//       "date": "2007-06-01T06:53:02.063073-05:00",
//       "close": 118.4
//   },
//   {
//       "date": "2007-06-04T06:53:02.063073-05:00",
//       "close": 121.33
//   },
//   {
//       "date": "2007-06-05T06:53:02.063073-05:00",
//       "close": 122.67
//   }
// ];
// const dataOne=[
//   {
//     "date": "2007-04-24T06:53:02.063073-05:00",
//     "close": 93.24
// },
// {
//     "date": "2007-04-25T06:53:02.063073-05:00",
//     "close": 5.35
// },
// {
//     "date": "2007-04-26T06:53:02.063073-05:00",
//     "close": 98.84
// },
// {
//     "date": "2007-04-27T06:53:02.063073-05:00",
//     "close": 99.92
// },
// {
//     "date": "2007-04-30T06:53:02.063073-05:00",
//     "close": 99.8
// },
// {
//     "date": "2007-05-01T06:53:02.063073-05:00",
//     "close": 99.47
// },
// {
//     "date": "2007-05-02T06:53:02.063073-05:00",
//     "close": 50.39
// },
// {
//     "date": "2007-05-03T06:53:02.063073-05:00",
//     "close": 300.4
// },
// {
//     "date": "2007-05-04T06:53:02.063073-05:00",
//     "close": 10.81
// },
// {
//     "date": "2007-05-07T06:53:02.063073-05:00",
//     "close": 103.92
// },
// {
//     "date": "2007-05-08T06:53:02.063073-05:00",
//     "close": 105.06
// },
// {
//     "date": "2007-05-09T06:53:02.063073-05:00",
//     "close": 106.88
// },
// {
//     "date": "2007-05-10T06:53:02.063073-05:00",
//     "close": 107.34
// },
// {
//     "date": "2007-05-11T06:53:02.063073-05:00",
//     "close": 108.74
// },
// {
//     "date": "2007-05-14T06:53:02.063073-05:00",
//     "close": 109.36
// },
// {
//     "date": "2007-05-15T06:53:02.063073-05:00",
//     "close": 107.52
// },
// {
//     "date": "2007-05-16T06:53:02.063073-05:00",
//     "close": 107.34
// },
// {
//     "date": "2007-05-17T06:53:02.063073-05:00",
//     "close": 109.44
// },
// {
//     "date": "2007-05-18T06:53:02.063073-05:00",
//     "close": 110.02
// },
// {
//     "date": "2007-05-21T06:53:02.063073-05:00",
//     "close": 111.98
// },
// {
//     "date": "2007-05-22T06:53:02.063073-05:00",
//     "close": 113.54
// },
// {
//     "date": "2007-05-23T06:53:02.063073-05:00",
//     "close": 112.89
// },
// {
//     "date": "2007-05-24T06:53:02.063073-05:00",
//     "close": 110.69
// },
// {
//     "date": "2007-05-25T06:53:02.063073-05:00",
//     "close": 113.62
// },
// {
//     "date": "2007-05-29T06:53:02.063073-05:00",
//     "close": 114.35
// },
// {
//     "date": "2007-05-30T06:53:02.063073-05:00",
//     "close": 118.77
// },
// {
//     "date": "2007-05-31T06:53:02.063073-05:00",
//     "close": 121.19
// },
// {
//     "date": "2007-06-01T06:53:02.063073-05:00",
//     "close": 118.4
// },
// {
//     "date": "2007-06-04T06:53:02.063073-05:00",
//     "close": 121.33
// },
// {
//     "date": "2007-06-05T06:53:02.063073-05:00",
//     "close": 122.67
// }
// ]
// const dataTwo =[
//   {
//     "date": "2007-04-20T06:53:02.063073-05:00",
//     "close": 73.24
// },
// {
//     "date": "2007-04-21T06:53:02.063073-05:00",
//     "close": 35.35
// },
// {
//     "date": "2007-04-22T05:53:02.063073-05:00",
//     "close": 18.84
// },{
//   "date": "2007-04-23T06:53:02.063073-05:00",
//   "close": 48.84
// },]

const brushMargin = {top: 20, bottom: 20, left: 50, right: 20};
const chartSeparation = 30;
export const background = "#584153";
export const background2 = "#af8baf";
const selectedBrushStyle = {
  fill: "rgba(255, 255, 255, 0.5)",
  stroke: "gray"
};

// accessors
const getStockValue = (d: AppleStock) => d.close;
 const getDate = (d: AppleStock) =>  new Date(d?.date);
// const getDate = (d: AppleStock) => {
//   const dateString = d.date; // Suponiendo que d.date es un string de fecha en formato ISO 8601
//   const dateObject = new Date(dateString);

 
//   return dateObject;
// };

export type BrushProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  compact?: boolean;
  stock:any;
};
type TooltipData = {
close:number;
  index: number;
  height: number;
  width: number;
  stock:any;
  x: number;
  y: number;
  color: string;
  value:number;
  date:any
};
function LineChart({
  compact = false,
  width,
  height,
  stock,
  margin = {
    top: 20,
    left: 50,
    bottom: 0,
    right: 20
  }
}: BrushProps) {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
  useTooltip<TooltipData>();
  const brushRef = useRef<BaseBrush | null>(null);
  const [filteredStock, setFilteredStock] = useState(stock);
  console.log(stock)
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // use TooltipWithBounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true
  });
  useEffect(() => {
    setFilteredStock(stock)
   
  }, [stock])
  
  const handleMouseOver = (event:any, datum:any) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    showTooltip({
      tooltipLeft: coords?.x,
      tooltipTop: coords?.y,
      tooltipData: datum
    });
  };

  const onBrushChange = (domain: Bounds | null) => {
    if (!domain) return;
    const { x0, x1, y0, y1 } = domain;
    const stockCopy = stock.filter((s:any) => {
      const x = getDate(s).getTime();
      const y = getStockValue(s);
     
      // return domain?.xValues?.includes(s.date)
      return x > x0 && x < x1 && y > y0 && y < y1;
    });
    
    setFilteredStock(stockCopy);
  };

  const innerHeight = height - margin.top - margin.bottom;
  const topChartBottomMargin = compact
    ? chartSeparation / 2
    : chartSeparation + 30;
  const topChartHeight = 0.85 * innerHeight - topChartBottomMargin;
  const bottomChartHeight = innerHeight - topChartHeight - chartSeparation;

  // bounds
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = Math.max(topChartHeight, 0);
  const xBrushMax = Math.max(width - brushMargin.left - brushMargin.right, 0);
  const yBrushMax = Math.max(
    bottomChartHeight - brushMargin.top - brushMargin.bottom,
    0
  );

  // scales
  const dateScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xMax],
        domain: extent(filteredStock, (d:any) => new Date(getDate(d))) as [Date, Date],
        
      }),
    [xMax, filteredStock]
  );
  const stockScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        domain: [0, max(filteredStock, getStockValue) || 0],
        nice: true
      }),
    [yMax, filteredStock]
  );
  const brushDateScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xBrushMax],
        domain: extent(stock, getDate) as [Date, Date]
      }),
    [xBrushMax,stock]
  );
  const brushStockScale = useMemo(
    () =>
      scaleLinear({
        range: [yBrushMax, 0],
        domain: [0, max(stock, getStockValue) || 0],
        nice: true
      }),
    [yBrushMax,stock]
  );

  const initialBrushPosition = useMemo(
    () => ({
      start: { x: brushDateScale(getDate(stock[0])) },
      end: { x: brushDateScale(getDate(stock[15])) }
    }),
    [brushDateScale,stock]
  );
  const GRADIENT_ID = "brush_gradient";
  function BrushHandle({ x, height, isBrushActive }: BrushHandleRenderProps) {
    const pathWidth = 8;
    const pathHeight = 15;
    if (!isBrushActive) {
      return null;
    }
    return (
      <Group left={x + pathWidth / 2} top={(height - pathHeight) / 2}>
        <path
          fill="#f2f2f2"
          d="M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5 M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12"
          stroke="#999999"
          strokeWidth="1"
          style={{ cursor: 'ew-resize' }}
        />
      </Group>
    );
  }
  return (
    <div>
      <svg ref={containerRef} width={width} height={height}>
      <LinearGradient
          id={GRADIENT_ID}
          from={background}
          to={background2}
          rotate={45}
        />
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill={`#fff`}
          rx={14}
        />
          <Grid
          top={margin.top}
          left={margin.left}
          xScale={dateScale}
          yScale={stockScale}
          width={xMax}
          height={yMax}
          stroke="black"
          strokeOpacity={0.1}
          // xOffset={dateScale.bandwidth() / 2}
        />
        <AreaChartLine
          dataPointOver={handleMouseOver}
          dataPointOut={hideTooltip}
          hideBottomAxis={compact}
          data={filteredStock && filteredStock}
          width={width}
          margin={{ ...margin, bottom: topChartBottomMargin }}
          yMax={yMax}
          xScale={dateScale}
          yScale={stockScale}
          height={height}
          // dataOne={dataOne}
          // dataTwo={dataTwo}
        />
        <AreaChartLine
          hideBottomAxis
          hideLeftAxis
          data={stock}
          height={height}
          width={width}
          yMax={yBrushMax}
          xScale={brushDateScale}
          yScale={brushStockScale}
          margin={brushMargin}
          top={topChartHeight + topChartBottomMargin + margin.top}
          // dataOne={dataOne}
          // dataTwo={dataTwo}
        >
          <Brush
            xScale={brushDateScale}
            yScale={brushStockScale}
            width={xBrushMax}
            height={yBrushMax}
            margin={brushMargin}
            handleSize={8}
            innerRef={brushRef}
            resizeTriggerAreas={["left", "right"]}
            brushDirection="horizontal"
            initialBrushPosition={initialBrushPosition}
            onChange={onBrushChange}
            onClick={() => setFilteredStock(stock)}
            selectedBoxStyle={selectedBrushStyle}
            renderBrushHandle={(props) => <BrushHandle {...props} />}
          />
  
        </AreaChartLine>

        
      </svg>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} >
          <div  style={{textAlign:"center",    fontSize: 'calc(0.4rem + 0.3vw)',color:"var(--elevvaTextSubtitle)"}}>
         FC
          </div>
          <div style={{    fontSize: 'calc(0.4rem + 0.3vw)',color:"var(--elevvaTextSubtitle)"}}>{ Math.floor(tooltipData?.close)} PPM</div>
          <div>
          
          </div>
        </TooltipInPortal>
      )}
    </div>
  );
}

export default LineChart;
