import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { DropdownList } from "react-widgets";
import LogoSupremacyBig from "../../assets/assets/elevva-mora_big.png";
import imageDefault from "../../assets/corporative/imageDefault.png";
import { ReactComponent as FiltersTable } from "../../assets/elevva-pro-icons/filters-table.svg";

import {
  ButtonMob,
  instance,
  knokinjurie,
  laptop1300,
  laptop1350,
  laptop1600,
  Loadingspinner,
  ModalInfo,
  ModalInfoComponent,
} from "../../utils/utils";
import "./dashboardCorporativo.css";
import {
  ComponentNameCorporativo,
  HabitosCorporativo,
  iconReview,
  iconReviewColor,
  ObjectivesCustomer,
  SeleccionaMembresia,
} from "./utils";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Switch from "react-switch";
import { ReactComponent as ArrowDown } from "../../assets/elevva-pro-icons/arrowDown.svg";
import { ReactComponent as BackArrow } from "../../assets/newIcons/arrow-back.svg";
import { ReactComponent as Lapiz } from "../../assets/newIcons/lapiz_edit.svg";

import { ReactComponent as ChevronBlack } from "../../assets/corporative/rigth-chevron-black.svg";
import { ReactComponent as Diabetes } from "../../assets/newIcons/OBJ-DIABETES.svg";
import { ReactComponent as Estado } from "../../assets/newIcons/OBJ-ESTADO.svg";
import { ReactComponent as Nutricion } from "../../assets/newIcons/OBJ-NUTRICION.svg";
import { ReactComponent as Peso } from "../../assets/newIcons/OBJ-PESO.svg";
import { ReactComponent as Tension } from "../../assets/newIcons/OBJ-TENSION.svg";

import { ReactComponent as ArrowLesion } from "../../assets/corporative/arrow-lesion-corporative.svg";
import icoNegativo from "../../assets/newIcons/ICO-NEGATIVO.svg";

import ReactHtmlParser from "html-react-parser";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Lesiones } from "../Lesiones/lesiones";
import { SwiperPlan } from "./helpResponsive";
import generatePdf from "./reportInbodyPdf";
const Dashboard = (props) => {
  const elevvaT = props.elevvaT;
  const navigate = useNavigate();
  const selectorRef = useRef(null);
  const [totalMemberships, settotalMemberships] = useState(null);
  const [totalCustomers, settotalCustomers] = useState(null);
  const [ModalComments, setModalComments] = useState(null);
  const [MembershipOptionId, setMembershipOptionId] = useState(null);
  const [arterial_prom, setarterial_prom] = useState("---/--");
  const [maxArterial, setMaxArterial] = useState(0);
  const [cardiaca_prom, setcardiaca_prom] = useState("---");
  const [maxCardiaca, setMaxCardiaca] = useState(0);
  const [recorrida_prom, setrecorrida_prom] = useState("---");
  const [maxrecorrida_prom, setmaxrecorrida_prom] = useState(0);
  const [maxcalorias_prom, setmaxcalorias_prom] = useState(0);
  const [calorias_prom, setcalorias_prom] = useState("---");
  const [variabilidad, setvariabilidad] = useState("---");
  const [maxvariabilidad, setmaxvariabilidad] = useState(0);
  const [userData, setUserData] = useState([]);
  const [CutMonth, setCutMonth] = useState(31);
  const [MonthApriori, setMonthApriori] = useState(1);
  const [habitoscarga, sethabitoscarga] = useState([]);
  const [mesProgramaEdit, setmesProgramaEdit] = useState([]);
  const [mesPrograma, setmesPrograma] = useState([
    {
      type: "valoracion_fisica",
      label: "Valoración Física",
      detail: {},
      content: "booking",
      id_content: 10,
      placeholder: "Valoración física",
    },
    {
      type: "valoracion_nutricional",
      label: "Valoración Nutricional",
      detail: {},
      content: "booking",
      id_content: 10,
      placeholder: "Valoración nutricional",
    },
    {
      type: "cardio",
      label: "Cardio Rutina 1",
      detail: {
        type: "caminata",
        notes:
          "Caminata continua durante 20 minuros  con una  intensidad baja. tratar de manternerse en zonas F.C 1 y 2, si se supera esta zonas o se siente fatigado  tomarse un tiempo y descansar mientras que se regula la FC.",
        method: "continua",
        target: "distancia",
        hr_zone: "1 y 2",
        duration: 20,
        intensity: "baja",
        description: `Calentamiento
        · Good mornings
        · 1 km run
        · 50 MB thrusters
 
 WOD
 FOR TIME (4 rounds)
        · 15 Bench press
        · 50 DU
        · 30 ALT C&J
 
 Calentamiento
        · Good mornings
        · 1 km run
        · 50 MB thrusters`,
      },
      content: "discipline",
      id_content: 3,
      placeholder: "Cardio #1",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "cardio",
      label: "Cardio Rutina 2",
      detail: {
        type: "caminata",
        notes:
          "Caminata continua durante 20 minuros  con una  intensidad baja. tratar de manternerse en zonas F.C 1 y 2, si se supera esta zonas o se siente fatigado  tomarse un tiempo y descansar mientras que se regula la FC.",
        method: "continua",
        target: "distancia",
        hr_zone: "1 y 2",
        duration: 20,
        intensity: "baja",
      },
      content: "discipline",
      id_content: 3,
      placeholder: "Cardio #2",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "reserva",
      label: "Clase personal",
      detail: {},
      content: "booking",
      id_content: 1,
      placeholder: "Entrenamiento Funcional",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "video",
      label: "Video #1",
      detail: {},
      content: "video",
      id_content: 1,
      placeholder: "Video Hipertension #1",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "reserva",
      label: "Clase personal",
      detail: {},
      content: "booking",
      id_content: 1,
      placeholder: "Entrenamiento Funcional #2",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "cardio",
      label: "Cardio Rutina 3",
      detail: {
        type: "caminata",
        notes:
          "Caminata continua durante 22 minuros  con una  intensidad baja. tratar de manternerse en zonas F.C 1 y 2, si se supera esta zonas o se siente fatigado  tomarse un tiempo y descansar mientras que se regula la FC.",
        method: "extensivo",
        target: "distancia",
        hr_zone: "1 y 2",
        duration: 22,
        intensity: "baja",
      },
      content: "discipline",
      id_content: 3,
      placeholder: "Cardio #3",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "reserva",
      label: "Clase personal",
      detail: {},
      content: "booking",
      id_content: 1,
      placeholder: "Entrenamiento Funcional #3",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "cardio",
      label: "Cardio Rutina 4",
      detail: {
        type: "caminata",
        notes:
          "Caminata continua durante 25 minuros  con una  intensidad baja. tratar de manternerse en zonas F.C 1 y 2, si se supera esta zonas o se siente fatigado  tomarse un tiempo y descansar mientras que se regula la FC.",
        method: "extensivo",
        target: "distancia",
        hr_zone: "1 y 2",
        duration: 25,
        intensity: "baja",
      },
      content: "discipline",
      id_content: 3,
      placeholder: "Cardio #4",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "valoracion_fisica",
      label: "Valoración Física",
      detail: {},
      content: "booking",
      id_content: 10,
      placeholder: "Valoración física",
    },
    {
      type: "reserva",
      label: "Clase personal",
      detail: {},
      content: "booking",
      id_content: 1,
      placeholder: "Entrenamiento Funcional #4",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "cardio",
      label: "Cardio Rutina 5",
      detail: {
        type: "caminata",
        notes:
          "Caminata  total de 25 minutos  dividida en 5 intervalos de 5 minutos,  compuestos de la siguiente forma:  Caminar 4 minutos  continuos,  descansar 30 segundos y durante 30 segundos hacer sentadillas en su puesto.  tratar de manternerse en zonas F.C 1 y 2, si se supera esta zonas o se siente fatigado  tomarse un tiempo y descansar mientras que se regula la FC.",
        method: "extensivo intervalos",
        target: "fortalecimiento",
        hr_zone: "1 y 2",
        duration: 25,
        intensity: "baja",
      },
      content: "discipline",
      id_content: 3,
      placeholder: "Cardio #5",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "video",
      label: "Video pilates #1",
      detail: {},
      content: "video",
      id_content: 4,
      placeholder: "Video Pilates #1",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "video",
      label: "Video hipertension #2",
      detail: {},
      content: "video",
      id_content: 4,
      placeholder: "Video Hipertensión #2",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "reserva",
      label: "Clase personal",
      detail: {},
      content: "booking",
      id_content: 1,
      placeholder: " Entrenamiento Funcional #5",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "cardio",
      label: "Cardio Rutina 6",
      detail: {
        type: "caminata",
        notes:
          "Caminata  total de 25 minutos  dividida en 5   intervalos de  5 minutos,  compuestos de la siguiente forma:  Caminar 4 minutos  continuos,  aumentar la intensidad de la 30 segundos y  descansar  30 segundos .  tratar de manternerse en zonas F.C 1 y 2, si se supera esta zonas o se siente fatigado  tomarse un tiempo y descansar mientras que se regula la FC.",
        method: "extensivo intervalos",
        target: "fortalecimiento",
        hr_zone: "1 y 2",
        duration: 25,
        intensity: "baja",
      },
      content: "discipline",
      id_content: 3,
      placeholder: "Cardio #6",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "reserva",
      label: "Clase personal",
      detail: {},
      content: "booking",
      id_content: 8,
      placeholder: "Hipertension #5",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "video",
      label: "Video pilates #3",
      detail: {},
      content: "video",
      id_content: 4,
      placeholder: "Video Pilates #3",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
    {
      type: "cardio",
      label: "Cardio Rutina 7",
      detail: {
        type: "caminata",
        notes:
          "Caminata  total de 30 minutos  dividida en 5   intervalos de  6 minutos,  compuestos de la siguiente forma:  Caminar 4 minutos  continuos, aumentar la intensidad de la caminata 30 segundos,  descansar 45 segundos y durante 45 segundos hacer sentadillas en su puesto.  tratar de manternerse en zonas F.C 1 y 2, si se supera esta zonas o se siente fatigado  tomarse un tiempo y descansar mientras que se regula la FC.",
        method: "extensivo intervalos",
        target: "fortalecimiento",
        hr_zone: "1 y 2",
        duration: 30,
        intensity: "baja",
      },
      content: "discipline",
      id_content: 3,
      placeholder: "Cardio #7",
    },
    {
      type: "descanso",
      label: "Descanso",
      detail: {},
      content: "rest",
      id_content: 0,
      placeholder: "Descanso",
    },
  ]);
  const [Comments, setComments] = useState(null);
  const [commentSelected, setcommentSelected] = useState(null);
  const [ModalEditExcersiseday, setModalEditExcersiseday] = useState(null);
  const [flagEditExcersiseday, setflagEditExcersiseday] = useState(false);
  const [flagPush, setflagPush] = useState(false);
  const [IndexEdit, setIndexEdit] = useState(null);
  const [selectFilterTag, setSelectFilterTag] = useState(null);
  const [modal, setModal] = useState(null);
  const objetivesCustomer = [
    { name: "Mejora de la composición corporal", icon: <Peso />, code: 10 },
    {
      name: "Desarrollo integral del deportista",
      icon: <Diabetes />,
      code: 20,
    },
    {
      name: laptop1300.matches
        ? "Mejorar habilidades técnicas"
        : "Mejorar habilidades técnicas",
      icon: <Estado />,
      code: 30,
    },
    {
      name: "Optimización de la carga de entrenamiento",
      icon: <Nutricion />,
      code: 40,
    },
    { name: "Mejorar el rendimiento físico", icon: <Peso />, code: 50 },
    {
      name: "Prevención y recuperación de lesiones",
      icon: <Tension />,
      code: 60,
    },
    {
      name: laptop1300.matches
        ? "Control y mejora de enfermedades crónicas"
        : "Control y mejora de enfermedades crón..",
      icon: <Tension />,
      code: 70,
    },
    { name: "Controlar diabetes", icon: <Diabetes />, code: 80 },
    { name: "Rendimiento en competencia", icon: <Estado />, code: 90 },
    { name: "Optimización mental y emocional", icon: <Diabetes />, code: 100 },
    {
      name: "Control y optimización de la recuperación",
      icon: <Peso />,
      code: 110,
    },
  ];

  const [injurieSeleted, setinjurieSeleted] = useState(null);
  const [InjurieOneSelected, setInjurieOneSelected] = useState(null);
  const modalesDolores = useRef(null);
  const modalesDoloresBack = useRef(null);
  const [infoLesiones, setInfoLesiones] = useState(null);
  const [modalInfoLesiones, setModalInfoLesiones] = useState(null);
  const [showTrainingPlan, setShowTrainingPlan] = useState(null);
  const [ModalEditExcersise, setModalEditExcersise] = useState(null);
  const [brazoiz, setbrazoiz] = useState(0);
  const [brazoizgrasa, setbrazoizgrasa] = useState(0);
  const [brazode, setbrazode] = useState(0);
  const [brazodegrasa, setbrazodegrasa] = useState(0);
  const [piernaiz, setpiernaiz] = useState(0);
  const [piernaizgrasa, setpiernaizgrasa] = useState(0);
  const [piernade, setpiernade] = useState(0);
  const [piernadegrasa, setpiernadegrasa] = useState(0);
  const [abdomenp, setabdomen] = useState(0);
  const [abdomenpgrasa, setabdomengrasa] = useState(0);
  const [maxl0, setmaxl0] = useState(0);
  const [IdCustomer, setIdCustomer] = useState(null);
  const [Logs, setLogs] = useState(null);
  const [inputsDay, setinputsDay] = useState({
    day: "",
    type: "",
    label: "Día de descanso",
    detail: {
      type: "",
      notes: "",
      method: "No aplica",
      target: "No aplica",
      hr_zone: "No aplica",
      duration: "No aplica",
      intensity: "No aplica",
      cardio_zone: "No aplica",
      activity_type: "No aplica",
      sets: "6 sets",
      description: "",
      class_type: "No aplica",
    },
    content: "",
    id_content: "",
    placeholder: "",
  });
  const [inputs, setInputs] = useState({
    imagen: "",
    birthday: "",
    height: "",
    weight: "",
    country: "",
    city: "",
    address: "",
    transportation_time: "",
    transportation: "",
    emergency_contact: "",
    emergency_contact_phone: "",
    answer_1: false,
    answer_2: false,
    answer_3: false,
    answer_4: false,
    answer_5: false,
    answer_6: false,
    answer_7: false,
    answer7Text: "",
    answer_8: false,
    answer_9: false,
  });
  const [buttonChange, setbuttonChange] = useState("Ver vista Posterior");
  const [tags, setTags] = useState([]);
  const [filterByTag, setFilterByTag] = useState("");
  const [modalFilters, setModalFilters] = useState(null);
  const backView = useRef(null);
  const frontalView = useRef(null);
  const [modalInfo, setModalInfo] = useState(null);
  const [summaryFrecuencyId, setSummaryFrecuencyId] = useState(null);
  const opciones = {
    locale: {
      applyLabel: "Aplicar",
      cancelLabel: "Cancelar",
    },
  };
  function ShowNoData() {
    return setModalInfo(
      <ModalInfoComponent
        error="none"
        textButtonAction="Entendido"
        handleCancel={() => setModalInfo(null)}
        handleReservar={() => setModalInfo(null)}
        iconoWidth={"2.5rem"}
        textSizeUp={"1.2rem"}
        icono={icoNegativo}
        component={
          IdCustomer ? (
            <div
              className="position-relative d-flex"
              style={{
                width: "100%",
                border: "1px solid #B6C1CE",
                borderRadius: "10px",
                height: "100%",
              }}
            >
              <br /> El usuario no presenta <br /> datos para esta consulta.
              <br />
              <br />
            </div>
          ) : (
            <div
              className="position-relative d-flex"
              style={{
                width: "100%",
                border: "1px solid #B6C1CE",
                borderRadius: "10px",
                height: "100%",
              }}
            >
              <br /> Selecciona un atleta <br /> para generar información.
              <br />
              <br />
            </div>
          )
        }
      />
    );
  }

  function ShowObjectivesData(objetivesCustomer, userData) {
    return setModalInfo(
      <ModalInfoComponent
        maxWidth="calc(100vw - 70%)"
        textButtonAction="Cerrar"
        handleCancel={() => setModalInfo(null)}
        component={
          <div
            className="position-relative d-flex"
            style={{
              width: "100%",
              border: "1px solid #B6C1CE",
              borderRadius: "10px",
              height: "100%",
            }}
          >
            <ObjectivesCustomer
              objetivesCustomer={objetivesCustomer}
              userData={userData && userData}
              idIdentificar={"actividadesPlanTrainer"}
              widtMap={"95%"}
            />
          </div>
        }
      />
    );
  }

  function recognizeType(typeName) {
    if (typeName == "Valoración física / Control") {
      return "valoracion_fisica";
    } else if (typeName == "Valoración nutricional / Control") {
      return "valoracion_nutricional";
    } else if (typeName == "Programa cardio libre") {
      return "cardio";
    } else if (typeName == "Entrenamiento 1 a 1") {
      return "reserva";
    } else if (typeName == "Clase grupal virtual") {
      return "grupal";
    } else if (typeName == "Video pregrabado") {
      return "video";
    } else if (typeName == "Sesión de Estiramiento") {
      return "sesion_estiramiento";
    } else if (typeName == "Entrenamiento de Fuerza") {
      return "entrenamiento_fuerza";
    } else if (typeName == "Fisioterapia y recuperación") {
      return "fisioterapia_recuperacion";
    } else if (typeName == "Entrenamiento libre") {
      return "entrenamiento_libre";
    } else {
      return "descanso";
    }
  }
  function daysChangeEdit(id) {
    if (id === 1) {
      setCutMonth(31);
      // setmesProgramaEdit(mesPrograma.slice(0, 28));
    } else if (id === 32) {
      setCutMonth(62);
      // setmesProgramaEdit(mesPrograma.slice(28, 56));
    } else if (id === 63) {
      setCutMonth(93);
      // setmesProgramaEdit(mesPrograma.slice(56, 84));
    } else if (id === 94) {
      setCutMonth(124);
      // setmesProgramaEdit(mesPrograma.slice(84, 112));
    } else if (id === 125) {
      setCutMonth(155);
      // setmesProgramaEdit(mesPrograma.slice(112, 140));
    } else if (id === 156) {
      setCutMonth(186);
      // setmesProgramaEdit(mesPrograma.slice(140, 168));
    } else if (id === 187) {
      setCutMonth(217);
      // setmesProgramaEdit(mesPrograma.slice(168, 196));
    } else if (id === 218) {
      setCutMonth(248);
      // setmesProgramaEdit(mesPrograma.slice(168, 196));
    } else if (id === 249) {
      setCutMonth(279);
      // setmesProgramaEdit(mesPrograma.slice(168, 196));
    } else if (id === 280) {
      setCutMonth(310);
      // setmesProgramaEdit(mesPrograma.slice(168, 196));
    } else if (id === 311) {
      setCutMonth(341);
      // setmesProgramaEdit(mesPrograma.slice(168, 196));
    } else {
      setCutMonth(372);
      // setmesProgramaEdit(mesPrograma.slice(196, 224));
    }
    setMonthApriori(id);
  }
  function ShowTrainingCustomer(userData, mesPrograma) {
    setShowTrainingPlan(
      <SwiperPlan
        userData={userData}
        mesPrograma={mesPrograma}
        // CardioTraining={(e) => CardioTraining(e)}
        anchoGrid={"100%"}
        widthBox={"auto"}
        minWidthBox={"auto"}
      />
    );
  }

  const renderSwitchh = (
    index,
    label,
    name,
    isCheck,
    textclasses,
    switchClasses,
    ananswer
  ) => {
    const shouldRenderTextArea = name === "answer_7" && isCheck;
    //const shouldShowTextArea = shouldRenderTextArea && showTextArea;
    return (
      <>
        <div style={{ display: "flex", pointerEvents: "none" }}>
          <Switch
            name={name}
            // onChange={(value) =>
            //   setInputs((val) => ({
            //     ...val,
            //     [name]: value,
            //   }))
            // }
            checked={isCheck}
            className={switchClasses}
            handleDiameter={25}
            height={35}
            width={75}
            onHandleColor="#FFFFFF"
            offHandleColor="#394F65"
            offColor="#E0E7FF"
            onColor="#394F65"
            uncheckedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 15,
                  color: "#394F65",
                  paddingRight: 2,
                  fontWeight: "700",
                }}
              >
                NO
              </div>
            }
            checkedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 15,
                  color: "white",
                  paddingRight: 2,
                  fontWeight: "700",
                }}
              >
                SI
              </div>
            }
          />
        </div>
      </>
    );
  };

  function getTags() {
    instance
      .get("memberships/corp/tags")
      .then((res) => res.data)
      .then((data) => {
        setTags(data.results);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    if (InjurieOneSelected && modalInfoLesiones) ShowInjuries();
  }, [InjurieOneSelected]);
  useEffect(() => {
    let MembershipOptionId = localStorage.getItem("MembershipOptionId");
    if (MembershipOptionId && Number(MembershipOptionId) > 0) {
      setMembershipOptionId(Number(localStorage.getItem("MembershipOptionId")));
    }
    getTags();
  }, []);
  const lesionesCorporative = [];
  function injuriesConsult(id) {
    instance
      .get(`customers/injuries/?customer=${id}`)
      .then((response) => {
        const info = response.data;

        if (info != null) {
          const conjuntoDePart = new Set(); // Un conjunto para rastrear los valores de 'part' ya procesados

          for (const objeto of info) {
            if (!conjuntoDePart.has(objeto.part)) {
              lesionesCorporative.push(objeto); // Agrega el objeto al resultado si su propiedad 'part' no ha sido procesada antes
              conjuntoDePart.add(objeto.part); // Agrega el valor 'part' al conjunto
            }
          }

          setInfoLesiones(info);
          setInjurieOneSelected(info[0]);
          setinjurieSeleted(lesionesCorporative);
          setModalInfo(null);
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        }
      });
  }
  useEffect(() => {
    if (MonthApriori && ModalEditExcersise) editTraining();
  }, [MonthApriori]);
  useEffect(() => {
    if (mesProgramaEdit && ModalEditExcersise) editTraining();
  }, [mesProgramaEdit]);
  useEffect(() => {
    if (mesPrograma && showTrainingPlan) {
      ShowTrainingCustomer(userData, mesPrograma);
    }
  }, [mesPrograma, userData]);

  useEffect(() => {
    updateMetrics(IdCustomer, summaryFrecuencyId);
  }, [summaryFrecuencyId]);
  useEffect(() => {
    if (IdCustomer) {
      updateMetrics(IdCustomer, summaryFrecuencyId);
      let styleModal = {
        display: "flex",
        position: "fixed",
        zIndex: 9999,
        whiteSpace: "pre-line",
        columnGap: "1rem",
        backgroundColor: "rgba(0, 0, 0, .6)",
      };

      setModalInfo(
        <div
          className="modalInfo justify-content-center align-items-center d-flex"
          style={styleModal}
        >
          <div
            className="modalInfoContenido"
            style={{
              width: "calc(25rem + 2vmin)",
              maxWidth: "100%",
              padding: "1.5% 2%",
              height: "auto",
            }}
          >
            <div
              className="title col-12 mb-1"
              style={{
                lineHeight: "100%",
                textAlign: "center",
                fontSize: "calc(1.5vmin + 0.5vw)",
              }}
            ></div>
            <h3>Procesando ...</h3>
            <Loadingspinner customStyle="grow" size="5rem" />
          </div>
        </div>
        // <ModalInfo
        //   error="none"
        //   handleClick={()=>setModalInfo(null)}
        //   title="procesando"
        //   text={<Loadingspinner customStyle="grow" size="5rem" />}
        //   classesMsg=""
        // />
      );
      setInfoLesiones(null);
      injuriesConsult(IdCustomer);
    }
  }, [IdCustomer, summaryFrecuencyId]);
  useEffect(() => {
    if (flagPush) {
      setflagPush(false);
      mesPrograma.push(inputsDay);
      setModalEditExcersiseday(null);
      setmesProgramaEdit(mesPrograma);
      editTraining();
    }
  }, [flagPush]);
  function ShowInjuries() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 9999,
      whiteSpace: "pre-line",
    };
    setModalInfoLesiones(
      <div
        className="modalInfo justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{ width: "calc(50rem + 2vmin)", maxWidth: "100%" }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModalInfoLesiones(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>

          <div
            className="title col-12 mb-5"
            style={{
              lineHeight: "100%",
              textAlign: "center",
              fontSize: "calc(1.5vmin + 0.5vw)",
            }}
          >
            <span
              style={{
                fontWeight: "500",
                fontSize: "calc(1.5vmin + 0.5vw)",
                color: "#394F65",
              }}
            >
              Lesiones{" "}
            </span>{" "}
            del atleta
          </div>

          <div className="col-12 d-flex justify-content-center my-2">
            <div className="col-5 d-flex flex-column  px-3">
              <span
                className="mb-2"
                style={{
                  color: "var(--elevvabutonLogin_User)",
                  textAlign: "left",
                  fontSize: "calc(0.3rem + 0.5vw)",
                }}
              >
                El cliente ha confirmado{" "}
                {injurieSeleted && injurieSeleted.length}{" "}
                {injurieSeleted && injurieSeleted.length == 1
                  ? `lesión`
                  : `lesiones`}{" "}
              </span>
              <PerfectScrollbar
                className="my-2"
                style={{ height: "calc(40vmin + 0.5vw)" }}
              >
                {injurieSeleted &&
                  injurieSeleted.map((lesion) => (
                    <div
                      key={lesion.id}
                      className=" d-flex  py-2"
                      style={{ alignItems: "center", cursor: "pointer" }}
                      onClick={() =>
                        setInjurieOneSelected(
                          injurieSeleted.filter((com) => com.id == lesion.id)[0]
                        )
                      }
                    >
                      <ArrowLesion />
                      <div
                        style={{
                          textAlign: "left",
                          marginLeft: "1rem",
                          lineHeight: "1",
                          fontSize: "calc(0.3rem + 0.5vw)",
                        }}
                      >
                        {knokinjurie(lesion.part)} <br></br>
                        <span
                          style={{
                            color: "#9DACBD",
                            fontSize: "calc(0.3rem + 0.5vw)",
                          }}
                        >
                          {lesion.date}
                        </span>
                      </div>
                    </div>
                  ))}
              </PerfectScrollbar>
            </div>
            <div className="separador-convenios"></div>
            <div className="col-7 px-3">
              <PerfectScrollbar
                className="pe-4 d-flex flex-column align-items-center"
                style={{ height: "calc(40vmin + 0.5vw)" }}
              >
                <div
                  className="title col-12 mb-3"
                  style={{
                    lineHeight: "100%",
                    textAlign: "left",
                    fontSize: "calc(1.5vmin + 0.5vw)",
                    color: "#394F65",
                  }}
                >
                  {knokinjurie(InjurieOneSelected?.part)}
                  <br></br>
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "calc(1.5vmin + 0.5vw)",
                      color: "#394F65",
                    }}
                  >
                    {InjurieOneSelected?.date}
                  </span>
                </div>
                <div
                  className="mb-2"
                  style={{
                    color: "#BEBEBE",
                    textAlign: "left",
                    fontSize: "calc(0.3rem + 0.5vw)",
                    marginRight: "auto",
                  }}
                >
                  <span style={{ color: "var(--elevvabutonLogin_User)" }}>
                    Observaciones generales
                  </span>
                </div>
                <div
                  className="px-2 py-3 w-100"
                  style={{
                    background: "#F1F6F9",
                    borderRadius: "20px",
                    color: "var(--elevvabutonLogin_User)",
                    fontSize: "calc(0.3rem + 0.5vw)",
                    textAlign: "left",
                  }}
                >
                  {InjurieOneSelected?.summary}
                </div>
              </PerfectScrollbar>
            </div>
          </div>
          <ButtonMob
            colorButton={"var(--elevvabutonLogin_User)"}
            borderbutton={`1px solid var(--elevvabutonLogin_User)`}
            colorTextButton={`#fff`}
            text="Cerrar"
            className=" py-1"
            handleClick={() => setModalInfoLesiones(null)}
          />
        </div>
      </div>
    );
  }
  function ShowPR(info) {
    const numItems = 4;

    const items = Array.from({ length: numItems }, (_, i) => i + 1);
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 9999,
      whiteSpace: "pre-line",
    };
    setModalInfoLesiones(
      <div
        className="modalInfo justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{ width: "calc(45rem + 2vmin)", maxWidth: "100%" }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModalInfoLesiones(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>

          <div
            className="title col-12 mb-1"
            style={{
              lineHeight: "100%",
              textAlign: "center",
              fontSize: "calc(1.5vmin + 0.8vw)",
            }}
          >
            <span
              style={{
                fontWeight: "500",
                fontSize: "calc(1.5vmin + 0.8vw)",
                color: "#394F65",
              }}
            >
              PR{" "}
            </span>{" "}
            del atleta
          </div>

          <div className="col-12  my-2">
            <PerfectScrollbar
              className="  px-3 col-12 d-flex flex-column"
              style={{
                height: "calc(40vmin + 0.5vw)",
                justifyContent:
                  Logs && Logs.length > 3 ? "flex-start" : "center",
              }}
            >
              {Logs &&
                Logs.map((test, index) => (
                  <div
                    className=" text-center d-flex flex-row col-12 my-1"
                    key={index}
                    style={{
                      border: "1px solid var(--elevvabutonLogin_User)",
                      borderRadius: "20px",
                      padding: "2% 3%",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div className="col-1">
                      {/* <PushUps style={{color:"#394f65"}}/> */}{" "}
                      <img
                        className="icon-PR"
                        src={test[0].exercise.icon}
                        alt={"icon" + test[0].exercise.name}
                      ></img>
                    </div>
                    <span
                      className="m-auto letraPequeña col-1"
                      style={{
                        color: "var(--elevvaTextTitle)",
                        padding: "1vmin",
                        textAlign: "left",
                        lineHeight: "1.1",
                      }}
                    >
                      <p style={{ fontSize: "1.7vmin", margin: "auto" }}>
                        {test[0].exercise.name}
                      </p>
                    </span>
                    <div
                      className=" fw-bold booking-all"
                      style={{
                        background: "transparent",

                        padding: "2%",
                        fontSize: "1vmin",
                        height: "fit-content",
                        cursor: "pointer",
                        color: "#A8C2D7",
                      }}
                    >
                      <div className="bitacora_corporativo">
                        <ChevronBlack style={{ color: "#394f65" }} />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="d-flex">
                        {items.map((e, i) => (
                          <div key={i} className="contenedor-corporative">
                            <div className="fila">Test {" " + i - -1}</div>
                            <div
                              className="fila"
                              style={{
                                color: "#394F65",
                                fontWeight: "600",
                                fontSize: "calc(1.5vmin + 0.5vw)",
                              }}
                            >
                              {test[i]?.metric || "---"}
                            </div>
                            <div className="fila linea-corp"></div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
            </PerfectScrollbar>
          </div>
          <ButtonMob
            colorButton={"var(--elevvabutonLogin_User)"}
            borderbutton={`1px solid var(--elevvabutonLogin_User)`}
            colorTextButton={`#fff`}
            text="Cerrar"
            className=" py-1"
            handleClick={() => setModalInfoLesiones(null)}
          />
        </div>
      </div>
    );
  }
  function editTraining() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 99,
      whiteSpace: "pre-line",
    };
    setShowTrainingPlan(null);
    setModalEditExcersise(
      <div
        className=" justify-content-center align-items-center"
        style={styleModal}
      >
        <div className=" d-flex edit_training_class">
          {/* <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModalEditExcersise(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div> */}

          <div
            className="col-12 d-flex"
            style={{ justifyContent: "center", flexDirection: "column" }}
          >
            <div
              className="d-flex mx-auto my-2"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                // minWidth: "calc(10rem + 10vw)",
              }}
            >
              <div
                className="mx-1"
                style={{
                  width: "40%",
                  fontSize: "calc(0.4rem + 0.4vw)",
                  textAlign: "center",
                  lineHeight: "100%",
                }}
              >
                Selecciona mes
              </div>
              <div style={{ width: "60%" }}>
                <DropdownList
                  busy
                  busySpinner={<ArrowDown></ArrowDown>}
                  defaultValue={{ id: 1, name: "Primer mes" }}
                  className="input-style-elevva-corporativo-pro"
                  id="newScrollmonth"
                  placeholder="Mes"
                  data={[
                    { id: 1, name: "Primer mes" },
                    { id: 32, name: "Segundo mes" },
                    { id: 63, name: "Tercer mes" },
                    { id: 94, name: "Cuarto mes" },
                    { id: 125, name: "Quinto mes" },
                    { id: 156, name: "Sexto mes" },
                    { id: 187, name: "Séptimo mes" },
                    { id: 218, name: "Octavo mes" },
                    { id: 249, name: "Noveno mes" },
                    { id: 280, name: "Décimo mes" },
                    { id: 311, name: "Onceavo mes" },
                    { id: 342, name: "Doceavo mes" },
                  ]}
                  dataKey="id"
                  textField="name"
                  onChange={(value) => daysChangeEdit(value.id)}
                  renderListItem={({ item }) => (
                    <div
                      className="w-100 mb-1"
                      style={{ borderBottom: "1px solid #9DACBD" }}
                    >
                      <div className="fw-bold">{item.name}</div>
                    </div>
                  )}
                />
              </div>
            </div>
            <PerfectScrollbar className="m-auto w-100 edit-training-style">
              {mesProgramaEdit &&
                mesProgramaEdit
                  .slice(CutMonth - 31, CutMonth)
                  .map((elemento, index) => (
                    <div
                      key={"mescut" + index}
                      className="d-flex w-100 mb-1 py-2"
                      style={{
                        justifyContent: "space-around",
                        background: "#DFDFDF",
                        borderRadius: "20px",
                        alignItems: "center",
                        paddingRight: "6%",
                        fontSize: "calc(0.4rem + 0.4vw)",
                      }}
                    >
                      <div
                        style={{
                          width: "32%",
                          fontSize: "calc(0.4rem + 0.4vw)",
                          padding: "0 3%",
                        }}
                      >
                        Día {index - -1}
                      </div>
                      <div
                        className="d-flex flex-column m-auto justify-content-center "
                        style={{ width: "28%" }}
                      >
                        <div
                          className="d-flex size_item_training"
                          style={{
                            cursor: "pointer",
                            borderRadius: "20px",
                            background: iconReviewColor(elemento.type),
                            padding: "0.5rem",
                            justifyContent: "center",
                          }}
                        >
                          {iconReview(elemento.type)}
                        </div>
                      </div>
                      <div
                        className="mx-1 w-100"
                        style={{
                          fontSize: "calc(0.4rem + 0.4vw)",
                          textAlign: "left",
                          lineHeight: "100%",
                        }}
                      >
                        {elemento.label}
                      </div>
                      <div
                        onClick={() =>
                          editDayTraining(CutMonth - 31 - -index, true)
                        }
                      >
                        <Lapiz></Lapiz>
                      </div>
                    </div>
                  ))}
            </PerfectScrollbar>
          </div>

          <br></br>
          <div className="btnAction_pruebas letraspopfi2 d-flex w-100 mt-2">
            <button
              style={{
                padding: "2% 10%",
                background: "var(--elevvabutonLogin_User)",
                border: "1px solid var(--elevvabutonLogin_User)",
              }}
              className="mx-auto d-flex ver_mas"
              onClick={() => editDayTraining(mesProgramaEdit.length)}
            >
              Crear
            </button>
            <button
              style={{
                padding: "2% 10%",
                background: "var(--elevvabutonLogin_User)",
                border: "1px solid var(--elevvabutonLogin_User)",
              }}
              className="mx-auto d-flex ver_mas"
              onClick={() => {
                let formData = new FormData();
                let file = mesProgramaEdit;

                formData.append("user", IdCustomer);
                formData.append("plan", JSON.stringify(file));

                instance
                  .post(`memberships/personal_training_plan/`, formData)
                  .then((response) => {
                    setmesPrograma(JSON.parse(response.data));
                    ShowTrainingCustomer(userData, mesPrograma);
                    setModalEditExcersise(null);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              Guardar
            </button>
            <button
              style={{
                padding: "2% 10%",
                background: "transparent",
                border: "1px solid var(--elevvabutonLogin_User)",
                color: "var(--elevvabutonLogin_User)",
              }}
              className="mx-auto d-flex ver_mas"
              onClick={() => {
                setModalEditExcersise(null);
                ShowTrainingCustomer(userData, mesPrograma);
              }}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    );
  }
  useEffect(() => {
    if (flagEditExcersiseday) {
      setflagEditExcersiseday(false);

      const nuevoVector = mesProgramaEdit.map((elemento, index) =>
        index === Number(IndexEdit) ? inputsDay : elemento
      );

      setModalEditExcersiseday(null);
      setmesProgramaEdit(nuevoVector);
      editTraining();
      setinputsDay({
        day: "",
        type: "",
        label: "Día de descanso",
        detail: {
          type: "",
          notes: "",
          method: "No aplica",
          target: "No aplica",
          hr_zone: "No aplica",
          duration: "No aplica",
          intensity: "No aplica",
          cardio_zone: "No aplica",
          activity_type: "No aplica",
          sets: "6 sets",
          description: "",
          class_type: "No aplica",
        },
        content: "",
        id_content: "",
        placeholder: "",
      });
    }
  }, [flagEditExcersiseday, IndexEdit]);
  function updateMetrics(IdCustomer, summaryFrecuencyId) {
    if (summaryFrecuencyId) {
      instance
        .get(
          `customers/metrics/summary/?customer=${IdCustomer}&range=${summaryFrecuencyId}`
        )
        .then((response) => {
          const summary = response.data;
          if (summary) {
            const valueSteps = summary.distance;

            const variability = Math.floor(summary["rmssd"]);
            const valorDistance = valueSteps
              ?.toLocaleString("en-US", { minimumFractionDigits: 0 })
              .replace(",", ".");
            const valorKcal = summary?.kcal
              .toLocaleString("en-US", { minimumFractionDigits: 0 })
              .replace(",", ".");

            setcalorias_prom(valorKcal || "--");
            setvariabilidad(variability || "--");
            setrecorrida_prom(valorDistance || "--");
            setcardiaca_prom(Math.round(summary.hr) || "--");

            if (Number(valorDistance) > 0) {
              setTimeout(() => {
                setmaxrecorrida_prom(50);
              }, 1000);
            } else {
              setmaxrecorrida_prom(0);
            }
            if (variability > 0) {
              setTimeout(() => {
                let calculePercent = (variability * 100) / 200;
                setmaxvariabilidad(calculePercent);
              }, 1000);
            } else {
              setmaxvariabilidad(0);
            }
            if (valorKcal > 0) {
              setTimeout(() => {
                setmaxcalorias_prom(50);
              }, 1000);
            } else {
              setmaxcalorias_prom(0);
            }

            setTimeout(() => {
              setMaxCardiaca((Math.round(summary.hr) * 100) / 220 || 0);
            }, 1000);
            if (summary.blood_pressure) {
              let pressureS = Math.round(summary.blood_pressure.s);
              let pressureD = Math.round(summary.blood_pressure.d);
              if (pressureS > 0) {
                if (pressureS > 999) {
                  pressureS =
                    summary.blood_pressure.s.toString().slice(0, 3) + "...";
                }
                if (pressureD > 999) {
                  pressureD =
                    summary.blood_pressure.d.toString().slice(0, 3) + "...";
                }
                setarterial_prom(pressureS + "/" + pressureD);
                setTimeout(() => {
                  setMaxArterial(
                    ((pressureS / 240 + pressureD / 160) / 2) * 100
                  );
                }, 1000);
              } else {
                setarterial_prom("---/--");
                setMaxArterial(0);
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  function editDayTraining(quantity, edit = false) {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: "999",
      whiteSpace: "pre-line",
    };

    if (edit == true) {
      setinputsDay(mesPrograma[quantity]);
    } else {
      setinputsDay((val) => ({
        ...val,
        ["day"]: quantity,
      }));
    }
    setModalEditExcersiseday(
      <div
        className=" justify-content-center align-items-center"
        style={styleModal}
      >
        <div className=" d-flex edit_training_class">
          <br></br>
          <div
            className="col-12 d-flex"
            style={{
              justifyContent: "center",
              flexDirection: "column",

              borderRadius: "20px",
              padding: "3% 0%",
            }}
          >
            <PerfectScrollbar className="m-auto w-100 edit-day-training px-2">
              {/* day */}
              <div
                className="d-flex mr-auto my-2 "
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  className="mx-1"
                  style={{
                    fontSize: "calc(0.4rem + 0.4vw)",
                    textAlign: "left",
                    lineHeight: "100%",
                    width: "35%",
                  }}
                >
                  Día
                </div>
                {edit ? (
                  <div style={{ width: "65%" }}>
                    <select
                      ref={selectorRef} // Asignar la referencia al selector
                      disabled={false}
                      defaultValue={quantity}
                      className="form-control selector_edit_day"
                      name="day"
                      style={{
                        color: "var(--elevvaTextTitle)",
                        width: "7.5rem",
                      }}
                      onChange={(e) =>
                        setinputsDay((val) => ({
                          ...val,
                          ["day"]: e.target.value,
                        }))
                      }
                    >
                      {mesPrograma.map((option, index) => (
                        <option key={index + "optionSelect"} value={index}>
                          Día {index - -1}
                        </option>
                      ))}
                      {/* <option value={index}>Día {quantity - -1}</option> */}
                    </select>
                    <i></i>
                  </div>
                ) : (
                  <div style={{ width: "65%" }}>
                    <select
                      disabled={true}
                      className="form-control selector_edit_day"
                      name="day"
                      style={{
                        color: "var(--elevvaTextTitle)",
                        width: "7.5rem",
                      }}
                    >
                      <option value={quantity}>Día {quantity - -1}</option>
                    </select>
                    <i></i>
                  </div>
                )}
              </div>
              {/* Entrenamiento */}
              <div
                className="d-flex mr-auto my-2"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  className="mx-1"
                  style={{
                    fontSize: "calc(0.4rem + 0.4vw)",
                    textAlign: "left",
                    lineHeight: "100%",
                    width: "35%",
                  }}
                >
                  Entrenamiento
                </div>
                <div style={{ width: "65%" }}>
                  <select
                    defaultValue={
                      mesPrograma[quantity]?.label || "Día de descanso"
                    }
                    className="form-control selector_edit_day"
                    name="label"
                    style={{ color: "var(--elevvaTextTitle)", width: "80%" }}
                    onChange={(e) =>
                      setinputsDay((val) => ({
                        ...val,
                        ["label"]: e.target.value,
                        ["type"]: recognizeType(e.target.value),
                      }))
                    }
                  >
                    <option value="Valoración física / Control">
                      Valoración física / Control
                    </option>
                    <option value="Valoración nutricional / Control">
                      Valoración nutricional / Control
                    </option>
                    <option value="Programa cardio libre">
                      Programa cardio libre
                    </option>
                    <option value="Entrenamiento 1 a 1">
                      Entrenamiento 1 a 1
                    </option>
                    <option value="Clase grupal virtual">
                      Clase grupal virtual
                    </option>
                    <option value="Video pregrabado">Video pregrabado</option>
                    <option value="Día de descanso">Día de descanso</option>
                    <option value="Sesión de Estiramiento">
                      Sesión de Estiramiento
                    </option>
                    <option value="Entrenamiento de Fuerza">
                      Entrenamiento de Fuerza
                    </option>
                    <option value="Fisioterapia y recuperación">
                      Fisioterapia y recuperación
                    </option>
                    <option value="Entrenamiento libre">
                      Entrenamiento libre
                    </option>
                  </select>
                  <i></i>
                </div>
              </div>
              {/* Tiempo */}
              <div
                className="d-flex mr-auto my-2"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  className="mx-1"
                  style={{
                    fontSize: "calc(0.4rem + 0.4vw)",
                    textAlign: "left",
                    lineHeight: "100%",
                    width: "35%",
                  }}
                >
                  Tiempo
                </div>
                <div style={{ width: "65%" }}>
                  <select
                    defaultValue={
                      mesPrograma[quantity]?.detail?.duration || "No aplica"
                    }
                    className="form-control selector_edit_day"
                    name="time"
                    style={{ color: "var(--elevvaTextTitle)", width: "60%" }}
                    onChange={(e) =>
                      setinputsDay((val) => ({
                        ...val,
                        detail: { ...val.detail, duration: e.target.value },
                      }))
                    }
                  >
                    <option value="10">10 min</option>
                    <option value="20">20 min</option>
                    <option value="30">30 min</option>
                    <option value="40">40 min</option>
                    <option value="50">50 min</option>
                    <option value="60">60 min</option>
                    <option value="90">90 min</option>
                    <option value="120">120 min</option>
                    <option value="No aplica">No aplica</option>
                  </select>
                  <i></i>
                </div>
              </div>
              {/* Tipo de clase */}
              <div
                className="d-flex mr-auto my-2"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  className="mx-1"
                  style={{
                    fontSize: "calc(0.4rem + 0.4vw)",
                    textAlign: "left",
                    lineHeight: "100%",
                    width: "35%",
                  }}
                >
                  Tipo de clase
                </div>
                <div style={{ width: "65%" }}>
                  <select
                    defaultValue={
                      mesPrograma[quantity]?.detail?.class_type || "No aplica"
                    }
                    className="form-control selector_edit_day"
                    name="time"
                    style={{ color: "var(--elevvaTextTitle)", width: "60%" }}
                    onChange={(e) =>
                      setinputsDay((val) => ({
                        ...val,
                        detail: { ...val.detail, class_type: e.target.value },
                      }))
                    }
                  >
                    <option value="Acompañamiento presencial">
                      Acompañamiento presencial
                    </option>
                    <option value="Acompañamiento virtual">
                      Acompañamiento virtual
                    </option>
                    <option value="Solo">Solo</option>
                    <option value="No aplica">No aplica</option>
                  </select>
                  <i></i>
                </div>
              </div>
              {/* Intesidad */}
              <div
                className="d-flex mr-auto my-2"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  className="mx-1"
                  style={{
                    fontSize: "calc(0.4rem + 0.4vw)",
                    textAlign: "left",
                    lineHeight: "100%",
                    width: "35%",
                  }}
                >
                  Intesidad
                </div>
                <div style={{ width: "65%" }}>
                  <select
                    defaultValue={
                      mesPrograma[quantity]?.detail?.intensity || "No aplica"
                    }
                    className="form-control selector_edit_day"
                    name="intensity"
                    style={{ color: "var(--elevvaTextTitle)", width: "60%" }}
                    onChange={(e) =>
                      setinputsDay((val) => ({
                        ...val,
                        detail: { ...val.detail, intensity: e.target.value },
                      }))
                    }
                  >
                    <option value="Alta">Alta</option>
                    <option value="Media">Media</option>
                    <option value="Baja">Baja</option>
                    <option value="No aplica">No aplica</option>
                  </select>
                  <i></i>
                </div>
              </div>

              {/* Tipo de actividad */}
              <div
                className="d-flex mr-auto my-2"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  className="mx-1"
                  style={{
                    fontSize: "calc(0.4rem + 0.4vw)",
                    textAlign: "left",
                    lineHeight: "100%",
                    width: "35%",
                  }}
                >
                  Tipo de actividad
                </div>
                <div style={{ width: "65%" }}>
                  <select
                    defaultValue={
                      mesPrograma[quantity]?.detail?.activity_type ||
                      "No aplica"
                    }
                    className="form-control selector_edit_day"
                    name="time"
                    style={{ color: "var(--elevvaTextTitle)", width: "60%" }}
                    onChange={(e) =>
                      setinputsDay((val) => ({
                        ...val,
                        detail: {
                          ...val.detail,
                          activity_type: e.target.value,
                        },
                      }))
                    }
                  >
                    <option value="Anaeróbica">Anaeróbica</option>
                    <option value="Aeróbica">Aeróbica</option>
                    <option value="Recuperación">Recuperación</option>
                    <option value="Prueba">Prueba</option>
                    <option value="No aplica">No aplica</option>
                  </select>
                  <i></i>
                </div>
              </div>

              {/* Objetivo  */}
              <div
                className="d-flex mr-auto my-2"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  className="mx-1"
                  style={{
                    fontSize: "calc(0.4rem + 0.4vw)",
                    textAlign: "left",
                    lineHeight: "100%",
                    width: "35%",
                  }}
                >
                  Objetivo
                </div>
                <div style={{ width: "65%" }}>
                  <select
                    defaultValue={
                      mesPrograma[quantity]?.detail?.target || "No aplica"
                    }
                    className="form-control selector_edit_day"
                    name="time"
                    style={{ color: "var(--elevvaTextTitle)", width: "60%" }}
                    onChange={(e) =>
                      setinputsDay((val) => ({
                        ...val,
                        detail: { ...val.detail, target: e.target.value },
                      }))
                    }
                  >
                    <option value="Quema calórica">Quema calórica</option>
                    <option value="Desarrollo de masa muscular">
                      Desarrollo de masa muscular
                    </option>
                    <option value="Acondicionamiento físico">
                      Acondicionamiento físico
                    </option>
                    <option value="valoración y prueba">
                      valoración y prueba
                    </option>
                    <option value="Recuperación">Recuperación</option>
                    <option value="Desarrollo de movilidad">
                      Desarrollo de movilidad
                    </option>
                    <option value="Desarrollo de agilidad">
                      Desarrollo de agilidad
                    </option>
                    <option value="Fortalecimiento">Fortalecimiento</option>
                    <option value="Desarrollo de potencia">
                      Desarrollo de potencia
                    </option>
                    <option value="Desarrollo de velocidad">
                      Desarrollo de velocidad
                    </option>
                    <option value="Desarrollo cardiovascular">
                      Desarrollo cardiovascular
                    </option>
                    <option value="No aplica">No aplica</option>
                  </select>
                  <i></i>
                </div>
              </div>
              {/* Metodología  */}
              <div
                className="d-flex mr-auto my-2"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  className="mx-1"
                  style={{
                    fontSize: "calc(0.4rem + 0.4vw)",
                    textAlign: "left",
                    lineHeight: "100%",
                    width: "35%",
                  }}
                >
                  Metodología
                </div>
                <div style={{ width: "65%" }}>
                  <select
                    defaultValue={
                      mesPrograma[quantity]?.detail?.method || "No aplica"
                    }
                    className="form-control selector_edit_day"
                    name="time"
                    style={{ color: "var(--elevvaTextTitle)", width: "60%" }}
                    onChange={(e) =>
                      setinputsDay((val) => ({
                        ...val,
                        detail: { ...val.detail, method: e.target.value },
                      }))
                    }
                  >
                    <option value="Intervalos">Intervalos</option>
                    <option value="Resistencia">Resistencia</option>
                    <option value="Por estaciones">Por estaciones</option>
                    <option value="Falla muscular">Falla muscular</option>
                    <option value="Máximos / PRs">Máximos / PRs</option>
                    <option value="EMOM">EMOM</option>
                    <option value="AMRAP">AMRAP</option>
                    <option value="TABATA">TABATA</option>
                    <option value="Carrera continua">Carrera continua</option>
                    <option value="No aplica">No aplica</option>
                  </select>
                  <i></i>
                </div>
              </div>
              {/* Zona de frecuencia cardíaca */}
              <div
                className="d-flex mr-auto my-2"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  className="mx-1"
                  style={{
                    fontSize: "calc(0.4rem + 0.4vw)",
                    textAlign: "left",
                    lineHeight: "100%",
                    width: "35%",
                  }}
                >
                  Zona de frecuencia cardíaca
                </div>
                <div style={{ width: "65%" }}>
                  <select
                    defaultValue={
                      mesPrograma[quantity]?.detail?.cardio_zone || "No aplica"
                    }
                    className="form-control selector_edit_day"
                    name="time"
                    style={{ color: "var(--elevvaTextTitle)", width: "60%" }}
                    onChange={(e) =>
                      setinputsDay((val) => ({
                        ...val,
                        detail: { ...val.detail, cardio_zone: e.target.value },
                      }))
                    }
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="1 y 2">1 y 2</option>
                    <option value="2 y 3">2 y 3</option>
                    <option value="3 y 4">3 y 4</option>
                    <option value="4 y 5">4 y 5</option>
                    <option value="1, 2, 3">1, 2, 3</option>
                    <option value="4, 5, 6">4, 5, 6</option>
                    <option value="No aplica">No aplica</option>
                  </select>
                  <i></i>
                </div>
              </div>
              {/* Zona corporal de trabajo  */}
              <div
                className="d-flex mr-auto my-2"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  className="mx-1"
                  style={{
                    fontSize: "calc(0.4rem + 0.4vw)",
                    textAlign: "left",
                    lineHeight: "100%",
                    width: "35%",
                  }}
                >
                  Zona corporal de trabajo
                </div>
                <div style={{ width: "65%" }}>
                  <select
                    defaultValue={
                      mesPrograma[quantity]?.detail?.hr_zone || "No aplica"
                    }
                    className="form-control selector_edit_day"
                    name="time"
                    style={{ color: "var(--elevvaTextTitle)", width: "60%" }}
                    onChange={(e) =>
                      setinputsDay((val) => ({
                        ...val,
                        detail: { ...val.detail, hr_zone: e.target.value },
                      }))
                    }
                  >
                    <option value="Tren inferior">Tren inferior</option>
                    <option value="Tren superior">Tren superior</option>
                    <option value="Core">Core</option>
                    <option value="Zona especifica">Zona especifica</option>
                    <option value="Todo el cuerpo">Todo el cuerpo</option>
                    <option value="No aplica">No aplica</option>
                  </select>
                  <i></i>
                </div>
              </div>
              {/* Descripción  */}
              <div
                className="d-flex mr-auto my-2"
                style={{
                  justifyContent: "center",

                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <div
                  className="mx-1"
                  style={{
                    fontSize: "calc(0.4rem + 0.4vw)",
                    textAlign: "left",
                    lineHeight: "100%",
                    width: "70%",
                  }}
                >
                  Describe la actividad de este día
                </div>
                <div className="d-flex" style={{ width: "100%" }}>
                  <textarea
                    defaultValue={mesPrograma[quantity]?.detail?.notes || null}
                    className="mt-2"
                    style={{
                      color: "#9DACBD",
                      borderRadius: "20px",
                      backgroundColor: "#fff",
                      width: "95%",
                      padding: "2%",
                      fontSize: "calc(0.4rem + 0.4vw)",
                    }}
                    placeholder="Escribe aquí"
                    rows={7}
                    onInput={(e) =>
                      setinputsDay((val) => ({
                        ...val,
                        detail: { ...val.detail, notes: e.target.value },
                      }))
                    }
                  ></textarea>
                </div>
              </div>
              {/* Observaciones  */}
              <div
                className="d-flex mr-auto my-2"
                style={{
                  justifyContent: "center",

                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <div
                  className="mx-1"
                  style={{
                    fontSize: "calc(0.4rem + 0.4vw)",
                    textAlign: "left",
                    lineHeight: "100%",
                    width: "20%",
                  }}
                >
                  Observaciones
                </div>
                <div className="d-flex" style={{ width: "100%" }}>
                  <textarea
                    defaultValue={
                      mesPrograma[quantity]?.detail?.description || null
                    }
                    className="mt-2"
                    style={{
                      color: "#9DACBD",
                      borderRadius: "20px",
                      backgroundColor: "#fff",
                      width: "95%",
                      padding: "2%",
                      fontSize: "calc(0.4rem + 0.4vw)",
                    }}
                    placeholder="Escribe aquí"
                    rows={7}
                    onInput={(e) =>
                      setinputsDay((val) => ({
                        ...val,
                        detail: { ...val.detail, description: e.target.value },
                      }))
                    }
                  ></textarea>
                </div>
              </div>
            </PerfectScrollbar>
          </div>

          <div className="btnAction_pruebas letraspopfi2 d-flex w-100 mt-3">
            <button
              style={{
                padding: "2% 10%",
                background: "var(--elevvabutonLogin_User)",
                border: "1px solid var(--elevvabutonLogin_User)",
              }}
              className="mx-auto d-flex ver_mas"
              onClick={() =>
                handleSaveClass(
                  edit ? selectorRef.current.value : quantity,
                  edit
                )
              }
            >
              Guardar
            </button>
            <button
              style={{
                padding: "2% 10%",
                background: "transparent",
                border: "1px solid var(--elevvabutonLogin_User)",
                color: " var(--elevvabutonLogin_User)",
              }}
              className="mx-auto d-flex ver_mas"
              onClick={() => setModalEditExcersiseday(null)}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    );
  }
  // const handleDateApplyEvent = (event, picker) => {
  //   let startDate = picker.startDate;
  //   let endDate = picker.endDate;
  //   setStartDate(startDate.format("YYYY-MM-DD"));
  //   setEndDate(endDate.format("YYYY-MM-DD"));
  // };
  const summaryFrecuency = [
    { id: 1, name: "Últimas 24 horas", valor: "ld" },
    { id: 2, name: "Última semana", valor: "l7" },
    { id: 3, name: "Último mes", valor: "l30" },
    { id: 4, name: "Últimos 3 meses", valor: "l90" },
    { id: 5, name: "Últimos 6 meses", valor: "l180" },
    { id: 6, name: "Último Año", valor: "l360" },
  ];
  const question_2 = [
    [
      `Presión <br/> constante`,
      process.env.PUBLIC_URL + "/assets/img/onboarding/presion_constanteB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/presion_constanteA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    // [
    //   `Paciente  <br/> y calmado`,
    //   process.env.PUBLIC_URL + "/assets/img/onboarding/calmadoB.svg",
    //   process.env.PUBLIC_URL + "/assets/img/onboarding/calmadoA.svg",
    //   useRef(null),
    //   useRef(null),
    //   useRef(null),
    // ],
  ];
  const pregunta_one = [
    [
      elevvaT("like_exercise"),
      "answer_1",
      inputs["answer_1"],
      "col-2 ps-3 align-self-center",
    ],
  ];
  const pregunta_three = [
    [
      elevvaT("activity_physical_right"),
      "answer_3",
      inputs["answer_3"],
      "col-2 ps-3 align-self-center",
    ],
  ];
  const transportes = [
    // titulo, img unselected, img selected, ref container, ref image
    [
      `Carro /  <br/> Moto`,
      process.env.PUBLIC_URL + "/assets/img/onboarding/carroB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/carroA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      `Bicicleta <br/>`,
      process.env.PUBLIC_URL + "/assets/img/onboarding/biciB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/biciA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      `Transporte <br/> público`,
      process.env.PUBLIC_URL + "/assets/img/onboarding/busB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/busA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
  ];
  const actividad_fisica = [
    // titulo, img unselected, img selected, ref container, ref image
    [
      "Yoga pilates",
      process.env.PUBLIC_URL + "/assets/img/onboarding/yogaA.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/yogaB.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Ejercicio funcional",
      process.env.PUBLIC_URL + "/assets/img/onboarding/funcionalA.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/funcionalB.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Caminar /Correr",
      process.env.PUBLIC_URL + "/assets/img/onboarding/caminarA.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/caminarB.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Rumba",
      process.env.PUBLIC_URL + "/assets/img/onboarding/rumbaA.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/rumbaB.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Ciclismo",
      process.env.PUBLIC_URL + "/assets/img/onboarding/ciclismoA.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/ciclismoB.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Otros",
      process.env.PUBLIC_URL + "/assets/img/onboarding/otrosA.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/otrosB.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
  ];
  const actividad_time = [
    ["Ninguna", useRef(null)],
    ["Menos de 3", useRef(null)],
    ["3 a 5 veces", useRef(null)],
    ["+ de 5 veces", useRef(null)],
  ];
  const actividad_agotado = [
    ["Normal", useRef(null)],
    ["Cansado", useRef(null)],
    ["Muy cansado", useRef(null)],
  ];
  const actividad_sesiones = [
    ["No hago sesiones", useRef(null)],
    ["Menos de 30 min", useRef(null)],
    ["Entre 30 y 60 min", useRef(null)],
    ["Más de 60 min", useRef(null)],
  ];
  const actividad_intensidad = [
    ["No hago sesiones", useRef(null)],
    ["Baja", useRef(null)],
    ["Media", useRef(null)],
    ["Alta", useRef(null)],
  ];
  const franja_horaria = [
    ["06:00 a 11:00", useRef(null)],
    ["11:00 a 17:00", useRef(null)],
    ["17:00 a 22:00", useRef(null)],
  ];
  const transportes_time = [
    ["De 2 a 3 horas", useRef(null)],
    ["Media hora", useRef(null)],
    ["Más de 3 horas", useRef(null)],
  ];
  function darsigno(x, y) {
    if (x - y >= 0) {
      return [+x];
    } else {
      return [-x];
    }
  }

  function dejarsolonumber(x) {
    if (x < 0) {
      return x * -1;
    } else {
      return x;
    }
  }

  useEffect(() => {
    if (IdCustomer) {
      instance
        .get(`customers/?customer=${IdCustomer}`)
        .then(async (response) => {
          const customer = response.data;

          //  setmesPrograma(response.data.membership.training_plan);
          setmaxl0(customer.age ? 220 - customer.age : 0);
          setUserData(customer);
          let actividad = actividad_fisica
            .filter((e, i) => customer?.preference_activity.includes(i - -1))
            .map((e) => e[0]);

          let preference = actividad_time
            .filter((e, i) => customer?.preference_activity_per_week == i)
            .map((e) => e[0]);
          let sesiones = actividad_sesiones
            .filter((e, i) => customer?.preference_session_time == i)
            .map((e) => e[0]);
          let intensity = actividad_intensidad
            .filter((e, i) => customer?.preference_intensity == i)
            .map((e) => e[0]);

          let fatigue = actividad_agotado
            .filter(
              (e, i) => customer?.preference_fatigue_level == (i - -1) * 10
            )
            .map((e) => e[0]);
          let preference_time = franja_horaria
            .filter(
              (e, i) => customer?.preference_training_time == (i - -1) * 10
            )
            .map((e) => e[0]);
          let transportesNow = transportes
            .filter((e, i) => customer?.transportation - 1 == i)
            .map((e) => e[0].replace(/<br\/>/g, ""));
          let transportes_hour = transportes_time
            .filter((e, i) => customer?.transportation_time - 1 == i)
            .map((e) => e[0]);
          sethabitoscarga([
            customer.physical_active
              ? "Realiza actividad física"
              : "No realiza actividad física",
            actividad.length > 0 ? `Practica ${actividad}` : null,
            preference.length > 0 ? `Entrena ${preference}` : null,
            sesiones.length > 0 ? `Sesiones ${sesiones}` : null,
            intensity.length > 0
              ? intensity.includes("No hago sesiones")
                ? intensity
                : `Intensidad ${intensity}`
              : null,
            fatigue.length > 0 ? `Nivel de cansancio ${fatigue}` : null,
            preference_time.length > 0 ? `Horario de ${preference_time}` : null,
            transportesNow.length > 0 ? `Transporte ${transportesNow}` : null,
            transportes_hour.length > 0
              ? `Hora en transporte ${transportes_hour}`
              : null,
          ]);
          await instance
            .get(`memberships/corp/members/${customer.user}`)
            .then((response) => {
              let membershipData = response.data;

              if (membershipData && membershipData.personal_training_plan) {
                let personal_training = JSON.parse(
                  membershipData.personal_training_plan
                );

                if (personal_training) {
                  setmesPrograma(personal_training);
                  setmesProgramaEdit(personal_training);
                  ShowTrainingCustomer(customer, personal_training);
                } else {
                  setmesPrograma([]);
                  setmesProgramaEdit([]);
                  ShowTrainingCustomer(customer, []);
                }
              } else {
                if (membershipData.training_plan) {
                  setmesPrograma(membershipData.training_plan);
                  setmesProgramaEdit(membershipData.training_plan);
                  ShowTrainingCustomer(customer, membershipData.training_plan);
                } else {
                  setmesPrograma([]);
                  setmesProgramaEdit([]);
                  ShowTrainingCustomer(customer, []);
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });

      instance
        .get(`customers/dailylog/?customer=${IdCustomer}`)
        .then((res) => res.data)
        .then((data) => {
          setComments(data);
          setcommentSelected(data[0]);
        })
        .catch((err) => console.error(err));
      instance
        .get(`customers/logs/?customer=${IdCustomer}`)
        .then((res) => res.data)
        .then((data) => {
          const groupedItems = {};
          console.log(data);

          data &&
            data.results.length > 0 &&
            data.results.forEach((item) => {
              const exerciseId = item.exercise.id;

              if (!groupedItems[exerciseId]) {
                groupedItems[exerciseId] = [];
              }

              groupedItems[exerciseId].push(item);
            });

          // Convertir el objeto en un array de objetos
          const groupedArray = Object.values(groupedItems);

          setLogs(groupedArray);
        })
        .catch((err) => console.error(err));
    }
  }, [IdCustomer]);

  useEffect(() => {
    if (IdCustomer) {
      instance
        .get(`customers/healthlog/?part=20&customer=${IdCustomer}`)
        .then((res) => {
          const daysselected = res.data;

          if (daysselected.length > 0) {
            const grasabrazo_iz = daysselected.filter((e) => e.type == 3);
            const masabrazo_iz = daysselected.filter((e) => e.type == 10);
            // console.log(masabrazo_iz);
            setbrazoiz(
              masabrazo_iz.length == 1
                ? masabrazo_iz[0].numeric_value
                : masabrazo_iz.length > 1
                ? darsigno(
                    masabrazo_iz[0].numeric_value,
                    masabrazo_iz[1].numeric_value
                  )
                : 0
            );
            setbrazoizgrasa(
              grasabrazo_iz.length == 1
                ? grasabrazo_iz[0].numeric_value
                : grasabrazo_iz.length > 1
                ? darsigno(
                    grasabrazo_iz[0].numeric_value,
                    grasabrazo_iz[1].numeric_value
                  )
                : 0
            );
          } else {
            setbrazoiz(0);
            setbrazoizgrasa(0);
          }
        });
      instance
        .get(`customers/healthlog/?part=30&customer=${IdCustomer}`)
        .then((res) => {
          const daysselected = res.data;
          if (daysselected.length > 0) {
            // console.log(daysselected);
            let grasabrazo_de = daysselected.filter((e) => e.type == 3);
            let masabrazo_de = daysselected.filter((e) => e.type == 10);
            // console.log(grasabrazo_de, masabrazo_de);
            setbrazode(
              masabrazo_de.length == 1
                ? masabrazo_de[0].numeric_value
                : masabrazo_de.length > 1
                ? darsigno(
                    masabrazo_de[0].numeric_value,
                    masabrazo_de[1].numeric_value
                  )
                : 0
            );
            setbrazodegrasa(
              grasabrazo_de.length == 1
                ? grasabrazo_de[0].numeric_value
                : grasabrazo_de.length > 1
                ? darsigno(
                    grasabrazo_de[0].numeric_value,
                    grasabrazo_de[1].numeric_value
                  )
                : 0
            );
          } else {
            setbrazode(0);
            setbrazodegrasa(0);
          }
        });
      instance
        .get(`customers/healthlog/?part=40&customer=${IdCustomer}`)
        .then((res) => {
          const daysselected = res.data;
          if (daysselected.length > 0) {
            const grasapierna_iz = daysselected.filter((e) => e.type == 3);
            const masapierna_iz = daysselected.filter((e) => e.type == 10);

            setpiernaiz(
              masapierna_iz.length == 1
                ? masapierna_iz[0].numeric_value
                : masapierna_iz.length > 1
                ? darsigno(
                    masapierna_iz[0].numeric_value,
                    masapierna_iz[1].numeric_value
                  )
                : 0
            );
            setpiernaizgrasa(
              grasapierna_iz.length == 1
                ? grasapierna_iz[0].numeric_value
                : grasapierna_iz.length > 1
                ? darsigno(
                    grasapierna_iz[0].numeric_value,
                    grasapierna_iz[1].numeric_value
                  )
                : 0
            );
          } else {
            setpiernaiz(0);
            setpiernaizgrasa(0);
          }
        });
      instance
        .get(`customers/healthlog/?part=50&customer=${IdCustomer}`)
        .then((res) => {
          const daysselected = res.data;
          if (daysselected.length > 0) {
            const grasapierna_de = daysselected.filter((e) => e.type == 3);
            const masapierna_de = daysselected.filter((e) => e.type == 10);

            setpiernade(
              masapierna_de.length == 1
                ? masapierna_de[0].numeric_value
                : masapierna_de.length > 1
                ? darsigno(
                    masapierna_de[0].numeric_value,
                    masapierna_de[1].numeric_value
                  )
                : 0
            );
            setpiernadegrasa(
              grasapierna_de.length == 1
                ? grasapierna_de[0].numeric_value
                : grasapierna_de.length > 1
                ? darsigno(
                    grasapierna_de[0].numeric_value,
                    grasapierna_de[1].numeric_value
                  )
                : 0
            );
          } else {
            setpiernade(0);
            setpiernadegrasa(0);
          }
        });
      instance
        .get(`customers/healthlog/?part=60&customer=${IdCustomer}`)
        .then((res) => {
          const daysselected = res.data;
          if (daysselected.length > 0) {
            const grasaabdomen = daysselected.filter((e) => e.type == 3);
            const masaabdomen = daysselected.filter((e) => e.type == 10);

            setabdomen(
              masaabdomen.length == 1
                ? masaabdomen[0].numeric_value
                : masaabdomen.length > 1
                ? darsigno(
                    masaabdomen[0].numeric_value,
                    masaabdomen[1].numeric_value
                  )
                : 0
            );
            setabdomengrasa(
              grasaabdomen.length == 1
                ? grasaabdomen[0].numeric_value
                : grasaabdomen.length > 1
                ? darsigno(
                    grasaabdomen[0].numeric_value,
                    grasaabdomen[1].numeric_value
                  )
                : 0
            );
          } else {
            setabdomen(0);
            setabdomengrasa(0);
          }
        });
      // instance
      //   .get(`customers/metrics/weekly/?customer=${IdCustomer}`)
      //   .then((response) => {
      //     if (response.data) {
      //       let currentWeek = response.data.current.week_days;

      //       setdayselected({
      //         lunes: currentWeek[0] == 0 ? false : true,
      //         martes: currentWeek[1] == 0 ? false : true,
      //         miércoles: currentWeek[2] == 0 ? false : true,
      //         jueves: currentWeek[3] == 0 ? false : true,
      //         viernes: currentWeek[4] == 0 ? false : true,
      //         sábado: currentWeek[5] == 0 ? false : true,
      //         domingo: currentWeek[6] == 0 ? false : true,
      //       });
      //     }
      //   })
      //   .catch((err) => console.log(err.response));
    }
  }, [IdCustomer]);

  useEffect(() => {
    instance
      .get("memberships/corp/memberships")
      .then((res) => res.data)
      .then((data) => {
        if (data.length == 1) {
          setMembershipOptionId(data[0].id);
        }
        settotalMemberships(data);
      })
      .catch((err) => console.error(err));
    setSummaryFrecuencyId("ld");
  }, []);

  function getCustomers() {
    localStorage.setItem("MembershipOptionId", MembershipOptionId);
    instance
      .get(`memberships/corp/${MembershipOptionId}/members/`)
      .then((res) => res.data)
      .then((data) => {
        settotalCustomers(data);

        // setIdCustomer(data[0].customer);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    if (MembershipOptionId) {
      getCustomers();
    }
  }, [MembershipOptionId]);
  function changeOptionVideos(valuesearch) {
    if (totalCustomers) {
      if (valuesearch != "") {
        let valueLower = valuesearch.toLowerCase();
        let filterNames = totalCustomers.filter(
          (memb) =>
            memb.last_name.toLowerCase().includes(valueLower) ||
            memb.first_name.toLowerCase().includes(valueLower)
        );
        settotalCustomers(filterNames);
      } else {
        if (MembershipOptionId) {
          instance
            .get(`memberships/corp/${MembershipOptionId}/members/`)
            .then((res) => res.data)
            .then((data) => {
              settotalCustomers(data);

              // setIdCustomer(data[0].customer);
            })
            .catch((err) => console.error(err));
        }
      }
    } else {
      if (valuesearch != "") {
        let styleModal = {
          display: "flex",
          position: "fixed",
          zIndex: 999,
          whiteSpace: "pre-line",
        };
        setModal(
          <div
            className="popUpPassword justify-content-center align-items-center"
            style={styleModal}
          >
            <div
              className="modalInfoContenido"
              style={{
                background: "white",
                borderRadius: "20px",
                boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
                padding: "2% 6%",
              }}
            >
              <div
                className=""
                style={{
                  position: "absolute",
                  top: "5%",
                  right: "1%",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  color: " #9DACBD",
                  cursor: "pointer",
                }}
                onClick={() => setModal(null)}
              >
                <i className="bi bi-x-circle"></i>
              </div>
              <div
                className=" mb-3 py-3"
                style={{
                  lineHeight: "100%",
                  textAlign: "center",

                  minWidth: "calc(18rem + 1vmin)",

                  color: "var(--elevvabutonLogin_User)",
                }}
              >
                <br />
                Debes seleccionar <br /> una membresía.
                <br />
              </div>
            </div>
          </div>
        );
      }
    }
    // setfiltervideos(videofiltro);
  }
  function onlyOne(checkbox) {
    var checkboxes = document.getElementsByName("customerList");

    if (checkbox.checked) {
      setIdCustomer(checkbox.value);
    } else {
      setIdCustomer(null);
      sethabitoscarga([]);
      setUserData([]);
      setInfoLesiones(null);
      setmaxvariabilidad(0);
      setvariabilidad("---");
      setcalorias_prom("---");
      setmaxcalorias_prom(0);
      setmaxrecorrida_prom(0);
      setrecorrida_prom("---");
      setMaxCardiaca(0);
      setcardiaca_prom("---");
      setarterial_prom("---/--");
      setMaxArterial(0);
    }

    checkboxes.forEach((item) => {
      if (item !== checkbox) item.checked = false;
    });
  }
  const TrainingEstadistics = [
    [
      `Tensión <br/> arterial promedio`,
      process.env.PUBLIC_URL + "/assets/img/tensionPromedio.svg",
      "var(--colorsesionesTension)",
      "mm Hg",
      arterial_prom,
      "var(--elevva)",
      maxArterial,
    ],
    [
      "Frecuencia <br/> cardiaca promedio",
      process.env.PUBLIC_URL + "/assets/img/frecuenciaPromedio.svg",
      "var(--colorsesionesHr)",
      "Ppm",
      cardiaca_prom,
      "var(--elevva)",
      maxCardiaca,
    ],
    [
      "Distancia recorrida  <br/>actividad libre",
      process.env.PUBLIC_URL + "/assets/img/distanciaPromedio.svg",
      "var(--colorsesionesKm)",
      "Metros",
      recorrida_prom,
      "var(--elevva)",
      maxrecorrida_prom,
    ],
    [
      "Quema de <br/> calorias",
      process.env.PUBLIC_URL + "/assets/img/quemaCalorias.svg",
      "var(--colorsesionesCal)",
      "Kilocalorias",
      calorias_prom,
      "var(--elevva)",
      maxcalorias_prom,
    ],
    [
      "Variabilidad <br/>de FC",
      process.env.PUBLIC_URL + "/assets/img/variabilidad.svg",
      "var(--colorsesionesCal)",
      "RMSSD",
      variabilidad,
      "var(--elevva)",
      maxvariabilidad,
    ],
  ];
  function handleSaveClass(quantity, edit) {
    if (edit) {
      setIndexEdit(quantity);
      setflagEditExcersiseday(edit);
    } else {
      setflagPush(true);
    }
  }
  // inicio habitos
  function ShowHabitos() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 9999,
      whiteSpace: "pre-line",
    };
    setModalComments(
      <div
        className="modalInfo justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{ width: "calc(100vw - 40%)", maxWidth: "100%" }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModalComments(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>

          <div className="col-12 d-flex justify-content-center my-2">
            <div className="col-4 d-flex flex-column  px-3">
              <img
                className=" col-12"
                src={process.env.PUBLIC_URL + "/assets/img/onboarding-girl.jpg"}
              />
            </div>

            <div className="col-8" style={{ paddingLeft: "3rem" }}>
              <div className="my-1 d-flex">
                <button
                  onClick={() => setModalComments(null)}
                  className={"button_w_h_back"}
                  style={{ background: "transparent", border: "none" }}
                >
                  <BackArrow width={"100%"} height={"100%"} />
                </button>
                <span className="title fw-light d-flex align-items-center">
                  {" "}
                  <strong>Hábitos e información del atleta</strong>
                </span>
              </div>
              <PerfectScrollbar
                className="pe-4 d-flex flex-column align-items-center"
                style={{ height: "50vh" }}
              >
                <div
                  className="d-flex flex-column justify-content-center gap-4"
                  style={{ alignItems: "center" }}
                >
                  {pregunta_one.map((value, index) => {
                    return (
                      <div
                        key={"pregunta_one" + index}
                        className="d-flex  py-2 col-10 col-md-12"
                      >
                        <div
                          className="words_health col-6 my-auto"
                          style={{ textAlign: "left" }}
                        >
                          {value[0]}
                        </div>

                        <div
                          key={index}
                          className="d-flex my-auto col-6 justify-content-center "
                        >
                          {renderSwitchh(
                            index,
                            value[0],
                            value[1],
                            userData?.physical_active,
                            "col-8 text-left fw-bold lh-1 d-flex align-items-center words_health",
                            value[3]
                          )}
                        </div>

                        <div className="my-auto py-3"></div>
                      </div>
                    );
                  })}
                  <div className="d-flex flex-column flex-md-row col-10 col-md-12  borderHave">
                    <div
                      className="words_health d-flex col-6 my-auto  justify-content-center"
                      style={{ textAlign: "left" }}
                    >
                      {elevvaT("accompaniment")}
                    </div>
                    <div className="  d-flex m-auto col-6 mt-1 mt-md-0">
                      {" "}
                      {question_2.map((objetivo, index) => {
                        return (
                          <div
                            className=" formSteps py-2 mx-auto mx-md-0 w-100"
                            key={"question_2" + index}
                          >
                            <div
                              key={index}
                              ref={objetivo[3]}
                              className="objetivoP"
                              style={{ alignItems: "center" }}
                            >
                              <div
                                ref={objetivo[4]}
                                id="icon"
                                style={{
                                  backgroundImage: `url(${objetivo[2]})`,
                                  backgroundColor: `var(--elevvabutonLogin_User) `,
                                  border: `6px solid var(--elevva)`,
                                }}
                                className="rounded-circle  text-center"
                              ></div>
                              <div
                                id="text"
                                className="p-3  text-center "
                                style={{
                                  color: "var(--elevvaTextTitle)",
                                }}
                              >
                                {ReactHtmlParser(objetivo[0])}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  {pregunta_three.map((value, index) => {
                    return (
                      <div
                        key={"pregunta_three" + index}
                        className="d-flex  col-10 col-md-12"
                        style={{ textAlign: "left" }}
                      >
                        <div className="words_health col-6  my-auto">
                          {value[0]}{" "}
                        </div>
                        <div className="d-flex col-6  justify-content-center my-auto">
                          {renderSwitchh(
                            index,
                            null,
                            value[1],
                            userData?.physical_active,
                            " text-left fw-bold lh-1 d-flex align-items-center words_health",
                            value[3]
                          )}
                        </div>
                      </div>
                    );
                  })}
                  {userData?.physical_active == true ? (
                    <>
                      {userData?.preference_activity.length > 0 ? (
                        <div className="d-flex flex-column flex-md-row py-1 col-10 col-md-12  borderHave">
                          <div
                            className="words_health d-flex col-6 my-auto  justify-content-center justify-content-md-start"
                            style={{ textAlign: "left" }}
                          >
                            {elevvaT("activity_physics")}
                          </div>
                          <div
                            className="m-auto  col-6 py-2 d-flex"
                            style={{ flexWrap: "wrap" }}
                          >
                            {actividad_fisica
                              .filter((e, i) =>
                                userData?.preference_activity.includes(i - -1)
                              )
                              .map((objetivo, index) => {
                                return (
                                  <div
                                    key={"objetivo-" + index}
                                    className=" formSteps py-auto d-flex justify-content-center"
                                    style={{ flex: "1" }}
                                  >
                                    <div
                                      key={index}
                                      ref={objetivo[3]}
                                      className="objetivoP justify-objetivoP "
                                      style={{ cursor: "pointer" }}
                                    >
                                      <div
                                        ref={objetivo[4]}
                                        id="icon"
                                        style={{
                                          backgroundImage: `url(${objetivo[2]})`,
                                          backgroundColor: `var(--elevvabutonLogin_User) `,
                                          border: `6px solid var(--elevva)`,
                                        }}
                                        className="rounded-circle  text-center"
                                      ></div>
                                      <div
                                        id="text"
                                        className="py-3  text-center "
                                        style={{
                                          color: "var(--elevvaTextTitle)",
                                          margin: "0",
                                          maxWidth: "80px",
                                          height: " auto",
                                        }}
                                      >
                                        {objetivo[0]}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      ) : null}

                      {userData.preference_activity_per_week ? (
                        <div className="d-flex flex-column flex-md-row col-10 col-md-12  borderHave py-3">
                          <div
                            className="words_health d-flex col-6 my-auto  justify-content-center justify-content-md-start"
                            style={{ textAlign: "left" }}
                          >
                            {elevvaT("many_times_week")}
                          </div>
                          <div className=" d-flex  justify-content-around m-0 pt-4 pt-md-0 col-6">
                            {" "}
                            {actividad_time
                              .filter(
                                (e, i) =>
                                  userData.preference_activity_per_week == i
                              )
                              .map((objetivo, index, arr) => {
                                return (
                                  <div
                                    key={index}
                                    ref={objetivo[1]}
                                    className="objetivo_text_ve objetivo_text_small rounded-circle col-6 mx-2 position-relative"
                                    style={{
                                      cursor: "pointer",
                                      alignItems: "center",
                                      backgroundColor: `var(--elevvabutonLogin_User) `,
                                      border: `6px solid var(--elevva)`,
                                    }}
                                  >
                                    <div
                                      id="text"
                                      style={{ color: "#ffff" }}
                                      className="rounded-circle d-flex align-items-center text-center position-absolute"
                                    >
                                      {objetivo[0]}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      ) : null}

                      {userData.preference_session_time ? (
                        <div className="d-flex flex-column flex-md-row col-10 col-md-12  borderHave py-3">
                          <div
                            className="words_health d-flex col-6 my-auto  justify-content-center justify-content-md-start"
                            style={{ textAlign: "left" }}
                          >
                            {elevvaT("long_sessions")}
                          </div>
                          <div className=" d-flex  justify-content-around m-0 pt-4 pt-md-0 col-6 ">
                            {" "}
                            {actividad_sesiones
                              .filter(
                                (e, i) => userData.preference_session_time == i
                              )
                              .map((objetivo, index, arr) => {
                                return (
                                  <div
                                    key={index}
                                    ref={objetivo[1]}
                                    className="objetivo_text_ve objetivo_text_small rounded-circle  mx-2 justify-content-center position-relative"
                                    style={{
                                      cursor: "pointer",
                                      alignItems: "center",
                                      backgroundColor: `var(--elevvabutonLogin_User) `,
                                      border: `6px solid var(--elevva)`,
                                    }}
                                  >
                                    <div
                                      id="text"
                                      style={{ color: "#ffff" }}
                                      className="rounded-circle d-flex align-items-center text-center position-absolute"
                                    >
                                      {objetivo[0]}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      ) : null}

                      {userData.preference_intensity ? (
                        <div className="d-flex flex-column flex-md-row col-10 col-md-12  borderHave py-3">
                          <div
                            className="words_health d-flex col-6  justify-content-center justify-content-md-start"
                            style={{ textAlign: "left" }}
                          >
                            {elevvaT("intensity_level_sessions")}
                          </div>
                          <div className=" d-flex  justify-content-around col-6 m-0 pt-4 pt-md-0 ">
                            {" "}
                            {actividad_intensidad
                              .filter(
                                (e, i) =>
                                  userData.preference_intensity / 10 == i
                              )
                              .map((objetivo, index, arr) => {
                                return (
                                  <div
                                    key={index}
                                    ref={objetivo[1]}
                                    className="objetivo_text_ve objetivo_text_small rounded-circle col-4 mx-2 position-relative"
                                    style={{
                                      cursor: "pointer",
                                      alignItems: "center",
                                      backgroundColor: `var(--elevvabutonLogin_User) `,
                                      border: `6px solid var(--elevva)`,
                                    }}
                                  >
                                    <div
                                      id="text"
                                      style={{ color: "#ffff" }}
                                      className="rounded-circle d-flex align-items-center text-center position-absolute"
                                    >
                                      {objetivo[0]}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      ) : null}

                      {userData.preference_fatigue_level ? (
                        <div className="d-flex flex-column flex-md-row col-10 col-md-12  borderHave py-3">
                          <div
                            className="words_health d-flex col-6 my-auto  justify-content-center justify-content-md-start"
                            style={{ textAlign: "left" }}
                          >
                            {elevvaT("how_tired")}
                          </div>
                          <div className=" d-flex  justify-content-around col-6 m-0 pt-4 pt-md-0 ">
                            {" "}
                            {actividad_agotado
                              .filter(
                                (e, i) =>
                                  userData.preference_fatigue_level ==
                                  (i - -1) * 10
                              )
                              .map((objetivo, index, arr) => {
                                return (
                                  <div
                                    key={index}
                                    ref={objetivo[1]}
                                    className="objetivo_text_ve objetivo_text_small rounded-circle col-4 mx-2 position-relative"
                                    style={{
                                      cursor: "pointer",
                                      alignItems: "center",
                                      backgroundColor: `var(--elevvabutonLogin_User) `,
                                      border: `6px solid var(--elevva)`,
                                    }}
                                  >
                                    <div
                                      id="text"
                                      style={{ color: "#ffff" }}
                                      className="rounded-circle d-flex align-items-center text-center position-absolute"
                                    >
                                      {objetivo[0]}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      ) : null}

                      {userData.preference_training_time ? (
                        <div className="d-flex flex-column flex-md-row col-10 col-md-12  borderHave py-3">
                          <div
                            className="words_health d-flex col-6 my-auto  justify-content-center justify-content-md-start"
                            style={{ textAlign: "left" }}
                          >
                            {elevvaT("take_your_workouts")}
                          </div>
                          <div className=" d-flex  justify-content-around col-6 m-0 pt-4 pt-md-0 ">
                            {" "}
                            {franja_horaria
                              .filter(
                                (e, i) =>
                                  userData.preference_training_time ==
                                  (i - -1) * 10
                              )
                              .map((objetivo, index, arr) => {
                                return (
                                  <div
                                    key={index}
                                    ref={objetivo[1]}
                                    className="objetivo_text_ve objetivo_text_small rounded-circle col-4 mx-2 position-relative"
                                    style={{
                                      cursor: "pointer",
                                      alignItems: "center",
                                      backgroundColor: `var(--elevvabutonLogin_User) `,
                                      border: `6px solid var(--elevva)`,
                                    }}
                                  >
                                    <div
                                      id="text"
                                      style={{ color: "#ffff" }}
                                      className="rounded-circle d-flex align-items-center text-center position-absolute"
                                    >
                                      {objetivo[0]}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}

                  <>
                    {userData?.transportation ? (
                      <div className="d-flex flex-column flex-md-row  pb-md-0 col-10 col-md-12  borderHave">
                        <div
                          className="words_health d-flex col-6 my-auto  justify-content-center justify-content-md-start"
                          style={{ textAlign: "left" }}
                        >
                          {elevvaT("transportation")}
                        </div>
                        <div className="d-flex m-auto col-6 mt-1 mt-md-0">
                          {" "}
                          {transportes
                            .filter((e, i) => userData?.transportation - 1 == i)
                            .map((objetivo, index) => {
                              return (
                                <div className=" formSteps py-2 m-auto m-md-0 w-100">
                                  <div
                                    key={index}
                                    ref={objetivo[3]}
                                    className="objetivoP"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div
                                      ref={objetivo[4]}
                                      id="icon"
                                      style={{
                                        backgroundImage: `url(${objetivo[2]})`,
                                        backgroundColor: `var(--elevvabutonLogin_User) `,
                                        border: `6px solid var(--elevva)`,
                                      }}
                                      className="rounded-circle  text-center mx-2"
                                    ></div>
                                    <div
                                      id="text"
                                      className=" text-center py-2 "
                                      style={{
                                        color: "var(--elevvaTextTitle)",
                                        margin: "auto",
                                      }}
                                    >
                                      {ReactHtmlParser(objetivo[0])}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : null}

                    {userData?.transportation_time ? (
                      <div className="d-flex flex-column flex-md-row py-2 col-10 col-md-12  borderHave">
                        <div
                          className="words_health d-flex col-6 my-auto  justify-content-center justify-content-md-start"
                          style={{ textAlign: "left" }}
                        >
                          {elevvaT("las_dayly")}
                        </div>
                        <div
                          className="d-flex m-auto  col-6 mt-1  justify-content-around m-md-0 ms-md-auto justify-content-md-center"
                          style={{ gap: "1rem" }}
                        >
                          {" "}
                          {transportes_time
                            .filter(
                              (e, i) => userData?.transportation_time - 1 == i
                            )
                            .map((objetivo, index, arr) => {
                              return (
                                <div
                                  key={index}
                                  ref={objetivo[1]}
                                  className="objetivo_text_ve objetivo_text_small rounded-circle col-4 mx-2  mx-md-0 position-relative"
                                  style={{
                                    cursor: "pointer",
                                    alignItems: "center",
                                    backgroundColor: `var(--elevvabutonLogin_User) `,
                                    border: `6px solid var(--elevva)`,
                                  }}
                                >
                                  <div
                                    style={{ color: "#ffff" }}
                                    id="text"
                                    className="rounded-circle d-flex align-items-center text-center position-absolute"
                                  >
                                    {objetivo[0]}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : null}
                  </>
                </div>
              </PerfectScrollbar>
            </div>
          </div>
        </div>
      </div>
    );
  }
  // fin habitos
  const changeView = (ref) => {
    if (ref == backView) {
      backView.current.style.display = "block";
      frontalView.current.style.display = "none";
      setbuttonChange("Ver vista frontal");
    } else {
      backView.current.style.display = "none";
      frontalView.current.style.display = "block";
      setbuttonChange("Ver vista Posterior");
    }
  };
  useEffect(() => {
    if (filterByTag) {
      handlerFilterByTag(filterByTag);
    } else {
      getCustomers();
    }
    setModalFilters(null);
  }, [filterByTag]);

  function handlerFilterByTag(idTag) {
    setSelectFilterTag(idTag);
    instance
      .get(`memberships/corp/${MembershipOptionId}/members?tags=${idTag}`)
      .then((res) => res.data)
      .then((data) => {
        console.log(data, "data");
        settotalCustomers(data);
        // setPageNext(data.next);
        // setPageBack(data.previous);
        // setCount(data.count);
        setModalFilters(null);
      })
      .catch((err) => console.error(err));
  }

  function filtersUser() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalFilters(
      <div
        className="popUpServices justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
          }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "1%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModalFilters(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div
            className=" mb-3 p-3"
            style={{
              lineHeight: "120%",
              textAlign: "center",

              minWidth: "calc(18rem + 1vmin)",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            <PerfectScrollbar style={{ maxHeight: "15rem", padding: "0 1rem" }}>
              {tags.map((tag, i) => (
                <div
                  onClick={() => handlerFilterByTag(tag.id)}
                  className="d-flex py-2 cursor-pointer"
                  key={i}
                  style={{
                    borderBottom: "1px solid #9DACBD",
                    justifyContent: "flex-start",
                    minWidth: "10rem",
                    fontSize: "calc(.4rem + .4vw)",
                  }}
                >
                  <label className="custom-checkbox me-2">
                    <input
                      type="radio"
                      name="user"
                      checked={selectFilterTag && selectFilterTag === tag.id}
                      onClick={() => {}}
                    />
                    <span className="checkmark"></span>
                  </label>{" "}
                  <span>{tag.name}</span>
                </div>
              ))}
            </PerfectScrollbar>
            <div className="d-flex w-100 mx-auto mt-3 jusfy-content-center">
              {" "}
              <button
                style={{ padding: "0.6rem 1rem", lineHeight: "1" }}
                className="  d-flex add_location "
                onClick={() => setFilterByTag(null)}
              >
                Borrar filtros
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
      <div
        className="d-flex position-relative"
        style={{
          height: "60px",
          background: "var(--backgroundOnboarding)",
          columnGap: "2rem",
        }}
      >
        <SeleccionaMembresia
          MembershipOptionId={MembershipOptionId}
          totalMemberships={totalMemberships && totalMemberships}
          membershipIdOption={(e) => setMembershipOptionId(e)}
        />{" "}
        <div className="logo-supremacy">
          <img style={{ width: "6rem" }} src={LogoSupremacyBig}></img>
        </div>
      </div>{" "}
      <div
        style={{
          flexGrow: "1",
          overflow: "auto",
          columnGap: "calc(.5rem + .5vw)",
        }}
        className="d-flex py-2 px-3"
      >
        <div
          style={{
            width: "13%",
            border: "1px solid #B6C1CE",
            borderRadius: "10px",
            height: "100%",
            padding: "1rem 0.6rem",
          }}
        >
          <div className="d-flex gap-1">
            <input
              placeholder="Ingresa atleta"
              type="search"
              className="searchAtletas py-1 mb-2 w-100"
              style={{
                border: "1px solid var(--elevvaTextSubtitle)",
                borderRadius: "20px",
              }}
              onChange={(e) => changeOptionVideos(e.target.value)}
            ></input>
            <FiltersTable className="cursor-pointer" onClick={filtersUser} />
          </div>
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <PerfectScrollbar
              style={{
                padding: "0",
                listStyle: "none",
                overflowX: "auto",
                height: "calc(80vmin + 0.5vw)",
              }}
            >
              {totalCustomers &&
                totalCustomers.map((memb) => (
                  <li
                    className="text-left d-flex my-1"
                    key={"customer" + memb.id}
                  >
                    {" "}
                    <input
                      type="checkbox"
                      id={"customerList" + memb.customer}
                      name="customerList"
                      value={memb.customer}
                      onClick={(e) => onlyOne(e.target)}
                    />{" "}
                    <div
                      className="d-flex w-100"
                      style={{
                        padding: "0.7rem 0",
                        fontSize: "calc(.4rem + .3vw)",
                        whiteSpace: "nowrap",
                        color: "#9DACBD",
                        borderBottom: "1px solid #9DACBD",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        {" "}
                        <img
                          className="ms-2 me-1"
                          style={{
                            objectFit: "cover",
                            width: laptop1600.matches ? "30px" : "25px",
                            maxHeight: "100%",
                            maxWidth: "100%",
                            height: laptop1600.matches ? "30px" : "25px",
                            borderRadius: "50%",
                          }}
                          src={
                            memb.profile_pic ? memb.profile_pic : imageDefault
                          }
                        ></img>{" "}
                      </div>

                      <div
                        className={
                          laptop1600.matches ? "d-flex align-items-center" : ""
                        }
                        style={{ lineHeight: "0.9" }}
                      >
                        {laptop1600.matches ? (
                          <span
                            style={{
                              fontSize: "calc(.4rem + .3vw)",
                              lineHeight: "1.2",
                            }}
                          >
                            {memb.first_name.length + memb.last_name.length > 15
                              ? memb.first_name +
                                " " +
                                memb.last_name.slice(0, 3) +
                                ".."
                              : memb.first_name + " " + memb.last_name}{" "}
                          </span>
                        ) : (
                          <span
                            style={{
                              fontSize: "calc(.4rem + .3vw)",
                              lineHeight: "1.2",
                            }}
                          >
                            {memb.first_name.length > 9
                              ? memb.first_name.slice(0, 8) + ".."
                              : memb.first_name}{" "}
                            <br></br>
                            {memb.last_name.length > 9
                              ? memb.last_name.slice(0, 8) + ".."
                              : memb.last_name}{" "}
                          </span>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
            </PerfectScrollbar>
          </div>
        </div>
        <div
          className="d-flex flex-column tacometro"
          style={{
            width: "12%",
            border: "1px solid #B6C1CE",
            borderRadius: "10px",
          }}
        >
          <DropdownList
            busy
            busySpinner={<ArrowDown></ArrowDown>}
            style={{
              width: laptop1300.matches ? "100%" : "90%",
              margin: laptop1300.matches ? "auto 0" : "0 5% 5% 5%",
            }}
            defaultValue={{ id: 1, name: "Última sesión", valor: "ld" }}
            className={"input-style-elevva-graphics"}
            id="optionSummary"
            placeholder={"Selecciona"}
            data={summaryFrecuency.map((memb) => ({
              id: memb.id,
              name: memb.name,
              valor: memb.valor,
            }))}
            dataKey="id"
            textField="name"
            onChange={(value) => setSummaryFrecuencyId(value.valor)}
            renderListItem={({ item }) => (
              <div
                className="w-100 mb-1"
                style={{ borderBottom: "1px solid #9DACBD" }}
              >
                <div>{item.name}</div>
              </div>
            )}
          />
          {TrainingEstadistics.map((estadistic) => (
            <div
              className="m-auto"
              style={{ width: laptop1300.matches ? "80%" : "90%" }}
            >
              <CircularProgressbarWithChildren
                value={estadistic[6]}
                circleRatio={0.75}
                styles={buildStyles({
                  rotation: 1 / 2 + 1 / 8,
                  pathColor: "var(--elevva)",
                  textSize: " calc(.3rem + .3vw)",
                  trailColor: "#eee",
                  textColor: "#394F65",
                })}
              >
                {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                <img
                  className="media_image_tac"
                  style={{ marginBottom: 3, height: "auto" }}
                  src={estadistic[1]}
                  alt="doge"
                />
                <div
                  className="text-center"
                  style={{
                    fontSize: "calc(.3rem + .3vw)",

                    color: "#394F65",
                  }}
                >
                  {ReactHtmlParser(estadistic[0])}
                </div>
                <div
                  style={{
                    fontSize: "calc(.3rem + .3vw)",

                    color: "#394F65",
                  }}
                >
                  {estadistic[4]}
                </div>
                <div
                  style={{
                    fontSize: "calc(.3rem + .3vw)",

                    color: "#394F65",
                  }}
                >
                  {estadistic[3]}
                </div>
              </CircularProgressbarWithChildren>
            </div>
          ))}
        </div>{" "}
        <div
          className="d-flex flex-column"
          style={{
            width: "35%",
            height: "100%",
            rowGap: "calc(.3rem + .3vw)",
          }}
        >
          <div
            className="position-relative d-flex flex-column"
            style={{
              width: "100%",
              columnGap: "calc(.3rem + .3vw)",
              rowGap: "calc(.3rem + .3vw)",
              height: "20%",
            }}
          >
            <div
              className="position-relative d-flex"
              style={{
                width: "100%",
                rowGap: "calc(.3rem + .3vw)",
                height: "100%",
              }}
            >
              <div
                className="position-relative d-flex"
                style={{
                  width: "100%",
                  border: "1px solid #B6C1CE",
                  borderRadius: "10px",
                  height: "100%",
                }}
              >
                <ComponentNameCorporativo
                  userData={userData}
                  padreclass={"d-flex col-12"}
                  disabled={IdCustomer}
                  onClickButton={
                    IdCustomer ? () => generatePdf(IdCustomer) : ShowNoData
                  }
                />{" "}
              </div>
            </div>

            <div
              className="position-relative d-flex"
              style={{
                width: "100%",
                rowGap: "calc(.3rem + .3vw)",
                columnGap: "calc(.3rem + .3vw)",
                height: "100%",
              }}
            >
              <div
                className="position-relative d-flex"
                style={{
                  width: "100%",
                  border: "1px solid #B6C1CE",
                  borderRadius: "10px",
                  height: "100%",
                }}
              >
                <div
                  className="d-flex w-100"
                  style={{ padding: "0 5%", alignItems: "center" }}
                >
                  <div
                    className="w-50"
                    style={{
                      fontSize: "calc(.4rem + .4vw)",
                      color: "var(--elevvaTextTitle)",
                    }}
                  >
                    PR <strong>del atleta</strong>
                  </div>

                  <button
                    className="mx-auto d-flex ver_mas"
                    // onClick={Logs && Logs.length > 0 ? ShowPR : ShowNoData}
                    onClick={
                      IdCustomer
                        ? () => navigate(`/PR/${IdCustomer}`)
                        : ShowNoData
                    }
                  >
                    Ver más
                  </button>
                </div>
              </div>
              <div
                className="position-relative d-flex"
                style={{
                  width: "100%",
                  border: "1px solid #B6C1CE",
                  borderRadius: "10px",
                  height: "100%",
                }}
              >
                <div
                  className="d-flex w-100"
                  style={{ padding: "0 5%", alignItems: "center" }}
                >
                  <button
                    className="mx-auto d-flex ver_mas"
                    onClick={() =>
                      ShowObjectivesData(objetivesCustomer, userData)
                    }
                  >
                    Ver Objetivos
                  </button>
                </div>
              </div>
            </div>
          </div>{" "}
          <div
            className="position-relative d-flex flex-column"
            style={{
              width: "100%",
              border: "1px solid #B6C1CE",
              borderRadius: "10px",
              height: "80%",
              alignItems: "center",
              justifyContent: "space-around",
              padding: "2% 0",
            }}
          >
            <span
              className=" letraPequeña"
              style={{
                color: "var(--elevvaTextTitle)",
              }}
            >
              Conoce <strong>tu cuerpo</strong>
              <hr className="m-2" />
            </span>
            <>
              <div
                className=" letraPequeña d-flex w-100 know_body_width"
                style={{
                  padding: "0 10vmin",
                  justifyContent: "space-evenly",
                  color: "var(--elevvaTextTitle)",
                }}
              >
                <span className="letraBody m-auto">
                  <img
                    alt="bascula"
                    style={{ width: "2.5vmin" }}
                    className="mx-1"
                    src={process.env.PUBLIC_URL + "/assets/img/bascula.svg"}
                  />
                  {/* <strong>Actual:</strong> 78,8 Kg */}
                  <strong>Actual:</strong>{" "}
                  {userData?.optimal_weight
                    ? userData.weight.toFixed(2) + " Kg"
                    : " ---"}
                </span>
                <span className="letraBody m-auto">
                  <img
                    alt="bascula"
                    style={{ width: "2.5vmin" }}
                    className="mx-1"
                    src={process.env.PUBLIC_URL + "/assets/img/bascula.svg"}
                  />{" "}
                  <strong>Objetivo: </strong>
                  <span
                    className="letraBody"
                    style={{ color: "var(--elevva)" }}
                  >
                    {userData?.optimal_weight
                      ? userData.optimal_weight.min?.toFixed(2) +
                        " - " +
                        userData.optimal_weight?.max.toFixed(2) +
                        " Kg"
                      : " ---"}
                  </span>
                </span>
              </div>
              <img
                alt="FRENTE"
                className="figurePartsInicio"
                src={process.env.PUBLIC_URL + "/assets/img/FRENTE.png"}
              />
              <div
                className="position-absolute "
                style={{
                  top: "22%",
                  left: "10%",
                  width: "30%",
                  transform: "rotate(-360deg)",
                }}
              >
                <span
                  className="letraBodyInside"
                  style={{ color: "var(--elevvabutonLogin_User)" }}
                >
                  Brazo derecho
                </span>
                <div style={{ lineHeight: "1.5vmin" }}>
                  {" "}
                  <span className="letraBodyInside">
                    {/* <i className="bi bi-caret-down-fill"></i> Grasa corporal: 5,8% */}
                    {Array.isArray(brazodegrasa) ? (
                      brazodegrasa[0] >= 0 ? (
                        <i className="bi bi-caret-up-fill"></i>
                      ) : (
                        <i className="bi bi-caret-down-fill"></i>
                      )
                    ) : null}{" "}
                    Grasa corporal:{" "}
                    {Array.isArray(brazodegrasa)
                      ? dejarsolonumber(brazodegrasa[0].toFixed(1)) + "%"
                      : brazodegrasa.toFixed(1) + "%"}
                  </span>
                  <br />
                  <span className="letraBodyInside">
                    {Array.isArray(brazode) ? (
                      brazode[0] >= 0 ? (
                        <i
                          className="bi bi-caret-up-fill"
                          style={{
                            color: "var(--elevvabutonLogin_User)",
                          }}
                        ></i>
                      ) : (
                        <i
                          style={{
                            color: "var(--elevvabutonLogin_User)",
                          }}
                          className="bi bi-caret-down-fill"
                        ></i>
                      )
                    ) : null}{" "}
                    {/* Mm: 37 kg */}
                    Masa muscular:{" "}
                    {Array.isArray(brazode)
                      ? dejarsolonumber(brazode[0].toFixed(1)) + "Kg"
                      : brazode.toFixed(1) + "Kg"}
                  </span>
                </div>
              </div>
              <div
                className="position-absolute"
                style={{
                  top: "22%",
                  right: "10%",
                  width: "30%",
                  textAlign: "right",
                }}
              >
                <span
                  className="letraBodyInside "
                  style={{ color: "var(--elevvabutonLogin_User)" }}
                >
                  Brazo izquierdo
                </span>
                <div style={{ lineHeight: "1.5vmin" }}>
                  {" "}
                  <span className="letraBodyInside ">
                    {/* Grasa corporal: 5,8% */}
                    Grasa corporal:{" "}
                    {Array.isArray(brazoizgrasa)
                      ? dejarsolonumber(brazoizgrasa[0].toFixed(1)) + "%"
                      : brazoizgrasa.toFixed(1) + "%"}
                    {Array.isArray(brazoizgrasa) ? (
                      brazoizgrasa[0] >= 0 ? (
                        <i className="bi bi-caret-up-fill"></i>
                      ) : (
                        <i className="bi bi-caret-down-fill"></i>
                      )
                    ) : null}{" "}
                  </span>
                  <br />
                  <span className="letraBodyInside ">
                    {/* Masa muscular: 37 kg{" "} */}
                    Masa muscular:{" "}
                    {Array.isArray(brazoiz)
                      ? dejarsolonumber(brazoiz[0].toFixed(1)) + "Kg"
                      : brazoiz.toFixed(1) + "Kg"}
                    {Array.isArray(brazoiz) ? (
                      brazoiz[0] >= 0 ? (
                        <i
                          className="bi bi-caret-up-fill"
                          style={{
                            color: "var(--elevvabutonLogin_User)",
                          }}
                        ></i>
                      ) : (
                        <i
                          style={{
                            color: "var(--elevvabutonLogin_User)",
                          }}
                          className="bi bi-caret-down-fill"
                        ></i>
                      )
                    ) : null}
                  </span>
                </div>
              </div>
              <span
                className="position-absolute linebodyInicio"
                style={{ top: "32%", left: "10%", width: "30%" }}
              >
                <hr />
                <span
                  className="rounded-circle position-absolute"
                  style={{
                    background: "var(--elevvaTexttitleLow)",
                    width: "8px",
                    height: "8px",
                    top: "40%",
                    left: "100%",
                  }}
                ></span>
              </span>
              <div
                className="position-absolute "
                style={{
                  top: "48%",
                  left: "10%",
                }}
              >
                <span
                  className="letraBodyInside"
                  style={{ color: "var(--elevvabutonLogin_User)" }}
                >
                  Abdomen
                </span>
                <div style={{ lineHeight: "1.5vmin" }}>
                  {" "}
                  <span className="letraBodyInside">
                    {/* <i className="bi bi-caret-down-fill"></i> Grasa corporal: 5,8% */}
                    {Array.isArray(abdomenpgrasa) ? (
                      abdomenpgrasa[0] >= 0 ? (
                        <i className="bi bi-caret-up-fill"></i>
                      ) : (
                        <i className="bi bi-caret-down-fill"></i>
                      )
                    ) : null}{" "}
                    Grasa corporal:{" "}
                    {Array.isArray(abdomenpgrasa)
                      ? dejarsolonumber(abdomenpgrasa[0].toFixed(1)) + "%"
                      : abdomenpgrasa.toFixed(1) + "%"}
                  </span>
                  <br />
                  <span className="letraBodyInside">
                    {Array.isArray(abdomenp) ? (
                      abdomenp[0] >= 0 ? (
                        <i
                          className="bi bi-caret-up-fill"
                          style={{
                            color: "var(--elevvabutonLogin_User)",
                          }}
                        ></i>
                      ) : (
                        <i
                          style={{
                            color: "var(--elevvabutonLogin_User)",
                          }}
                          className="bi bi-caret-down-fill"
                        ></i>
                      )
                    ) : null}{" "}
                    {/* Masa muscular: 37 kg */}
                    Masa muscular:{" "}
                    {Array.isArray(abdomenp)
                      ? dejarsolonumber(abdomenp[0].toFixed(1)) + "Kg"
                      : abdomenp.toFixed(1) + "Kg"}
                  </span>
                </div>
              </div>
              <div
                className="position-absolute "
                style={{
                  top: "70%",
                  left: "10%",
                  width: "30%",
                  transform: "rotate(-360deg)",
                }}
              >
                <span
                  className="letraBodyInside"
                  style={{ color: "var(--elevvabutonLogin_User)" }}
                >
                  Pierna derecha
                </span>
                <div style={{ lineHeight: "1.5vmin" }}>
                  {" "}
                  <span className="letraBodyInside">
                    {Array.isArray(piernadegrasa) ? (
                      piernadegrasa[0] >= 0 ? (
                        <i className="bi bi-caret-up-fill"></i>
                      ) : (
                        <i className="bi bi-caret-down-fill"></i>
                      )
                    ) : null}{" "}
                    Grasa corporal:
                    {Array.isArray(piernadegrasa)
                      ? dejarsolonumber(piernadegrasa[0].toFixed(1)) + "%"
                      : piernadegrasa.toFixed(1) + "%"}
                  </span>
                  <br />
                  <span className="letraBodyInside">
                    {Array.isArray(piernade) ? (
                      piernade[0] >= 0 ? (
                        <i
                          className="bi bi-caret-up-fill"
                          style={{
                            color: "var(--elevvabutonLogin_User)",
                          }}
                        ></i>
                      ) : (
                        <i
                          style={{
                            color: "var(--elevvabutonLogin_User)",
                          }}
                          className="bi bi-caret-down-fill"
                        ></i>
                      )
                    ) : null}{" "}
                    Masa muscular:{" "}
                    {Array.isArray(piernade)
                      ? dejarsolonumber(piernade[0].toFixed(1)) + "Kg"
                      : piernade.toFixed(1) + "Kg"}
                  </span>
                </div>
              </div>
              <div
                className="position-absolute"
                style={{
                  top: "70%",
                  right: "10%",
                  width: "30%",
                  textAlign: "right",
                }}
              >
                <span
                  className="letraBodyInside "
                  style={{ color: "var(--elevvabutonLogin_User)" }}
                >
                  Pierna izquierda
                </span>
                <div style={{ lineHeight: "1.5vmin" }}>
                  {" "}
                  <span className="letraBodyInside ">
                    {/* Grasa corporal: 5,8% */}
                    Grasa corporal:
                    {Array.isArray(piernaizgrasa)
                      ? dejarsolonumber(piernaizgrasa[0].toFixed(1)) + "%"
                      : piernaizgrasa.toFixed(1) + "%"}
                    {Array.isArray(piernaizgrasa) ? (
                      piernaizgrasa[0] >= 0 ? (
                        <i
                          className="bi bi-caret-up-fill"
                          style={{
                            color: "var(--elevvabutonLogin_User)",
                          }}
                        ></i>
                      ) : (
                        <i
                          style={{
                            color: "var(--elevvabutonLogin_User)",
                          }}
                          className="bi bi-caret-down-fill"
                        ></i>
                      )
                    ) : null}
                  </span>
                  <br />
                  <span className="letraBodyInside ">
                    {/* Masa muscular: 37 kg{" "} */}
                    Masa muscular:
                    {Array.isArray(piernaiz)
                      ? dejarsolonumber(piernaiz[0].toFixed(1)) + "Kg"
                      : piernaiz.toFixed(1) + "Kg"}
                    {Array.isArray(piernaiz) ? (
                      piernaiz[0] >= 0 ? (
                        <i className="bi bi-caret-up-fill"></i>
                      ) : (
                        <i className="bi bi-caret-down-fill"></i>
                      )
                    ) : null}{" "}
                  </span>
                </div>
              </div>
              <span
                className="position-absolute linebodyInicio"
                style={{ top: "45%", left: "10%", width: "38%" }}
              >
                <hr />
                <span
                  className="rounded-circle position-absolute "
                  style={{
                    background: "var(--elevvaTexttitleLow)",
                    width: "8px",
                    height: "8px",
                    top: "40%",
                    left: "100%",
                  }}
                ></span>
              </span>
              <span
                className="position-absolute linebodyInicio"
                style={{
                  top: "32%",
                  right: "10%",
                  width: "30%",
                  transform: "rotate(-180deg)",
                }}
              >
                <hr />
                <span
                  className="rounded-circle position-absolute "
                  style={{
                    background: "var(--elevvaTexttitleLow)",
                    width: "8px",
                    height: "8px",
                    top: "40%",
                    left: "100%",
                  }}
                ></span>
              </span>
              <span
                className="position-absolute linebodyInicio"
                style={{ top: "66%", left: "10%", width: "35%" }}
              >
                <hr />
                <span
                  className="rounded-circle position-absolute"
                  style={{
                    background: "var(--elevvaTexttitleLow)",
                    width: "8px",
                    height: "8px",
                    top: "40%",
                    left: "100%",
                  }}
                ></span>
              </span>
              <span
                className="position-absolute linebodyInicio"
                style={{
                  top: "66%",
                  right: "10%",
                  width: "35%",
                  transform: "rotate(-180deg)",
                }}
              >
                <hr />
                <span
                  className="rounded-circle position-absolute"
                  style={{
                    background: "var(--elevvaTexttitleLow)",
                    width: "8px",
                    height: "8px",
                    top: "40%",
                    left: "100%",
                  }}
                ></span>
              </span>
            </>
          </div>
        </div>{" "}
        <div
          className="d-flex flex-column"
          style={{
            width: "20%",
            height: "calc(100vh - 60px - calc(.52rem + .53vw))",
            rowGap: "calc(.3rem + .3vw)",
          }}
        >
          <div
            style={{
              width: "100%",
              border: "1px solid #B6C1CE",
              borderRadius: "10px",
              height: "20%",
            }}
          >
            <HabitosCorporativo
              habitos={habitoscarga}
              heightMap={"17vmin"}
              idIdentificar={"nextBookingPanel"}
              handleClick={IdCustomer ? ShowHabitos : ShowNoData}
              handleClickVer={(e) => navigate(`/misclases/${e}`)}
            />
          </div>{" "}
          <div
            className="position-relative d-flex"
            style={{
              background: "white",
              width: "100%",
              border: "1px solid #B6C1CE",
              borderRadius: "10px",

              flexDirection: "column",
              textAlign: "center",
              justifyContent: "center",
              flex: 1,
              alignItems: "center",
            }}
          >
            <span
              className="letraPequeña mb-1"
              style={{
                color: "var(--elevvaTextTitle)",
                fontSize: "calc(0.4rem + 0.4vw)",
              }}
            >
              Lesiones <strong>del atleta</strong>
            </span>

            <span
              className="letraPequeña-Perfil text-center"
              style={{
                color: "var(--elevvaTextTitle)",
                fontSize: "calc(0.4rem + 0.3vw)",
                lineHeight: "1.1",
                color: "#9DACBD",
              }}
            >
              Acá podrá ver <br></br>
              las lesiones de su atleta
            </span>
            {infoLesiones && infoLesiones.length > 0 ? (
              <Lesiones
                modalesDolores={modalesDolores}
                modalesDoloresBack={modalesDoloresBack}
                elevvaT={elevvaT}
                frontalView={frontalView}
                backView={backView}
                nameFigure={"figurePartsPerfilNew"}
                classMargin={"m-0"}
                // updateLesion={changeData}
                infoLesiones={infoLesiones && infoLesiones}
              />
            ) : (
              <div className={"position-relative display-block m-0"}>
                <div
                  className={`containerCuerpo col-12 `}
                  style={{ display: "block" }}
                >
                  <img
                    className={`figurePartsPerfilNew`}
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/img/onboarding/FRENTE.png"
                    }
                  />
                </div>
              </div>
            )}
            {infoLesiones && infoLesiones.length > 0 ? (
              <div
                style={{
                  position: "absolute",
                  bottom: "30%",
                  right: "15%",
                  transform: "translateX(65%)",
                }}
              >
                <button
                  title="Girar"
                  style={{
                    background: "var(--elevvabutonLogin_User)",
                    borderRadius: "50%",
                    border: "1px solid var(--elevvabutonLogin_User)",
                    color: "#ffff",
                  }}
                  onClick={() =>
                    buttonChange == "Ver vista frontal"
                      ? changeView(frontalView)
                      : changeView(backView)
                  }
                >
                  <i className="bi bi-arrow-clockwise"></i>
                </button>
              </div>
            ) : null}
            <div className="m-0 w-100">
              <button
                className="mx-auto d-flex ver_mas"
                onClick={
                  infoLesiones && infoLesiones.length > 0
                    ? ShowInjuries
                    : ShowNoData
                }
              >
                Ver más
              </button>
            </div>
          </div>
        </div>
        <div
          className="pt-3 text-center"
          style={{
            width: "30%",
            border: "1px solid #B6C1CE",
            borderRadius: "10px",
            maxWidth: laptop1350.matches ? "auto" : "18rem",
          }}
        >
          <span
            className="text-center"
            style={{
              fontSize: "calc(0.4rem + 0.4vw)",
              color: "var(--elevvaTextTitle)",
            }}
          >
            {" "}
            <strong>Plan de entrenamiento</strong>
          </span>

          {showTrainingPlan ? (
            <button
              style={{
                padding: "1% 7%",
                background: "var(--elevvabutonLogin_User)",
                border: "1px solid var(--elevvabutonLogin_User)",
              }}
              className="mx-auto d-flex ver_mas my-1"
              onClick={editTraining}
            >
              Modificar Sesión
            </button>
          ) : null}
          <div
            className="col-12 d-flex"
            style={{
              justifyContent: "center",
              maxWidth: laptop1350.matches ? "30rem" : "auto",
            }}
          >
            {showTrainingPlan}
            {ModalEditExcersise}
            {ModalEditExcersiseday}
          </div>
        </div>
      </div>
      {ModalComments}
      {modalInfoLesiones}
      {modalInfo}
      {modal}
      {modalFilters}
    </div>
  );
};
export default Dashboard;
