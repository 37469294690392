import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { instanceMob, laptopMax1350 } from "./utils";
import { ReactComponent as SearchInvidual } from "../assets/elevva-pro-icons/search-individual-table.svg";
import { ReactComponent as ArrowLeft } from "../assets/elevva-pro-icons/table-arrow-left.svg";
import { ReactComponent as ArrowRight } from "../assets/elevva-pro-icons/table-arrow-right.svg";
import { ReactComponent as EditIcon } from "../assets/elevva-pro-icons/edit-icon.svg";
import { ReactComponent as DeleteTag } from "../assets/elevva-pro-icons/delete-tag.svg";

import moment from "moment";

const TableCustomPlans = (props) => {
  const plans = props.plans;
  const setPlanSelected = props.setPlanSelected;
  const page = props.page;
  const count = props.count;
  const changePage = props.changePage;

  const deletePlan = props.deletePlan;
  const editPlan = props.editPlan;
  const detailPlan = props.detailPlan;
  function getPlanDetail(id) {
    return instanceMob
      .get(`admin/plan/${id}`)
      .then((res) => res.data)
      .catch((err) => {
        console.error(err);
        return null; // Opcional: Retornar null en caso de error
      });
  }

  return (
    <div className={laptopMax1350.matches ? "p-1" : "p-3"}>
      <div style={{ width: "100%" }}>
        <PerfectScrollbar
          className="p-3"
          style={{
            maxHeight: "calc(75vh - 10rem)",
            overflowY: "scroll",
          }}
        >
          <table style={{ width: "100%" }}>
            <thead
              className="size-table-users"
              style={{
                width: "100%",
                overflow: "scroll",
                position: "sticky",
                top: "0",
                backgroundColor: "white",
              }}
            >
              <tr>
                <th
                  style={{
                    fontWeight: "normal",
                    width: "80%",
                  }}
                >
                  <div className="d-flex gap-2 align-items-center ">
                    {" "}
                    <span className="size-table-users">Nombre</span>{" "}
                  </div>
                </th>
                {/* <th
                  style={{
                    fontWeight: "normal",
                    width: "15%",
                  }}
                >
                  Precio
                </th>
                <th
                  style={{
                    fontWeight: "normal",
                    width: "15%",
                  }}
                >
                  Accesos
                </th>
                <th
                  style={{
                    fontWeight: "normal",
                    width: "30%",
                  }}
                >
                  Servicios
                </th>
                <th
                  style={{
                    fontWeight: "normal",
                    width: "10%",
                  }}
                >
                  Duración
                </th> */}
              </tr>
            </thead>
            <tbody className="w-100">
              {plans.length > 0 &&
                plans.map((plan) => (
                  <tr
                    key={plan.id}
                    className="size-table-users"
                    style={{
                      borderBottom: "1px solid #A8C2D7",
                      color: "#394F65",
                    }}
                  >
                    <td
                      style={{ width: "80%" }}
                      className="flex gap-2 align-items-center justify-content-center py-3"
                    >
                      <div className="d-flex gap-2 align-items-center ">
                        <div className="size-table-users">{plan.name}</div>
                      </div>
                    </td>
                    {/* <td style={{ width: "15%" }}>{plan.price}</td> */}
                    {/* <td style={{ width: "20%" }}>
                      {plan.services.length > 0
                        ? plan.services.map((e) => (
                            <>
                              {e.quota == -1
                                ? "Ilimitados"
                                : e.quota > 0
                                ? e.quota
                                : "0"}{" "}
                              <br />
                            </>
                          ))
                        : "----"}
                    </td> */}
                    {/* <td style={{ width: "30%" }}>
                      <div className="d-flex align-items-center gap-2">
                        <div style={{ lineHeight: "1" }}>
                          <span className="size-table-users">
                            {plan.services.length > 0
                              ? plan.services.map((e) => (
                                  <>
                                    {e.service_name} <br />
                                  </>
                                ))
                              : "----"}
                          </span>
                        </div>
                      </div>
                    </td> */}
                    {/* <td style={{ width: "5%" }}> {"----"}</td> */}
                    <td style={{ width: "20%", textAlign: "right" }}>
                      <div className="d-flex gap-2">
                        <div>
                          <SearchInvidual
                            width={"1rem"}
                            height={"1rem"}
                            onClick={() => detailPlan(plan)}
                          />
                        </div>
                        <div>
                          <EditIcon
                            onClick={async () => {
                              let planDetail = await getPlanDetail(plan.id);
                              editPlan({
                                ...planDetail,
                                services: plan.services,
                              });
                            }}
                            className="cursor-pointer"
                            width={"1rem"}
                            height={"1rem"}
                          />
                        </div>
                        <div>
                          {" "}
                          <DeleteTag
                            onClick={() => deletePlan(plan)}
                            className="cursor-pointer"
                            width={"1rem"}
                            height={"1rem"}
                          />
                        </div>
                      </div>
                      {/* <button
                        onClick={() => setUserDetail(user)}
                        className="pe-3"
                        style={{
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <SearchInvidual />
                      </button> */}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </PerfectScrollbar>
        <div className="d-flex justify-content-end align-items-center gap-3 mt-2">
          <span>
            {page * 10 - 10 === 0 || count >= 10
              ? page === 1
                ? 1
                : count
              : page * 10 - 10}
            - {page * 10 >= count ? count : page * 10} de {count}
          </span>
          <div
            onClick={count <= 10 ? null : () => changePage("previous")}
            className="cursor-pointer"
          >
            <ArrowLeft />
          </div>
          <div
            onClick={page * 10 >= count ? null : () => changePage("next")}
            className="cursor-pointer"
          >
            <ArrowRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableCustomPlans;
