import { laptop1600, urlImages } from "../../../utils/utils";

const UserDetailCompare = (props) => {
  const userDetail = props.userDetail;
  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column align-items-center gap-3">
        <div className="d-flex flex-column align-items-center ">
          {" "}
          <img
            className="ms-2 me-1"
            style={{
              objectFit: "cover",
              width: laptop1600.matches ? "70px" : "55px",
              maxHeight: "100%",
              maxWidth: "100%",
              height: laptop1600.matches ? "70px" : "55px",
              borderRadius: "50%",
            }}
            src={
              userDetail && userDetail?.profile_pic.includes("https")
                ? userDetail?.profile_pic
                : `${urlImages}${userDetail?.profile_pic}` ||
                  "/assets/logo-supremacy.png"
            }
          ></img>{" "}
        </div>
        <div className="d-flex flex-column text-center size-table-users">
          <span style={{ color: "#394F65" }}>
            <strong>{userDetail?.full_name}</strong>
          </span>
          <span style={{ color: "#394F65" }} className="letras-button-users">
            {userDetail?.membership_name}
          </span>
        </div>
      </div>
    </div>
  );
};
export default UserDetailCompare;
