import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Graphics } from "../../assets/elevva-pro-icons/graphics.svg";
import { ReactComponent as House } from "../../assets/elevva-pro-icons/house.svg";
import { ReactComponent as Message } from "../../assets/elevva-pro-icons/Message.svg";
import { ReactComponent as PersonalReview } from "../../assets/elevva-pro-icons/personalReview.svg";
import { ReactComponent as SignOut } from "../../assets/elevva-pro-icons/signOut.svg";
import { ReactComponent as Mob } from "../../assets/elevva-pro-icons/Mob.svg";
import {
  checkPathNavFoot,
  checkPathNavFootMobile,
  perlo,
} from "../../utils/utils.js";
export const Allitems = (props) => {
  const navigate = useNavigate();
  const handlePasswordUpdate = props.handlePasswordUpdate;
  const typeCustomer = props.typeCustomer || null;
  const location = useLocation();

  const Barra = [
    ["Admin", <House width={"100%"} />, useRef(null), useRef(null), "/admin"],
    ["Mob", <Mob width={"100%"} />, useRef(null), useRef(null), "/mob"],
    [
      "Graficas",
      <Graphics width={"100%"} />,
      useRef(null),
      useRef(null),
      "/graphics",
    ],
    [
      "PersonalReview",
      <PersonalReview width={"100%"} />,
      useRef(null),
      useRef(null),
      "/Dashboard",
    ],
    [
      "Message",
      <Message width={"100%"} />,
      useRef(null),
      useRef(null),
      "/notifications",
    ],

    [
      "Cerrar sesión",
      <SignOut width={"100%"} />,
      useRef(null),
      useRef(null),
      "out",
    ],
  ];
  useEffect(() => {
    if (!checkPathNavFoot(location.pathname, typeCustomer)) {
      console.log(location.pathname, "999999999");
      var activeme = Barra.forEach((e) => {
        if (e[4] == location.pathname) {
          e[2].current.classList =
            "d-flex option-page fw-bold  paddingNav_list active";
          e[2].current.active = true;
        } else {
          e[2].current.classList =
            "d-flex option-page fw-bold  paddingNav_list ";
          e[2].current.active = false;
        }
      });
    }
    if (!checkPathNavFootMobile(location.pathname, typeCustomer)) {
      var activeme = Barra.forEach((e) => {
        if (e[4] == location.pathname) {
          e[2].current.classList =
            "d-flex option-page fw-bold  paddingNav_list active";
          e[2].current.active = true;
        } else {
          e[2].current.classList =
            "d-flex option-page fw-bold  paddingNav_list ";
          e[2].current.active = false;
        }
      });
    }
  }, [location]);
  const selectOption = (
    refContainer,
    index,
    classess,
    arr,
    onlyone,
    pathHere
  ) => {
    if (onlyone === true) {
      for (const iterator of arr) {
        iterator[2].current.classList = classess;
        iterator[2].current.active = false;
        iterator[3] = false;
      }
    }

    if (refContainer.current.active === true) {
      refContainer.current.classList = classess;
      refContainer.current.active = false;
    } else {
      refContainer.current.classList = classess + " active";
      refContainer.current.active = true;
    }
    if (pathHere == "out") {
      perlo();
    } else if (pathHere == "changePassword") {
      handlePasswordUpdate();
    } else {
      return navigate(pathHere);
    }
  };

  return (
    <div
      className=" w-100 d-flex"
      style={{
        height: "auto",
        flexDirection: "column",
        gap: "20px",
        marginTop: "30px",
      }}
    >
      {Barra.map((elementos, index) => (
        <div
          data-bs-dismiss={""}
          key={index}
          id={`Barra` + index}
          ref={elementos[2]}
          style={{
            fontWeight: "600",

            justifyContent: "center",
          }}
          className="d-flex option-page"
          onClick={() =>
            selectOption(
              elementos[2],
              index,
              "d-flex option-page fw-bold paddingNav_list",
              Barra,
              true,
              elementos[4]
            )
          }
        >
          <div className=" d-flex">{elementos[1]} </div>
        </div>
      ))}
    </div>
  );
};
