import CardService from "./card-services";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import { Navigation } from "swiper";
import { laptop1600, Loadingspinner } from "../../../utils/utils";
import { ReactComponent as SearchIconAlone } from "../../../assets/elevva-pro-icons/searchIconAlone.svg";
import "swiper/swiper.min.css";
import debounce from "../functions/debounce";
const Services = (props) => {
  const modalServiceDetail = props.modalServiceDetail;
  const services = props.services;

  const addService = props.addService;
  const isLoading = props.isLoading;
  const deleteService = props.deleteService;
  const searchServices = props.searchServices;
  const editService = props.editService;
  const showSwiperLength = laptop1600.matches ? 6 : 3;
  return (
    <div className="d-flex justify-content-center h-100 align-items-center flex-column">
      <div
        className="d-flex w-100"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem 1rem 0rem 1rem",
        }}
      >
        <div
          className="py-0"
          style={{ marginLeft: laptop1600.matches ? "40%" : "25%" }}
        >
          <span
            style={{
              fontSize: "calc(.4rem + .4vw)",
              color: "var(--elevvaTextTitle)",
            }}
          >
            Panel de <strong>servicios</strong>{" "}
          </span>
        </div>
        <div className="d-flex ">
          <div
            className="mx-3"
            style={{ position: "relative", marginLeft: "auto" }}
          >
            <input
              onChange={debounce(searchServices, 500)}
              placeholder="Ver servicios"
              type="search"
              className="searchAtletas  py-1 w-100"
              style={{
                border: "1px solid var(--elevvaTextSubtitle)",
                borderRadius: "20px",
                fontSize: "calc(.4rem + .4vw)",
              }}
            ></input>
            <span className="search-icon-admin">
              <SearchIconAlone fontSize={10} />
            </span>
          </div>
        </div>
        <button
          style={{ marginRight: "2%" }}
          className="  d-flex add_location"
          onClick={addService}
        >
          Agregar servicio +
        </button>
      </div>
      {isLoading ? (
        <Loadingspinner customStyle="grow" size="2.5rem" />
      ) : (
        <div
          className="position-relative  h-100"
          style={{
            width: "95%",
            overflow: "hidden",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className={
              "d-flex position-absolute  w-100 h-100 swiper-pagination_p"
            }
          >
            <div
              style={{ zIndex: 10 }}
              className={`button-prev-exercise-${`service`} swiper-button-prev-exercise`}
            ></div>
            <div
              className={`button-next-exercise-${`service`} swiper-button-next-exercise`}
              style={{
                zIndex: 10,
                left: laptop1600.matches ? "90%" : "88%",
                transform: "translateX(-50%)",
              }}
            ></div>
          </div>
          <Swiper
            style={{ width: "80%", padding: "1rem 0" }}
            id={`swiper-service`}
            keyboard={{
              enabled: true,
            }}
            slidesPerView={"auto"}
            navigation={{
              nextEl: `.button-next-exercise-${`service`}`,
              prevEl: `.button-prev-exercise-${`service`}`,
            }}
            modules={[Navigation]}
          >
            {Array.from(
              {
                length: laptop1600.matches
                  ? Math.ceil(services?.length / 6)
                  : Math.ceil(services?.length),
              },
              (_, i) => i + 1
            ).map((item, index) => (
              <SwiperSlide
                key={`slide-${index}`}
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3,1fr)",
                  gridTemplateRows: laptop1600.matches
                    ? "repeat(2,1fr)"
                    : "repeat(1,1fr)",
                  gap: " 0.5rem",
                }}
                className=" position-relative w-auto mx-2"
              >
                {services?.length > 0 &&
                  services
                    .slice(
                      index * showSwiperLength,
                      (index + 1) * showSwiperLength
                    )
                    .map((service, index) => (
                      <CardService
                        key={`service-${service.id}`}
                        step={1}
                        modalServiceDetail={modalServiceDetail}
                        service={service}
                        deleteService={deleteService}
                        editService={editService}
                      />
                    ))}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  );
};

export default Services;
