import React from "react";
import { ButtonMob } from "../../../utils/utils";

const ModalCustom = ({ onCancel, onConfirm, children, loader }) => {
  const styleModal = {
    display: "flex",
    position: "fixed",
    zIndex: 999,
    whiteSpace: "pre-line",
  };

  return (
    <div
      className="popUpServices justify-content-center align-items-center"
      style={styleModal}
    >
      <div
        className="modalInfoContenido"
        style={{
          background: "white",
          borderRadius: "20px",
          boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
          padding: "6% 10%",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "5%",
            right: "6%",
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={onCancel}
        >
          <i className="bi bi-x-circle"></i>
        </div>

        <div
          className="pt-3 my-4 mx-auto"
          style={{
            lineHeight: "100%",
            textAlign: "center",

            color: "var(--elevvabutonLogin_User)",
          }}
        >
          {children} {/* Aquí se renderizan los children */}
        </div>

        <ButtonMob
          handleClick={onConfirm}
          colorButton={"var(--elevvabutonLogin_User)"}
          text={loader ? "Cargando..." : "Confirmar"}
          className={"mb-3 mt-3 letraspopfi2actualizar"}
        />
      </div>
    </div>
  );
};

export default ModalCustom;
