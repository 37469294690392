import { useEffect, useState } from "react";
import { ReactComponent as SearchIconAlone } from "../../assets/elevva-pro-icons/searchIconAlone.svg";
import { DropdownList } from "react-widgets";
import { ReactComponent as ArrowDown } from "../../assets/elevva-pro-icons/arrowDown.svg";
import CardBookings from "./components/card-bookings";
import { ButtonMob, instanceMob, Loadingspinner } from "../../utils/utils";
import debounce from "./functions/debounce";
import ModalBookingUsers from "./components/bookingModalUser";
import ModalCustom from "./components/modal-custom";
import ConfirmDeletionModal from "./components/modal-delete";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

const MobBooking = () => {
  const [listBooking, setListBooking] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNext, setPageNext] = useState(null);
  const [pageBack, setPageBack] = useState(null);
  const [dataBackup, setDataBackup] = useState({});
  const [count, setCount] = useState(10);
  const [modal, setModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [modalDelete, setModalDelete] = useState(null);
  const [modalError, setModalError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loaderDelete, setLoaderDelete] = useState(false);
  console.log(users);
  console.log(listBooking);
  function getUsers() {
    instanceMob
      .get(`admin/membership`)
      .then((res) => res.data)
      .then((data) => {
        setUsers(data.results);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    if (localStorage.mob_api_key) {
      setIsLoading(true);
      instanceMob
        .get(`admin/availability/times`)
        .then((res) => res.data)
        .then((data) => {
          setListBooking(data.results);
          setCount(data.count);
          setPageNext(data.next);
          setPageBack(data.previous);
          setDataBackup(data);
          setIsLoading(false);
        })
        .catch((err) => console.error(err));
      getUsers();
    }
  }, []);

  function getListFilter(value) {
    instanceMob
      .get(`admin/availability/times?search=${value}`)
      .then((res) => res.data)
      .then((data) => {
        setListBooking(data.results);
        setPageNext(data.next);
        setPageBack(data.previous);
        setCount(data.count);
      })
      .catch((err) => console.error(err));
  }

  function searchBooking(e) {
    let valueLower = e.target.value.toLowerCase();
    if (valueLower == "") {
      setListBooking(dataBackup.results);
      setCount(dataBackup.count);
      setPageNext(dataBackup.next);
      setPageBack(dataBackup.previous);
    } else {
      getListFilter(valueLower);
    }
  }
  function post_user_booking(event, id, startTime) {
    instanceMob
      .post(`admin/booking/create`, {
        customer: Number(event.target[0].value),
        availability: id,
        start_time: [startTime],
      })
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        if (Object.keys(data.failed_bookings).length > 0) {
          setModalError(
            <ConfirmDeletionModal
              onCancel={() => setModalError(null)}
              onConfirm={() => setModalError(null)}
              loader={false}
            >
              {data.failed_bookings[0].error_message}
            </ConfirmDeletionModal>
          );
        } else {
          setModal(null);
        }
      })
      .catch((err) => {
        if (err.response) {
          setModalError(
            <ConfirmDeletionModal
              onCancel={() => setModalError(null)}
              onConfirm={() => setModalError(null)}
              loader={false}
            >
              {err.response.data}
            </ConfirmDeletionModal>
          );
        }
      });
  }
  function addUser(id, startTime) {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModal(
      <div
        className="popUpServices justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
            maxWidth: "40rem",
            minWidth: "35rem",
          }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "1%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => {
              setModal(null);
              setLoader(false);
            }}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div style={{ minWidth: "15rem" }}>
            <div className="d-flex justify-content-center my-3">
              Agregar <strong>asistente</strong>{" "}
            </div>
            <form
              id="add-user-form"
              onSubmit={(e) => {
                e.preventDefault();
                post_user_booking(e, id, startTime);
              }}
            >
              <div className="d-flex  w-100 " id="location-select">
                <DropdownList
                  required
                  style={{ width: "100%" }}
                  busy
                  busySpinner={<ArrowDown></ArrowDown>}
                  name="customer"
                  className={"input-style-elevva-graphics"}
                  id="optionSummary"
                  placeholder={"Seleccionar usuario"}
                  data={users.map((memb) => ({
                    id: memb.customer_id,
                    name: memb.customer_name,
                    value: memb.customer_id,
                  }))}
                  dataKey="id"
                  textField="name"
                  renderListItem={({ item }) => (
                    <div
                      key={item.id + "user"}
                      className="w-100 mb-1"
                      style={{ borderBottom: "1px solid #9DACBD" }}
                    >
                      <div>{item.name}</div>
                    </div>
                  )}
                />
              </div>
              <div className="col-12">
                <div className="col-12 btnAction_pruebas letraspopfi2">
                  <ButtonMob
                    colorButton={"var(--elevvabutonLogin_User)"}
                    text={loader ? "Cargando..." : "Confirmar"}
                    className={"mb-3 mt-3 letraspopfi2actualizar"}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
  function modalServiceDetail(id, start_time) {
    setModal(
      <ModalBookingUsers
        id={id}
        startTime={start_time}
        onCancel={() => setModal(null)}
        onConfirm={addUser}
        cancelBooking={cancelBooking}
      />
    );
  }

  function cancelBooking(id, startTime) {
    const handleDelete = () => {
      setLoaderDelete(true);
      let formData = new FormData();
      formData.append("pk", id);
      instanceMob
        .post(`admin/booking/cancel`, formData)
        .then((res) => {
          if (res.status == "204" || res.status == "200") {
            setLoaderDelete(false);
            addUser(id, startTime);
          }
        })
        .catch((err) => {
          let msg_error = err.response.data;
          if (msg_error) {
            setModalDelete(null);
            setModalError(
              <ConfirmDeletionModal
                onCancel={() => setModalError(null)}
                onConfirm={() => setModalError(null)}
                loader={false}
              >
                {msg_error}
              </ConfirmDeletionModal>
            );
            console.log(msg_error);
          }
        });
    };

    setModalDelete(
      id ? (
        <ConfirmDeletionModal
          onCancel={() => setModalDelete(null)}
          onConfirm={handleDelete}
          loader={loaderDelete}
        >
          ¿Estas seguro de <br></br>
          <strong>eliminar este asistente? </strong>
        </ConfirmDeletionModal>
      ) : (
        <ConfirmDeletionModal
          onCancel={() => setModalDelete(null)}
          onConfirm={() => setModalDelete(null)}
          loader={false}
        >
          <br />
          Usuario
          <br />
          <strong> eliminado</strong>
          <br />
          <strong>correctamente.</strong>
          <br />
        </ConfirmDeletionModal>
      )
    );
  }
  useEffect(() => {
    modalDelete && cancelBooking();
  }, [loaderDelete]);

  return (
    <>
      {modal}
      {modalDelete}
      {modalError}
      <div className="w-100">
        <div className="d-flex w-100 justify-content-between align-items-center">
          <div className="py-0" style={{ marginLeft: "40%" }}>
            <span
              style={{
                fontSize: "calc(.4rem + .4vw)",
                color: "var(--elevvaTextTitle)",
              }}
            >
              Administración de <strong>cupos</strong>{" "}
            </span>
          </div>
          <div
            style={{ justifyContent: "flex-end" }}
            className="d-flex mx-3 my-2"
          >
            <div
              className="mx-3"
              style={{ position: "relative", marginLeft: "auto" }}
            >
              <input
                placeholder="Buscar reservas"
                type="search"
                className="searchUsers "
                style={{
                  border: "1px solid var(--elevvaTextSubtitle)",
                  borderRadius: "20px",
                  fontSize: "calc(.33rem + .32vw)",
                  padding: "0.4rem 1rem",
                }}
                onChange={debounce(searchBooking, 500)}
              ></input>
              <span className="search-icon-admin">
                <SearchIconAlone fontSize={10} />
              </span>
            </div>{" "}
          </div>
        </div>
        {isLoading ? (
          <Loadingspinner customStyle="grow" size="2.5rem" />
        ) : (
          <PerfectScrollbar
            style={{ maxHeight: "70vh" }}
            className="d-flex flex-wrap gap-3"
          >
            {listBooking.map(
              (booking) =>
                booking.schedules.length > 0 &&
                booking.schedules.map((schedule, index) => (
                  <CardBookings
                    booking={booking}
                    schedule={schedule}
                    key={booking.id + "-schedule-" + index}
                    modalServiceDetail={modalServiceDetail}
                  />
                ))
            )}
          </PerfectScrollbar>
        )}
      </div>
    </>
  );
};
export default MobBooking;
